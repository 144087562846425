import { useEffect, useState } from 'react';
import { PER_PAGE } from 'root/site/utils/constants';
import store from 'root/site/store';

export const useGetRecords = ({ model, options, tag, perPage = PER_PAGE, skip = 0, filter }) => {
	const [limit, setLimit] = useState(perPage);

	const [records, setRecords] = useState();
	useEffect(() => {
		tag && store.ui.switchTag(tag);
		let loading = true;
		getRecords()
			.then(res => {
				loading && setRecords(res);
			})
			.catch(err => {
				console.error('Fetch error', err);
			});
		return () => (loading = false);
	}, [limit, filter]);

	const getRecords = async () => {
		const findFilter = {
			...options,
			where: options ? { ...options.where, ...filter } : { ...filter },
			limit,
			skip,
		};

		if (!store.model[model]) throw new Error(`Model "${model}" does not exist!`);
		const records = await store.model[model].find(findFilter);
		return records;
	};

	return [records, limit, setLimit];
};
