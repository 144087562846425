import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import store from 'root/site/store';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import { Separator, ElementItem } from 'root/site/components';
import { dateAndTime } from 'root/site/utils/date-format';
import { Loader } from '@smartplatform/ui';
import { useShowCounter } from '../../utils/hooks';
import { VideoPreview, MediaPreview } from '../media-preview';
import BigArticle from './BigArticle';
import MidArticle from './MidArticle';
import { Badges } from './Badges';

const NoData = () => (
	<div style={{ textAlign: 'center', paddingBottom: 30 }}>
		<Loader />
		{/*<h4 align="center">Загрузка</h4>*/}
	</div>
);

const PlaceHolder = () => (
	<div className="news__box empty">
		<div className="news__img-wrap">
			<span className="empty" />
		</div>
		<div className="news__box-wrap">
			<h3 className="header-3" />

			<div className="main-news">
				<div className="d-flex flex-column justify-content-end">
					<div className="main-news__item" />
					{/*<div className="main-news__item"></div>*/}
				</div>
			</div>
		</div>
	</div>
);

const MainPageList = ({ records, tags, onTagSwitch, type, tag }) => {
	const showCounter = useShowCounter();
	const [showPhoto, setShowPhoto] = useState();
	const [showVideo, setShowVideo] = useState();

	const getItemLink = (item, children) => {
		switch (item.MODEL.INFO.name) {
			case `Article`:
				return <Link to={`/news/${item.id}`}>{children || <h3 className="header-3">{item.name}</h3>}</Link>;
			case `Gallery`:
				return <a onClick={() => setShowPhoto(item)}>{children || <h3 className="header-3">{item.name}</h3>}</a>;
			case `Media`:
				return <a onClick={() => setShowVideo(item)}>{children || <h3 className="header-3">{item.name}</h3>}</a>;
		}
	};

	const button = (
		<button className="btn" onClick={() => store.history.push('/news')}>
			Все новости
		</button>
	);

	let _records = records;
	if (records) {
		if (records.length > 0 && records.length < 10) {
			_records = [...records, ...new Array(10 - records.length)];
		}
	}

	return (
		<>
			{type === 'news' ? (
				<div className="sep-white">
					<Separator tags={[{ name: 'Все новости', id: 'all' }, ...(tags || [])]} onTagSwitch={onTagSwitch} />
				</div>
			) : (
				<div className="sep-placeholder" />
			)}
			<div className="container">
				<div className="row">
					{/* верска для desktop */}
					{/* попапы */}
					{!showPhoto && showVideo && (
						<VideoPreview
							record={showVideo}
							onClose={() => {
								history.pushState({}, ``, `/`);
								setShowVideo();
							}}
							onClick={history.pushState({}, ``, `/videogallery/${showVideo.id}`)}
						/>
					)}
					{!showVideo && showPhoto && (
						<MediaPreview
							galleryId={showPhoto.id}
							onClose={() => {
								history.pushState({}, ``, `/`);
								setShowPhoto();
							}}
							onClick={history.pushState({}, ``, `/photogallery/${showPhoto.id}`)}
						/>
					)}
					{/* --- */}
					<MediaQuery minWidth={1223}>
						{!_records ? (
							<>
								<div className="d-flex justify-content-around news-wrap">
									<BigArticle />
									<PlaceHolder />
									<PlaceHolder />
								</div>
								<div className="d-flex justify-content-around news-wrap">
									{[...new Array(5)].map((v, i) => (
										<PlaceHolder key={i} />
									))}
								</div>
							</>
						) : _records.length === 0 ? (
							<div className="d-flex justify-content-around news-wrap">
								<NoData />
							</div>
						) : tag === 'all' ? (
							//* вкладка всех новостей с компонентом новостью дня*
							<>
								<div className="d-flex justify-content-around news-wrap">
									<BigArticle article={records[0]} getItemLink={getItemLink} showCounter={showCounter} />
									<MidArticle article={records[1]} getItemLink={getItemLink} showCounter={showCounter} />
									<MidArticle article={records[2]} getItemLink={getItemLink} showCounter={showCounter} />
								</div>
								<div className="d-flex justify-content-around news-wrap">
									{records.slice(3, 8).map((article, i) => (
										<MidArticle article={article} key={i} getItemLink={getItemLink} showCounter={showCounter} />
									))}
								</div>
							</>
						) : (
							//* остальные стандартные вкладки
							<>
								{[...new Array(2)].map((v, i) => (
									<div key={i} className="d-flex justify-content-around news-wrap">
										{_records.slice(i * 5, i * 5 + 5).map((article, i) => (
											<MidArticle article={article} key={i} getItemLink={getItemLink} showCounter={showCounter} />
										))}
									</div>
								))}
							</>
						)}
					</MediaQuery>

					{/* верска для планшета */}
					<MediaQuery maxWidth={1223} minWidth={768}>
						<div className="col-6">
							<div className="direct-speech__main">
								{records && records.length > 0 ? (
									<>
										<div className="direct-speech__main-wrap-img-big">
											<div className="badges">
												<Badges article={records[0]} width={150} />
											</div>
											<img
												alt=""
												src={
													records[0].imported
														? `/api/containers/articles/download/${records[0].image}`
														: `${records[0].downloadFile('thumbnail')}?image=[{"resize":{"width":480}}]`
												}
												className="direct-speech__main-img"
											/>
										</div>
										<div className="direct-speech__info">
											{getItemLink(records[0])}
											<ElementItem grey small text={dateAndTime(records[0].publishAt)} icon={calendar} top="2px" />
											{showCounter && (
												<ElementItem
													grey
													small
													text={records[0].views && records[0].views().totalCount}
													icon={eye}
													top="2px"
												/>
											)}
										</div>
									</>
								) : (
									<NoData />
								)}
							</div>
						</div>
						<div className="col-6">
							{records &&
								records.length > 1 &&
								records.slice(1, 3).map(article => {
									const { id, name, publishAt } = article;
									const thumbnail = article.downloadFile('thumbnail');
									const views = article.views() && article.views().totalCount;

									return (
										<div key={`${article.MODEL.INFO.name}-${id}`} className="direct-speech__main ">
											<div className="direct-speech__info">
												{getItemLink(article)}
												<ElementItem grey small text={dateAndTime(publishAt)} icon={calendar} top="2px" />
												{showCounter && <ElementItem grey small text={views} icon={eye} top="2px" />}
											</div>
										</div>
									);
								})}
						</div>

						{records &&
							records.length > 3 &&
							records.slice(3, 5).map(article => {
								const { id, name, publishAt } = article;
								const thumbnail = article.downloadFile('thumbnail');
								const views = article.views() && article.views().totalCount;
								return (
									<div key={`${article.MODEL.INFO.name}-${id}`} className="col-6 pt-5">
										<div className="direct-speech__main">
											<div className="direct-speech__side-item-wrap-img">
											<div className="badges">
													<Badges article={article} width={120} />
											</div>
												
												<img
													alt=""
													src={
														article.imported
															? `/api/containers/articles/download/${article.image}`
															: `${thumbnail}?image=[{"resize":{"width":300}}]`
													}
													className="direct-speech__main-img"
												/>
											</div>
											<div className="direct-speech__info">
												{getItemLink(article)}
												<ElementItem grey small text={dateAndTime(publishAt)} icon={calendar} top="2px" />
												{showCounter && <ElementItem grey small text={views} icon={eye} top="2px" />}
											</div>
										</div>
									</div>
								);
							})}
					</MediaQuery>
					{/* верска для мобильного телефона */}
					<MediaQuery maxWidth={767}>
						<div className="no-gutter" style={{ width: '100%' }}>
							{records && records.length > 0 ? (
								records.map((article,i) => {
									const { id, name, publishAt } = article;
									const thumbnail = article.downloadFile('thumbnail');
									const views = article.views() && article.views().totalCount;
									return (
										<div key={`${article.MODEL.INFO.name}-${id}`} className="direct-speech__main ">
											
											<div className="direct-speech__main-wrap-img">
											<div className="badges">
												{(i === 0) && <Badges article={article} width={150} />}
											</div>
												<img
													alt=""
													src={
														article.imported
															? `/api/containers/articles/download/${article.image}`
															: `${thumbnail}?image=[{"resize":{"width":300}}]`
													}
													className="direct-speech__main-img"
												/>
											</div>
											<div className="direct-speech__info">
												{getItemLink(article)}

												<ElementItem grey small text={dateAndTime(publishAt)} icon={calendar} top="2px" />
											</div>
										</div>
									);
								})
							) : (
								<NoData />
							)}
						</div>
						{type === 'news' && (
							<Link to="/news" className="btn-news-mobile">
								Все новости
							</Link>
						)}
					</MediaQuery>
				</div>
			</div>
		</>
	);
};

export default MainPageList;
