import { PLENARY, COMMITTEE, getCommitteeQuestions, getPlenaryQuestions } from '..';

export default async session => {
	if (session.type === COMMITTEE) {
		return await getCommitteeQuestions(session.committeeSessionId);
	}
	else if (session.type === PLENARY) {
		return await getPlenaryQuestions(session.plenarySessionId);
	}
	return [];
}
