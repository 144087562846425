import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

import { Tabs, Tab } from '../../components/route-tabs';
import store from '../../store';
import Single from './Single';
import './style.scss';

@observer
export default class Fractions extends React.Component {

	@observable fractions = [];
	@observable depConv = {};
	
	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Депутатские объединения', link: '/fractions' }]);
		this.init();
	}
	
	init = async () => {
		this.fractions = await store.model.Fraction.find({
			where: { existing: true },
			order: 'id asc',
		});
		
		console.log('fractions', this.fractions);
	};
	
	render() {
		return <div className="container fractions">
			<h1>Депутатские объединения</h1>
			
			<Switch>
				<Route path="/fractions" exact render={() => <Redirect to={`/fractions/1`} />}/>
			<Tabs>
				{this.fractions.map(fraction => (
					<Tab key={fraction.id} path={`/fractions/${fraction.id}`} title={fraction.shortName}><Single fraction={fraction}/></Tab>
				))}
			</Tabs>
			</Switch>
		</div>;
	}
	
}
