import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../button';
import { Loader } from '@smartplatform/ui';
import { getCurrentDeputies } from '../../../utils';
import { getRoleNames } from '../helpers';
import store from '../../../store';
import Protocol from './Protocol';
import './style.scss';

@observer
export default class SessionProtocol extends React.Component {
	@observable session = null;
	@observable stage = null;
	@observable allDeputies = [];
	@observable allMembers = [];

	@observable isLoading = true;
	@observable error = null;
	@observable redirect = false;

	constructor(props) {
		super(props);
		this.sessionId = parseInt(this.props.match.params.id);
		this.stageId = parseInt(this.props.match.params.questionId);
		this.init();
	}

	@action init = async () => {
		try {
			if (this.sessionId && this.stageId) {
				this.session = await store.model.Session.findById(this.sessionId, {
					include: ['chairman'],
				});
				this.stage = await store.model.ViewQuestionStage.findById(this.stageId, {
					include: ['question', 'status', 'type', 'votes'],
				});
				this.allDeputies = await getCurrentDeputies({ ignoreEndDate: true, sessionDate: this.session.registrationStart });
				this.allMembers = await store.model.SessionMember.find({
					where: { sessionId: this.sessionId },
					order: 'id desc',
				});
				this.allFractions = await store.model.Fraction.find({ include: ['deputies'] });
				this.allDepConvs = await store.model.DeputyConvocation.find();
			}

			let canView = false;
			let roleNames = [];

			if (this.stage.publish === 'all') {
				canView = true;
			} else if (store.model.user) {
				roleNames = await getRoleNames();
				if (roleNames.includes('admin') || roleNames.includes('protocolist') || roleNames.includes('staff')) {
					canView = true;
				} else if (roleNames.includes('deputy') && this.stage.publish === 'deputy') {
					canView = true;
				}
			}

			if (!canView) {
				store.debugLog(`QuestionProtocol > init: редирект, юзер не имеет права на просмотр протокола, roles: ${roleNames}`, 'info');
				this.redirect = true;
			}
		} catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	render() {
		if (this.redirect) return <Redirect to={`/active-session/${this.sessionId}`} />;
		if (this.isLoading)
			return (
				<div className="protocol-init">
					<Loader />
				</div>
			);
		if (this.error) return <div className="protocol-init error">Ошибка: {this.error}</div>;

		return (
			<div className="session-protocol">
				<div className="controls">
					<Button onClick={() => window.print()}>
						<FontAwesomeIcon icon={faPrint} /> Печать
					</Button>
				</div>
				<Protocol
					key={this.stage.id}
					session={this.session}
					stage={this.stage}
					allDeputies={this.allDeputies}
					allMembers={this.allMembers}
					allFractions={this.allFractions}
					allDepConvs={this.allDepConvs}
				/>
			</div>
		);
	}
}
