import React from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { getPlenaryQuestionData, getCommitteeQuestionData } from '../helpers';
import voting from '../store-site';
import './style.scss';

@observer
export default class QuestionContent extends React.Component {

	@observable name = '';
	@observable files = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.question.id !== this.props.question.id ||
			(prevProps.question.stage && this.props.question.stage &&  prevProps.question.stage.id !== this.props.question.stage.id)) {
			this.init();
		}
	}
	
	init = async () => {
		const { question } = this.props;
		// console.log('init', question, question.stage);
		this.name = question.order ? question.name : voting.getQuestionName(question);
		this.files = question.__files || [];
		this.isLoading = false;
	};
	
	showFiles = (e, file) => {
		e.preventDefault();
		// console.log('showFiles', toJS(file));
		voting.showFile(file);
	};
	
	render() {
		const { question } = this.props;

		return <div className="question-content">
			<div className="name">
				{this.name}
				{voting.currentQuestion && voting.currentQuestion.id === question.id && <em>{voting.getQuestionStageName(question)}</em>}
			</div>
			<ul className="files">
				{this.files.map(file => {
					if (!file.url && !file.pages) return null;
					let link = file.url ?
						<a target="_blank" href={file.url} title={file.name}>{file.name}</a>
						:
						<a href="#" onClick={e => this.showFiles(e, file)} title={file.name}>{file.name}</a>
					return <li key={file.id}>{link}</li>;
				})}
			</ul>
		</div>;
	}
	
}
