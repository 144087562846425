import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { formatDateStr } from '../../utils/dateUtils';
import store from '../../store';
import empty from '../../img/user.png';

@withRouter @observer
export default class DeputyCard extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		const { depConv, className } = this.props;
		
		const deputy = depConv.deputy;
		
		const photo = deputy.photo ? <img src={deputy.downloadFile('photo')} alt=""/> : <img src={empty} alt="" />;
		const end = depConv.endDate ? <div style={{fontWeight: 600}}>Полномочия досрочно сложены {formatDateStr(depConv.endDate, 'dd.MM.yyyy')}</div> : null;
		const division = <>
			{depConv.fraction && !depConv.division && <strong>{depConv.fraction.shortName}. </strong>}
			{
				depConv.divisionGroup ?
					depConv.divisionGroup.name
					:
					depConv.division ? `${depConv.division.name} №${depConv.division.number}` : (depConv.divisionText || '')
			}
		</>;

		return <div key={deputy.id} className={'deputy-card ' + (className || '')}>
			<div className="photo"><Link to={`/deputies/${deputy.id}`}>{photo}</Link></div>
			<div className="info">
				<div className="name"><Link to={`/deputies/${deputy.id}`}>{deputy.lastName} {deputy.firstName} {deputy.middleName}</Link></div>
				<div className="division">{division}{end}</div>
				{depConv.convocationId === store.currentConvocation.id && !depConv.endDate && <div className="links">
					<button className="white-btn" onClick={() => this.props.history.push(`/reception?deputy=${deputy.id}`)}>Интернет-приемная</button>
					{deputy.appointment && <button className="white-btn" onClick={() => this.props.history.push(`/appointments?deputy=${deputy.id}`)}>Запись на личный прием</button>}
					{/*<Link to={`/reception?deputy=${deputy.id}`}>Интернет-приемная</Link>*/}
					{/*{deputy.appointment && <Link to={`/appointments?deputy=${deputy.id}`}>Запись на личный прием</Link>}*/}
				</div>}
			</div>
		</div>;
	}
	
}
