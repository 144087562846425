import React from 'react';
import store from '../store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

const { ui, route } = store;
@observer
export class Separator extends React.Component {
	@observable currentTag;

	componentDidMount() {
		const cat = route.params && route.params.cat;
		this.currentTag = cat || (this.props.tags && this.props.tags[0].id);
	}

	handleClick = clickedTag => {
		if (this.currentTag == clickedTag) {
			return;
		}

		this.currentTag = clickedTag;
		this.props.onTagSwitch && this.props.onTagSwitch(clickedTag);
	};

	render() {
		const { tags } = this.props;
		return (
			<div className={`separator `}>
				<div className="container">
					<div className="separator__tags">
						<div className="separator__border"></div>
						{tags &&
							this.currentTag &&
							tags.map(el => {
								const { name, id } = el;
								return (
									<div
										key={`separator-${name}`}
										className={`separator__tags-item ${this.currentTag == id ? 'separator__tags-item-active' : ''}`}
										onClick={() => this.handleClick(id)}
									>
										{name}
									</div>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
}
