import React from 'react';
import { useMediaQuery } from 'react-responsive'
import {DropDownList} from './DropDownList';
import {TabsMobile} from './TabsMobile';
import ReactSVG from 'react-svg';
import ArrowTabs from 'root/site/img/ArrowTabs.svg';

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 767 })
	return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
  }
 


export class Tabs extends React.Component {
	constructor(props) {
	  super();
	  this.state = {
		active: 0,
		text : props.children[0]
	  }
	}
	
	select = (i,item) => {
	  let _this = this;
	  return function() {
		_this.setState({
		  active: i,
		  text: item
		});
	  }
	  
	  
	}

	
	renderTabs = () => {
	  return React.Children.map(this.props.children, (item, i) => {
		if (i%2 === 0) {
		  let active = this.state.active === i ? 'separator__tags-item-active' : '';
		  return (
			
		  <div onClick={this.select(i,item)} className={`separator__tags-item ${active} `}>{item}</div> 
		  
		
		  
		  
		  )} 
	  });
	}

	
	
	renderContent() {
	  return React.Children.map(this.props.children, (item, i) => {
		if (i-1 === this.state.active) {
		  return <>{item}</>;
		} else {
		  return;
		}
	  });
	}
	
	render() {
	  return (
		  <>
		  <Desktop>
		  <div className={`separator `}>
			<div className="container">
				<div className="separator__tags">
					<div className="separator__border"></div>
					
		  {this.renderTabs()}
		  
		</div>
		</div>
		</div>
	
		{this.renderContent()}
		</Desktop>
		<Mobile>
		<TabsMobile>
		<div className="accor-mobile">
            <div className="head">{this.state.text}<ReactSVG className="arrow__icon" src={ArrowTabs} /></div>
            <div className="tab-mobile__body">
			{this.renderTabs()}
			
			</div>
			</div>
			</TabsMobile>{this.renderContent()}</Mobile>
		</>
	  );
	}
  }

  