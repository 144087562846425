import React from 'react';
import './styles.scss';

import img from './img/imgnews2.jpg';




export const SimilarNewsFile = [
	{
		image: img,
		title: 'Петр Гоголев: Послание главы государства ориентировано на человека, на общество',
        date: '26 февраля 2019',
        time: '17:19',
        link: '#',
	},
	{
		image: img,
		title: 'Петр Гоголев: Послание главы государства ориентировано на человека, на общество',
        date: '26 февраля 2019',
        time: '17:19',
        link: '#',
    },
];

const SimilarNewsList2 = SimilarNewsFile.map((SimilarNewsFile, index) => (
	
	<div key={index} className="col-6">
		<div className="row">
			<div className="col-6">
			<div className="SimilarNews_img_slider">
			<img src={SimilarNewsFile.image}></img>
			</div>
			</div>
			<div className="col-6">
			<div className="SimilarNews_title mb-2">
		<a>	{SimilarNewsFile.title}</a>
		</div>
		<div className="SimilarNews_date mb-5">
			
			{SimilarNewsFile.date}, {SimilarNewsFile.time}
		</div>
			</div>
		</div>
		
	</div>
 ));


export default class SimilarNews extends React.Component {
	render() {
		return (
			<div className="background-page-light-grey ">
				<div className="container pb-5">

					<div className="row justify-content-center align-items-center mb-4 mt-3">
					<div className="col-12 name-page mb-4 mt-3">Похожие новости</div>
						{SimilarNewsList2}

					</div>
				</div>
			</div>
		);
	}
}
