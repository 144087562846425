import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import Results from './Results';
import VotingButtons from './VotingButtons';
import Requests from './speech-requests';
import { STATUS_INACTIVE, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED, STATUS_CALCULATING } from '../store-site';
import voting from '../store-site';

@observer
export default class Chairman extends React.Component {
	
	render() {
		const { question, status } = this.props;
		
		if (voting.session.end) {
			return <div className="deputy-panel">
				<Results useView question={question}/>
			</div>;
		}

		if (!voting.session.registrationStart) {
			return <div className="deputy-panel">Ожидание регистрации</div>
		}
		
		if (!voting.member) {
			return <div className="deputy-panel">Зарегистрируйтесь на голосование</div>
		}
		
		if (!voting.isQuorum) {
			return <div className="deputy-panel">Ожидание кворума</div>
		}
		
		let content;
		
		if (status === STATUS_CANCELED) {
			content = 'Голосование отменено';
		}
		else if (status === STATUS_DISCUSSED) {
			content = '';
		}
		else if (status === STATUS_VOTING) {
			content = <VotingButtons question={question} />;
		}
		else if (status === STATUS_CALCULATING) {
			content = <div>
				<Loader size={20} /> Идет подсчет голосов...
			</div>
		}
		else {
			content = <Results useView question={question}/>;
		}
		
		return <div className="deputy-panel">
			{content}
			{voting.isChairman && <Requests />}
		</div>;
	}
	
}
