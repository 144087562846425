import React from 'react';
import { useBreadcrumbs } from 'root/site/utils/hooks';
import { Tabs } from 'root/site/components';

import Publications  from './Publications';
import AnalyticalMaterials  from './AnalyticalMaterials';
import PublishingActivity  from './PublishingActivity';


const informationAnalyticalActivity = () => {
	useBreadcrumbs('Информационно-аналитическая деятельность', '/information-analytical-activity');
	return (
		<>
        <div className="background-page-light-grey">
			
			<div className="container">
				<div className="row pb-5 pt-5">
                <div className="col-12">
					<h2 className="header-2">Информационно-аналитическая деятельность</h2>
                </div>
				</div>
			</div> 
			<Tabs>
            
            Издания Государственно-правового управления <Publications />
            Аналитические материалы <AnalyticalMaterials />
            Издательская деятельность <PublishingActivity />
					
				</Tabs>
			</div> 
        </>
    );

};
export default informationAnalyticalActivity;
