import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';

import { Loader } from '@smartplatform/ui';
import { formatTime } from './index';
import { ImagesSlider } from '../../components/media-preview/MediaPreview';
import store from '../../store';

import 'react-image-lightbox/style.css';

@withRouter @observer
export default class Gallery extends React.Component {
	
	@observable isLoading = true;
	@observable gallery = null;
	@observable error = null;
	
	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([
			{ name: props.type === 'plenary' ? 'Пленарные заседания' : 'Заседания комитетов', link: `/sessions` },
			{ name: 'Заседание', link: `/sessions/${props.match.params.id}` },
			{ name: 'Фоторепортаж' },
		]);
		this.init();
	}
	
	init = async () => {
		this.id = this.props.match.params.id;
		try {
			const record = await store.model.PlenarySession.findById(this.id);
			this.gallery = await store.model.Gallery.findById(record.galleryId, { include: ['images'] });
		}
		catch(e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};
	
	render() {
		let content = <Loader/>;
		
		if (!this.isLoading) {
			content = <>
				<ImagesSlider images={this.gallery.images()} />
			</>;
		}
		
		return <div className="session-gallery">
			<h1>Фоторепортаж</h1>
			{content}
		</div>;
	}
	
}
