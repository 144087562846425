import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useBreadcrumbs, useSetRelationFilter, useSetRootPath } from '../../utils/hooks';
import store from '../../store';
import List from './List';
import Single from './Single';
import PrintVersion from './PrintVersion';

import './styles.scss';
import './single.scss';

const NewsPage = () => {
	const type = 'news';
	useSetRootPath('/news');
	useBreadcrumbs('Новости', '/news');
	useSetRelationFilter({
		where: { and: [{ type: 'news' }, { publish: true }, { publishAt: { lte: Date.now() } }] },
		// limit: 1,
	});

	useEffect(() => {
		return () => store.ui.setDateRange();
	}, []);

	return (
		<>
			<div style={{ minHeight: '80vh' }}>
				<Switch>
					{/* <Route exact path={`/news`} component={() => <List type={type} />} /> */}
					<Route exact path={'/news/'} component={() => <List type={type} />} />
					<Route exact path={'/news/:id'} component={() => <Single />} />
				</Switch>
			</div>
		</>
	);
};
export default NewsPage;
