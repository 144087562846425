import React from 'react';

import groupBy from 'lodash/groupBy';
import { Loader, HtmlValue } from '@smartplatform/ui';
import { cutEmptyParagraphs } from '../../utils';

import { Accordion, DropDownList } from 'root/site/components';
import { useGetRecord } from 'root/site/utils/hooks';

const SingleAward = ({ awardId }) => {
	const award = useGetRecord({
		model: 'Award',
		id: awardId,
		options: {
			include: ['years', { relation: 'information', scope: { order: 'name ASC' } }, { relation: 'recipients', scope: { include: ['year'], order: 'name ASC' } }],
		},
		setBreadcrumbs: false,
	});

	if (!award) {
		return <div className="container base-page"><Loader /></div>;
	}

	const information = award.information();
	const recipients = award.recipients();
	const groupedByYear = groupBy(recipients, 'year.name');

	return (
		<div className="row pb-5 pt-5">
			<div className="col-12 col-md-2">
				{/*<img className="award__img" src={award.importId ? `/api/containers/awards/download/${award.thumbnail}` : award.downloadFile('thumbnail')} width="100%" />*/}
				<img className="award__img" src={award.downloadFile('thumbnail')} width="100%" />
			</div>
			<div className="col-12 col-md-10">
				<div className="note-page-title mb-5">
					<h1>{award.name}</h1>
				</div>
				{award.importId && <Accordion title="Положение о награде">
					<HtmlValue value={cutEmptyParagraphs(award.content)} className="award-text"/>
				</Accordion>}
				{!award.importId && information.length > 0 &&
					information.map(info => (
						<Accordion
							key={`info-${info.id}`}
							title={info.name}
						>
							<HtmlValue value={info.text} className="information-award__text" />
						</Accordion>
					))}
				{recipients.length > 0 && (
					<Accordion title="Список награжденных">
						{Object.keys(groupedByYear).sort().reverse().map(year => (
							<Accordion key={`year-${year}`} title={`Награжденные в ${year} году`}>
								<ol className="text__ol_strong">
									{groupedByYear[year].map(person => (
										<li key={`person-${person.id}`}>
											<a href={person.pdf ? `/api/containers/awardrecipients/download/${person.pdf}` : person.order && person.downloadFile('order')} download>
												{person.name}
											</a>
										</li>
									))}
								</ol>
							</Accordion>
						))}
					</Accordion>
				)}
			</div>
		</div>
	);
};

export default SingleAward;
