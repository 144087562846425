import React from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import { dateAndTime } from 'root/site/utils/date-format';
import calendar from 'root/site/img/calendar.svg';

const SimilarNews = ({ articles }) => {
	if (!articles) {
		return (
			<div className="container base-page">
				<Loader />
			</div>
		);
	}
	return (
		<div className="background-page-light-grey ">
			<div className="container pb-5">
				<div className="row pb-4 pt-4">
					<div className="col-12 pb-4 pt-4">
						<h2 className="header-2">Похожие новости</h2>
					</div>
					{articles.map(article => {
						const { id, name, publishAt, thumbnail } = article;
						return (
							<div key={id} className="col-6">
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="similar-news__img">
											<img
												alt="news"
												src={
													article.imported
														? `/api/containers/articles/download/${article.image}`
														: article.downloadFile('thumbnail')
												}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="similar-news__title mb-2">
											<Link to={`/news/${id}`}> {name}</Link>
										</div>
										<div className="similar-news__date mb-5">
											<ReactSVG className="simple-icon simple-icon-dark simple-icon-small" src={calendar} />
											<span className="note-page__date-small">{dateAndTime(publishAt)}</span>
										</div>
									</div>
								</div>
							</div>
						);
					})}
					{articles.length < 2 && <div className="col-6" />}
				</div>
			</div>
		</div>
	);
};

export default SimilarNews;
