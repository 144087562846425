import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { groups, LAW_Y, LAW, DECREE, DECREE_Y } from './groups';

import store from '../../store';
import './style.scss';

export const formatDate = dateStr => (dateStr ? format(new Date(dateStr), 'dd.MM.yyyy') : '-');

const docStyle = {
	border: 'none',
	margin: '15px 0',
	width: '100%',
	height: '50vh',
};

@observer
export default class Law extends React.Component {
	@observable isLoading = true;
	@observable error = null;
	@observable committee = null;
	@observable passport = null;
	@observable file = null;
	@observable initiators = [];
	@observable linkedDocs = [];

	constructor(props) {
		super(props);
		this.record = props.record;
		this.init();
	}

	@action init = async () => {
		const committees = await store.model.Committee.find({ where: { externalId: this.record.duePerson } });
		this.committee = committees[0];

		const passports = await this.record.refLinks.find({
			fields: ['id', 'childId'],
			include: [
				{
					relation: 'child',
					scope: {
						include: [
							{
								relation: 'refLinks',
								scope: {
									where: { childId: { neq: null } },
									include: [
										{
											relation: 'child',
											scope: {
												include: [
													{
														relation: 'refFiles',
														scope: {
															include: ['docFile'],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		});
		this.passport = passports.length > 0 ? passports[0] : null;

		if (this.passport) {
			this.initiators = await store.model.RefCorresp.find({
				where: { documentId: this.passport.child.id },
				include: [{ relation: 'organization' }],
			});
		}
		// если мы на странице закона , то ищем постановление к нему
		if (this.props.law && this.passport) {
			console.log(this.passport);
			const passportDocs = this.passport.child?.refLinks()?.map(doc => doc.child) || [];
			const decreeMap = {
				[LAW_Y]: DECREE_Y,
				[LAW]: DECREE,
			};
			const decreeGroup = decreeMap[this.record.docGroup];
			this.linkedDocs = passportDocs.filter(doc => doc.docGroup === decreeGroup) || [];
		}

		this.isLoading = false;
	};

	docFiles = document =>
		document.refFiles()?.map(refFile => {
			const docFile = refFile.docFile;
			console.log('docFile', docFile);
			const url = window.location.origin + docFile.downloadFile('contents');
			return (
				<div className="doc-file" key={refFile.id}>
					<div>
						<a target="_blank" href={url}>
							{refFile.description}
						</a>
					</div>
					<iframe src={`https://docs.google.com/viewer?url=${url}&embedded=true`} style={docStyle} />
				</div>
			);
		});

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		return (
			<div className="law row">
				<div className="doc-files col-lg-6 col-md-6 col-sm-12 col-12">
					{this.docFiles(this.record)}
					{/* если мы на странице закона , то показываем постановление к нему	 */}
					{this.props.law && this.linkedDocs.length > 0 && this.linkedDocs.map(item => this.docFiles(item))}
				</div>
				<div className="passport-data col-lg-6 col-md-6 col-sm-12 col-12">
					<div className="name">
						<strong>{this.record.name}</strong>
					</div>
					<p>Паспортные данные</p>
					<div className="passport-table">
						{this.committee && (
							<div>
								<label>Ответственный комитет:</label>
								{this.committee.name}
							</div>
						)}
						{this.passport && (
							<div>
								<label>{groups[this.record.docGroup] === 'Закон' ? 'Проект закона:' : 'Проект постановления:'}</label>
								<Link to={`/documents/${this.passport.childId}`}>{`${this.passport.child.freeNum} от ${formatDate(
									this.passport.child.updatedAt
								)}`}</Link>
							</div>
						)}
						{this.initiators.length > 0 && (
							<div>
								<label>Инициаторы:</label>
								<ul>
									{this.initiators.map(initiator => {
										const name = initiator.organization.name.replace(/[-,]/, '').replace(/народный депутат.+/, '');
										return (
											<li key={initiator.id}>
												{initiator.organization ? (
													initiator.organization.deputyId ? (
														<Link to={`/deputies/${initiator.organization.deputyId}`}>{name}</Link>
													) : (
														name
													)
												) : null}
											</li>
										);
									})}
								</ul>
							</div>
						)}
						{this.record.baseLaw ? (
							<div>
								<label>Актуальный текст закона с учетом всех изменений:</label>
								<a href={window.location.origin + this.record.baseLaw.downloadFile('filename')}>
									{this.record.baseLaw.filename.slice(0, -4)}
								</a>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		);
	}
}
