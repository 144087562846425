import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const getExtension = fileName => {
	const re = /(?:\.([^.]+))?$/;
	return re.exec(fileName)[1];
};

class FileUpload {
	@observable progress = 0;
	
	constructor(file) {
		this.file = file;
		this.error = null;
	}
}

@observer
export default class Images extends React.Component {
	
	static propTypes = {
		record: PropTypes.object.isRequired,
		store: PropTypes.object.isRequired,
		accept: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
		relation: PropTypes.string,
		className: PropTypes.string,
		canUpload: PropTypes.bool,
	};
	
	
	static defaultProps = {
		canUpload: true,
		relation: 'attachments',
	};
	
	@observable attachments = [];
	@observable uploadingFiles = [];
	@observable rejectedFiles = [];
	@observable progress = null;
	@observable uploadingCount = 0;
	
	constructor(props) {
		super(props);
		this.store = props.store;
		this.record = props.record;
		this.init();
	}
	
	@action init = async () => {
		const relation = this.record[this.props.relation];
		if (relation) {
			this.attachments = await relation();
			this.attachments.forEach(attachment => attachment.__changed = false);
			console.log('* attachments', this.attachments);
		}
		else {
			console.warn('model', this.record.MODEL.INFO.name, 'does not have relation', this.props.relation);
		}
	};
	
	handleProgress(i, event) {
		// console.log(' --- event: ', event);
		this.uploadingFiles[i].progress = Math.round(event.percent);
	}
	
	handleEnd(i, error, result) {
		this.uploadingCount--;
		if (error) {
			console.warn('upload error:', error);
			this.uploadingFiles[i].error = error;
		}
		else {
			this.uploadingFiles[i] = null;
			this.init();
		}
	}
	
	onDrop = async (accepted, rejected) => {
		this.uploadingFiles = accepted.map(file => new FileUpload(file));
		this.rejectedFiles = rejected;
		this.uploadingCount = accepted.length;
		
		accepted.forEach((file, i) => {
			const attachment = new this.store.Attachment({
				filename: file.name,
			});
			attachment.save().then(() => {
				console.log('attachment.id', attachment.id);
				const attachments = this.record[this.props.relation];
				attachments.add(attachment.id).then(() => {
					console.log('link!');
					attachment.uploadFile('filename', file)
						.on('progress', (event) => this.handleProgress(i, event))
						.end((error, result) => this.handleEnd(i, error, result));
				});
			});
			
		});
	};
	
	onDeleteOver(id) {
		const listItem = document.getElementById(`file-${id}`);
		if (listItem) listItem.classList.add('delete-hover');
	}
	
	onDeleteOut(id) {
		const listItem = document.getElementById(`file-${id}`);
		if (listItem) listItem.classList.remove('delete-hover');
	}
	
	deleteAttachment(attachment) {
		const index = this.attachments.findIndex(a => a.id === attachment.id);
		if (index !== -1) {
			this.attachments.splice(index, 1);
		}
		else {
			console.warn('attachment not found', attachment);
		}
		attachment.deleteFile('filename')
			.end((error, result) => {
				this.record[this.props.relation].remove(attachment.id);
			});
	}
	
	render() {
		return <div className="attached-images">
			<ul className="uploaded-files">
				{this.attachments ? this.attachments.map(attachment => {
					const url = attachment.downloadFile('filename');
					return <li key={attachment.id} id={`file-${attachment.id}`}>
						<a className="file-name" target="_blank" href={url}>{attachment.filename}</a>
						&nbsp;<FontAwesomeIcon icon={faTrash} className="delete-attachment" onClick={() => this.deleteAttachment(attachment)} />
					</li>;
				}) : <li>-</li>}
			</ul>
			
			{this.uploadingCount > 0 && <div className="uploading-files">
				<ul>
					{this.uploadingFiles.filter(upload => !!upload).map((fileUpload, i) => {
						const { file, progress, error } = fileUpload;
						return <li key={i} id={'upload-' + i}>
							<div className="file-icon file-icon-xs" data-type={getExtension(file.name) || ''}/>
							{file.name} ({file.size} байт)
							{!error && <span className="upload-progress">{progress} %</span>}
							{error && <div className="upload-error">Ошибка загрузки</div>}
						</li>;
					})}
				</ul>
				{this.progress && <div className="progress">{this.progress} %</div>}
			</div>}
			
			{this.props.canUpload &&
			<Dropzone accept={this.props.accept} onDrop={this.onDrop.bind(this)} style={{}} className="drop-zone">
				<button className="white-btn">Загрузить результат голосования</button>
			</Dropzone>}
		
		</div>;
	}
}
