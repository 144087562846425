import React from 'react';
import { action, observable, computed, runInAction } from 'mobx';
import throttle from 'lodash/throttle';

import { isAdmin } from './helpers';
import { formatDateStr, getCurrentDeputies } from '../../utils';
import store from '../../store';

const UPDATE_INTERVAL = 1000;
export const VOTEEND_INTERVAL = 15000;

const INCLUDE_STAGE = {
	relation: 'stages',
	scope: {
		include: [
			{
				relation: 'type',
				scope: {
					fields: ['id', 'name']
				}
			},
			{ relation: 'activeQueue' },
		],
		order: 'id desc',
		// limit: 1,
	}
};

export const STATUS_INACTIVE = 'inactive';
export const STATUS_DISCUSSED = 'discussed';
export const STATUS_VOTING = 'voting';
export const STATUS_CALCULATING = 'calculating';
export const STATUS_VOTED = 'voted';
export const STATUS_CANCELED = 'canceled';
export const STATUS_FINISHED = 'finished';

class VotingStore {
	
	@observable id = null;
	@observable user = null;
	@observable session = null;
	@observable error = null;
	@observable redirect = false;
	@observable deputy = null;
	@observable chairman = null;
	@observable member = null;
	@observable allMembers = [];
	@observable members = [];
	@observable questions = [];
	@observable currentQuestion = null;
	@observable currentStage = null;
	@observable preselectedQuestion = null;
	@observable preselectedStage = null;
	@observable questionName = '';
	@observable customType = '';
	@observable canVote = false;
	@observable currentQuestionResult = null;
	@observable currentVote = null;
	@observable canRegister = false;
	@observable currentRegistration = null;
	@observable speechRequests = [];
	@observable currentFile = null;
	@observable autoLogout = false;

	@observable initialized = false;
	@observable isLoading = false;
	@observable isRegistering = false;
	@observable isAdmin = false;
	@observable isTrueAdmin = false;
	@observable isStaff = false;
	@observable isInvited = false;
	@observable isProtocolist = false;
	@observable isDeleting = false;
	@observable isEndingVoting = false;
	@observable isUpdating = false;
	@observable isOnline = true;
	
	@observable discussionTypes = {};
	@observable questionStatuses = {};
	@observable questionTypes = [];
	@observable allDeputies = [];
	@observable totalDeputies = 0;
	@observable allInvited = [];
	@observable allStaff = [];
	@observable allProtocolists = [];
	@observable adminVotes = null;
	@observable onlyVotes = false;

	@observable fraction = '1/2';
	@observable from = 'present';
	@observable period = 60;
	@observable periodStr = '01:00';
	@observable isSecret = false;
	@observable timeLeft = 60;
	@observable quorum = 36;
	
	initial = true;
	votingTimer = null;
	updateTimer = null;
	initSubscribers = [];
	onUpdateEndSubscribers = [];
	serverDate = new Date();
	lastUpdate = null;
	@observable lastUpdateDate = new Date();
	delta = 0;
	sessionFinished = false;
	allowUpdates = false;
	
	constructor() {
		this.init = throttle(this.init, 1000, { leading: true, trailing: false });
	}
	
	@action init = async (initiator) => {
		console.log('----------- init', initiator);
		
		this.redirect = false;
		
		this.initStageProperty();
		const res = await store.model.SessionQuestionDiscussType.find({ order: 'id asc' });
		res.forEach(type => this.discussionTypes[type.id] = type);
		
		this.questionTypes = await store.model.QuestionStage.find({ order: 'id asc' });

		const statuses = await store.model.SessionQuestionStatus.find({ order: 'id asc' });
		statuses.forEach(status => this.questionStatuses[status.code] = status);

		this.allDeputies = await getCurrentDeputies();
		console.log('this.allDeputies', this.allDeputies);
		this.totalDeputies = this.allDeputies.length;
		
		if (store.model.user && store.model.user.deputyId) {
			const index = this.allDeputies.findIndex(deputy => deputy.id === store.model.user.deputyId);
			if (index === -1) {
				console.warn(`редирект: deputyId = ${store.model.user.deputyId} не найден в действующих депутатах`);
				this.redirect = true;
				return;
			}
		}
		
		this.isTrueAdmin = await isAdmin();
		if (this.isTrueAdmin) {
			this.allInvited = await this.getUsersForRole('invited');
			this.allStaff = await this.getUsersForRole('staff');
			this.allProtocolists = await this.getUsersForRole('protocolist');
		}

		await this.load();
		
		this.stopUpdates('init');
		this.clearAllTimers();
		this.setTimeout(() => {
			this.clearAllTimers();
			this.startUpdates('init');
		}, UPDATE_INTERVAL + 100);
		
		this.initialized = true;
		this.initSubscribers.forEach(fn => fn(this));
		store.subscribeOnHMR(this.onHMR);
	};
	
	onInit = fn => {
		if (this.initialized) {
			fn(this);
		}
		else {
			this.initSubscribers.push(fn);
		}
	};
	
	initStageProperty = () => {
		if (!store.model.SessionQuestion.prototype.hasOwnProperty('stage')) {
			Object.defineProperty(store.model.SessionQuestion.prototype, 'stage', {
				get: function() {
					const stage = this.stages().length > 0 ? this.stages()[0] : null;
					if (stage) stage.typeName = stage.type ? stage.type.name : null;
					return stage;
				}
			});
		}
	};
	
	@action load = async () => {
		if (!this.id) {
			this.error = 'Не указан корректный ID.';
			return;
		}
		
		this.isLoading = true;
		try {
			await this.checkRole();
			await this.getSession();
			await this.getChairman()
			await this.getDeputy();

			await this.getQuestions();
			
			this.onlyVotes = this.session.onlyVotes;
			this.sessionFinished = this.session.end;
			
			let save = false;
			if (this.session.elected === null) {
				this.session.elected = this.allDeputies.length;
				console.log('load', this.session.elected);
				save = true;
			}
			if (this.session.quorum === null) {
				this.session.quorum = Math.round(this.allDeputies.length / 2) + 1;
				save = true;
			}
			if (save && this.isAdmin) await this.session.save();
			
			await this.checkRegistration();
			await this.getCurrentQuestion();
			await this.getMembers();
			
		}
		catch(e) {
			console.error(e);
			if (e.statusCode === 404) {
				store.debugLog(`store-site > load: редирект из-за ошибки 404: ${e.message}`, 'error');
				console.warn(`store-site > load: редирект из-за ошибки 404: ${e.message}`, 'error');
				this.redirect = true;
			}
			this.error = e.message;
		}
		
		this.initial = false;
		this.isLoading = false;
	};
	
	
	/** update */
	
	@action update = async () => {
		// console.time('update');
		if (this.sessionFinished) return;

		this.isUpdating = true;

		let lastUpdate = null;
		let isOnline = true;
		
		try {
			const res = await store.model.Session.getDate(this.id, this.deputy ? this.deputy.id : 0);
			const serverTime = res.serverTime;
			lastUpdate = res.lastUpdate;
			// console.log({ serverTime, lastUpdate });
			this.serverDate = new Date(serverTime);
			await this.ping();
		}
		catch (e) {
			if (e) {
				if (e.statusCode === 401) {
					store.debugLog(`store-site > update: редирект из-за ошибки 401, ${e.message}`, 'info');
					await this.logout();
					return;
				}
				else {
					isOnline = false;
					console.error(e);
				}
			}
		}
		
		this.isOnline = isOnline;
		
		if (this.isAdmin && this.currentStage && this.currentStage.voteStart && !this.currentStage.voteEnd) {
			this.adminVotes = await this.getVotes(this.currentQuestion);
		}
		else {
			this.adminVotes = null;
		}
		
		let doUpdate = true;
		if (this.deputy && this.lastUpdate && lastUpdate && lastUpdate <= this.lastUpdate) {
			doUpdate = false;
		}
		
		const start = performance.now();
		if (store.local.showDebugInfo) console.log('isUpdating...');

		if (doUpdate) {
			try {
				await this.getSession();
				const promises = [
					this.getChairman(),
					this.checkIfCanRegister(),
					this.getMembers(),
					this.checkRegistration(),
				];
				await Promise.all(promises);
			} catch (e) {
				if (e.statusCode === 401) {
					store.debugLog(`store-site > update: редирект из-за ошибки 401, ${e.message}`, 'info');
					await this.logout();
					return;
				}
				else if (e.statusCode === 404) {
					store.debugLog(`store-site > update:редирект из-за ошибки 404, ${e.message}`, 'info');
					console.warn(`store-site > update: редирект из-за ошибки 404, ${e.message}`, 'info');
					this.redirect = true;
					return;
				}
				else {
					console.error(e);
				}
			}
		}
		
		try {
			await this.checkSpeechRequests();
			await this.getCurrentQuestion();
			await this.checkIfCanVote();
			const questionsUpdated = this.checkIfQuestionsUpdated();
			if (questionsUpdated) await this.getQuestions();
		}
		catch (e) {
			if (e) {
				if (e.statusCode === 401) {
					store.debugLog(`store-site > update: редирект из-за ошибки 401, ${e.message}`, 'info');
					await this.logout();
					return;
				}
				else {
					console.error(e);
				}
			}
		}
		
		this.isUpdating = false;
		if (store.local.showDebugInfo) console.log('...updated in', performance.now() - start, 'ms');

		if (!this.isAdmin && this.currentStage) {
			this.isEndingVoting = this.currentStage.voteEnd !== null && this.currentStage.statusId === null;
		}
		
		if (!this.sessionFinished && this.session && this.session.end) {
			if (!this.isAdmin) {
				this.stopUpdates('session finished');
				store.debugLog(`store-site > update: редирект из-за завершения сессии`);
				store.route.push({ path: '/profile' });
			}
		}

		if (this.onUpdateEndSubscribers.length > 0) {
			console.log('> onUpdateEndSubscribers', this.onUpdateEndSubscribers.length);
			for (let subscriber of this.onUpdateEndSubscribers) {
				await subscriber();
			}
			this.onUpdateEndSubscribers = [];
			this.startUpdates('after safelyExecute');
		}
		
		this.lastUpdate = lastUpdate;
		this.lastUpdateDate = new Date();
		// console.log('>', this.lastUpdate);
		// console.timeEnd('update');
	};
	
	logout = async () => {
		console.log('---- logout!', store.model.user);
		this.stopUpdates('logout');
		this.clearAllTimers();
		store.model.user = null;
		store.model.isAuthorized = false;
		store.route.push({ path: '/login' });
	};
	
	testUpdates = async () => {
		console.log('isUpdating?', this.isUpdating);
		const test = async () => {
			console.log('testing...');
			await this.fakeDelay(3000);
			console.log('...test done');
		};
		await this.safelyExecute(test);
	};
	
	fakeDelay = delay => new Promise(resolve => setTimeout(resolve, delay));
	
	ping = async () => {
		if (this.member) {
			try {
				this.delta = await this.member.ping(new Date().getTime());
			}
			catch(e) {
				console.warn('---->', e);
			}
		}
		else if (this.isAdmin) {
			this.delta = new Date().getTime() - this.serverDate.getTime();
		}
	};
	
	checkRole = async () => {
		console.log('checkRole', this.user);
		if (!this.user) return false;
		let isAdmin = false;
		let isStaff = false;
		let isInvited = false;
		let isProtocolist = false;

		if (store.model.RoleMapping) {
			try {
				const roleMappings = await store.model.RoleMapping.find({
					where: {
						prinicalType: 'USER',
						principalId: this.user.id,
					},
					include: ['role']
				});
				const roleNames = roleMappings.filter(rm => !!rm.role).map(rm => rm.role.name)
				isAdmin = roleNames.includes('admin');
				isStaff = roleNames.includes('staff');
				isInvited = roleNames.includes('invited');
				isProtocolist = roleNames.includes('protocolist');
				console.log('roleNames', roleNames);
			}
			catch(e) {
				console.log('not admin!');
			}
		}
		this.isAdmin = isAdmin;
		this.isStaff = isStaff;
		this.isInvited = isInvited;
		this.isProtocolist = isProtocolist;
	};
	
	/** fetch data */
	
	processUpdate = (update, name, model, isArray) => {
		if (!update[name]) {
			this[name] = null;
		}
		else {
			if (!this[name]) this[name] = new model();
			this[name].fromJSON(update[name]);
/*
			if (isArray) {
				this[name] = update[name].map(json => {
					const record = new model();
					record.fromJSON(json);
					return record;
				});
			}
			else {
				if (!this[name]) this[name] = new model();
				this[name].fromJSON(update[name]);
			}
*/
		}
	};
	
	getSession = async update => {
		try {
			this.session = await store.model.Session.findById(this.id, {
				include: ['activeQueue'],
			});
		}
		catch (e) {
			const error = new Error('Сессия не найдена');
			error.statusCode = 404;
			throw error;
		}
	};
	
	getChairman = async update => {
		if (!this.session) {
			console.log('no session', this.session);
			return;
		}
		try {
			this.chairman = this.session.deputyId !== null ? await store.model.Deputy.findById(this.session.deputyId) : null;
		}
		catch (e) {
			console.warn(e);
		}
	};
	
	checkIfQuestionsUpdated = async () => {
		if (!this.session) return;
		try {
			const res = await this.session.checkIfQuestionsUpdated(this.questions.map(q => q.id));
			if (res) console.log('epz session was updated, updating questions...');
		}
		catch (e) {
			console.error('checkQuestionsUpdates', e);
		}
	};
	
	getQuestions = async () => {
		if (!this.session) return;
		try {
			const questions = await this.session.questions.find({
				include: [
					INCLUDE_STAGE,
				],
				order: 'order asc',
			});
			
			// достаем документы для вопросов для всех кроме админа (ему они не нужны)
			if (!this.isAdmin) {
				const plenaryIds = questions.map(q => q.questionId).filter(questionId => !!questionId);
				if (plenaryIds.length > 0) {
					const plenary = await store.model.Question.find({
						where: { id: { inq: plenaryIds } },
						fields: ['id'],
						include: [
							{
								relation: 'documents',
								scope: {
									fields: ['id', 'name'],
									include: [
										{
											relation: 'file',
											scope: {
												order: 'sort asc'
											}
										}
									]
								}
							}
						]
					});
					plenary.forEach(question => {
						const sessionQuestion = questions.find(sq => sq.questionId === question.id);
						if (sessionQuestion) {
							sessionQuestion.__files = question.documents().map(document => ({
								id: document.id,
								name: document.name,
								pages: document.file().map(file => file.path)
							}));
						}
						// console.log('-', sessionQuestion, sessionQuestion.__files);
					});
				}
			}
			this.questions = questions;
		}
		catch (e) {
			console.warn(e);
		}
		// console.log('this.questions', this.questions.map(q => q.stages().length));
	};
	
	getDeputy = async () => {
		// if (!this.user || !this.user.deputyId) return;
		if (!store.model.user) return;
		const user = await store.model.User.findById(store.model.user.id, { fields: ['id', 'deputyId'] });
		if (user && user.deputyId) {
			this.deputy = await store.model.Deputy.findById(user.deputyId);
		}
	};
	
	getMembers = async () => {
		if (store.model.SessionMember) {
			try {
				this.allMembers = await store.model.SessionMember.find({
					where: {
						and: [
							{ sessionId: this.id },
							// { end: null },
						],
					},
					include: [
						{
							relation: 'deputy',
							scope: {
								fields: ['id', 'lastName', 'firstName', 'middleName', 'phone']
							}
						},
						{
							relation: 'user',
							scope: {
								fields: ['id', 'lastName', 'firstName', 'middleName', 'email']
							}
						},
					],
					order: 'id desc'
				});
				
				this.members = this.allMembers.filter(m => m.end === null);
			}
			catch (e) {
				console.warn(e);
				throw e;
			}
		}

		if (this.isAdmin && store.local.autoLogout) {
			const expired = [];
			this.members.forEach(member => {
				if (member.lastPing) {
					const lastPing = new Date(member.lastPing).getTime();
					const seconds = Math.ceil((this.serverDate.getTime() - lastPing) / 1000);
					const delay = parseInt(store.local.autoLogoutDelay) || 10;
					if (seconds > delay) {
						console.log('- expired', this.memberFio(member), member.lastPing, member.delay, seconds, 'delay', delay);
						expired.push(member);
					}
					else {
						// console.log('- not expired', member.lastPing, member.delay, seconds, 'delay', delay);
					}
				}
			});
			Promise.all(expired.map(member => {
				member.end = this.serverDate;
				return member.save();
			}));
		}
	};
	
	memberFio = member => {
		if (member.deputy) {
			return this.fio(member.deputy);
		}
		else if (member.user) {
			return this.fio(member.user);
		}
		else {
			return  '';
		}
	};
	
	isDeputyRegistered = deputy => this.members.findIndex(member => member.deputyId === deputy.id) !== -1;
	
	isUserRegistered = user => this.members.findIndex(member => member.userId === user.id) !== -1;
	
	preselectQuestion = async question => {
		this.preselectedQuestion = question;
		this.preselectedStage = null;
	};
	
	preselectType = async type => {
		console.log('preselectType', type);
		this.preselectedStage = type;
	};
	
	@action getCurrentQuestion = async () => {
		if (!this.session) return;
		
		if (this.session.end) {
			this.currentQuestion = null;
			this.currentStage = null;
			return;
		}
		
		if (!this.session.questionId) {
			// console.log('----------- getCurrentQuestion: null');
			this.currentQuestion = null;
			this.currentStage = null;
		}
		else {
			try {
				this.currentQuestion = await store.model.SessionQuestion.findById(this.session.questionId, {
					include: [
						INCLUDE_STAGE
					]
				});
				this.currentStage = this.currentQuestion.stage;
				// console.log('----------- currentStage:', this.currentStage);
			}
			catch (e) {
				console.warn(e);
			}
		}
		
		if (this.currentStage && this.currentStage.voteStart && !this.currentStage.voteEnd) {
			const timePassed = Math.round((this.serverDate.getTime() - new Date(this.currentStage.voteStart).getTime()) / 1000);
			this.timeLeft = this.currentStage.period - timePassed;// + Math.round(this.delta / 1000);
			// console.log('timePassed', timePassed, 'timeLeft', this.timeLeft);
			if (this.isAdmin && this.timeLeft <= 0) {
				this.stopVoting();
			}
		}

		if (!this.preselectedQuestion) {
			this.preselectedQuestion = this.currentQuestion;
		}
		
		if (this.currentStage) {
			if (!this.preselectedStage) {
				if (this.currentStage.type) {
					// this.preselectedStage = this.currentStage.type;
				} else if (this.currentStage.customType) {
					// this.preselectedStage = 'new';
					this.customType = this.currentStage.customType;
				}
			}
		}
	};
	
	checkIfCanVote = async () => {
		let canVote = false;
		if (this.deputy && this.member && this.currentStage && this.currentStage.voteStart) {
			if (!this.currentStage.voteEnd) {
				const res = await store.model.Vote.find({
					where: {
						and: [
							{ questionId: this.currentStage.id },
							{ deputyId: this.member.deputyId },
						]
					}
				});
				canVote = true;
				this.currentVote = res.length > 0 ? res[0] : null;
				this.currentQuestionResult = this.currentVote ? this.interpretResult(this.currentVote.result) : null;
			}
			else {
				this.currentVote = null;
				this.currentQuestionResult = null;
			}
		}
		this.canVote = canVote;
	};
	
	checkIfCanRegister = async () => {
		if (!this.user) {
			this.currentRegistration = null;
			this.canRegister = false;
			return;
		}
		let res = [];
		if (this.session && this.member) {
			res = await store.model.SessionQuestionDiscussQueue.find({
				where: {
					and: [
						{ sessionId: this.session.id },
						{ userId: this.user.id },
						{ canceledAt: null },
						{ finishedAt: null },
					]
				},
				include: ['type'],
				order: 'id desc',
			});
		}
		this.currentRegistration = res.length > 0 ? res[0] : null;
		// console.log('currentRegistration', this.currentRegistration);
		this.canRegister = res.length === 0;
	};
	
	interpretResult = result => {
		if (result === null) return 'Воздержался';
		if (result === true) return 'За';
		if (result === false) return 'Против';
		return null;
	};
	
	checkSpeechRequests = async () => {
		if ((!this.isAdmin && !this.isChairman) || !this.session) return;
		this.speechRequests = await store.model.SessionQuestionDiscussQueue.find({
			where: {
				and: [
					{ sessionId: this.session.id },
					{ canceledAt: null },
					{ finishedAt: null },
				]
			},
			include: [
				{
					relation: 'deputy',
					scope: {
						fields: ['id', 'lastName', 'firstName', 'middleName']
					}
				},
				{
					relation: 'user',
					scope: {
						fields: ['id', 'lastName', 'firstName', 'middleName']
					}
				},
				{
					relation: 'type',
					scope: {
						fields: ['id', 'name']
					}
				},
			],
			order: 'id asc'
		});
		// console.log('requests', this.speechRequests);
	};
	
	
	/** reset */

	@action reset = () => {
		console.log('...resetting session');
		this.session = null;
		this.error = null;
		this.deputy = null;
		this.chairman = null;
		this.member = null;
		this.members = [];
		// this.questions = [];
		this.currentQuestion = null;

		this.isLoading = false;
		this.isRegistering = false;
		this.isAdmin = false;
	};
	
	@action softReset = async () => {
		this.deputy = null;
		this.chairman = null;
		this.member = null;
		this.currentQuestionResult = null;
		this.currentVote = null;
		this.canVote = false;
		
		this.isLoading = false;
		this.isRegistering = false;
		this.isAdmin = false;
	};

	
	/** registration */
	
	checkRegistration = async () => {
		if (!this.user || (!this.user.deputyId && !this.isInvited && !this.isStaff && !this.isProtocolist)) {
			this.member = null;
			return;
		}
		const res = await store.model.SessionMember.find({
			where: {
				sessionId: this.id,
				userId: this.user.id,
				end: null,
			},
		});
		this.member = res.length > 0 ? res[0] : null;
		// console.log('checkRegistration', this.member);
	};
	
	register = async e => {
		if (e) e.preventDefault();
		if (this.isRegistering) return;
		this.isRegistering = true;
		if (this.member) return;
		const member = new store.model.SessionMember();
		member.sessionId = this.id;
		member.deputyId = this.user.deputyId;
		member.userId = this.user.id;
		member.date = this.serverDate;
		member.code = this.user.deputyId ? 'deputy' : this.isInvited ? 'invited' : this.isProtocolist ? 'protocolist' : 'staff';
		await member.save();
		await this.getMembers();
		this.member = member;
		this.checkIfCanVote();
		this.isRegistering = false;
	};
	
	cancelMyRegistration = async () => {
		if (this.member) {
			this.member.end = this.serverDate;
			await this.member.save();
			if (this.currentStage) {
				const requests = await store.model.SessionQuestionDiscussQueue.find({
					where: {
						and: [
							{ questionId: this.currentStage.id },
							{ userId: this.member.userId },
							{ canceledAt: null }
						]
					}
				});
				for (let request of requests) {
					request.canceledAt = this.serverDate;
					await request.save();
				}
			}
		}
	};
	
	
	/** getters */
	
	@computed get isChairman () {
		return this.deputy && this.chairman && this.deputy.id === this.chairman.id;
	}
	
	@computed get isParticipant () {
		return this.deputy || this.isAdmin || this.isStaff || this.isInvited || this.isProtocolist;
	}
	
	@computed get isParticipantTrue () {
		return store.model.user && (!!store.model.user.deputyId || store.model.user.id === 1);
	}
	
	@computed get isQuorum () {
		return this.presentDeputiesCount >= this.session.quorum;
	}
	
	@computed get presentDeputiesCount () {
		const deputies = this.members.filter(member => member.code === 'deputy');
		const uniqueMembers = new Set(deputies.map(member => member.deputyId));
		return uniqueMembers.size;
	}
	
	@computed get presentInvited () {
		return this.members.filter(member => member.code === 'invited');
	}
	
	@computed get presentStaff () {
		return this.members.filter(member => member.code === 'staff');
	}

	
	/** admin */
	
	setUser = async user => {
		clearTimeout(this.votingTimer);
		this.user = user;
		this.reset();
		await this.load();
		// if (this.currentQuestion && this.currentQuestion.stage && this.currentQuestion.stage.voteStart && !this.currentQuestion.stage.voteEnd) {
		// 	this.startVotingTimer();
		// }
	};
	
	setChairman = async deputy => {
		await this.safelyExecute(async () => {
			if (this.isAdmin) {
				this.chairman = deputy;
				this.session.chairman = deputy;
				this.session.deputyId = deputy ? deputy.id : null;
				await this.session.save();
			}
		});
	};
	
	getUserForRole = async roleName => {
		const role = await store.model.Role.findOne({ where: { name: roleName }});
		if (role) {
			const res = await store.model.RoleMapping.find({ where: { roleId: role.id }, limit: 1 });
			if (res.length > 0) {
				const users = await store.model.User.find({ where: { id: res[0].principalId }});
				if (users.length > 0) return users[0];
			}
		}
		return null;
	};
	
	getUsersForRole = async roleName => {
		const role = await store.model.Role.findOne({ where: { name: roleName }});
		if (role) {
			const roleMappings = await store.model.RoleMapping.find({ where: { roleId: role.id }});
			const userIds = roleMappings.map(rm => rm.principalId);
			return await store.model.User.find({ where: { id: { inq: userIds }}});
		}
		return [];
	};
	
	getNextQuestion = () => {
		if (!this.currentQuestion) {
			return this.questions.length > 0 ? this.questions[0] : null;
		}
		else {
			const index = this.questions.findIndex(question => this.currentQuestion.id === question.id);
			if (index < this.questions.length - 1) {
				return this.questions[index + 1];
			}
		}
		return null;
	};
	
	startRegistration = async () => {
		if (this.isAdmin) {
			this.session.registrationStart = this.serverDate;
			this.session.initialized = true;
			await this.session.save();
		}
	};
	
	startVoting = async question => {
		if (!question.stage) {
			console.warn('no stage!', question);
			return;
		}
		this.timeLeft = this.period;
		
		await this.safelyExecute(async () => {
			await this.removeActiveQueue();
			question.stage.voteStart = this.serverDate;
			question.stage.fraction = this.fraction;
			question.stage.from = this.from;
			question.stage.period = this.period;
			question.stage.isSecret = this.isSecret;
			await question.stage.save();
			this.currentQuestion = question;
			this.session.currentQuestion = question;
			await this.session.save();
		});
	};
	
	getQuestionName = question => {
		if (!question) return null;
		if (question.code === 'question') return question.name;
		if (question.code === 'agenda') return 'Утверждение повестки';
		return 'Процедурный';
	};
	
	getQuestionStageName = question => {
		if (!question.stage) return '';
		if (question.stage.type) return question.stage.type.name;
		return question.stage.customType || 'без названия';
	};
	
	getStageName = stage => {
		if (!stage) return '';
		let name = '';
		if (stage.type) {
			name = stage.type.name;
		}
		else {
			name = stage.customType || 'без названия';
		}
		return name[0].toUpperCase() + name.slice(1);
	};
	
	startVotingTimer = () => {
		clearTimeout(this.votingTimer);
		if (!this.currentQuestion || !this.currentQuestion.stage || !this.currentQuestion.stage.voteStart || this.currentQuestion.stage.voteEnd) return;
		const timePassed = Math.round((new Date().getTime() - new Date(this.currentQuestion.stage.voteStart).getTime()) / 1000);
		console.log('-- startVotingTimer: period:', this.currentQuestion.stage.period, 'time passed:', timePassed);
		this.timeLeft = this.currentQuestion.stage.period - timePassed;
		this.updateVotingTimer();
	};
	
	updateVotingTimer = () => {
		this.timeLeft--;
		// console.log('updateVotingTimer', this.timeLeft);
		if (this.currentQuestion && this.currentQuestion.stage && this.currentQuestion.stage.canceledAt) {
			console.log('currentQuestion is canceled, clearTimeout');
			clearTimeout(this.votingTimer);
			return;
		}
		if (this.timeLeft > 0) {
			this.votingTimer = this.setTimeout(this.updateVotingTimer, 1000);
		}
		else {
			this.stopVoting(this.currentQuestion);
		}
	};
	
	@action startDiscussion = async () => {
		console.time('startDiscussion');
		const promises = [];
		
		if (this.currentQuestion && this.currentStage && !this.currentStage.voteStart) {
			// await this.cancelQuestion(this.currentQuestion);
			this.currentQuestion.stage.canceledAt = this.serverDate;
			this.currentQuestion.stage.status = this.questionStatuses.canceled;
			promises.push(this.currentQuestion.stage.save());
		}
		
		let question = this.preselectedQuestion || this.currentQuestion;
		
		const questionStage = new store.model.SessionQuestionStage();
		questionStage.questionId = question.id;
		questionStage.sessionId = this.session.id;
		questionStage.type = this.preselectedStage === 'new' ? null : this.preselectedStage;
		questionStage.customType = this.preselectedStage === 'new' ? this.customType : null;
		questionStage.isSecret = this.isSecret;
		questionStage.discussionStart = this.serverDate;
		// await questionStage.save();
		promises.push(questionStage.save());
		
		this.session.questionId = question.id;
		// await this.session.save();
		promises.push(this.session.save());
		
		await Promise.all(promises);

		console.timeEnd('startDiscussion');

		// this.selectedQuestion = null;
		// this.selectedStage = null;
		// this.questionName = '';
		// this.customType = '';
	};
	
	cancelQuestion = async question => {
		const save = async () => {
			console.log('cancelling question', question.name);
			question.stage.canceledAt = this.serverDate;
			question.stage.status = this.questionStatuses.canceled;
			await question.stage.save();
		};

		await this.safelyExecute(save);
	};
	
	gotoNextQuestion = async () => {
		this.currentQuestion = null;
		this.session.currentQuestion = null;
		this.session.questionId = null;
		await this.session.save();
	};
	
	switchOnlyVotes = async value => {
		const onlyVotes = !value;
		this.onlyVotes = onlyVotes;
		
		const save = async () => {
			this.session.onlyVotes = onlyVotes;
			console.log('onlyVotes', this.session.onlyVotes, onlyVotes);
			await this.session.save();
		};
		
		await this.safelyExecute(save);
	};
	
	stopVoting = async () => {
		if (!this.isAdmin) return;
		if (!this.currentQuestion) {
			console.warn('no current question!', this.currentQuestion);
			return;
		}
		if (!this.currentStage) {
			console.warn('no stage!', this.currentQuestion);
			return;
		}
		
		clearTimeout(this.votingTimer);
		
		const finishStage = async () => {
			this.isEndingVoting = true;
			this.currentStage.voteEnd = this.serverDate;
			this.currentStage.present = this.members.filter(m => !!m.deputyId).length;
			const lastNumber = await this.getLastStageNumber();
			console.log('lastNumber', lastNumber);
			this.currentStage.number = lastNumber + 1;
			await this.currentStage.save();
		};
		
		await this.safelyExecute(finishStage);
		
/*
		await this.safelyExecute(async () => {
			this.isEndingVoting = true;
			this.currentStage.voteEnd = this.serverDate;
			this.currentStage.present = this.members.filter(m => !!m.deputyId).length;
			const lastNumber = await this.getLastStageNumber();
			console.log('lastNumber', lastNumber);
			this.currentStage.number = lastNumber + 1;
			await this.currentStage.save();
		});
*/

		setTimeout(this.finishVoting, VOTEEND_INTERVAL);
	};
	
	getLastStageNumber = async () => {
		const res = await store.model.SessionQuestionStage.find({
			where: {
				and: [
					{ sessionId: this.session.id },
					{ number: { neq: null }},
					{ canceledAt: null },
				]
			},
			fields: ['id', 'number'],
			order: 'id desc',
		});
		if (res.length === 0) return 0;
		return res[0].number;
	};
	
	finishVoting = async () => {
		await this.safelyExecute(async () => {
			const { yes, no, abstained } = await this.getVotes(this.currentQuestion);
			if (yes + no + abstained === 0) {
				this.currentStage.yesCount = yes;
				this.currentStage.noCount = no;
				this.currentStage.abstainCount = abstained;
				this.currentStage.status = this.questionStatuses.no;
				await this.currentStage.save();
			}
/*
			const { yes, no, abstained } = await this.getVotes(this.currentQuestion);
			console.log({ yes, no, abstained });
			this.currentStage.yesCount = yes;
			this.currentStage.noCount = no;
			this.currentStage.abstainCount = abstained;
			
			if (!this.onlyVotes) this.calcResults(this.currentQuestion);
			await this.currentStage.save();
*/
			this.isEndingVoting = false;
		});
	};
	
	safelyExecute = async (fn, initiator) => {
		console.log('> safelyExecute: isUpdating', this.isUpdating);
		this.stopUpdates(initiator || 'safelyExecute');
		if (this.isUpdating) {
			console.log('> safelyExecute: pushing subscriber...');
			this.onUpdateEndSubscribers.push(fn);
		}
		else {
			console.log('> safelyExecute: executing now');
			await fn();
			this.startUpdates(initiator || 'safelyExecute');
		}
	};
	
	getVotes = async question => {
		if (!question) {
			console.log('!getVotes: no question');
			return;
		}
		const votes = await store.model.Vote.find({
			where: {
				and: [
					{ questionId: question.stage.id },
				]
			}
		});
		let yes = 0;
		let no = 0;
		let abstained = 0;
		votes.forEach(vote => {
			if (vote.result === true) yes++;
			if (vote.result === false) no++;
			if (vote.result === null) abstained++;
		});
		// console.log({ yes, no, abstained });
		return { yes, no, abstained };
	};
	
	calcResults = question => {
		const countFunc = this.voteFractions[question.stage.fraction];
		const countFrom = this.voteFrom[question.stage.from].countFunc();
		const yes = countFunc(question.stage.yesCount, question.stage.noCount, countFrom);
		question.stage.status = yes ? this.questionStatuses.yes : this.questionStatuses.no;
		console.log('--- calcResults: yes', question.stage.yesCount, 'no', question.stage.noCount, 'count', question.stage.fraction, 'from', question.stage.from, countFrom, 'result', yes ? 'принято' : 'не принято');
	};
	
	get voteFractions() {
		return {
			'1/2': (yes, no, countFrom) => yes > no && yes > countFrom / 2,
			// '1/3': (yes, no, countFrom) => yes > no && yes > countFrom / 3,
			'2/3': (yes, no, countFrom) => yes > no && yes > countFrom * 2 / 3,
		}
	};
	
	@computed get voteFrom() {
		return {
			present: { name: `от присутствующих`, countFunc: () => this.members.filter(m => !!m.deputyId).length },
			elected: { name: `от избранных`, countFunc:() => this.session.elected || this.allDeputies.length },
			fixed: { name: 'от установленного', countFunc: () => 70 },
		}
	};

	deleteSession = async e => {
		if (e) e.preventDefault();
		
		await this.safelyExecute(async () => {
			console.log('deleting session...');
			store.debugLog(`удаление сессии ${this.session.id}, ${formatDateStr(new Date())}`, 'warning');
			this.isDeleting = true;
			
			const res = await this.session.deleteSession();
			console.log('res', res);

			await this.session.delete();
			console.log('...session deleted');
		});
		this.stopUpdates('deleteSession');
		
		store.route.push({ path: '/profile' });
	};
	
	resetSession = async e => {
		if (e) e.preventDefault();

		await this.safelyExecute(async () => {
			console.log('resetting session...');
			store.debugLog(`сброс сессии ${this.session.id}, ${formatDateStr(new Date())}`, 'warning');
			this.isLoading = true;
			
			const res = await this.session.resetSession();
			console.log('res', res);
			
			runInAction(() => {
				this.session.registrationStart = null;
				this.session.deputyId = null;
				this.session.chairman = null;
				this.session.end = null;
				this.session.initialized = null;
				this.session.questionId = null;
				this.session.activeQueueId = null;
				this.session.elected = this.allDeputies.length;
				this.session.quorum = Math.round(this.allDeputies.length / 2) + 1;
			});
			
			await this.session.save();

			await this.init();
			console.log('...session reset');
			this.isLoading = false;
		}, 'resetSession');
	};
	
	@action finishSession = async e => {
		if (e) e.preventDefault();

		await this.safelyExecute(async () => {
			console.log('finishing session...');
			// this.isLoading = true;
			this.currentQuestion = null;
			this.currentStage = null;
			
			const promises = [];
			const sessionId = this.session.id;
			
			this.session.end = this.serverDate;
			await this.session.save();
			
			let records = await store.model.SessionMember.find({ where: { sessionId }});
			promises.push(records.map(r => {
				if (!!r.end) {
					// console.log('already offline', r.end);
					return Promise.resolve(null);
				}
				// console.log('online', r.end, this.serverDate);
				r.end = this.serverDate;
				return r.save();
			}));
			
			await Promise.all(promises);
			console.log('...session finished');
			// this.isLoading = false;
		}, 'finishSession');
	};
	
	speechRequest = async typeId => {
		if (!this.isParticipant) return;
		const req = new store.model.SessionQuestionDiscussQueue();
		req.date = this.serverDate;
		req.typeId = typeId;
		req.sessionId = this.session.id;
		// req.questionId = this.currentQuestion.id;
		// req.stageId = this.currentStage.id;
		req.deputyId = this.user.deputyId;
		req.userId = this.user.id;
		await req.save();
		this.currentRegistration = req;
	};
	
	speechCancel = async typeId => {
		this.stopUpdates('speechCancel');
		if (!this.member || !this.session) return;
		const res = await store.model.SessionQuestionDiscussQueue.find({
			where: {
				and: [
					{ userId: this.user.id },
					{ sessionId: this.session.id },
					{ canceledAt: null },
					{ finishedAt: null },
				]
			},
			order: 'id desc',
			limit: 1,
		});
		if (res.length > 0) {
			const request = res[0];
			request.canceledAt = this.serverDate;
			await request.save();
		}
		this.currentRegistration = null;
		this.startUpdates('speechCancel');
	};
	
	setActiveQueue = async queue => {
		if (this.session) {
			if (this.session.activeQueue) {
				this.session.activeQueue.finishedAt = this.serverDate;
				this.session.activeQueue.save();
			}
			this.session.activeQueue = queue;
			await this.session.save();
			await this.checkSpeechRequests();
		}
	};
	
	removeQueue = async queue => {
		queue.finishedAt = this.serverDate;
		await queue.save();
		await this.removeActiveQueue();
	};
	
	removeAllQueues = async () => {
		await this.removeActiveQueue();
		const promises = this.speechRequests.map(queue => {
			queue.finishedAt = this.serverDate;
			return queue.save();
		});
		await Promise.all(promises);
	};
	
	removeActiveQueue = async () => {
		if (this.currentStage) {
			this.currentStage.activeQueue = null;
			await this.currentStage.save();
			await this.checkSpeechRequests();
		}
	};
	
	/** deputy */
	
	@action vote = async (question, result) => {
		console.log('vote', question, result);
		// this.canVote = false;
		this.currentQuestionResult = this.interpretResult(result);
		if (!this.member) {
			console.warn('not registered!');
			return;
		}
		if (this.currentQuestion && question.id !== this.currentQuestion.id) {
			console.warn('not current question!');
			return;
		}

		const res = await store.model.Vote.find({
			where: {
				and: [
					{ questionId: this.currentStage.id },
					{ deputyId: this.member.deputyId },
				]
			}
		});
		this.currentVote = res.length > 0 ? res[0] : null;

		if (!this.currentVote) {
			this.currentVote = new store.model.Vote();
		}
		this.currentVote.sessionId = this.session.id;
		this.currentVote.questionId = question.stage.id;
		this.currentVote.deputyId = this.member.deputyId;
		this.currentVote.result = result;
		this.currentVote.date = this.serverDate;
		try {
			await this.currentVote.save();
		}
		catch(e) {
			this.currentQuestionResult = null;
			throw e;
		}
		console.log('vote', result, question, this.currentVote);
	};
	
	
	/** timer */
	
	startUpdates = initiator => {
		console.log('startUpdates', initiator);
		this.allowUpdates = true;
		this.makeUpdate();
	};
	
	stopUpdates = initiator => {
		console.log('stopUpdates', initiator);
		clearTimeout(this.updateTimer);
		// this.clearAllTimers();
		this.allowUpdates = false;
	};
	
	makeUpdate = async () => {
		clearTimeout(this.updateTimer);
		if (this.allowUpdates) {
			await this.update();
			this.updateTimer = this.setTimeout(this.makeUpdate, UPDATE_INTERVAL);
		}
	};

	
	/** utils */

	fio = record => (record.lastName || record.firstName || record.middleName) ? `${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}` : record.username;
	fioShort = record => (record.lastName || record.firstName || record.middleName) ?
		`${record.lastName} ${record.firstName ? record.firstName.substr(0, 1) + '.' : ''} ${record.middleName ? record.middleName.substr(0, 1) + '.' : ''}`
		:
		record.username;
	
	showFile = file => {
		this.currentFile = file;
	};
	
	clearAllTimers = () => {
		console.log('clearing all timers', window.__sessionTimers);
		if (window.__sessionTimers) {
			window.__sessionTimers.forEach(clearTimeout);
		}
		window.__sessionTimers = [];
	};
	
	setTimeout = (fn, timeout) => {
		const timerId = setTimeout(fn, timeout);
		if (!window.__sessionTimers) window.__sessionTimers = [];
		window.__sessionTimers.push(timerId);
		return timerId;
	};
	
	onHMR = () => {
		this.clearAllTimers();
	};
	
}

export default new VotingStore();
