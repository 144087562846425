import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import store from '../../../../store';
import './style.scss';

const Details = ({ appointment }) => <div className="appointment-details">
	<div className="block">
		<div className="date">{format(new Date(appointment.createdAt), 'dd.MM.yyyy HH:mm')}</div>
		<div className="info">
			Ваша заявка на личный прием поступила в Государственное Собрание (Ил Тумэн) РС(Я)<br />
			Дата поступления: <strong>{format(new Date(appointment.createdAt), 'dd.MM.yyyy')}</strong><br />
			Статус: <strong>поступила</strong>
		</div>
	</div>
	{appointment.statusId > 1 && <div className="block">
		<div className="date">{format(new Date(appointment.acceptDate), 'dd.MM.yyyy HH:mm')}</div>
		<div className="info">
			Ваша заявка подтверждена. <br />
			Прием состоится <strong>{format(new Date(appointment.dueDate), 'dd.MM.yyyy HH:mm')}</strong>. Место: <strong>{appointment.place}</strong><br />
			Статус: <strong>подтверждена</strong>
		</div>
	</div>}
	{appointment.statusId > 2 && <div className="block">
		<div className="date">{format(new Date(appointment.acceptDate), 'dd.MM.yyyy HH:mm')}</div>
		<div className="info">
			Личный прием состоялся: <strong>{format(new Date(appointment.factDate), 'dd.MM.yyyy HH:mm')}</strong>.<br />
			Статус: <strong>личный прием состоялся</strong>
		</div>
	</div>}
</div>;

@observer
export default class List extends React.Component {

	@observable appointments = [];
	@observable isLoading = true;
	@observable currentAppointment = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.appointments = await store.model.Appointment.find({
			where: { ownerId: store.model.user.id },
			include: ['deputy', 'status'],
			order: 'id desc',
		});
		this.appointments.forEach(appointment => appointment.__show = observable.box(false));
		this.isLoading = false;
		// if (this.appointments.length > 0) this.currentAppointment = this.appointments[0];
	};
	
	showDetails = (appointment, e) => {
		if (e) e.preventDefault();
		appointment.__show.set(!appointment.__show.get());
		if (!this.currentAppointment || this.currentAppointment.id !== appointment.id) {
			this.currentAppointment = appointment;
		}
		else {
			this.currentAppointment = null;
		}
	};
	
	render() {
		let content = '...';
		
		if (!this.isLoading) {
			if (this.appointments.length === 0) {
				content = 'У вас еще нет записей на прием';
			}
			else {
				const appointments = this.appointments.map(appointment => {
					// const isCurrent = this.currentAppointment && this.currentAppointment.id === appointment.id;
					const isCurrent = appointment.__show.get();
					return <React.Fragment key={appointment.id}>
						<tr className={'appointment-item' + (isCurrent ? ' current' : '')}>
							<td className="createdAt">{format(new Date(appointment.createdAt), 'dd.MM.yyyy HH:mm')}</td>
							<td className="from">{appointment.deputy.lastName} {appointment.deputy.firstName} {appointment.deputy.middleName}</td>
							<td className="date">{format(new Date(appointment.date), 'dd.MM.yyyy')}</td>
							<td className="status">{appointment.status ? appointment.status.name : '-'}</td>
							<td width={120} className="actions"><a href="#" onClick={e => this.showDetails(appointment, e)}>{isCurrent ? 'Свернуть' : 'Развернуть'}</a></td>
						</tr>
						{isCurrent && <tr>
							<td colSpan={5} className="details-cell"><Details appointment={appointment}/></td>
						</tr>}
					</React.Fragment>;
				});
				content = <table className="items-list appointments-list">
					<thead>
					<tr>
						<th>Дата подачи заявки</th>
						<th>Народный депутат</th>
						<th>Дата личного приема</th>
						<th>Статус</th>
						<th/>
						{/*<th/>*/}
					</tr>
					</thead>
					<tbody>
					{appointments}
					</tbody>
				</table>
			}
		}
		
		return <div className="appointments-page">
			{content}
		</div>;
	}
	
}
