import React, { useState, useEffect } from 'react';

import SubscriptionForm from './SubscriptionForm';
import SubscriptionMessage from './SubscriptionMessage';
import store from 'root/site/store';
import { Section } from 'root/site/components';

import { useSubscription } from './useSubscription';
import './styles.scss';

const Subscription = ({ showOnMain }) => {
	const [values, setValues] = useState();
	const message = useSubscription({ values });

	if (store.route.path !== '/' || showOnMain) {
		return (
			<div className='subscription pt-4'>
				<div className='container pb-3 pt-4'>
					<div className='row pt-5'>
						<div className='col-12 col-lg-5 mb-5'>
							<span className='subscription__text-header'>Подписка</span>
							<span className='subscription__text-subheader'>Оставьте ваш e-mail, чтобы получать новости</span>
						</div>
						<div className='col-12 col-lg-7 mb-5'>
							{message ? <SubscriptionMessage message={message} /> : <SubscriptionForm onSubmit={setValues} />}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Subscription;
