import React from 'react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import ReactSVG from 'react-svg';
import Slider from 'react-slick';
import sliderArrow from './img/sliderArrow.svg';
import calendar from 'root/site/img/calendar.svg';

import { ElementItem } from '../../components';

import { useGetRecords } from '../../utils/hooks';
import { dateAndTime } from '../../utils/date-format';

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 766 });
	return isDesktop ? children : null;
};

const PrevArrow = ({ onClick }) => <ReactSVG className="main-slider__arrow-prev" src={sliderArrow} onClick={onClick} />;
const NextArrow = ({ onClick }) => <ReactSVG className="main-slider__arrow-next" src={sliderArrow} onClick={onClick} />;
const resize = '?image=[{"resize":{"width":1110}}]';

const MainSlider = () => {
	const [records] = useGetRecords({
		model: 'MainSlide',
		options: {
			order: 'order DESC',
			include: ['article'],
		},
	});

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 10000,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1223,
				settings: {
					nextArrow: false,
					prevArrow: false,
				},
			},
		],
	};

	if (!records) {
		return <div className="main-slider-placeholder" />;
	}

	const filteredRecords = [];
	const formatRecords = () => {
		records.forEach(record => {
			const { id, article } = record;
			let date;
			let img;
			let link;
			let name;
			if (!article) {
				name = record.name;
				img = record.downloadFile('thumbnail');
				date = dateAndTime(record.createdAt);
				link = record.link;
				filteredRecords.push({ id, img, date, link, name });
				return;
			}
			const { publish, publishAt, removeAt } = article;
			if (
				publish &&
				(publishAt === null || Date.parse(publishAt) < Date.now()) &&
				(removeAt === null || Date.parse(removeAt) > Date.now())
			) {
				img =
					article && article.thumbnail
						? article.downloadFile('thumbnail')
						: article && article.imported && `/api/containers/articles/download/${article.image}`;
				date = article.publishAt && dateAndTime(article.publishAt);
				link = `/news/${article.id}`;
				name = article.name;
				filteredRecords.push({ id, img, date, link, name });
			}
		});
	};

	formatRecords();
	return (
		// <Desktop>
		<div className="container-fluid main-slider">
			<Slider {...settings}>
				{filteredRecords.map(slide => {
					const { id, name, link, img, date } = slide;
					return (
						<div className="main-slider-wrapper mb-4" key={`main-slide-${id}`}>
							<img className="main-slider-wrapper_img" src={img + resize} alt={name} />
							{/*<img className='main-slider-wrapper_img' src={img} alt="" />*/}
							<div className="main-slider-wrapper_text">
								<div className="main-slider-wrapper_text__wrap">
									<a href={link} className="main-slider-wrapper_text_title">
										{name}
									</a>
								</div>
								<div className="note-page__date note-page__date-white main-slider-wrapper_text_date">
									<ElementItem icon={calendar} fill="white" text={date} />
								</div>
							</div>
						</div>
					);
				})}
			</Slider>
		</div>
		// </Desktop>
	);
};

export default MainSlider;
