import React, { useEffect, useState } from "react";
import store from "root/site/store";
import HtmlValue from "@smartplatform/ui/src/components/editor/HtmlValue";
import "./Governance.css"

export default function GovernanceSingle(props) {
    const [item, getItem] = useState();
    useEffect(()=>{
        async function itogi(){
            const l = await store.model.Governance.findById(props.match.params.id);
            getItem(l)
        }
        itogi()
    }, []);

    if(!item) return <div>Загрузка...</div>;
    return <div className="container governance">
        {item.imported || <img src={item.image} alt={item.name} className="float-left m-3"/>}
        <HtmlValue value={item.text}/>

    </div>
}
