import React from 'react';
import { Section } from 'root/site/components/';
import { ElementItem } from 'root/site/components/ElementItem';
import { Link } from 'react-router-dom';

import calendar from 'root/site/img/calendar.svg';
import store from '../../store';
import { dateAndTime } from '../../utils/date-format';
import { Loader } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Badges } from 'root/site/components/article/Badges';

@observer
class DirectSpeech extends React.Component {
	@observable records;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		const records = await store.model.Article.find({
			where: { type: 'direct-speech', publish: true, publishAt: { lte: Date.now() } },
			include: ['views','tags'],
			limit: 3,
			order: 'publishAt DESC',
		});

		this.records = records;
	};

	render() {
		if (!this.records) {
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		}
		const type = 'direct-speech';

		return (
			<Section title="Прямая речь" link={'/direct-speech'}>
				<div className="row no-gutter">
					{this.records.length > 0 && (
						<div className="col-12 col-md-6 col-lg-8">
							<div className="direct-speech__main">
								<div className="direct-speech__main-wrap-img">
									<div className='badges'>
										<Badges article={this.records[0]} width={120} />
									</div>
									<img
										alt=""
										src={
											this.records[0].imported
												? `/api/containers/articles/download/${this.records[0].image}`
												: this.records[0].downloadFile('thumbnail')
										}
										className="direct-speech__main-img"
									/>
								</div>
								<div className="direct-speech__info">
									<Link to={`/${type}/${this.records[0].id}`}>
										<h1>{this.records[0].name}</h1>
									</Link>
									<ElementItem grey small text={dateAndTime(this.records[0].publishAt)} icon={calendar} top="2px" />
								</div>
							</div>
						</div>
					)}
					{this.records.length > 1 && (
						<div className="col-12 col-md-6 col-lg-4">
							{this.records.slice(1).map(record => {
								const { id, imported, name, publishAt } = record;
								const imgSrc = imported
									? `/api/containers/articles/download/${this.records[0].image}`
									: record.downloadFile('thumbnail');
								return (
									<div key={`direct-speech-${id}`} className="direct-speech__side direct-speech__side-grey">
										<div className="direct-speech__side-item">
											<div className="direct-speech__side-item-wrap-img">
												<div className='badges'>
													<Badges article={record} width={120} />
												</div>
												<img
													alt=""
													src={imgSrc}
													className="direct-speech__side-item-img direct-speech__side-item-img-small"
												/>
											</div>
											<div className="direct-speech__side-item-text mb-2">
												<Link to={`/${type}/${id}`}>
													<h4 className="mb-3 mt-3">{name}</h4>
												</Link>
												<ElementItem grey small text={dateAndTime(publishAt)} icon={calendar} top="2px" />
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</Section>
		);
	}
}
export default DirectSpeech;
