import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../button';
import Requests from './speech-requests';
import voting from '../store-site';

@observer
export default class RequestButtons extends React.Component {
	
	@observable isLoading = false;
	
	toggleRegister = async typeId => {
		if (this.isLoading) {
			console.log('- waiting for previous request');
			return;
		}
		this.isLoading = true;
		if (!voting.currentRegistration) {
			await voting.speechRequest(typeId);
		}
		else {
			await voting.speechCancel(typeId);
		}
		this.isLoading = false;
	};
	
	render() {
		if (!voting.member) {
			return null;
		}
		if (voting.isChairman) {
			return <Requests />;
		}
		const registeredType = voting.currentRegistration ? voting.currentRegistration.typeId : null;
		const isActive = voting.session.activeQueue && voting.session.activeQueue.userId === voting.user.id && !voting.session.activeQueue.finishedAt;
		return <div className="register-buttons">
			{!isActive ?
				<>
					<div>Заявка на выступление:</div>
					<div className={'speech-request' + (this.isLoading ? ' is-loading' : '')}>
						<Button
							className={'register-btn' + (registeredType === 1 ? ' active' : '')}
							// disabled={this.isLoading || (registeredType !== null && registeredType !== 1)}
							disabled={registeredType !== null && registeredType !== 1}
							onClick={() => this.toggleRegister(1)}>
							Выступление
						</Button>
						{voting.deputy && <>
							<Button
								className={'register-btn' + (registeredType === 2 ? ' active' : '')}
								// disabled={this.isLoading || (registeredType !== null && registeredType !== 2)}
								disabled={registeredType !== null && registeredType !== 2}
								onClick={() => this.toggleRegister(2)}>
								По ведению
							</Button>
							<Button
								className={'register-btn' + (registeredType === 3 ? ' active' : '')}
								// disabled={this.isLoading || (registeredType && registeredType !== 3)}
								disabled={registeredType && registeredType !== 3}
								onClick={() => this.toggleRegister(3)}>
								На вопросы
							</Button>
						</>}
					</div>
				</>
				:
				<>
					<div>Выступление</div>
					<div className="is-active"><FontAwesomeIcon icon={faMicrophone}/> Микрофон</div>
				</>
			}
		</div>
	}
	
}
