import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Form from './Form';
import Single from './Single';
import Confirm from './ConfirmEmail';
import './style.scss';

export default () => (
	<Switch>
		<Route path="/reception" exact component={Form} />
		<Route path="/reception/confirm" exact component={Confirm} />
		<Route path="/reception/:id" component={Single} />
	</Switch>
);
