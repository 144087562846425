import React from 'react';

import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import { Breadcrumbs, Subscription } from '../../components';

import { HtmlValue } from '@smartplatform/ui';
import { useBreadcrumbs, useGetRecord, useGetRecords } from 'root/site/utils/hooks';
import SingleAward from './SingleAward';

import LastUpdated from '../../components/last-updated';

import './styles.scss';

const AwardsPage = () => {
	useBreadcrumbs('Награды', '/awards');
	const [informationAwards] = useGetRecords({
		model: 'InformationAward',
		// id: 1,
		// setBreadcrumbs: false,
		options: { include: ['attachments'] },
	});

	const [awards] = useGetRecords({
		model: 'Award',
	});

	console.log('info', informationAwards);
	if (!informationAwards || !awards) {
		return <div />;
	}
	const information = informationAwards[0];
	const attachments = information.attachments();

	return (
		<>
			<div className="container">
				<div className="row justify-content-center mb-4">
					<div className="note-page-title mb-5 mt-5">
						<h1>Награды Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)</h1>
					</div>
				</div>
				<LastUpdated dateStr="23 декабря 2019" />
				<br />
				<br />
			</div>

			<div className="background-page-light-grey">
				<div className="container container_pb_90 container_pt_40 base-page">
					{awards.map(award => (
						<SingleAward key={`award-${award.id}`} awardId={award.id} />
					))}

					<div className="row">
						<div className="col information-award__text">
							{information && <HtmlValue value={information.text} className="information-award__text" />}
							{attachments.length > 0 &&
								attachments.map(e => (
									<div className="pt-5" key={e.id}>
										<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
										<p>
											<a href={e.downloadFile('filename')}>{e.name || e.filename}</a>
										</p>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AwardsPage;
