import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Popconfirm, Loader, Checkbox } from '@smartplatform/ui';
import { Button } from '../button';
import voting from '../store-site';
import { formatDateStr } from 'root/site/utils';
import store from '../../../store';
import './style.scss';

@observer
export default class SessionSummary extends React.Component {
	@observable isLoading = true;
	@observable isAdmin = false;
	@observable isDeputy = false;
	@observable deputies = [];
	@observable showDeputies = false;
	@observable changed = false;
	@observable quorum = '';
	@observable elected = '';
	@observable isSaving = '';
	@observable warned = false;
	@observable autoLogoutDelay = 10;
	@observable autoLogoutDelayError = false;
	@observable editingDelay = false;

	constructor(props) {
		super(props);
		voting.onInit(() => this.init());
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}

	init = async () => {
		this.autoLogoutDelay = store.local.autoLogoutDelay;
		if (store.model.user) {
			// this.isAdmin = await isAdmin();
			this.isAdmin = voting.isAdmin;
			this.isDeputy = !!voting.user.deputyId;
			this.deputies = voting.allDeputies;
		}
		this.quorum = voting.session.quorum;
		this.elected = voting.session.elected;
		console.log('summary', this.elected, voting.initialized);
		this.isLoading = false;
	};

	onSelectMount = el => (this.selectNode = el);

	onMouseDown = e => {
		if (this.selectNode && this.showDeputies && !this.selectNode.contains(e.target)) {
			this.showDeputies = false;
		}
	};

	showDeputiesList = e => {
		e.preventDefault();
		this.showDeputies = true;
	};

	setChairman = async deputy => {
		this.showDeputies = false;
		await voting.setChairman(deputy);
	};

	chairmanWarning = async e => {
		if (e) e.preventDefault();
		this.warned = true;
	};

	startRegistration = async e => {
		if (e) e.preventDefault();
		await voting.startRegistration();
	};

	onQuorumChange = e => {
		this.quorum = e.target.value;
		this.changed = true;
	};

	onElectedChange = e => {
		this.elected = e.target.value;
		this.changed = true;
	};

	save = async () => {
		this.changed = false;
		this.isSaving = true;
		if (parseInt(this.quorum)) {
			voting.session.quorum = parseInt(this.quorum);
		} else {
			this.quorum = voting.session.quorum;
		}
		if (parseInt(this.elected)) {
			voting.session.elected = parseInt(this.elected);
		} else {
			this.elected = voting.session.elected;
		}
		await voting.session.save();
		this.isSaving = false;
	};

	onAutoLogoutDelayChange = e => {
		this.autoLogoutDelay = e.target.value;
		this.autoLogoutDelayError = !parseInt(e.target.value);
		this.editingDelay = true;
	};

	onAutoLogoutDelaySubmit = () => {
		this.editingDelay = false;
		this.autoLogoutDelay = store.local.autoLogoutDelay = parseInt(this.autoLogoutDelay) || 10;
		store.local.save();
	};

	render() {
		if (this.isLoading) return <div className="session-summary" />;

		return (
			<div className="session-summary" key={voting.user ? voting.user.id : 0}>
				<div className="left">
					{/*<div><Link to={sessionUrl(voting.session)}>Перейти в заседание.</Link></div>*/}

					<div>
						<span className={'disc' + (voting.chairman ? ' green' : '')} />
						Председатель:&nbsp;
						{!voting.isAdmin ? (
							<>{voting.chairman ? <strong>{voting.fio(voting.chairman)}</strong> : <em>не назначен</em>}</>
						) : (
							<div className="chairman-select" ref={this.onSelectMount}>
								<a href="#" onClick={this.showDeputiesList}>
									{voting.chairman ? <strong>{voting.fio(voting.chairman)}</strong> : 'назначить'}
								</a>
								{this.showDeputies && (
									<div className="deputies-select-list">
										<div key="0" onClick={() => this.setChairman(null)}>
											Сбросить
										</div>
										{this.deputies.map(deputy => (
											<div key={deputy.id} onClick={() => this.setChairman(deputy)}>
												{voting.fio(deputy)}
											</div>
										))}
									</div>
								)}
							</div>
						)}
						{/*
					{store.local.showDebugInfo && <>
						<Button onClick={voting.testUpdates} variant="grey" size={20} style={{ marginLeft: 5 }}>test</Button>
						<Button onClick={voting.startUpdates} variant="grey" size={20} style={{ marginLeft: 5 }}>start</Button>
						<Button onClick={voting.clearAllTimers} variant="grey" size={20} style={{ marginLeft: 5 }}>clear</Button>
					</>}
*/}
					</div>

					<div>
						{voting.session.end ? (
							<span className="session-finished">
								<span className="disc red" />
								<strong>Сессия завершена</strong> <em>{formatDateStr(voting.session.end)}</em>
							</span>
						) : voting.session.registrationStart ? (
							<>
								<span className="disc green" />
								Идет регистрация. <em>Начало</em>: <strong>{formatDateStr(voting.session.registrationStart)}</strong>.
							</>
						) : (
							<>
								<span className="disc" />
								{voting.isAdmin ? (
									voting.chairman ? (
										<a href="#" onClick={this.startRegistration}>
											Начать регистрацию
										</a>
									) : this.warned ? (
										<>
											<span className="red-text">Председатель не назначен.</span>{' '}
											<a href="#" onClick={this.startRegistration}>
												Начать без председателя
											</a>
										</>
									) : (
										<a href="#" onClick={this.chairmanWarning}>
											Начать регистрацию
										</a>
									)
								) : (
									'Ожидание регистрации'
								)}
							</>
						)}
						{(voting.isAdmin || voting.isStaff || voting.isProtocolist) && (
							<span className="admin-links">
								{(voting.isAdmin || voting.isStaff) && (
									<>
										&nbsp;
										<a href={`/active-session/${voting.session.id}/online`} target="_blank">
											Мониторинг присутствующих
										</a>
									</>
								)}
								. &nbsp;
								<a href={`/active-session/${voting.session.id}/protocol`} target="_blank">
									Протокол заседания
								</a>
							</span>
						)}
					</div>

					{!voting.session.end && (
						<div>
							Всего зарегистрировано: <strong>{voting.presentDeputiesCount}</strong>&nbsp;
							{voting.isQuorum ? <span className="quorum green">Кворум</span> : <span className="quorum red">Кворума нет</span>}&nbsp;
						</div>
					)}

					{voting.isAdmin ? (
						<div className="session-settings">
							Кворум: <input type="text" value={this.quorum} onChange={this.onQuorumChange} />
							&nbsp; Избранные: <input type="text" value={this.elected} onChange={this.onElectedChange} />
							<Button onClick={this.save} size={20} disabled={!this.changed}>
								Сохранить
							</Button>
							{this.isSaving && <Loader size={18} />}
						</div>
					) : (
						<div className="session-settings">
							Кворум: <strong>{voting.session.quorum}</strong>. Избранные: <strong>{voting.session.elected}</strong>
						</div>
					)}
				</div>

				<div className={'right' + (voting.isAdmin ? ' admin' : '')}>
					<div>
						<span className={'disc' + (voting.member ? ' green' : '')} />
						{voting.deputy ? voting.fio(voting.deputy) : voting.isAdmin ? 'Администратор' : voting.fio(voting.user)}
					</div>

					{voting.isAdmin && (
						<div className="only-votes">
							<Checkbox
								value={!voting.onlyVotes}
								onChange={voting.switchOnlyVotes}
								disabled={voting.currentStage && voting.currentStage.voteStart && !voting.currentStage.voteEnd}
							/>
							Выводить результат
							<Checkbox value={store.local.autoLogout} className="auto-logout" onChange={v => (store.local.autoLogout = v)} />
							Автовыход&nbsp;
							<input type="text" className="logout-delay" value={this.autoLogoutDelay} onChange={this.onAutoLogoutDelayChange} />{' '}
							сек.&nbsp;
							{this.editingDelay && (
								<Button
									size={20}
									onClick={this.onAutoLogoutDelaySubmit}
									disabled={this.autoLogoutDelayError}
									title={this.autoLogoutDelayError ? 'Ошибка! Введите целое число' : ''}
								>
									Ok
								</Button>
							)}
						</div>
					)}

					{!voting.isAdmin
						? voting.session.registrationStart &&
						  !voting.session.end &&
						  voting.isOnline && (
								<div>
									{voting.member ? (
										<>
											<div>
												<span className="green-text">Вы зарегистрированы</span> ({formatDateStr(voting.member.date)})
											</div>
											<a href="#" onClick={voting.cancelMyRegistration}>
												Отозвать регистрацию.
											</a>
										</>
									) : !voting.isRegistering ? (
										<>
											<span className="red-text">Вы не зарегистрированы</span>.{' '}
											<a href="#" onClick={voting.register}>
												Зарегистрироваться.
											</a>
										</>
									) : (
										'Идет регистрация...'
									)}
								</div>
						  )
						: voting.isAdmin && (
								<div className="session-admin">
									<div>
										<Popconfirm content="Вы уверены?" onConfirm={voting.resetSession}>
											Сбросить заседание
										</Popconfirm>
										&nbsp;|&nbsp;
										<Popconfirm content="Вы уверены?" onConfirm={voting.deleteSession}>
											Удалить заседание
										</Popconfirm>
									</div>
									<div>
										<Popconfirm content="Вы уверены?" onConfirm={voting.finishSession}>
											Завершить заседание
										</Popconfirm>
									</div>
								</div>
						  )}

					{!voting.isAdmin && !voting.isProtocolist && (
						<div>
							{voting.isOnline ? (
								<span className="green-text">Онлайн</span>
							) : (
								<>
									<span className="red-text">Оффлайн</span>. Проверьте Интернет-соединение
								</>
							)}
						</div>
					)}

					{/*<div>currentQuestion: {voting.currentQuestion ? voting.currentQuestion.id : 'null'}</div>*/}
				</div>
			</div>
		);
	}
}
