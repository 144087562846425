import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import logo from './img/logo.png';
import logo_black from './img/logo_black.png';

import store from 'root/site/store';
const { ui } = store;

export const Title = () => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	const isMobile = useMediaQuery({ maxWidth: 767 });

	return (
		<Link to="/" className="header__title">
			{isMobile ? (
				<div className="header__logo-box">
					<figure className="header__logo">
						{/*<img src={(isMobile && !ui.smallMenu) ? logo_black : logo} alt="logo" className="header__logo-image" />*/}
						<img src={logo} alt="logo" className="header__logo-image" />
					</figure>
				</div>
			) : (
				<>
					<span className="header__title-block">
						<strong>Государственное&nbsp;собрание&nbsp;(ИЛ&nbsp;ТУМЭН)</strong>
						Республики&nbsp;Саха&nbsp;(Якутия)
					</span>
				</>
			)}
		</Link>
	);
};
