import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/О гос гражданской службе РС(Я) (ред от 19_06_2019).doc'
import document2 from './docs/О реестре государственных должностей РС(Я) (ред от 30_05_2017).doc'
import document3 from './docs/Об утверждении положения о кадровом резерве на ГГС РС(Я) (ред от 14_08_2017).doc'
import document4 from './docs/О кодексе этики и служебного поведения (ред от 18_01_2019)_0.doc'
import document5 from './docs/О некоторых вопросах развития ГГС РС(Я).doc'

export default class NPA extends React.Component {
    render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pt_40">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-3 pb-5">
                            <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                                Нормативные правовые акты РФ и РС(Я) регулирующие вопросы поступления, прохождения государственной гражданской службы, формирования кадрового резерва
                            </h2>
                            <div className="pb-md-5" style={{textAlign : 'center'}}>
					            <p><b>Федеральное законодательство</b></p>
					        </div>
                        </div>
                        <div className="col-12 col-md-10">
                        <ul className="text__ul ml-5">
                            <li><span><a href="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=102081744" target="_blank">Федеральный закон Российской Федерации от 27 мая 2003 года № 58-ФЗ "О системе государственной службы Российской Федерации"</a></span></li>
                            <li><span><a href="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=102088054" target="_blank">Федеральный закон Российской Федерации от 27 июля 2004 года № 79-ФЗ "О государственной гражданской службе Российской Федерации"</a></span></li>
                            <li><span><a href="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=102090879" target="_blank">Указ Президента Российской Федерации от 1 февраля 2005 года № 110 "О проведении аттестации государственных гражданских служащих Российской Федерации"</a></span></li>
                            <li><span><a href="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=102090880" target="_blank">Указ Президента Российской Федерации от 1 февраля 2005 года № 111 "О порядке сдачи квалификационного экзамена государственными гражданскими служащими Российской Федерации и оценки их знаний, навыков и умений (профессионального уровня)"</a></span></li>
                            <li><span><a href="http://pravo.gov.ru/proxy/ips/?docbody=&link_id=0&nd=102139510" target="_blank">Указ Президента Российской Федерации от 1 июля 2010 года № 821 "О комиссиях по соблюдению требований к служебному поведению федеральных государственных служащих и урегулированию конфликта интересов"</a></span></li>
                        </ul>
                        </div>
                        <div className="col-12 pt-5">
                            <div className="pb-md-5" style={{textAlign : 'center'}}>
					            <p><b>Законодательство Республики Саха (Якутия)</b></p>
					        </div>
                        </div>
                        <div className="col-12 col-md-10">
                            <div className="pt-5">
                                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                                <p>
                                    <a href={document}>Закон Республики Саха (Якутия) от 30 марта 2005 года 215-З № 433-III (ред. от 19.06.2019) "О государственной гражданской службе Республики Саха (Якутия)"</a> (MS Word, 224 Кб)
                                </p>
                            </div>
                            <div className="pt-5">
                                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                                <p>
                                    <a href={document2}>Закон Республики Саха (Якутия) от 11 октября 2006 года 385-З № 783-III (ред. от 30.05.2017) "О реестре государственных должностей Республики Саха (Якутия) и должностей государственной гражданской службы Республики Саха (Якутия)" </a>(MS Word, 203 Кб)
                                </p>
                            </div>
                            <div className="pt-5">
                                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                                <p>
                                    <a href={document3}>Указ Президента Республики Саха (Якутия) от 28 марта 2014 года № 2550 (ред. от 14.08.2017 ) "Об утверждении Положения о кадровом резерве на государственной гражданской службе Республики Саха (Якутия)" </a>(MS Word, 156 Кб)
                                </p>
                            </div>
                            <div className="pt-5">
                                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                                <p>
                                    <a href={document4}>Указ Президента Республики Саха (Якутия) от 16.01.2009 N 1278 (ред. от 18.01.2019) "О Кодексе этики и служебного поведения лиц, замещающих государственные должности Республики Саха (Якутия), назначаемых и освобождаемых от должности Главой Республики Саха (Якутия), и государственных гражданских служащих Республики Саха (Якутия)" </a>(MS Word, 87,5 Кб)
                                </p>
                            </div>
                            <div className="pt-5">
                                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                                <p>
                                    <a href={document5}>Указ Главы Республики Саха (Якутия) от 03 октября 2016 N 1421 "О некоторых вопросах развития государственной гражданской службы Республики Саха (Якутия)" </a>(MS Word, 64 Кб)
                                </p>
                            </div>
                        </div>
                    </div>
				</div>
            </div>
        );
    }
}
