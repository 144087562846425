import React from 'react';
import { Redirect } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSignOutAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';

import { Tabs, Tab } from '../../components/route-tabs';
import store from '../../store';
import Main from './Main';
import DeputyAppeals from './deputy/appeals';
import DeputyDispatches from './deputy/dispatches';
import CitizenAppeals from './citizen/appeals';
import DeputyAppointments from './deputy/appointments';
import CitizenAppointments from './citizen/appointments';
import Committees from './Committees';
import Plenary from './Plenary';
import './style.scss';

@observer
export default class Profile extends React.Component {
	
	@observable user = null;
	@observable canViewSessions = false;
	@observable committeeSessions = false;
	@observable plenarySessions = false;
	@observable canEdit = false;
	@observable isMounted = true;
	
	timer = null;

	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidMount() {
		store.ui.setBreadcrumbs([{ name: 'Профиль', link: '/profile' }]);
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer);
		this.isMounted = false;
		this.canViewSessions = false;
		this.canEdit = false;
	}
	
	init = async () => {
		if (store.model.user) {
			try {
				this.user = await store.model.User.findById(store.model.user.id);
				const roleNames = store.model.roles ? store.model.roles.map(role => role.name) : [];
				
				this.canViewSessions = roleNames.includes('admin') ||
					roleNames.includes('invited') ||
					roleNames.includes('staff') ||
					roleNames.includes('protocolist') ||
					roleNames.includes('session ruler') ||
					roleNames.includes('deputy');
				
				this.canEdit = roleNames.includes('admin') ||
					roleNames.includes('press') ||
					roleNames.includes('award') ||
					roleNames.includes('sessions') ||
					roleNames.includes('editor') ||
					roleNames.includes('actual acts') ||
					roleNames.includes('speaker secretariat') ||
					roleNames.includes('discussions') ||
					roleNames.includes('appeals') ||
					roleNames.includes('dispatches') ||
					roleNames.includes('static');

				await this.checkActiveSessions();
			}
			catch(e) {
				if (e.statusCode === 401) this.gotoLogin();
			}
		}
		else {
			this.gotoLogin();
		}
	};

	gotoLogin = () => {
		store.model.user = null;
		store.model.isAuthorized = false;
		store.route.push('/login');
	};
	
	/**
	 * Проверка на наличие активных заседаний.
	 */
	checkActiveSessions = async () => {
		if (!this.canViewSessions || !this.isMounted) return;
		
		const activeCommitteeSessions = await store.model.Session.find({
			where: {
				and: [
					{ registrationStart: { neq: null } },
					{ committeeSessionId: { neq: null } },
					{ end: null },
				],
			},
			fields: ['id', 'registrationStart', 'end', 'committeeSessionId'],
		});
		this.committeeSessions = activeCommitteeSessions.length || false;
		
		const activePlenarySessions = await store.model.Session.find({
			where: {
				and: [
					{ registrationStart: { neq: null } },
					{ plenarySessionId: { neq: null } },
					{ end: null },
				],
			},
			fields: ['id', 'registrationStart', 'end', 'plenarySessionId'],
		});
		this.plenarySessions = activePlenarySessions.length || false;
		
		this.timer = setTimeout(this.checkActiveSessions, 2000);
	};
	
	logout = async () => {
		await store.model.logout();
	};
	
	edit = () => {
		store.route.path = '/profile/edit';
		store.route.push();
	};
	
	admin = () => {
		window.open('http://iltumen.ru/admin', '_blank');
	};
	
	render() {
		if (!store.model.isAuthorized) return <Redirect to="/login" />;
		
		// console.log('canViewSessions', this.canViewSessions);
		
		return <div className="container profile-main">
			<div className="profile-header row">
				<div className="col-lg-4 col-md-4 col-sm-12 col-12"><h2>Личный кабинет</h2></div>
				<div className="col-lg-8 col-md-8 col-sm-12 col-12 profile-buttons">
					{store.model.isAuthorized && (this.canEdit || store.model.Article.INFO.WRITE || (store.model.Dispatch && store.model.Dispatch.INFO.WRITE)) &&
						<button className="white-btn" onClick={this.admin}>Управление <FontAwesomeIcon icon={faUserCog} /></button>
					}
					<button className="white-btn" onClick={this.edit}>Редактировать <FontAwesomeIcon icon={faPencilAlt} /></button>
					<button className="white-btn" onClick={this.logout}>Выход <FontAwesomeIcon icon={faSignOutAlt} /></button>
				</div>
			</div>
			{this.canViewSessions ?
				<Tabs>
					<Tab path="/profile" exact title="Профиль"><Main /></Tab>
					<Tab path="/profile/appeals" title="Обращения граждан"><DeputyAppeals /></Tab>
					<Tab path="/profile/appointments" title="Записи на прием"><DeputyAppointments /></Tab>
					<Tab path="/profile/dispatches" title="Уведомления"><DeputyDispatches /></Tab>
					<Tab path="/profile/committee" title="Заседания комитетов" badge={this.committeeSessions}><Committees user={this.user} /></Tab>
					<Tab path="/profile/plenary" title="Пленарные заседания" badge={this.plenarySessions}><Plenary /></Tab>
				</Tabs>
				:
				<Tabs>
					<Tab path="/profile" exact title="Профиль"><Main /></Tab>
					<Tab path="/profile/appeals" title="Мои обращения"><CitizenAppeals /></Tab>
					<Tab path="/profile/appointments" title="Записи на прием"><CitizenAppointments /></Tab>
				</Tabs>
			}
		</div>;
	}
	
}
