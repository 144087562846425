import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { isAfter } from 'date-fns';

import { Loader } from '@smartplatform/ui';
import { trimmedName, formatDate } from '../documents/tools';
import store from '../../store';
import { getStartNumDeputyLaws } from '../../utils/getStartNumDeputyLaws';


@withRouter @observer
export default class DeputyLaws extends React.Component {
	
	@observable deputy = null;
	@observable laws = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		this.deputy = await store.model.Deputy.findById(this.id);
		
		const organiz = await store.model.OrganizCl.find({ where: { deputyId: this.id }})
		if (organiz.length > 0) {
			const res = await store.model.RefCorresp.find({
				where: { organizationId: { inq: organiz.map(o => o.id) } },
				include: [
					{
						relation: 'document',
						scope: {
							fields: ['id'],
							include: [
								{
									relation: 'refLinks',
									scope: {
										fields: ['id', 'childId'],
										where: {
											name: { ilike: '%Закон Республики Саха (Якутия)%' },
										},
										include: [
											{
												relation: 'child',
												scope: {
													fields: ['id', 'name', 'year', 'date', 'freeNum']
												}
											},
										]
									}
								}
							]
						}
					}
				],
			})

			const laws = [];
			res.forEach(r => {
				if (r.document && r.document.refLinks() && r.document.refLinks().length > 0) {
					const refLink = r.document.refLinks()[0];
					if (refLink.child && isAfter(new Date(refLink.child.date), new Date(2018, 8, 24))) {
					// if (refLink.child) {
						laws.push(refLink.child);
					}
				}
			});

			const years = {};
			laws.forEach(law => {
				if (!years[law.year]) years[law.year] = [];
				years[law.year].push(law);
			});
			console.log('laws', years);
			this.laws = years;
		}

		store.ui.setBreadcrumbs([
			{ name: 'Депутаты', link: '/deputies' },
			{
				name: `${this.deputy.lastName} ${this.deputy.firstName} ${this.deputy.middleName}`,
				link: `/deputies/${this.id}`,
			},
			{
				name: `Законы инициированные депутатом`,
				link: `/deputies/${this.id}/laws`,
			},
		]);
	}
	
	render() {
		if (!this.deputy) {
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		}
		
		const deputy = this.deputy;
		const deputyName = `${deputy.lastName} ${deputy.firstName} ${deputy.middleName}`;
		
		return <div>
			<div className="container">
				<div className="row mb-4 mt-5">
					<div className="col-12">
						<h3 className="header-2">
							<span>
								Законы, инициированные депутатом. {deputyName}
							</span>
						</h3>
					</div>
				</div>
			</div>
			<div className="container base-page deputy-details">
				{Object.keys(this.laws).length > 0 && (
					<div className="laws">
						{Object.keys(this.laws)
							.sort((a, b) => +b - +a)
							.map((year, i, arr) => {
								
								const startNum = getStartNumDeputyLaws(this.laws, i, arr);
								
								return <div key={year} className='year'>
									<p><strong>{year}</strong></p>
									<ol start={startNum}>
										{this.laws[year].map(law => <li key={law.id}>
											<Link
												to={`/documents/${law.id}`}>{law.freeNum} от {formatDate(new Date(law.date))} "{trimmedName(law.name)}"</Link>
										</li>)}
									</ol>
								</div>;
							})}
					</div>
				)}
			</div>
		</div>;
	}
}
