import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import format from 'date-fns/format';

import { Column, Table } from '@smartplatform/ui';
import store from '../../store';
import './style.scss';

@observer
export default class List extends React.Component {
	@observable records = null;

	constructor(props) {
		super(props);
		this.init();
		store.ui.setBreadcrumbs([{ name: 'Общественные обсуждения', link: '/discussions' }]);
	}

	init = async () => {
		const getUserRole = async () => {
			const id = store.model.user && store.model.user.id;
			return (
				id &&
				(await store.model.RoleMapping.findOne({
					where: { principalId: id },
				}))
			);
		};

		const userRole = await getUserRole();

		const allDiscussions = await store.model.Discussion.find({
			where: {
				publish: true,
				startDate: { lt: Date.now() },
				// endDate: { gt: Date.now() },
			},

			include: [
				{ relation: 'comments', scope: { fields: { id: true, publish: true } } },
				userRole ? { relation: 'roles' } : {},
			],
			order: 'createdAt DESC',
		});

		const getDiscussions = async () => {
			const records = [];
			await allDiscussions.forEach(async discussion => {
				if (discussion.allRoles) {
					records.push(discussion);
					return;
				}

				if (!userRole) {
					return;
				}

				const discussionRoles = discussion.roles();
				if (discussionRoles.find(role => role.id === userRole.roleId) || discussion.registeredOnly) {
					records.push(discussion);
					return;
				}
			});
			return records;
		};

		this.records = await getDiscussions();
	};

	docName = document => (
		<span title={document.id}>
			{document.freeNum}: {document.name}
		</span>
	);

	count = record => record.comments().filter(comment => comment.publish).length;
	status = record => (new Date(record.endDate) > Date.now() ? 'Идет обсуждение' : 'Завершено');
	startDateFormating = record => format(new Date(record.startDate), 'dd.MM.yyyy');

	render() {
		const table = (
			<Table records={this.records} path="discussions">
				<Column multiline property="name" label="Заголовок" />
				<Column width={100} computed={this.startDateFormating} label="Начало обсуждений" />
				<Column width={100} computed={this.count} label="Комментариев" />
				<Column width={150} computed={this.status} label="Статус" />
			</Table>
		);

		if (!this.records) return '...';

		return (
			<div className="container base-page discussions">
				<h1>Общественные обсуждения</h1>
				{this.records && this.records.length > 0 ? table : 'В настоящий момент открытых обсуждений нет'}
			</div>
		);
	}
}
