import React from 'react';
import { Switch, Route } from 'react-router-dom';

import store from '../../store';
import List from './List';
import Deputy from './Deputy';
import DeputyNews from './DeputyNews';
import DeputyLaws from './DeputyLaws';
import './style.scss';

export default class Deputies extends React.Component {
	componentDidMount() {
		store.ui.setBreadcrumbs([{ name: 'Депутаты', link: '/deputies' }]);
	}

	componentWillUnmount() {
		store.ui.setBreadcrumbs([]);
	}

	render() {
		return (
			<Switch>
				<Route path="/deputies" exact component={List} />
				<Route path="/deputies/conv" component={List} />
				<Route path="/deputies/:id" exact component={Deputy} />
				<Route path="/deputies/:id/news" component={() => <DeputyNews />} />
				<Route path="/deputies/:id/laws" component={() => <DeputyLaws />} />
			</Switch>
		);
	}
}
