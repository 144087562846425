import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import store from '../../store';
import './style.scss';
import MidArticle from 'root/site/components/article/MidArticle';
import { Link } from 'react-router-dom';
import { MediaPreview, VideoPreview } from 'root/site/components';
import { HtmlValue, Pager } from '@smartplatform/ui';
import MediaQuery from 'react-responsive/src';
import Banner from 'root/site/pages/main/Banner';

const PER_PAGE = 10;
@observer
export default class TagMaterials extends React.Component {
	@observable isLoading = true;
	@observable records = [];
	@observable page = 1;
	@observable selectedPhoto = null;
	@observable selectedVideo = null;
	@observable tag = null;

	constructor(props) {
		super(props);
		this.init();
		this.ref = React.createRef()
	}


	@action init = async () => {
		const { tagPath } = this.props.match.params;
		const { route: { params } } = store;
		this.page = parseInt((params && params.page) || 1);

		try {
			this.tag = store.ui.tags.find(tag => tag.path === tagPath) || null;
			if (!this.tag) throw new Error('Категория не найдена');
			const records = await store.model.ViewTagMaterial.find({
				where: {
					tagPath,
					publish: true,
					publishAt: { lte: Date.now() },
				},
				limit: PER_PAGE,
				skip: (this.page - 1) * PER_PAGE,
				order: 'publishAt desc',
			});
			this.totalCount = records.totalCount;
			records.forEach(rec => rec.downloadFile = function(property) {
				return store.model.downloadFile(store.model[rec.model], rec.id, property);
			});
			this.records = records;

		} catch (e) {
			console.error('TagMaterial init error', e);
		}

		//* патч метода загрузки в зависимости от модели записи


		if (this.records.length < PER_PAGE) this.records = [...this.records, ...new Array(PER_PAGE - this.records.length)];


		this.isLoading = false;
	};

	onPageChange = (page) => {
		this.page = page;
		this.init();
	};

	getItemLink = (item, children) => {

		switch (item.model) {
			case `Article`:
				return <Link to={`/news/${item.id}`}>{children || <h3 className='header-3'>{item.name}</h3>}</Link>;
			case `Gallery`:
				return <a onClick={() => this.selectedPhoto = item}>{children ||
				<h3 className='header-3'>{item.name}</h3>}</a>;
			case `Media`:
				return <a onClick={() => this.selectedVideo = item}>{children ||
				<h3 className='header-3'>{item.name}</h3>}</a>;
		}
	};


	render() {
		if (this.isLoading) return '...';
		if ((this.records.length === 0) || !this.tag) return <div className='container mt-5 mb-5'>Материалов с таким
			тегом не
			найдено</div>;

		return <div className='tagmaterial-list container mt-5 mb-5'>    {/* попапы */}
			{!this.selectedPhoto && this.selectedVideo && (
				<VideoPreview
					record={this.selectedVideo}
					onClose={() => {
						this.props.history.pushState({}, ``, `/`);
						this.selectedVideo = null;
					}}
					onClick={this.props.history.push(`/videogallery/${this.selectedVideo.id}`)}
				/>
			)}
			{!this.selectedVideo && this.selectedPhoto && (
				<MediaPreview
					galleryId={this.selectedPhoto.id}
					onClose={() => {
						this.props.history.pushState({}, ``, `/`);
						this.selectedPhoto = null;
					}}
					onClick={this.props.history.push(`/photogallery/${this.selectedPhoto.id}`)}
				/>
			)}


			{/*<img className='image' src={this.tag?.downloadFile('image')} alt='description' />*/}

			<HtmlValue value={this.tag?.description} />
			<div style={{position:'relative'}}>
			<div style={{position:'absolute',top:-85}} ref={this.ref}></div>
			</div>
			<MediaQuery minWidth={1223} >
				{[...new Array(2).fill(null)].map((v, i) => (
					<div key={i} className='d-flex justify-content-between'>
						{this.records.slice(i * 5, i * 5 + 5).map((article, i) => (
							<MidArticle article={article} key={i} getItemLink={this.getItemLink} />
						))}
					</div>
				))}
			</MediaQuery>

			<MediaQuery minWidth={768} maxWidth={1222}>
				{[...new Array(5).fill(null)].map((v, i) => (
					<div key={i} className='d-flex justify-content-between'>
						{this.records.slice(i * 2, i * 2 + 2).map((article, i) => (
							<MidArticle article={article} key={i} getItemLink={this.getItemLink} />
						))}
					</div>
				))}
			</MediaQuery>


			<MediaQuery maxWidth={767}>
				{this.records.map((article, i) => (
					<MidArticle article={article} key={i} getItemLink={this.getItemLink} />
				))}
			</MediaQuery>


			<Pager
				itemsPerPage={PER_PAGE}
				totalCount={this.totalCount}
				current={this.page}
				onChange={async(page) => {
					store.route.push({ params: { ...store.route.params, page: page } },
					);
					await this.init();
					this.ref.current.scrollIntoView();
				}}
				className='iltumen-pager'
				directChange={true}
			/>
		</div>
	}
}

