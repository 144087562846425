import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter, Route, Switch } from 'react-router-dom';

import * as Pages from './pages';
import { Page, PageWithMenu, PageWithTabs } from './components';
import GovernanceSingle from 'root/site/pages/governance/GovernanceSingle';
import Search from 'root/site/pages/search/Search';
import PlenaryList from 'root/site/pages/plenarysingle/PlenaryList';
import PlenarySinglePage from 'root/site/pages/plenarysingle/PlenarySinglePage';
import FindPage from './FindPage';

@withRouter
@observer
export default class UILayer extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path='/subscriptions/:hash' component={Pages.Subscriptions} />
				<Route path="/" exact component={Pages.MainPage} />
				<Route path="/tag/:tagPath" exact component={Pages.TagMaterials} />
				<Route path="/news" component={Pages.NewsPage} />
				<Route path="/direct-speech" component={Pages.DirectSpeech} />
				{/* <Route path="/expert-opinion" component={Pages.ExpertOpinion} /> */}
				<Route path="/login" component={Pages.Auth} />
				<Route path="/profile/edit" component={Pages.ProfileEdit} />
				<Route path="/profile" component={Pages.Profile} />
				<Route path="/photogallery" component={Pages.GalleryPage} />
				<Route path="/sessions" component={Pages.Sessions} />
				
				<Route path="/videogallery" component={Pages.VideoGallery} />
				<Route path="/contacts" exact component={Pages.ContactsPage} />
				{/* <Route path="/newsingle" exact component={Pages.NewSinglePage} /> */}
				{/* <Route path="/credentials" exact component={Pages.CredentialsPage} /> */}
				{/* <Route path="/opendata" exact component={Pages.OpenDataPage} /> */}
				{/* <Route path="/officialsymbols" exact component={Pages.OfficialSymbolsPage} /> */}
				{/* <Route path="/constitution" component={Pages.ConstitutionPage} /> */}
				{/* <Route path="/historical-review" component={Pages.HistoricalReview} /> */}
				{/* <Route path="/position" component={Pages.PositionPage} /> */}
				{/* <Route path="/status" component={Pages.StatusPage} /> */}
				{/* <Route path="/budget-resources" component={Pages.BudgetPage} /> */}
				{/* <Route path="/representatives" component={Pages.RepresentativesPage} /> */}
				{/*<PageWithTabs path="/analytics" code="analytics"/>*/}

				{/* <Route path="/staffing" component={Pages.StaffingPage} /> */}
				{/* <Route path="/apparatus" component={Pages.ApparatusPage} /> */}

				{/* <Route path="/deliberative" component={Pages.DeliberativePage} /> */}
				<Route path="/deputies" component={Pages.DeputiesPage} />
				{/*<Route path="/deputy-associations" component={Pages.AssociationsPage} />*/}
				<Route path="/fractions" component={Pages.Fractions} />
				<Route path="/committees" component={Pages.Committees} />
				<Route path="/information-analytical-activity" component={Pages.informationAnalyticalActivity} />
				{/* <Route path="/anti-corruption" component={Pages.AntiCorruptionPage} /> */}
				<Route path={`/news/:id/print`} component={Pages.GovernancePage} />
				<Route path="/reception" component={Pages.Reception} />
				<Route path="/appointments" component={Pages.Appointments} />
				{/*<Route path="/executive-functions" component={Pages.ExecutiveFunctions} />*/}
				<Route path="/documents" component={Pages.Documents} />
				{/*<Route path='/statistics' component={Pages.Statistics} />*/}
				<Route path="/discussions" component={Pages.Discussions} />
				<Route path="/deputy-search" component={Pages.DeputySearch} />
				<Route path="/search" exact component={Search} />
				<Route path="/search/:query" component={Search} />
				<Route path="/plenary" exact component={PlenaryList} />
				<Route path="/plenary/:id" exact component={PlenarySinglePage} />
				<Route path="/itogi" component={Pages.Itogi} />
				<Route path="/meeting/:id" component={Pages.Meeting} />
				<Route path="/pobeda75" component={Pages.Pobeda75} />
				<Route path="/online" component={Pages.Online} />
				<Route path="/online-local" render={() => <Pages.Online network="local" />} />
				<Route path="/online-espd" render={() => <Pages.Online network="espd" />} />

				{/* Пока оставить как есть */}
				<Route path="/speaker" component={Pages.ChairpersonPage} />
				{/* Есть соответствующая модель и админка */}
				<Route path="/governance" exact component={Pages.GovernancePage} />
				<Route path="/governance/:id" component={GovernanceSingle} />
				<Route path="/awards" component={Pages.AwardsPage} />
				<Route path="/media-accreditation" component={Pages.MediaPage} />
				<Route path="/speaker-plan" component={Pages.WorkPlan} />
				{/* С этим не понятно, что делать */}
				<Route path="/onepagelaw" exact component={Pages.OnePageLaw} />

				{/* Страницы, которые перейдут в новую систему */}
				{/*<PageWithTabs key="officialsymbols" path="/officialsymbols" code="officialsymbols" />*/}
				{/*<PageWithTabs key="constitution" path="/constitution" code="constitution" />*/}
				{/*<PageWithTabs key="historical-review" path="/historical-review" code="historical-review" />*/}
				{/*<PageWithTabs key="position" path="/position" code="position" />*/}
				{/*<PageWithTabs key="status" path="/status" code="status" />*/}
				{/*<PageWithTabs key="representatives" path="/representatives" code="representatives" />*/}
				{/*<PageWithTabs key="staffing" path="/staffing" code="staffing" />*/}
				{/*<PageWithMenu key="deliberative" path="/deliberative" code="deliberative" />*/}
				{/*<PageWithMenu key="anti-corruption" path="/anti-corruption" code="anti-corruption" />*/}

				<Route path="/committee-session/:id" component={Pages.CommitteeSession} />
				<Route path="/plenary-session/:id" component={Pages.Meeting} />
				<Route path="/verify_success" component={Pages.VerifySuccess}/>
				<Route>
					<FindPage />
				</Route>
			</Switch>
		);
	}
}
