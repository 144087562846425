import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/Положение_о_депутатском_расследовании.doc';

import LastUpdated from '../../components/last-updated';

export default class RegulationsInvestigation extends React.Component {
	
	accordions = [];
	state = { allClosed: true };
	
	accInstance = instance => {
		this.accordions.push(instance);
	};
	
	toggleAll = e => {
		e.preventDefault();
		if (this.state.allClosed) {
			this.accordions.forEach(accordion => accordion.open());
		}
		else {
			this.accordions.forEach(accordion => accordion.close());
		}
		this.setState({ allClosed: !this.state.allClosed });
	};
	
	render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pb_90 container_pt_40">
				<div className="row">
				<div className="col-12 pt-3 pb-5">
                <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                Положение о депутатском расследовании
					</h2>
					
					<LastUpdated dateStr="23 декабря 2019" /><br /><br />
					
					
					<div className="pb-5" style={{textAlign : 'center'}}>
					<p>Утверждено постановлением Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от 15 июня 2005 года ГС № 1097-III</p>
					</div>

                    <div className="pb-5" style={{textAlign : 'center'}}>
					<p><em>(в ред. постановлений Государственного Собрания (Ил Тумэн) РС(Я)</em></p>
					<p><em>от 30 мая 2006 года ГС № 1679-III, от 19 июня 2008 года ГС № 120-IV,</em></p>
					<p><em>от 17.06.2015 года ГС № 629-V)</em></p>
					</div>
                    <div className="history-text-page">
                        <p className="pb-5">
                            Настоящее Положение определяет основания, порядок и сроки проведения депутатских расследований, компетенцию комиссии по депутатскому расследованию (далее – комиссия) и особенности статуса членов указанной комиссии, обязанности и ответственность органов государственной власти Республики Саха (Якутия), органов местного самоуправления, граждан и негосударственных организаций в создании условий для проведения депутатских расследований.
                        </p>
                    </div>
					
					<p><a href="#" onClick={this.toggleAll}>{this.state.allClosed ? 'Развернуть все' : 'Свернуть все'}</a><br/><br/></p>

					<Accordion getInstance={this.accInstance} title="1. Общие положения">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Депутатские расследования являются одной из форм контроля Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее - Государственное Собрание) за соблюдением и исполнением законодательства Республики Саха (Якутия) органами государственной власти Республики Саха (Якутия) и органами местного самоуправления и проводятся в целях:</p></li>
							<ol className="text__ol_strong ml-5">
                                <li><p>укрепления демократического порядка организации и функционирования государственной власти;</p></li>
                                <li><p>информирования общества об обстоятельствах и о причинах негативных событий, имеющих общественную значимость, и выработки рекомендаций по ликвидации последствий указанных событий.</p></li>
                            </ol>
                            <li><p>Депутатское расследование не подменяет собой предварительное следствие, проводимое правоохранительными органами, и рассмотрение дел в судах.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="2. Основания для проведения депутатского расследования">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Депутатское расследование проводится на основании:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>обращений граждан, поступивших в Государственное Собрание или в адрес народных депутатов Республики Саха (Якутия) (далее - депутат), фракций политических партий, представленных в Государственном Собрании (далее - фракция), о совершенных и предполагаемых нарушениях законодательства Республики Саха (Якутия);</p>
                                <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 19.06.2008 ГС N 120-IV)</em></p></li>
                                <li><p>информации уполномоченных органов о фактах неисполнения или ненадлежащего исполнения законодательства Республики Саха (Якутия), ставших известными в результате соответствующих проверок;</p></li>
                                <li><p>сообщений в средствах массовой информации о совершенных и предполагаемых нарушениях законодательства Республики Саха (Якутия).</p></li>
                            </ol>
							<li><p>Депутатское расследование может проводиться в случае возникновения территориальных споров по изменению границ муниципальных образований Республики Саха (Якутия).</p></li>
							<li><p>Депутатское расследование проводится в случае совершения действий, несовместимых с установленным законодательством Республики Саха (Якутия) статусом:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>депутата;</p></li>
                                <li><p>должностного лица органа государственной власти Республики Саха (Якутия) и органа местного самоуправления;</p></li>
                                <li><p>Уполномоченного по правам человека в Республике Саха (Якутия);</p></li>
                                <li><p>Уполномоченного по правам ребенка в Республике Саха (Якутия).</p></li>
                            </ol>
							<li><p>Депутатское расследование проводится в случае выяснения причин и обстоятельств аварий и катастроф, повлекших за собой человеческие жертвы или нанесших значительный материальный ущерб, а также в других случаях.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="3. Инициатива проведения депутатского расследования ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Право инициировать депутатское расследование в Государственном Собрании принадлежит депутату, фракции или группе депутатов.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 19.06.2008 ГС N 120-IV)</em></p></li>
							<li><p>При инициировании депутатского расследования на имя Председателя Государственного Собрания либо на имя заместителя Председателя Государственного Собрания направляется письменное обращение, подписанное членом фракции, уполномоченным подписывать документы, исходящие от фракции, в соответствии с положением о фракции, или группой депутатов в количестве не менее одной пятой от числа избранных депутатов или поддержанное решением постоянного комитета Государственного Собрания.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 19.06.2008 ГС N 120-IV)</em></p>
                            <p>Указанное обращение должно содержать:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>краткое описание фактов и событий, подлежащих расследованию;</p></li>
                                <li><p>указание на источник, огласивший факты и события, подлежащие расследованию, кроме случаев очевидных событий;</p></li>
                                <li><p>обоснование общественной значимости фактов и событий, подлежащих расследованию;</p></li>
                                <li><p>предложение по персональному составу комиссии.</p></li>
                            </ol>
                            <li><p>Председатель Государственного Собрания либо заместитель Председателя Государственного Собрания обязан на ближайшем пленарном заседании поставить на голосование вопрос о необходимости проведения депутатского расследования. Решение о проведении депутатского расследования принимается большинством голосов от числа избранных депутатов и оформляется постановлением Государственного Собрания.</p></li>
                        </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="4. Формирование комиссии ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Состав комиссии утверждается постановлением Государственного Собрания.</p></li>
							<li><p>Количественный состав комиссии не может быть менее пяти и более пятнадцати человек.</p></li>
							<li><p>В состав комиссии входят представители фракций. Если фракция не представляет своего представителя в состав комиссии, это не является препятствием для ее создания.</p>
							<p><em>(п. 3 введен постановлением Государственного Собрания (Ил Тумэн) РС(Я) от 19.06.2008 ГС N 120-IV)</em></p></li>
							<li><p>Принципы формирования персонального состава комиссии определяются Государственным Собранием самостоятельно.</p></li>
							<li><p>Государственное Собрание, формирующее комиссию, одновременно избирает председателя, заместителя председателя и секретаря комиссии.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="5. Срок депутатского расследования ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Моментом начала работы комиссии является принятие Государственным Собранием постановления об образовании такой комиссии.</p></li>
							<li><p>Моментом окончания работы комиссии является представление на пленарном заседании Государственного Собрания доклада о результатах депутатского расследования.</p></li>
							<li><p>Срок депутатского расследования устанавливается Государственным Собранием. Срок работы комиссии не может превышать срока окончания полномочий депутатов в Государственном Собрании данного созыва.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="6. Функции комиссии">
                    <p>Комиссия в ходе депутатского расследования:</p>
                    <ol className="text__ol_strong ml-5">
							<li><p>устанавливает достоверность фактов и событий, послуживших основанием для проведения данного депутатского расследования;</p></li>
							<li><p>устанавливает причины и обстоятельства фактов и событий, послуживших основанием для проведения данного депутатского расследования;</p></li>
							<li><p>выявляет лиц, чьи действия (бездействие) способствовали наступлению неблагоприятного события либо не способствовали его предотвращению или предотвращению (уменьшению) тяжелых  последствий;</p></li>
							<li><p>выявляет лиц, чьи действия (бездействие) повлекли совершение должностным лицом действий (бездействия), несовместимых с его статусом.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="7. Полномочия комиссии">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Комиссия в целях полноты и объективности депутатского расследования вправе:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>приглашать на свои заседания или слушания для дачи объяснений и предоставления информации должностных лиц Республики Саха (Якутия), государственных и муниципальных служащих Республики Саха (Якутия);</p>
                                <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
                                <li><p>приглашать на свои заседания или слушания для дачи объяснений граждан, руководителей или представителей общественных и религиозных объединений, коммерческих и некоммерческих организаций, предприятий независимо от формы собственности;</p></li>
                                <li><p>запрашивать и получать необходимую для данного депутатского расследования информацию от органов государственной власти Республики Саха (Якутия), органов местного самоуправления, общественных и религиозных объединений, коммерческих и некоммерческих организаций, предприятий независимо от формы собственности;</p></li>
                                <li><p>привлекать к расследованию независимых специалистов, а также общественные объединения, иные негосударственные организации, за исключением коммерческих, на добровольной безвозмездной основе;</p></li>
                                <li><p>направлять своих представителей в государственные органы и организации с целью проведения проверок и сбора сведений в случаях и порядке, которые установлены настоящим Положением;</p></li>
                                <li><p>осуществлять иные действия, установленные настоящим Положением.</p></li>
                            </ol>
							<li><p><strong>Исключен.</strong> - Постановление Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III.</p></li>
							<li><p>Комиссия самостоятельна в своей работе и до окончания депутатского расследования никому не подотчетна. Вмешательство в работу комиссии со стороны депутатов и иных должностных лиц не допускается.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="8. Ограничения деятельности комиссии ">
                    <p>Комиссия не вправе:</p>
                    <ol className="text__ol_strong ml-5">
							<li><p>проводить расследование в отношении лица, которое является подозреваемым, обвиняемым, подсудимым или осужденным по делу о преступлении, признаки которого совпадают с предметом депутатского расследования;</p></li>
							<li><p>запрашивать материалы, находящиеся в производстве суда, органов следствия и дознания, и материалы оперативно-розыскной деятельности;</p></li>
							<li><p>использовать методы, разрешенные законом только субъектам оперативно-розыскной деятельности.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="9. Результаты депутатского расследования">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Комиссия после завершения депутатского расследования либо по истечении установленного срока расследования утверждает на своем заседании доклад о результатах депутатского расследования. Доклад считается утвержденным, если за него проголосовало не менее двух третей от числа членов комиссии, присутствующих на ее заседании. Утвержденный доклад направляется Председателю Государственного Собрания.</p></li>
							<li><p>В случае, если на заседании комиссии доклад о депутатском расследовании не был утвержден, председатель комиссии либо заместитель председателя комиссии направляет Председателю Государственного Собрания справку с изложением причин, по которым данный доклад не был утвержден. Указанная справка оглашается на ближайшем пленарном заседании Государственного Собрания.</p></li>
							<li><p>В утвержденном докладе о результатах депутатского расследования в зависимости от характера расследования указываются выявленные комиссией:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>степень достоверности фактов и событий, послуживших основанием для проведения данного депутатского расследования;</p></li>
                                <li><p>причины и обстоятельства фактов и событий, послуживших основанием для проведения данного депутатского расследования;</p></li>
                                <li><p>лица, чьи действия (бездействие) способствовали наступлению неблагоприятного события либо не способствовали его предотвращению или предотвращению (уменьшению) тяжелых последствий;</p></li>
                                <li><p>лица, чьи действия (бездействие) повлекли совершение должностным лицом действий (бездействия), несовместимых с его статусом.</p></li>
                            </ol>
                            <li><p>В случае, если комиссия выявила или установила достоверность фактов принятия решений, совершения должностным лицом действий (бездействия), несовместимых с его статусом, в докладе о результатах депутатского расследования указываются предложения, адресованные соответствующим государственным органам:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>о привлечении должностного лица к дисциплинарной, административной или гражданско-правовой ответственности, в том числе о взыскании с должностного лица стоимости ущерба, нанесенного его решениями, действиями (бездействием);</p></li>
                                <li><p>о возбуждении уголовного дела, если в действиях (бездействии) должностного лица содержатся признаки преступления;</p></li>
                                <li><p>об устранении условий, способствовавших совершению должностным лицом действий, несовместимых с его статусом;</p></li>
                                <li><p>об устранении условий, способствовавших наступлению неблагоприятных событий, имеющих общественную значимость, а также о мерах по ликвидации последствий этих событий.</p></li>
                            </ol>
                            <li><p>Комиссия вправе включить в доклад о результатах депутатского расследования также предложения:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>о мерах по недопущению в будущем аварий, катастроф, конфликтов и других событий, которые можно предотвратить;</p></li>
                                <li><p>о мерах по совершенствованию государственной службы, функционированию государственной власти в целом.</p></li>
                            </ol>
                            <li><p>Доклад о результатах депутатского расследования должен быть включен в повестку ближайшего пленарного заседания Государственного Собрания. На основе данного доклада Государственное Собрание принимает постановление, подлежащее обязательному опубликованию, а также направляет это постановление в те государственные органы, организации, от которых зависит реализация предложений, содержащихся в докладе. Проект указанного постановления разрабатывается комиссией и вносится на рассмотрение Государственного Собрания депутатами - членами комиссии.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="10. Формы работы комиссии ">
                    <p>Комиссия для реализации поставленных перед ней целей использует следующие формы работы:</p>
                    <ol className="text__ol_strong ml-5">
							<li><p>заседания;</p></li>
							<li><p>слушания;</p></li>
							<li><p>проведение проверок и сбор сведений в местах событий;</p></li>
							<li><p>направление запросов в государственные, муниципальные и негосударственные органы и организации.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="11. Обязанности и ответственность члена комиссии ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Член комиссии обязан:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>участвовать во всех формах работы комиссии;</p></li>
                                <li><p>исполнять поручения председателя, заместителя председателя комиссии, а также комиссии в целом.</p></li>
                            </ol>
							<li><p>В случае неисполнения членом комиссии своих обязанностей, в том числе двукратной неявки на ее заседания и слушания, независимо от причин Государственное Собрание принимает на основе информации комиссии решение об отзыве данного члена комиссии и о его замене.</p></li>
							<li><p>В период депутатского расследования ни один член комиссии не вправе разглашать сведения, ставшие ему известными в ходе депутатского расследования, давать интервью средствам массовой информации, участвовать в брифингах и пресс-конференциях, а также в иной публичной форме сообщать о ходе депутатского расследования или оглашать свое мнение, касающееся данного расследования. Нарушение этого запрета влечет за собой исключение из состава комиссии.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="12. Обязанности и ответственность лица, привлеченного к депутатскому расследованию">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Лицо, привлеченное к депутатскому расследованию, в период работы комиссии не вправе разглашать сведения, ставшие ему известными в ходе депутатского расследования, давать интервью средствам массовой информации, участвовать в брифингах и пресс-конференциях, а также в иной публичной форме сообщать о ходе депутатского расследования или оглашать свое мнение, касающееся данного расследования. Нарушение этого запрета влечет за собой ответственность, предусмотренную законодательством.</p></li>
							<li><p>Лица, привлеченные к депутатскому расследованию, обязаны по запросу комиссии в течение 15 рабочих дней предоставить необходимую (необходимые) для проведения парламентского расследования информацию (документы, материалы). Предоставление комиссии сведений о частной жизни граждан допускается только с их согласия.</p>
                            <p><em>(п. 2 введен постановлением Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
							<li><p>Лица, привлеченные к депутатскому расследованию, обязаны:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>прибыть на заседание комиссии;</p></li>
                                <li><p>дать все необходимые объяснения и правдиво ответить на вопросы, поставленные комиссией.</p>
                                <p><em>(п. 3 введен постановлением Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
                            </ol>
                            <li><p>За отказ от предоставления комиссии необходимой (необходимых) для проведения парламентского расследования информации (документов, материалов), за уклонение от такого предоставления либо за предоставление комиссии заведомо неполной или заведомо ложной информации, а также за отказ от дачи объяснений, за дачу заведомо ложных ответов на вопросы, поставленные комиссией, лица, привлеченные к депутатскому расследованию, несут ответственность в соответствии с законодательством.</p>
                            <p><em>(п. 4 введен постановлением Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
                            <li><p>Комиссия вправе направить в орган или должностному лицу, уполномоченным принять соответствующее решение, предложение о принятии в соответствии с законодательством мер в отношении лица, привлеченного к депутатскому расследованию, в случае отказа от предоставления комиссии необходимой (необходимых) для проведения парламентского расследования информации (документов, материалов), в случае уклонения от такого предоставления либо в случае предоставления комиссии заведомо неполной или заведомо ложной информации, а также в случае отказа от дачи объяснений, в случае дачи заведомо ложных ответов на вопросы, поставленные комиссией.</p>
                            <p><em>(п. 5 введен постановлением Государственного Собрания (Ил Тумэн) РС(Я) от 30.05.2006 ГС N 1679-III)</em></p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="13. Заседания комиссии">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>На заседаниях комиссии принимаются все решения комиссии, в том числе утверждается план ее работы, распределяются обязанности между членами комиссии, заслушиваются результаты выполнения поручений членами комиссии.</p></li>
							<li><p>Заседание комиссии созывается председателем комиссии, а в его отсутствие - заместителем председателя комиссии.</p></li>
							<li><p>Заседание комиссии правомочно, если на нем присутствует не менее двух третей от общего состава комиссии.</p>
                                <p>Решение комиссии принимается большинством голосов от числа членов комиссии, присутствующих на ее заседании, если иное не предусмотрено настоящим Положением.</p></li>
							<li><p>Заседание комиссии является закрытым, если не принято иное решение комиссии. На закрытом заседании комиссии присутствуют, кроме членов комиссии, только лица, специально приглашенные на данное заседание. Список приглашенных на каждое последующее заседание оформляется решением комиссии.</p></li>
							<li><p>Заседание комиссии не позднее чем за два дня до его проведения может быть объявлено открытым, если решение об этом принято не менее чем двумя третями голосов от числа членов комиссии, присутствующих на ее заседании. На открытом заседании комиссии вправе присутствовать журналисты, аккредитованные при данной комиссии, депутаты, специально уполномоченные представители Главы Республики Саха (Якутия), Правительства Республики Саха (Якутия), общественных и религиозных объединений.</p>
							<p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 17.06.2015 ГС N 629-V)</em></p></li>
							<li><p>Желающие присутствовать на открытом заседании комиссии не позднее чем за день до его проведения подают заявки. Председатель комиссии либо заместитель председателя комиссии обеспечивает при содействии руководства Государственного Собрания подготовку помещения, позволяющего провести открытое заседание комиссии.</p></li>
							<li><p>На закрытых и открытых заседаниях комиссии ведутся протокол и стенограмма.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="14. Слушания комиссии">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Слушания комиссии могут проводиться по решению комиссии в случае необходимости заслушивания объяснений и информации лиц, деятельность которых расследуется комиссией, очевидцев событий, а также заключений экспертов.</p></li>
							<li><p>Председатель комиссии либо заместитель председателя комиссии совместно с секретарем комиссии своевременно обеспечивают присутствие на данных слушаниях лиц, приглашенных для дачи объяснений, предоставления информации или для сообщения результатов экспертизы.</p></li>
							<li><p>Слушания комиссии являются открытыми, если не принято иное решение комиссии. О слушаниях комиссии объявляется не позднее чем за пять дней до их проведения. На слушаниях комиссии вправе присутствовать журналисты, аккредитованные при данной комиссии, депутаты, специально уполномоченные представители Главы Республики Саха (Якутия), Правительства Республики Саха (Якутия), общественных и религиозных объединений.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 17.06.2015 ГС N 629-V)</em></p></li>
							<li><p>Желающие присутствовать на слушаниях комиссии не позднее чем за день до их проведения подают заявки. Председатель комиссии либо заместитель председателя комиссии обеспечивает при содействии руководства Государственного Собрания подготовку помещения, позволяющего провести слушания комиссии.</p></li>
							<li><p>Решение о закрытых слушаниях комиссии принимается не менее чем двумя третями голосов от числа членов комиссии, присутствующих на ее заседании.</p></li>
							<li><p>На закрытых слушаниях комиссии присутствуют, кроме членов комиссии, только лица, специально приглашенные на данные слушания. Список приглашенных на закрытые слушания оформляется решением комиссии.</p></li>
							<li><p>Слушания комиссии ведет председатель комиссии либо заместитель председателя комиссии.</p></li>
							<li><p>Ведущий слушания:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>объявляет об открытии и о закрытии слушаний, а также о переносе данных слушаний на другое время;</p></li>
                                <li><p>задает вопросы лицам, приглашенным для дачи объяснений, предоставления информации или для сообщения результатов экспертизы;</p></li>
                                <li><p>предоставляет слово членам комиссии и участникам слушаний;</p></li>
                                <li><p>следит за порядком в помещении, где проходят слушания.</p></li>
                            </ol>
							<li><p>Ведущий слушания, а равно члены комиссии не вправе во время слушаний выносить свои оценки лицам, приглашенным на данные слушания.</p></li>
							<li><p>На закрытых и открытых слушаниях комиссии ведутся протокол и стенограмма.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="15. Проведение проверок и сбор сведений">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Комиссия вправе в целях обеспечения полноты расследования имеющих общественную значимость фактов, послуживших основанием для проведения депутатского расследования, проводить проверки в соответствующих государственных органах и организациях. В этих целях комиссия принимает решение о проведении проверки с указанием государственного органа или организации, в котором (которой) проводится проверка, и направляет члена (членов) комиссии для проведения данной проверки.</p></li>
							<li><p>Член комиссии имеет право:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>истребовать любые документы, кроме материалов оперативно-розыскной деятельности, не законченных производством материалов дознания, предварительного следствия, судебного рассмотрения и знакомиться с указанными документами на месте;</p></li>
                                <li><p>составлять опись документов, а также предметов, относящихся к расследуемым фактам, требовать от соответствующих должностных лиц их сохранности, о чем составляется акт;</p></li>
                                <li><p>опрашивать должностных лиц и иных сотрудников государственного органа или организации, в котором (которой) проводится проверка;</p></li>
                                <li><p>входить в любые помещения в сопровождении уполномоченных сотрудников государственного органа или организации, в котором (которой) проводится проверка.</p></li>
                            </ol>
							<li><p>Объем проверки, проводимой комиссией, должен соответствовать целям соответствующего депутатского расследования. Для обеспечения этого принципа член комиссии не вправе истребовать документы, содержание которых не относится к целям депутатского расследования, а также задавать вопросы, явно выходящие за пределы депутатского расследования. В спорных случаях член комиссии должен обратиться в комиссию с обоснованием необходимости получения информации, в предоставлении которой ему было отказано. При согласии комиссии с доводами члена комиссии такая информация должна быть ему предоставлена.</p></li>
							<li><p>Комиссия вправе в целях обеспечения полноты расследования причин и обстоятельств событий, послуживших основанием для проведения депутатского расследования, собирать сведения непосредственно в местах, где произошло (есть предположение, что произошло) расследуемое событие. В этих целях комиссия принимает решение о необходимости сбора сведений непосредственно в месте события и направляет члена (членов) комиссии для сбора таких сведений на место события, а также в соответствующие государственные и муниципальные органы и организации.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="16. Направление запросов">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Комиссия вправе в целях обеспечения полноты расследования направлять запросы руководителям органов государственной власти Республики Саха (Якутия), органов местного самоуправления, организаций, общественных и религиозных объединений, коммерческих и некоммерческих организаций или предприятий независимо от формы собственности.</p></li>
							<li><p>Запрос комиссии должен содержать:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>указание целей депутатского расследования;</p></li>
                                <li><p>перечень сведений, необходимых для депутатского расследования;</p></li>
                                <li><p>срок, в течение которого должна быть предоставлена требуемая информация;</p></li>
                                <li><p>способ предоставления информации;</p></li>
                                <li><p>указание об ответственности за отказ от предоставления требуемой информации или за предоставление заведомо ложной информации.</p></li>
                            </ol>
                            <li><p>Руководитель органа государственной власти Республики Саха (Якутия), органа местного самоуправления, организации, общественного или религиозного объединения, коммерческой или некоммерческой организации, предприятия независимо от формы собственности обязан предоставить в срок требуемую информацию.</p></li>
							<li><p>Комиссия не вправе запрашивать, а соответствующие должностные лица предоставлять материалы оперативно-розыскной деятельности, а также не законченные производством материалы дознания, предварительного следствия, судебного рассмотрения. </p></li>
						</ol>
                    </Accordion>
                    

                    </div>
					</div>


                    <div className="row pt-3 pb-5 pl-3">
                    <div className="col-12 ">
                        <div className="pt-3 pb-5">
                        <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                        <p><a href={document}>Положение о депутатском расследовании </a> (MS Word, 76 Кб)</p></div>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}
