import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import voting from '../store-site';
import store from '../../../store';
import { formatDateStr } from '../../../utils';

@observer
export default class VotesLog extends React.Component {

	@observable votes = [];
	
	constructor(props) {
		super(props);
		this.load();
	}
	
	load = async () => {
		if (voting.session) {
			this.votes = await store.model.Vote.find({
				where: {
					and: [
						{ sessionId: voting.session.id },
					]
				},
				include: [
					{
						relation: 'deputy',
						scope: {
							fields: ['id', 'lastName', 'firstName', 'middleName']
						}
					}
				],
				order: 'id desc'
			});
		}
		// console.log('load votes', this.votes, voting.session);
		setTimeout(this.load, 2000);
	};
	
	render() {
		return <div className="votes-log">
			<strong>Голоса:</strong>
			<div className="list">
				{this.votes.map(vote => {
					const resultStr = vote.result === true ? 'yes' : vote.result === false ? 'no' : 'abstain';
					const time = new Date(vote.updatedAt).getTime() + voting.delta;
					return <div key={vote.id} className="vote">
						<div className="top">
							<div className="deputy">{voting.fioShort(vote.deputy)}</div>
							<div className={'result ' + resultStr}>{voting.interpretResult(vote.result)}</div>
						</div>
						<div className="date">{formatDateStr(new Date(time), 'dd.MM.yyyy HH:mm:ss')} <small>({vote.questionId})</small></div>
					</div>})
				}
			</div>
		</div>;
	}
	
}
