import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import print from 'root/site/img/print.svg';
import { Loader } from '@smartplatform/ui';
import store from '../../store';
import ReactSVG from 'react-svg';
import 'react-image-lightbox/style.css';

@withRouter
@observer
export default class DocumentPreview extends React.Component {
	@observable index = 0;
	@observable images = [];

    async componentDidMount() {
		this.id = this.props.match.params.id;
		const images = await store.model.QuestionDocumentFile.find({
			where: { documentId: this.id }
		});
		this.images = images.map(image => 'https://epz.iltumen.ru/uploads/' + image.path);
	};

	closeBrowserTabWithImages = () => window.close();

	render() {
        if (!this.images.length) return <Loader /> 
		return (
			<div className="session-questions">
					<Lightbox
						mainSrc={this.images[this.index]}
						nextSrc={this.images[(this.index + 1) % this.images.length]}
						prevSrc={this.images[(this.index + this.images.length - 1) % this.images.length]}
						onCloseRequest={this.closeBrowserTabWithImages}
						onMovePrevRequest={() => (this.index = (this.index + this.images.length - 1) % this.images.length)}
						onMoveNextRequest={() => (this.index = (this.index + 1) % this.images.length)}
						toolbarButtons={[
							<a href={`/questions/${this.id}/print`} target="_blank" rel="noopener noreferrer">
								<ReactSVG className="simple-icon " src={print} />
								<span>Версия для печати</span>
							</a>,
						]}
					/>
			</div>
		);
	}
}
