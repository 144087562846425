import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { groups, plural, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT } from './groups';
import store from '../../store';
import { formatDate, trimmedName } from './tools';
import './style.scss';

@observer
export default class Document extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable docWho = [];
	@observable who = [];
	@observable error = null;
	@observable steps = [];

	constructor(props) {
		super(props);
		this.record = props.record;
		this.init();
	}

	@action init = async () => {
		this.steps = await store.model.RefLink.find({
			where: { documentId: this.record.id },
			order: 'orderNum asc',
			include: [
				{
					relation: 'child',
					scope: {
						include: [
							{
								relation: 'refFiles',
								scope: {
									// fields: { id: true, description: true, filesize: true, docFileId: true },
									include: ['docFile'],
								},
							},
							{
								relation: 'refLinks',
								scope: {
									include: [
										{
											relation: 'child',
											scope: {
												include: [
													{
														relation: 'refFiles',
														scope: {
															// fields: { id: true, name: true, description: true, filesize: true, docFileId: true },
															include: ['docFile'],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		});

		this.steps.forEach(step => console.log('-', step.orderNum, step.name));
		this.isLoading = false;
	};

	docFiles = document => {
		// console.group('> docFiles', document, document ? document.externalId : null);
		if (!document) {
			// console.log('> docFiles: no document!');
			console.groupEnd();
			// return 'No files!';
		}
		// console.log('document.refFiles()', document.refFiles());
		const files = document.refFiles().map(refFile => {
			// const url = `${window.location.origin}/api/refFiles/${refFile.id}/open`;
			const docFile = refFile.docFile;
			// console.log('refFile', refFile, refFile.docFileId);
			// console.log('docFile', docFile);
			if (!docFile)
				return (
					<div className="doc-file" key={refFile.id}>
						No file! (refFile.id: {refFile.id}, {refFile.docFileId || 'undefined'})
					</div>
				);
			const url = docFile.downloadFile('contents');
			return (
				<div className="doc-file" key={refFile.id}>
					{/*<a href="#" onClick={e => downloadFile(e, refFile)}>{refFile.description}</a>*/}
					<a target="_blank" href={url}>
						{refFile.description}
					</a>
				</div>
			);
		});
		console.groupEnd();
		return files;
	};

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		const content = this.steps.map((refLink, i) => {
			return (
				<div key={refLink.id} className="step">
					<div className="step-line" />
					<div className="step-point" />
					<strong>{refLink.child.name}</strong>
					{this.docFiles(refLink.child)}
					{refLink.child
						? refLink.child.refLinks().map(refLink => {
								if (!refLink.child || refLink.child.refFiles().length === 0) return null;
								return (
									<div key={refLink.id} className="substep">
										<div className="date">
											{refLink.child ? formatDate(refLink.child.date) : 'No date!'}
											<span />
										</div>
										<div className="content">
											<strong>{refLink.child.name}</strong>
											{this.docFiles(refLink.child)}
										</div>
									</div>
								);
						  })
						: null}
				</div>
			);
		});

		return (
			<div className="passport">
				<div className="doc-name">
					<strong>{trimmedName(this.record.name)}</strong>
				</div>

				{content}
			</div>
		);
	}
}
