import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import SessionCard from '../sessions-page/SessionCard';
import store from '../../store';

@observer
export default class PlenaryMeeting extends React.Component {

	@observable isLoading = true;
	@observable record = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const records = await store.model.PlenarySession.find({
			where: {
				publish: true,
				meetingId: { neq: null },
			},
			order: 'id desc',
			limit: 1,
		});
		this.record = records.length > 0 ? records[0] : null;
		this.isLoading = false;
	};
	
	render() {
		return <div className="background-page-light-grey-small">
			<div className="container plenary-main">
				<div className="section__heading">
					<h2 className="header-2"><Link to="/sessions">Пленарные заседания</Link></h2>
				</div>
				{this.isLoading ?
					<Loader/>
					:
					(this.record && <SessionCard session={this.record} type="plenary" />)
				}
				<div className="all-link"><Link to="/sessions">Все пленарные заседания</Link></div>
			</div>
		</div>;
	}
	
}
