import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { useGetRecord, useCheckViews } from '../../utils/hooks';
import { dateAndTime } from 'root/site/utils/date-format';

import { Loader, HtmlValue } from '@smartplatform/ui';

import { ElementItem, ShareInSocial } from 'root/site/components';

import eye from 'root/site/img/eye.svg';
import print from 'root/site/img/print.svg';
import calendar from 'root/site/img/calendar.svg';
import { cutEmptyParagraphs } from '../../utils';

// Компонент для модели Article с типом не равным "news"

const SingleArticle = ({ id }) => {
	// console.log('got id', id);
	const options = { include: ['views'] };
	const record = useGetRecord({ model: 'Article', id, options });
	const [article, setArticle] = useState();
	useCheckViews(id, 'Article');

	useEffect(() => {
		const init = async () => {
			await formatData();
		};
		if (record) {
			init();
		}
	}, [record]);

	const formatData = async () => {
		const views = await record.views().length;
		const thumbnail = record.thumbnail ? record.downloadFile('thumbnail') : '';
		const publishAt = dateAndTime(record.publishAt);

		setArticle({ ...record, views, thumbnail, publishAt });
	};

	if (!article || !record) {
		return (
			<div className="container base-page">
				<Loader />
			</div>
		);
	}
	const { thumbnail, views, name, content, contentTop, publishAt } = article;

	return (
		<div>
			<div className="container">
				<div className="note-page-title pb-5 pt-5">
					<h1>{name}</h1>
				</div>
			</div>
			<div className="background-page-light-grey">
				<div className="container">
					<div className="row pb-4 pt-4">
						<div className="col-2">
							<div className="news-views">
								<ElementItem textColor={'var(--color-gray-light-text)'} fill="dark" icon={eye} text={views} top="2px" />
							</div>
						</div>

						<div className="col-auto">
							<div className="news-date">
								<ElementItem
									textColor={'var(--color-gray-light-text)'}
									fill="dark"
									icon={calendar}
									text={publishAt}
									top="2px"
								/>
							</div>
						</div>
					</div>
					<div className="news-img__wrapper">
						<img
							alt={name}
							className="news-img__image"
							src={article.imported ? `/api/containers/articles/download/${article.image}` : thumbnail}
						></img>
					</div>
					<div className="row news-wrapper">
						<div className="col-md-12 d-md-block">
							<div className="text-news">
								<HtmlValue value={cutEmptyParagraphs(contentTop)} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row justify-content-center align-items-center pb-5 pt-3">
					<div className="d-md-block col-md-6">
						<div className="print-version">
							<div>
								<a href={`/news/${id}/print`} target="_blank" rel="noopener noreferrer">
									<ReactSVG className="simple-icon simple-icon-dark" src={print} />
									<span>Версия для печати</span>
								</a>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 d-flex justify-content-center">
						<ShareInSocial />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleArticle;
