import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, Route, withRouter } from 'react-router-dom';

import { Page } from '../../components/page';
import { PATH } from './index';
import store from '../../store';

@withRouter @observer
export default class OtherAgreements extends React.Component {

	@observable isLoading = true;
	@observable records = [];
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const res = await store.model.Page.find({ where: { route: 'executive-functions-gs' }, fields: ['id'] });
		const parent = res.length > 0 ? res[0] : null;
		if (parent) {
			this.records = await store.model.Page.find({ where: { parentId: parent.id }, fields: ['id', 'route', 'title'] });
		}
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return null;
		
/*
		const otherAgreementsLinks = this.records.map(record => <div key={record.id}>
			<Link to={`${PATH}/other/${record.route}`}>{record.title}</Link>
		</div>);
*/
		
		const otherAgreementsRoutes = this.records.map(record => (
			<Route
				key={record.id}
				path={`${PATH}/other/${record.route}`}
				render={() => <Page code={record.route} contentOnly />}
			/>
		));
		
		return <>
			<Route path={`${PATH}/other`} exact render={() => <>
				<Page code="executive-functions-gs" contentOnly />
			</>}/>
			{otherAgreementsRoutes}
		</>;
	}
	
}
