import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Single from './Single';
import CommitteesNews from './CommitteesNews';
import './style.scss';
import { PrintSingleVersion } from './PrintCommitte';

export default () => (
	<Switch>
		<Route path="/committees" exact component={List} />
		<Route path="/committees/:id" exact component={Single} />
		<Route path="/committees/:id/news" component={() => <CommitteesNews />} />
	</Switch>
);
