import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from '../../store';
import {withRouter} from 'react-router-dom';
import { Loader } from '@smartplatform/ui';
import { ArticleList } from '../../components';

import { Pager } from '@smartplatform/ui';

const PER_PAGE = 5;

@withRouter @observer
export default class DeputyNews extends React.Component {
	@observable articles = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const { ui, route } = store;
		const { params } = route;
		
		this.id = this.props.match.params.id;
		this.deputy = await store.model.Deputy.findById(this.id);
		this.page = parseInt((params && params.page) || 1);
		this.route = route;
		
		if (this.deputy) {
			const { lastName, firstName, middleName, id } = this.deputy;
			const fullName = lastName + ' ' + firstName + ' ' + middleName;
			ui.setBreadcrumbs([
				{ name: 'Депутаты', link: '/deputies' },
				{ name: fullName, link: `/deputies/${id}` },
				{ name: 'Новости', link: '/news' },
			]);
		}
		
		this.articles = await store.model.ViewDeputyArticle.find({
			where: {
				deputyId: this.id,
			},
			order: 'publishAt DESC',
			skip: (this.page - 1) * PER_PAGE,
			limit: PER_PAGE,
			include: {
				relation: 'article',
				scope: {
					include: 'views',
				},
			},
		});
		window.scrollTo(0, 0);
	};
	
	render() {
		if (!this.articles) {
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		}
		
		const { lastName, firstName, middleName } = this.deputy;
		
		return (
			<div>
				<div className="container">
					<div className="row mb-4 mt-5">
						<div className="col-6">
							<h3 className="header-2">
							<span>
								Новости. {lastName} {firstName} {middleName}
							</span>
							</h3>
						</div>
					</div>
				</div>
				
				<ArticleList records={this.articles.map(e => e.article)} type="news" />
				<div className="container pager-div">
					<Pager
						itemsPerPage={PER_PAGE}
						totalCount={this.articles.totalCount}
						current={this.page}
						onChange={page => {
							this.route.push({ params: { page: page } });
						}}
						className="iltumen-pager"
						directChange={true}
					/>
				</div>
			</div>
		);
	}
}