import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import voting from '../store-site';

@observer
export default class Question extends React.Component {

	@observable expanded = false;
	
	constructor(props) {
		super(props);
	}
	
	toggle = () => this.expanded = !this.expanded;
	
	render() {
		const { question } = this.props;
		// console.log('renderQuestion', question);
		
		const selected = voting.preselectedQuestion === question;
		const current = voting.currentQuestion && voting.currentQuestion.id === question.id;
		const className = classNames('question', {
			selected,
			current,
			past: !!question.stage,
		})
		
		return <>
			<div key={question.id} className={className} onClick={() => voting.preselectQuestion(question)} title={question.name}>
				<div className="name">
					{question.code === 'question' ?
						<em>Вопрос №{question.order}</em> : <em>{voting.getQuestionName(question)}</em>}
					<span>{question.name}</span>
				</div>
				{/*{question.stage && <div className={'expand-btn' + (this.expanded ? ' expanded' : '')} onClick={this.toggle}><FontAwesomeIcon icon={faCaretDown}/></div>}*/}
				<div className="last-stage">{question.stage ? voting.getQuestionStageName(question) : 'ожидание'}</div>
			</div>
{/*
			{this.expanded && <div className="stages">
				{question.stages().map(stage => <div key={stage.id}>
					{stage.type ? stage.type.name : stage.customType}
				</div>)}
			</div>}
*/}
		</>;
	}
	
}
