import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import './style.scss';

@observer
export default class ListSelect extends React.Component {

	static propTypes = {
		items: PropTypes.array,
		value: PropTypes.any,
		onChange: PropTypes.func,
		disabled: PropTypes.bool,
		className: PropTypes.string,
	};
	
	static defaultProps = {
	
	};
	
	constructor(props) {
		super(props);
	}
	
	select = value => {
		this.props.onChange && this.props.onChange(value);
	};
	
	render() {
		const { items, value, className, disabled } = this.props;
		
		const _className = classNames('list-select', className, disabled);
		
		return <div className={_className}>
			{items.map((item, i) => {
				let _value = item.hasOwnProperty('value') ? item.value : item;
				let _label = item.hasOwnProperty('value') ? item.label : item;
				const className = classNames('item', {
					selected: _value === value,
				});
				return <div key={i} className={className} onClick={() => this.select(_value)}>{_label}</div>
			})}
		</div>;
	}
	
}
