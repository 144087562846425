import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import store from '../../store';
import { observer } from 'mobx-react';

const { ui } = store;

@observer
export default class UserNotifications extends React.Component {
	async componentDidMount() {
		await ui.setUserMessages();
	}

	render() {
		return ui.userMessages > 0 ? (
			<span className="message-icon" title="Уведомления пользователя">
				<FontAwesomeIcon icon={faEnvelope} />
				<span className="message-icon-text">{ui.userMessages}</span>
			</span>
		) : null;
	}
}
