import React from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from '../../store';
import { Pager } from '@smartplatform/ui';
import { ArticleList, Calendar, Separator } from '../../components';

const PER_PAGE = 10;
const TYPE = 'article';

@observer
class NewsList extends React.Component {
	@observable records;
	@observable tags = store.ui.tags.filter(tag => tag.type === TYPE) || [];

	componentDidMount() {
		window.scrollTo(0, 0);
		this.init();
	}

	init = async () => {
		const { tags, records, page, totalCount } = await store.ui.getTagsWithRecords({
			model: 'Article',
			relation: 'articles',
			type: TYPE,
			modelType: 'news',
			fields: ['id', 'name', 'publishAt'],
			include: ['tags']
		});
		this.records = records;
		this.page = page;
		this.totalCount = totalCount;
		this.tags = tags;
	};

	render() {
		return (
			<div>
				<div className="container">
					<div className="row mb-4 mt-5">
						<div className="col-6">
							<h2 className="header-2">Новости</h2>
						</div>
						<div className="col-6">
							<Calendar />
						</div>
					</div>
				</div>
				<div className="sep-white">
					{this.tags && (
						<Separator
							tags={[{ name: 'Все новости', id: 'all' }, ...this.tags]}
							onTagSwitch={e => {
								store.route.push({ path: store.ui.rootPath, params: { page: 1, cat: e } });
							}}
						/>
					)}
				</div>

				{this.records && (
					<>
						<ArticleList records={this.records} type={this.props.type} />
						<div className="container pager-div">
							<Pager
								itemsPerPage={PER_PAGE}
								totalCount={this.totalCount}
								current={this.page}
								onChange={page => {
									store.route.push({ params: { ...store.route.params, page: page } });
								}}
								className="iltumen-pager"
								directChange={true}
								goMessage={<span className="go-btn">Перейти</span>}
							/>
						</div>
					</>
				)}
			</div>
		);
	}
}
export default NewsList;
