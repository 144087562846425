import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactSVG from 'react-svg';
import vk_footer from './img/vk_footer.svg';
// import facebook_footer from './img/facebook_footer.svg';
// import instagram_footer from './img/instagram_footer.svg';
import telegram_footer from './img/telegram_footer.svg';
import youtube_footer from './img/youtube_footer.svg';
import { Subscription } from '../subscription';

import './styles.scss';
import logo from '../../img/gerb-vector.svg';

const socialLinks = [
	// {
	// 	link: 'https://www.facebook.com/%D0%98%D0%BB-%D0%A2%D2%AF%D0%BC%D1% %D0%BD-114651979972652',
	// 	name: 'fb',
	// 	icon: facebook_footer,
	// },
	{ link: 'https://t.me/iltumen', name: 'telegram', icon: telegram_footer },
	{ link: 'http://vk.com/il.tumen', name: 'vk', icon: vk_footer },
	// { link: 'https://www.instagram.com/il.tumen/', name: 'instagram', icon: instagram_footer },
	{ link: 'https://www.youtube.com/channel/UC1gqfWbjxnxPPQKJhxkau_w', name: 'yt', icon: youtube_footer },
];
const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 768 });
	return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

export default class Footer extends React.Component {
	render() {
		return (
			<div className="footer">
				<Subscription />
				<div className="container mb-5 pt-5">
					<div className="row justify-content-center align-items-center">
						<div className="col-12 col-md-8 col-lg-10">
							<div className="row justify-content-center align-items-center">
								<div className="col-auto">
									<img src={logo} alt="logo" className="footer__logo-image" width={80} height={80} />
								</div>
								<div className="col">
									<div className="footer__logo-name">
										<h2>Государственное&nbsp;Собрание&nbsp;(Ил&nbsp;Тумэн)</h2>
										Республики Саха (Якутия)
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4 col-lg-2  d-flex justify-content-around justify-content-md-between social__wrapper">
							{socialLinks.map(e => {
								const { link, name, icon } = e;
								return (
									<a key={link} href={link} target="_blank" rel="noopener noreferrer">
										<ReactSVG className="social" src={icon} />
									</a>
								);
							})}
						</div>
					</div>
					<Desktop>
						<div className="row align-items-end">
							<div className="col-12">
								<div className="col-sm-background">
									<p>677022, Республика Саха (Якутия)</p>
									<p>г. Якутск, ул. Ярославского, 24/1</p>
								</div>
							</div>
						</div>
					</Desktop>
				</div>
				<Mobile>
					<div className="col-sm-background">
						<div className="col-12 col-md-6">
							<p>677022, Республика Саха (Якутия)</p>
							<p>г. Якутск, ул. Ярославского, 24/1</p>
						</div>
					</div>
				</Mobile>
			</div>
		);
	}
}
