import { action, observable } from 'mobx';
import moment from 'moment';
export default class UIStore {
	@observable page = null;
	@observable smallMenu = false;
	@observable menu = false;
	@observable showDevConfig = false;
	@observable tag = false;
	@observable breadcrumbs = [];
	@observable relationFilter;
	@observable limit;
	@observable loading;
	@observable defaultLimit;
	@observable rootPath;
	@observable dateRange;
	@observable previewImgSrc;
	@observable userMessages;
	@observable menuItems = [];
	@observable tags = [];

	constructor(store) {
		this.store = store;
	}
	init = async () => {
		this.menuItems = await this.store.model.MenuItem.find({ order: 'column asc, weight asc ' });
		this.tags = await this.store.model.Tag.find({
			order: 'weight desc, id asc',
			fields: ['id', 'name', 'type', 'path', 'description'],
		});
	};

	@action setSmallMenu = set => {
		this.smallMenu = set;
	};

	@action hideMenu = () => {
		this.menu = false;
	};

	@action toggleMenu = () => {
		this.menu = !this.menu;
	};

	@action switchTag = tag => {
		this.tag = tag;
	};

	@action setBreadcrumbs = breadcrumb => {
		this.breadcrumbs = breadcrumb;
	};

	@action setRelationFilter = relationFilter => {
		// console.log('got data', relationFilter);
		this.relationFilter = relationFilter;
	};

	@action setDateRange = month => {
		this.dateRange = month;
		// console.log('--- current mounth ', this.dateRange);
	};
	@action setLimit = limit => {
		this.limit = limit;
	};

	@action setLoading = loading => {
		this.loading = loading;
	};

	@action setDefaultLimit = defaultLimit => {
		this.defaultLimit = defaultLimit;
	};

	@action setRootPath = path => {
		this.rootPath = path;
	};

	getTagsWithRecords = async ({
									model,
									type = 'article',
									modelType,
									relation,
									perPage = 10,
									customFormat,
									include = [],
									fields,
								}) => {
		const {
			route: { params },
		} = this.store;
		//* тэги будем брать из сразу из стора
		const tags = this.tags.filter(tag => tag.type === type);
		const page = parseInt((params && params.page) || 1);
		const cat = params && params.cat;
		const calendarFilter = this.dateRange && {
			between: [moment(this.dateRange[0]).format(), moment(this.dateRange[1]).format()],
		};
		const options = {
			where: {
				and: [
					{ publish: true },
					{ publishAt: calendarFilter || { lte: Date.now() } },
					{ or: [{ removeAt: null }, { removeAt: { gt: Date.now() } }] },
				],
			},
			order: 'publishAt DESC',
			include: [{ relation: 'views', scope: { limit: 0 } }, ...include],
			fields,
			// limit: perPage,
			// skip: (page - 1) * perPage,
		};
		let records;
		let totalCount;
		if (cat === 'all' || !cat) {
			records = await this.store.model[model].find({
				...options,
				where: {
					...options.where,
					type: modelType || null,
				},
				limit: perPage,
				skip: (page - 1) * perPage,
			});
			totalCount = records.totalCount;
		} else {
			records = await tags.filter(e => e.id === +cat)[0][relation].find({ ...options });
			totalCount = records.totalCount;
			records = records.slice((page - 1) * perPage, page * perPage);
		}
		// console.log('--- records', records);
		if (customFormat) {
			records = await customFormat(records);
		}
		return { tags, records, page, totalCount };
	};

	@action setPreviewImgSrc = src => {
		this.previewImgSrc = src;
		console.log('this.previewImgSrc', this.previewImgSrc);
	};

	@action setUserMessages = async id => {
		if (!this.store.model.DispatchRecipient) return; // у некоторых ролей нет доступа к этой модели
		const messages = await this.store.model.DispatchRecipient.find({
			where: { deputyId: this.store.model.user.deputyId, checked: null },
			include: ['dispatch'],
		});
		this.userMessages = messages.filter(m => m.dispatch.sended).length;
		return this.userMessages;
	};
}
