import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from '../../store';
import {withRouter} from 'react-router-dom';
import { Loader } from '@smartplatform/ui';
import { ArticleList } from '../../components';

import { Pager } from '@smartplatform/ui';

const PER_PAGE = 5;

@withRouter @observer
export default class DeputyNews extends React.Component {
	@observable committee = null;
	@observable articles = null;
	@observable page = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const { ui, route } = store;
		const { params } = route;
		
		this.id = this.props.match.params.id;
		this.committee = await store.model.Committee.findById(this.id);
		this.page = parseInt((params && params.page) || 1);
		this.route = route;
		
		if (this.committee) {
			const { name, id } = this.committee;
			ui.setBreadcrumbs([
				{ name: 'Комитеты и комиссия', link: '/committees' },
				{ name: name, link: `/committees/${id}` },
				{ name: 'Новости', link: '/news' },
			]);
		}
		
		this.articles = await this.committee.articles();
		window.scrollTo(0, 0);
	};
	
	render() {
		if (!this.articles) {
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		}
		
		return (
			<div>
				<div className="container">
					<div className="row mb-4 mt-5">
						<div className="col-6">
							<h3 className="header-2">
							<span>
								Новости. {this.committee.name}
							</span>
							</h3>
						</div>
					</div>
				</div>
				
				<ArticleList records={this.articles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice((this.page - 1) * PER_PAGE, this.page * PER_PAGE)} type="news" />
				<div className="container pager-div">
					<Pager
						itemsPerPage={PER_PAGE}
						totalCount={this.articles.totalCount}
						current={this.page}
						onChange={page => {
							this.route.push({ params: { page: page } });
						}}
						className="iltumen-pager"
						directChange={true}
					/>
				</div>
			</div>
		);
	}
}
