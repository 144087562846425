// Я знаю, что это колхоз, но просидев 3 часа над размышлениями как сделать упорядоченный список у разных элементов - я понял, что это единственный выход
export const getStartNumDeputyLaws = (laws, i, arr) => {
	if (!i) {
		return 1;
	} else if (i === 1) {
		return laws[Object.keys(laws)[arr.length - 1]].length + 1;
	} else if (i === 2) {
		return laws[Object.keys(laws)[arr.length - 1]].length + laws[Object.keys(laws)[arr.length - 2]].length + 1;
	} else if (i === 3) {
		return laws[Object.keys(laws)[arr.length - 1]].length + laws[Object.keys(laws)[arr.length - 2]].length + laws[Object.keys(laws)[arr.length - 3]].length + 1;
	} else if (i === 4) {
		return laws[Object.keys(laws)[arr.length - 1]].length + laws[Object.keys(laws)[arr.length - 2]].length + laws[Object.keys(laws)[arr.length - 3]].length + laws[Object.keys(laws)[arr.length - 4]].length + 1;
	} else if (i === 5) {
		return laws[Object.keys(laws)[arr.length - 1]].length + laws[Object.keys(laws)[arr.length - 2]].length + laws[Object.keys(laws)[arr.length - 3]].length + laws[Object.keys(laws)[arr.length - 4]].length + laws[Object.keys(laws)[arr.length - 5]].length + 1;
	}
};
