import React from 'react';
import banner from './img/banner-1110x180.jpg';
import mobileBanner from './img/banner-mobile.jpeg';
import { Link } from 'react-router-dom';
import { gosusligiBanner } from './banners/gosuslugi';
import InnerHTML from 'dangerously-set-html-content'

export default function({ option }) {
	return <InnerHTML html={gosusligiBanner} />;


	// return <div dangerouslySetInnerHTML={{ __html: gosusligiBanner }} />;

	// return (
	// 	<Link to="/tag/yakutia100anniversary" alt="">
	// 		<img style={{ width: '100%' }} src={option === 'desktop' ? banner : mobileBanner} />
	// 	</Link>
	// );
}
