
import React from 'react';

export class TabsMobile extends React.Component {
  constructor() {
    super();
    this._handleClick = this._handleClick.bind(this);
  }
  
  componentDidMount() {
    this._handleClick();
  }
  
  _handleClick() {
    const acc = this._acc.children;
    for (let i = 0; i < acc.length; i++) {
      let a = acc[i];
      a.onclick = () => a.classList.toggle("tab-mobile__active");
    }
  }
  
  render() {
    return (
      <div 
        ref={a => this._acc = a} 
        onClick={this._handleClick}>
        {this.props.children}
      </div>
    )
  }
}


