import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import { formatDateStr } from '../../tools';
import store from '../../../../store';
import getStatusLabels from '../../getStatusLabels';
import './style.scss';

const MAX_LENGTH = 1000;

const STATUS_TEXT = {
	1: 'Ваше обращение поступило в Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)',
	2: 'Ваше обращение зарегистрировано в Государственном Собрании (Ил Тумэн) Республики Саха (Якутия)',
	3: 'Запрошены необходимые для рассмотрения Вашего обращения документы и материалы из:',
	4: 'В связи с тем, что решение вопросов, содержащихся в Вашем обращении, не входит в компетенцию Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), в соответствии с частью 3 статьи Федерального закона от 2 мая 2006 года №59-ФЗ "О порядке рассмотрения обращений граждан Российской Федерации" обращение направлено по компетенции в:',
	5: 'Ваше обращение рассмотрено',
};

@observer
export default class Appeal extends React.Component {
	@observable isLoading = true;
	@observable appeal = null;
	@observable showAll = false;
	@observable showFirst = true;
	@observable logs = [];
	@observable showAllLogs = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		this.appeal = await store.model.Appeal.findById(this.id, {
			include: ['deputy'],
		});
		await this.loadLogs();
		this.isLoading = false;
	};

	loadLogs = async () => {
		this.logs = await store.model.AppealLog.find({
			where: {
				appealId: this.appeal.id,
			},
			include: ['status'],
			order: 'id asc',
		});
		this.logs.forEach(log => (log.__show = observable.box(false)));
	};

	toggleShowAll = e => {
		e.preventDefault();
		this.showAll = !this.showAll;
	};

	toggleAllLogs = () => {
		this.showAllLogs = !this.showAllLogs;
	};
	toggleLog = log => {
		if (!log) {
			this.showFirst = !this.showFirst;
		} else {
			log.__show.set(!log.__show.get());
		}
	};

	render() {
		const appealLogs = () => {
			const firstLog = (
				<tr
					key="createdAt"
					className={'appeal-log' + (this.showFirst ? ' current' : '')}
					onClick={() => this.toggleLog(null)}
				>
					<td className="date">{format(new Date(this.appeal.createdAt), 'dd.MM.yyyy HH:mm')}</td>
					<td colSpan={2} className="status">
						Поступило
					</td>
				</tr>
			);
			if (this.showAllLogs) {
				return (
					<>
						{firstLog}
						{this.showAllLogs && (
							<tr className="appeal-details">
								<td />
								<td colSpan={2} className="details">
									<div>{STATUS_TEXT[1]}</div>
									<div className="log-date">
										Дата поступления: <em>{format(new Date(this.appeal.createdAt), 'dd.MM.yyyy HH:mm')}</em>
									</div>
									<div className="log-status">
										Статус: <em>поступило</em>
									</div>
								</td>
							</tr>
						)}
						{this.logs.map(log => {
							const isCurrent = this.showAllLogs;
							// const isCurrent = log.__show.get();
							const labels = getStatusLabels(log.statusId);
							const dateLabel = labels.dateLabel;
							const textLabel = log.statusId === 2 ? <>{labels.textLabel}: </> : null;
							return (
								<React.Fragment key={log.id}>
									<tr className={'appeal-log' + (isCurrent ? ' current' : '')} onClick={() => this.toggleLog(log)}>
										<td className="date">{log.date ? format(new Date(log.date), 'dd.MM.yyyy HH:mm') : '-'}</td>
										<td colSpan={2} className="status">
											{log.status ? log.status.name : '-'}
										</td>
									</tr>
									{isCurrent && (
										<tr className="appeal-details">
											<td />
											<td colSpan={2} className="details">
												<div>{STATUS_TEXT[log.statusId]}</div>
												{log.text && (
													<div className="log-text">
														{textLabel}
														<em dangerouslySetInnerHTML={{ __html: log.text.replace(/[\r\n]+/g, '<br />') }} />
													</div>
												)}
												<div className="log-date">
													{dateLabel}: <em>{format(new Date(log.date), 'dd.MM.yyyy HH:mm')}</em>
												</div>
												{log.file && (
													<div className="log-file">
														Файл:
														<em>
															<a target="_blank" href={log.downloadFile('file')}>
																{log.file}
															</a>
														</em>
													</div>
												)}
												<div className="log-status">
													Статус: <em>{log.status ? log.status.name : '-'}</em>
												</div>
											</td>
										</tr>
									)}
								</React.Fragment>
							);
						})}
					</>
				);
			}

			if (this.logs.length === 0) {
				return firstLog;
			} else {
				// const log = this.logs[this.logs.length - 1];
				return (
					<>
						{firstLog}
						{this.logs.map((log, index) => {
								return <tr key={index} className={'appeal-log current'} onClick={() => this.toggleLog(log)}>
									<td className="date">{log.date ? format(new Date(log.date), 'dd.MM.yyyy HH:mm') : '-'}</td>
									<td colSpan={2} className="status">
										{log.status ? log.status.name : '-'}
									</td>
								</tr>
							})
						}
					</>
				)
			}
		};

		if (this.isLoading) return '...';
		return (
			<div className="appeal-page">
				<p>
					Обращение № <strong>{this.id}</strong> от <strong>{formatDateStr(this.appeal.createdAt)}</strong>
				</p>

				<p>
					<label>Депутату:</label>&nbsp;
					<Link to={`/deputies/${this.appeal.deputyId}`}>
						{this.appeal.deputy.lastName} {this.appeal.deputy.firstName} {this.appeal.deputy.middleName}
					</Link>
				</p>

				{this.appeal.attachment && (
					<p>
						<label>Приложенный файл:</label>
						<br />
						<a href={this.appeal.downloadFile('attachment')}>{this.appeal.attachment}</a>
					</p>
				)}

				<div>
					<label>Текст обращения:</label>
					<br />
					{this.appeal.text.length > MAX_LENGTH && !this.showAll ? (
						<>{this.appeal.text.substr(0, MAX_LENGTH)}...</>
					) : (
						this.appeal.text
					)}
					{this.appeal.text.length > MAX_LENGTH && (
						<div>
							<a href="#" onClick={this.toggleShowAll}>
								{!this.showAll ? 'Показать все' : 'Свернуть'}
							</a>
						</div>
					)}
				</div>

				<table className="appeal-history">
					<thead>
						<tr>
							<th>Дата</th>
							<th> Статус</th>
							<th width="10%">
								<a style={{ color: 'var(--color-red)' }} href="#" onClick={this.toggleAllLogs}>
									{this.showAllLogs ? 'Свернуть' : 'Развернуть'}
								</a>
							</th>
						</tr>
					</thead>
					<tbody>{appealLogs()}</tbody>
				</table>
			</div>
		);
	}
}
