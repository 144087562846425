import format from 'date-fns/format';

export const trimmedName = str => {
	let name = str
		.replace(/«/g, '"')
		.replace(/»/g, '"')
		.trim();
	if (name.substr(0, 1) === '"') {
		return name.substr(1);
	}
	return name;
};

export const formatDate = dateStr => (dateStr ? format(new Date(dateStr), 'dd.MM.yyyy') : '-');

export const downloadFile = async (e, refFile) => {
	e.preventDefault();
	try {
		const file = await refFile.docFile;
		const byteArray = file.contents;

		const blob = new Blob([new Uint8Array(byteArray.data).buffer], { type });
		console.log('blob', blob);
		const data = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = data;
		link.download = refFile.description;
		link.click();
	} catch (e) {
		console.error(e);
	}
};

/*
export const downloadFile = async (e, refFile) => {
	e.preventDefault();
	try {
		const file = await refFile.docFile;
		const ext = file.fileExtension;
		const byteArray = file.contents;
		
		const type = mime.lookup(ext);
		// console.log('downloadFile', file, type);
		// console.log('byteArray', byteArray.data.slice(0, 128));
		
		if (type) {
			const blob = new Blob([new Uint8Array(byteArray.data).buffer], { type });
			console.log('blob', blob);
			const data = window.URL.createObjectURL(blob);
			
			if (type === 'application/pdf') {
				window.open(data, refFile.description);
			}
			else {
				const link = document.createElement('a');
				link.href = data;
				link.download = refFile.description;
				link.click();
			}
		}
		else {
			console.error('Unknown mime type for extension', ext);
		}
	}
	catch (e) {
		console.error(e);
	}
};
*/
