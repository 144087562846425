import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Select, MaskedInput } from '@smartplatform/ui';
import { ListSelect } from '../list-select';
import { Button } from '../button';
import voting from '../store-site';

@observer
export default class VotingSettings extends React.Component {

	@observable started = false;
	
	constructor(props) {
		super(props);
	}
	
	onFractionChange = fraction => {
		voting.fraction = fraction;
	};
	
	onFromChange = from => {
		voting.from = from;
	};
	
	onPeriodChange = e => {
		console.log('onPeriodChange', e.target.value);
		voting.period = e.target.value;
	};
	
/*
	onPeriodChange = e => {
		voting.periodStr = e.target.value;
		const arr = e.target.value.replace(/_/g, '0').split(':');
		const seconds = (parseInt(arr[0]) || 0)  * 60 + (parseInt(arr[1]) || 0);
		// console.log('>', arr, seconds);
		voting.period = seconds;
	};
*/
	
	onPeriodBlur = e => {
		voting.periodStr = e.target.value.replace(/_/g, '0');
	};
	
	startVoting = () => {
		this.started = true;
		voting.startVoting(voting.currentQuestion);
	};

	render() {
		return <div className="voting-settings">
			<label>Установки голосования</label>
			{!voting.onlyVotes && <>
				<ListSelect
					items={Object.keys(voting.voteFractions)}
					value={voting.fraction}
					onChange={this.onFractionChange}
					className="select-fraction"
					isRequired
				/>
				<ListSelect
					items={Object.keys(voting.voteFrom).map(key => ({ label: voting.voteFrom[key].name, value: key }))}
					value={voting.from}
					onChange={this.onFromChange}
					className="select-from"
					isRequired
				/>
			</>}
			<div>
				<input value={voting.period} style={{ width: 80 }} onChange={this.onPeriodChange} type="number"  pattern="[0-9]*" /> секунд
			</div>
			<div className="actions">
				<Button onClick={this.startVoting} disabled={!voting.period || this.started}>Начать голосование</Button>
				{this.started && <Loader size={18} style={{ marginLeft: 10 }}/>}
			</div>
		</div>;
	}
	
}
