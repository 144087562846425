import React from 'react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class Confirm extends React.Component {

	render() {
		return (
			<div className="container reception">
				<h1>Интернет-приемная</h1>
                    <p>Спасибо за подтверждение адреса электронной почты</p>
                    {/*<p>Номер вашей заявки: №<strong>{this.props.match.params.id}</strong></p>*/}
                    <p>
                        <Link to="/profile/">Вернуться в профиль</Link>
                    </p>
			</div>
		); 
	}
}
