import React from 'react';
import {useGetRecords} from "root/site/utils/hooks";

function BannerAnniversary(){

    const [records] = useGetRecords({
        model: 'BannerHome',
    });

    if (!records || records.length === 0) return null;
    const bannerAnniversary = records[0];
    if (!bannerAnniversary.show){
        return null
    };

    return(
        <>
            <div className='container'>
                <div className='wrapper-banner-anniversary'>
                    {
                        !bannerAnniversary.filename || !bannerAnniversary.link ?
                            <p>Не выбрано изображение, или не заполнена ссылка</p>
                            :
                            <a href={bannerAnniversary.link} target='_blank'>
                                <img style={{maxWidth: '100%', minWidth: '100%'}} src={bannerAnniversary.downloadFile('filename')} />
                            </a>
                    }
                </div>
            </div>
        </>
    );
}

export default BannerAnniversary;