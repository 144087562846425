import React from 'react';
import Slider from 'react-slick';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import { dateAndTime } from 'root/site/utils/date-format';
import sliderArrow from './img/sliderArrow.svg';
import { plural, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT } from '../documents/groups';
import store from '../../store';

const PrevArrow = ({ onClick }) => <ReactSVG className="slider__arrow-prev" src={sliderArrow} onClick={onClick} />;
const NextArrow = ({ onClick }) => <ReactSVG className="slider__arrow-next" src={sliderArrow} onClick={onClick} />;

export const formatDate = date => date ? format(new Date(date), 'dd.MM.yyyy') : '-';

export const paths = {
	[LAW]: '',
	[DECREE]: 'DECREE',
	[LAW_PASSPORT]: 'LAW_PASSPORT',
	[DECREE_PASSPORT]: 'DECREE_PASSPORT',
};

export const all = {
	[LAW]: 'Все законы',
	[DECREE]: 'Все постановления',
	[LAW_PASSPORT]: 'Все проекты',
	[DECREE_PASSPORT]: 'Все проекты',
};

export const LawsSlider = ({ records, tab }) => {
	
	const Slide = ({ slide }) => {
		const handleClick = () => {
			console.log('slide click');
		};

		return (
			<div className="laws__slide" onClick={() => handleClick()}>
				<div className="slide-law">
					<span className="slide-law__date">{formatDate(slide.date)}</span>
					<div className="slide-law__text">
						<Link to={`/documents/${slide.id}`}>{slide.name}</Link>
					</div>
				</div>
			</div>
		);
	};

	const slidesToShow = 4;
	const settings = {
		infinite: false,
		slidesToShow,
		slidesToScroll: 1,
		speed: 250, // пожалуйста, не ставьте скорость 1000 мс - это невыносимо медленно для любого юзера!
		// autoplaySpeed: 1000,
		cssEase: 'linear',
		// pauseOnHover: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		// rtl: true,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	
	const goto = (e, { pathname, search }) => {
		e.preventDefault();
		store.route.path = pathname;
		store.route.params = { group: search };
		store.route.push();
	};
	
	return (
		<div className="laws__slider mb-5">
			{/* <div className="container">
				<div className="row align-items-center"></div>
				<div style={{ padding: '0 10%' }}> */}
			<Slider {...settings}>
				{records.map((record, index) => (
					<Slide key={`slide-${index}`} slide={record} />
				))}
			</Slider>
			<div className="laws__more">
{/*
				<Link className="link link-active" to={{ pathname: '/documents', search: paths[tab] }}>
					{all[tab]}
				</Link>
*/}
				<a href="#" className="link link-active" onClick={e => goto(e, { pathname: '/documents', search: paths[tab] })}>
					{all[tab]}
				</a>
			</div>
		</div>
	);
};
