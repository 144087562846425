import React, { useState } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { LawsSlider } from './LawsSlider';
import { Section, Separator, ElementItem } from 'root/site/components';
import MediaQuery from 'react-responsive';
import calendar from 'root/site/img/calendar.svg';
import { plural, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT } from '../documents/groups';
import store from '../../store';
import { formatDate, paths } from './LawsSlider';

const tabs = [ LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT ];

@observer
class Laws extends React.Component {
	
	@observable tab = LAW;
	@observable records = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.isLoading = true;
		this.records = await store.model.Document.find({
			where: {
				and: [
					{ docGroup: this.tab },
					{ name: { neq: null }},
				],
			},
			order: 'date desc',
			limit: 8,
		});
		this.isLoading = false;
	};
	
	@action setTab = tab => {
		console.log('setTab', tab);
		this.tab = tab;
		this.init();
	};
	
	render() {
		return (
			<>
				<MediaQuery minWidth={768}>
					<div className="container">
						<h2 className="name-section-position mt-5 pb-5" style={{marginBottom: '0 !important'}}>Законотворчество</h2>
						<div className="laws">
							<div className="laws__tags">
								{tabs.map(tab => {
									return (
										<div key={tab}
										     className={`laws__tags-item ${this.tab === tab ? 'laws__tags-item-active' : ''}`}
										     onClick={() => this.setTab(tab)}>
											{plural[tab]}
										</div>
									);
								})}
							</div>
							
							<div className="laws__container">
								{this.isLoading ?
									<div className="laws-placeholder"/>
									:
									<LawsSlider records={this.records} tab={this.tab} />
								}
							</div>
						</div>
					</div>
				</MediaQuery>
				{/* версия для телефонов : limit- 3 записи выводится аналогично экспертному мнению */}
				<MediaQuery maxWidth={767}>
					<>
						<div className="sep-white">
							<Separator />
						</div>
						
						<div className="no-gutter">
							<div className="container">
								<div className="section__heading">
									<h2 className="header-2 mt-5 pb-5" style={{marginBottom: '0 !important'}}>Законотворчество</h2>
								</div>
							</div>
						
							{this.records.slice(0, 3).map(doc => (
								<div className="direct-speech__main" key={doc.id}>
									<div className="direct-speech__info">
										<h1><Link to={`/documents/${doc.id}`}>{doc.name}</Link></h1>
										<ElementItem medium text={formatDate(doc.date)} icon={calendar} top="2px" />
									</div>
								</div>
							))}

{/*
							<div className="direct-speech__main ">
								<div className="direct-speech__info">
									<h1>Закон Республики Саха (Якутия) от 21 ноябрь 2018 года 2058-3 №27-VI «О внесении
										изменения в статью 8 Закона Республики Саха (Якутия) “О муниципальном жилищном
										контроле в Республике Саха (Якутия)»</h1>
									<ElementItem medium text="25-26 сентября 2018 год" icon={calendar} top="2px" />
								</div>
							</div>
							
							<div className="direct-speech__main ">
								<div className="direct-speech__info">
									<h1>Закон Республики Саха (Якутия) от 21 ноябрь 2018 года 2058-3 №27-VI «О внесении
										изменения в статью 8 Закона Республики Саха (Якутия) “О муниципальном жилищном
										контроле в Республике Саха (Якутия)»</h1>
									<ElementItem medium text="25-26 сентября 2018 год" icon={calendar} top="2px" />
								</div>
							</div>
							
							<div className="direct-speech__main ">
								<div className="direct-speech__info">
									<h1>Закон Республики Саха (Якутия) от 21 ноябрь 2018 года 2058-3 №27-VI «О внесении
										изменения в статью 8 Закона Республики Саха (Якутия) “О муниципальном жилищном
										контроле в Республике Саха (Якутия)»</h1>
									<ElementItem medium text="25-26 сентября 2018 год" icon={calendar} top="2px" />
								</div>
							</div>
*/}
						
						
						</div>
					</>
				</MediaQuery>
			</>
		);
	}
}

export default Laws;
