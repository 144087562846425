import React, { useState } from 'react';
import { ElementItem, MediaPreview } from '../../components';
import { dateAndTime } from 'root/site/utils/date-format';
import calendar from 'root/site/img/calendar.svg';
import picture from 'root/site/img/picture.svg';
import eye from 'root/site/img/eye.svg';
import {Link} from "react-router-dom";
import { Badges } from 'root/site/components/article/Badges';

const Gallery = ({ records }) => {

	let imgSize;
	let couter = 1;
	// console.log(showGallery);

	return records.map((record, index) => {
		const { id, thumbnail, name, publishAt, imagesCount, views } = record;
		if (couter === 1 || couter === 2) {
			imgSize = '';
		} else {
			imgSize = '-small';
		}

		if ((index + 1) % 5) {
			couter++;
		} else {
			couter = 1;
		}

		return (
			<React.Fragment key={`gallery-${id}`}>
				<Link to={`photogallery/${record.id}`} className={`col-sm-12 col-md-6 col-lg-${imgSize ? 4 : 6} `}>
					<div className="gallery-photo">
						<div className="gallery-photo__overlay">
							<div className='badges'>
								<Badges article={record} width={120} />
							</div>
							<img
								alt={name}
								className="gallery-photo__images gallery-photo__images"
								src={record.imported ? '/api/containers/attachments/download/' + record.thumbnailImported : thumbnail}
							/>

							<div className="gallery-photo__content">
								<div className={'gallery-photo__title gallery-photo__title' + imgSize}>
									{name}
									<div className={'gallery-photo__date' + imgSize}>
										<ElementItem medium icon={calendar} text={dateAndTime(publishAt)} fill="white" center top="1px" />
									</div>
								</div>

								<div className={'gallery-photo__count gallery-photo__count' + imgSize}>
									<ElementItem medium icon={picture} text={imagesCount} fill="white" bigIcon />
									<ElementItem medium icon={eye} text={views} fill="white" bigIcon top="-1px" />
								</div>
							</div>
						</div>
					</div>
				</Link>
			</React.Fragment>
		);
	});
};

export default Gallery;
