import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './об аппарате 23.05.2016.docx';


export default class AwardScience extends React.Component {
	render() {
		return (
		
			<Accordion title="Положение об аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)">
				<div className="row">
				<div className="col-6"></div>
				<div className="col-6">
				<p className="note-page__date pt-5" style={{textAlign: 'right'}}>
					Приложение <br/>
					к распоряжению Председателя <br/>
					Государственного Собрания (Ил Тумэн) <br/>
					Республики Саха (Якутия) <br/>
					от  «23» мая 2016 года № 61
				</p>
				</div></div>
				<h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
				Положение об аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)
					</h2>
					<Accordion title="Общие положения">
						<ol className="text__ol_strong pt-5"> 
							<li><p>Аппарат Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее – аппарат) является постоянно действующим органом, обеспечивающим деятельность Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее – Государственное Собрание). Аппарат является юридическим лицом, имеет на праве оперативного управления имущество, находящееся в собственности Республики Саха (Якутия), печать с изображением Государственного герба Республики Саха (Якутия), свое наименование, соответствующие печати, штампы, бланки, счета в банках.</p></li>
							<li><p>Аппарат осуществляет правовое, организационное, документационное, аналитическое, информационное, финансовое, материально-техническое, социально-бытовое обеспечение деятельности Государственного Собрания, его руководства, народных депутатов Республики Саха (Якутия) постоянных комитетов, комиссий Государственного Собрания, а также депутатских объединений Государственного Собрания в соответствии с пунктом 2 статьи 13 Конституционного закона Республики Саха (Якутия) от 10 июля 2002 года 35-З № 401-II «О Государственном Собрании (Ил Тумэн) Республики Саха (Якутия)» и со статьей 146 Регламента Государственного Собрания.</p></li>
							<li><p>Аппарат при осуществлении своей деятельности руководствуется Конституцией Российской Федерации, федеральным законодательством, Конституцией (Основным законом) Республики Саха (Якутия), законодательством Республики Саха (Якутия), Регламентом Государственного Собрания, распоряжениями Председателя Государственного Собрания, распоряжениями и приказами руководителя аппарата Государственного Собрания, а также настоящим Положением.</p></li>
							<li><p>Аппарат осуществляет свою деятельность в соответствии с примерными программами деятельности Государственного Собрания в периоды весенней и осенней сессий, планами работы аппарата и его структурных подразделений, поручениями Председателя Государственного Собрания и заместителей Председателя Государственного Собрания, руководителя аппарата Государственного Собрания и заместителей руководителя Аппарата. Аппарат координирует свою деятельность и взаимодействует с аппаратами Совета Федерации и Государственной Думы Федерального Собрания Российской Федерации, федеральными органами государственной власти, органами государственной власти других субъектов Российской Федерации, исполнительными органами государственной власти Республики Саха (Якутия), органами местного самоуправления муниципальных образований Республики Саха (Якутия). </p></li>
							<li><p>В аппарате не могут образовываться структуры политических партий, религиозных, общественных объединений, за исключением профессиональных союзов.</p></li>
							<li><p>Структурные подразделения аппарата осуществляют свою деятельность в соответствии с настоящим Положением и административным регламентом соответствующего структурного подразделения.</p></li>
							<li><p>В структуре аппарата предусматриваются должности государственной гражданской службы Республики Саха (Якутия), а также должности, не являющиеся должностями государственной гражданской службы Республики Саха (Якутия).</p></li>
						</ol>
					</Accordion>
					<Accordion title="Функции аппарата">
						<p>К функциям аппарата относятся:</p>
						<ol className="text__ol_strong"> 
						<li><p>организация проведения пленарных заседаний Государственного Собрания, заседаний Совета Государственного Собрания, заседаний постоянных комитетов, комиссий Государственного Собрания, депутатских объединений Государственного Собрания, а также подготовка документов и материалов к ним;</p></li>
						<li><p>обеспечение подготовки парламентских слушаний, «правительственных часов», «круглых столов» и иных мероприятий, проводимых в Государственном Собрании, а также документов и материалов к ним;</p></li>
						<li><p>участие в разработке проектов законов Республики Саха (Якутия), проектов постановлений Государственного Собрания;</p></li>
						<li><p>подготовка к рассмотрению Государственным Собранием проектов нормативных правовых актов;</p></li>
						<li><p>организация планирования деятельности Государственного Собрания, аппарата и структурных подразделений аппарата;</p></li>
						<li><p>оказание методической и консультационной помощи народным депутатам Республики Саха (Якутия);</p></li>
						<li><p>обеспечение в пределах своей компетенции исполнения решений Государственного Собрания, распоряжений и поручений Председателя Государственного Собрания, а также поручений первого заместителя Председателя Государственного Собрания, заместителей Председателя Государственного Собрания и председателей постоянных комитетов, комиссий Государственного Собрания;</p></li>
						<li><p>проведение правовой, антикоррупционной и лингвистической экспертиз проектов законов Республики Саха (Якутия) и проектов постановлений Государственного Собрания;</p></li>
						<li><p>разработка проектов положений и иных документов, регулирующих вопросы деятельности Государственного Собрания, в соответствии с федеральным законодательством и законодательством Республики Саха (Якутия);</p></li>
						<li><p>представление в установленном законодательством порядке интересов Государственного Собрания в Конституционном суде Российской Федерации, Конституционном суде Республики Саха (Якутия), судах общей юрисдикции и арбитражных судах в порядке, установленном федеральными законами;</p></li>
						<li><p>осуществление мониторинга федерального законодательства и законодательства Республики Саха (Якутия), а также подготовка предложений по совершенствованию законодательства Республики Саха (Якутия);</p></li>
						<li><p>подготовка проектов государственных контрактов, договоров и соглашений, заключаемых от имени Государственного Собрания, и их правовая экспертиза;</p></li>
						<li><p>подготовка информационно-аналитических материалов к рассматриваемым Государственным Собранием проектам нормативных правовых актов, иным вопросам, а также к мероприятиям, проводимым Государственным Собранием;</p></li>
						<li><p>рассмотрение поступающих в Государственное Собрание документов федеральных органов государственной власти, обращений органов государственной власти других субъектов Российской Федерации, органов местного самоуправления муниципальных образований Республики Саха (Якутия), организаций и граждан, подготовка по данным документам и обращениям аналитических, справочных и иных необходимых материалов;</p></li>
						<li><p>обеспечение подготовки информации об итогах деятельности Государственного Собрания за периоды весенней и осенней сессий;</p></li>
						<li><p>обеспечение взаимодействия Государственного Собрания с представительными органами муниципальных образований Республики Саха (Якутия);</p></li>
						<li><p>развитие информационных технологий в деятельности Государственного Собрания, формирование, сопровождение и развитие информационных систем, в том числе официального сайта Государственного Собрания в информационно-телекоммуникационной сети Интернет;</p></li>
						<li><p>обеспечение освещения в средствах массовой информации деятельности Государственного Собрания, народных депутатов Республики Саха (Якутия), постоянных комитетов, комиссий Государственного Собрания, депутатских объединений Государственного Собрания;</p></li>
						<li><p>обеспечение работы Государственного Собрания с обращениями граждан, организация приема граждан Председателем Государственного Собрания, первым заместителем Председателя Государственного Собрания, заместителями Председателя Государственного Собрания, председателями постоянных комитетов, комиссий Государственного Собрания;</p></li>
						<li><p>организация и обеспечение функционирования единой системы делопроизводства в Государственном Собрании;</p></li>
						<li><p>организация прохождения государственной гражданской службы Республики Саха (Якутия) государственными гражданскими служащими в аппарате;</p></li>
						<li><p>осуществление финансового, налогового, бухгалтерского, статистического учета и отчетности;</p></li>
						<li><p>планирование расходов Государственного Собрания;</p></li>
						<li><p>формирование и утверждение плана закупок товаров, работ, услуг для обеспечения деятельности Государственного Собрания;</p></li>
						<li><p>обеспечение предоставления помещений народным депутатам Республики Саха (Якутия), осуществляющим свою деятельность на профессиональной постоянной основе;</p></li>
						<li><p>обеспечение оборудованием, инструментами, технической документацией и иными средствами, необходимыми для исполнения должностных обязанностей народных депутатов Республики Саха (Якутия), осуществляющих свою деятельность на профессиональной постоянной основе, и работников аппарата;</p></li>
						<li><p>обеспечение в пределах своей компетенции взаимодействия с профсоюзным комитетом Государственного Собрания и Советом ветеранов Государственного Собрания;</p></li>
						<li><p>иные функции в соответствии с решениями Государственного Собрания, распоряжениями и поручениями Председателя Государственного Собрания, первого заместителя Председателя Государственного Собрания, заместителей Председателя Государственного Собрания, руководителя аппарата и заместителей руководителя аппарата.</p></li>
						</ol>
					</Accordion>
					<Accordion title="Структура аппарата">
					<ol className="text__ol_strong pt-5"> 
					<li><p>Структурными подразделениями аппарата являются управления, а также самостоятельный отдел, не входящий в состав управлений.</p></li>
					<li><p>Структура аппарата утверждается распоряжением Председателя Государственного Собрания.</p></li>
					<li><p>В соответствии со структурой аппарата Председатель Государственного Собрания утверждает штатное расписание аппарата.</p></li>
					<li><p>В штатное расписание аппарата включаются должности государственной гражданской службы Республики Саха (Якутия), предусмотренные Законом Республики Саха (Якутия) от 11 октября 2006 года 385-З № 783-III «О Реестре государственных должностей Республики Саха (Якутия) и должностей государственной гражданской службы Республики Саха (Якутия)», а также должности, не относящиеся к должностям государственной гражданской службы Республики Саха (Якутия).</p></li>
					</ol>
					</Accordion>
					<Accordion title="Организация деятельности аппарата">
					<ol className="text__ol_strong pt-5"> 
						<li><p>Председатель Государственного Собрания координирует деятельность аппарата, в том числе:</p></li>
						<ol className="text__ol_strong"> 
							<li><p>утверждает структуру аппарата;</p></li>
							<li><p>утверждает Положение об аппарате;</p></li>
							<li><p>издает распоряжения, обязательные для исполнения работниками аппарата;</p></li>
							<li><p>решает иные вопросы деятельности аппарата.</p></li>
						</ol>
						<li><p>В случае отсутствия Председателя Государственного Собрания деятельность аппарата координирует первый заместитель Председателя Государственного Собрания или один из заместителей Председателя Государственного Собрания в соответствии с распоряжением Председателя Государственного Собрания.</p></li>
						<li><p>Руководитель аппарата утверждается на должность постановлением Государственного Собрания по представлению Председателя Государственного Собрания.
Должность руководителя аппарата относится к высшей группе должностей государственной гражданской службы Республики Саха (Якутия) категории «руководители».
Руководитель аппарата подчиняется Председателю Государственного Собрания.</p></li>
						<li><p>Руководитель аппарата:</p></li>
						<ol className="text__ol_strong"> 
							<li><p>обеспечивает выполнение основных задач и осуществление функций аппарата;</p></li>
							<li><p>осуществляет контроль за деятельностью структурных подразделений аппарата;</p></li>
							<li><p>издает в пределах своих полномочий распоряжения и приказы;</p></li>
							<li><p>обеспечивает осуществление финансово-хозяйственной деятельности Государственного Собрания в соответствии со сметой Государственного Собрания в пределах средств, предусматриваемых законом Республики Саха (Якутия) о государственном бюджете Республики Саха (Якутия) на очередной финансовый год;</p></li>
							<li><p>заключает гражданско-правовые договоры, государственные контракты в порядке, установленном федеральным законодательством;</p></li>
							<li><p>утверждает должностные регламенты работников аппарата;</p></li>
							<li><p>организует работу по охране труда и диспансеризации работников аппарата;</p></li>
							<li><p>организует работу единой комиссии по государственным закупкам для нужд Государственного Собрания;</p></li>
							<li><p>организует делопроизводство в Государственном Собрании;</p></li>
							<li><p>формирует примерную программу и составляет отчеты по деятельности Государственного Собрания, представляет их Председателю Государственного Собрания;</p></li>
							<li><p>осуществляет иные полномочия по организации деятельности аппарата.</p></li>
						</ol>
						<li><p>Руководитель аппарата несет персональную ответственность за выполнение задач и осуществление функций аппарата в рамках возложенных на него полномочий.</p></li>
						<li><p>Руководитель аппарата имеет первого заместителя руководителя аппарата – управляющего делами и двух заместителей. В случае отсутствия руководителя аппарата руководство аппаратом осуществляет первый заместитель руководителя аппарата Государственного Собрания – управляющий делами или один из заместителей руководителя аппарата в соответствии с распоряжением руководителя аппарата.</p></li>
						<li><p>Первый заместитель руководителя аппарата – управляющий делами назначается на должность и освобождается от должности распоряжением Председателя Государственного Собрания по представлению руководителя аппарата. Должность первого заместителя руководителя аппарата – управляющего делами относится к высшей группе должностей государственной гражданской службы Республики Саха (Якутия) категории «руководители».</p></li>
						<li><p>Первый заместитель руководителя аппарата – управляющий делами осуществляет организацию и координацию деятельности структурных подразделений аппарата в соответствии с утвержденной структурой аппарата.</p></li>
						<li><p>Заместители руководителя аппарата назначаются на должность и освобождаются от должности распоряжением Председателя Государственного Собрания по представлению руководителя аппарата.</p></li>
						<li><p>Заместители руководителя аппарата осуществляют организацию и координацию деятельности структурных подразделений аппарата Государственного Собрания в соответствии с утвержденной структурой аппарата.</p></li>
						<li><p>Руководители структурных подразделений аппарата назначаются на должность и освобождаются от должности распоряжением Председателя Государственного Собрания по представлению руководители аппарата.</p></li>
						<li><p>Структурные подразделения аппарата возглавляют:</p></li>
						<ol className="text__ol_strong"> 
							<li><p>государственные гражданские служащие аппарата, должности которых относятся к высшей и главной группам должностей государственной гражданской службы Республики Саха (Якутия) категории «руководители»;</p></li>
							<li><p>государственные гражданские служащие аппарата, должности которых относятся к главной группе должностей государственной гражданской службы Республики Саха (Якутия) категории «специалисты».</p></li>
						</ol>
						<li><p>Руководители структурных подразделений аппарата, указанные в пункте 24 настоящего Положения, осуществляют свою деятельность и несут ответственность за организацию работы в соответствии с должностными регламентами, а также административными регламентами структурных подразделений аппарата.</p></li>
						<li><p>Государственные гражданские служащие аппарата, должности которых относятся к высшей и главной группам должностей государственной гражданской службы Республики Саха (Якутия) категории «помощники (советники)», назначаются на должность и освобождаются от должности распоряжением Председателя Государственного Собрания по представлению первого заместителя Председателя Государственного Собрания, заместителей Председателя Государственного Собрания, руководителя аппарата. Указанные в абзаце первом настоящего пункта государственные гражданские служащие осуществляют свою деятельность и несут ответственность за организацию работы в соответствии с должностными регламентами, а также административными регламентами структурных подразделений.</p></li>
						<li><p>Государственные гражданские служащие аппарата, должности которых относятся к ведущей и старшей группам должностей государственной гражданской службы Республики Саха (Якутия) категории «специалисты», назначаются на должность и освобождаются от должности руководителем аппарата по представлению руководителей соответствующих структурных подразделений аппарата. Указанные в абзаце первом настоящего пункта государственные гражданские служащие осуществляют свою деятельность в соответствии с их должностными регламентами, а также административными регламентами структурных подразделений аппарата.</p></li>
						<li><p>Замещение вакантных должностей государственной гражданской службы Республики Саха (Якутия) осуществляется в порядке, установленном Законом Республики Саха (Якутия) от 30 марта 2005 года 214-З № 433-III «О государственной гражданской службе Республики Саха (Якутия)».</p></li>
						<li><p>После назначения на должность государственной гражданской службы Республики Саха (Якутия) работнику аппарата выдается служебное удостоверение установленного образца. Служебное удостоверение работника аппарата является документом, подтверждающим его личность и должность.</p></li>
						<li><p>С государственным гражданским служащим аппарата заключается служебный контракт.</p></li>
						</ol>
					</Accordion>
					<Accordion title="Правовой статус и ответственность работников аппарата">
					<ol className="text__ol_strong pt-5"> 
					<li><p>Работник аппарата имеет право на:</p></li>
						<ol className="text__ol_strong">
							<li><p>обеспечение надлежащих организационно-технических условий, необходимых для исполнения должностных обязанностей;</p></li>
							<li><p>ознакомление с должностными регламентами и иными документами, определяющими его права и обязанности по замещаемой должности гражданской службы, критериями оценки эффективности исполнения должностных обязанностей, показателями результативности профессиональной служебной деятельности и условиями должностного роста;</p></li>
							<li><p>отдых, обеспечиваемый установлением нормальной продолжительности служебного времени, предоставлением выходных дней и нерабочих праздничных дней, а также ежегодных оплачиваемых основного и дополнительных отпусков;</p></li>
							<li><p>оплату труда и другие выплаты в соответствии с законодательством и со служебным контрактом;</p></li>
							<li><p>получение в установленном порядке информации и материалов, необходимых для исполнения должностных обязанностей;</p></li>
							<li><p>доступ в установленном порядке к сведениям, составляющим государственную и служебную тайну, если исполнение должностных обязанностей связано с использованием таких сведений;</p></li>
							<li><p>ознакомление с отзывами о профессиональной служебной деятельности и другими документами до внесения их в личное дело, материалами личного дела, а также на приобщение к личному делу письменных объяснений и других документов и материалов;</p></li>
							<li><p>защиту персональных данных;</p></li>
							<li><p>дополнительное профессиональное образование в порядке, установленном законодательством;</p></li>
							<li><p>членство в профессиональном союзе;</p></li>
							<li><p>иные права в соответствии с законодательством.</p></li>
						</ol>
						<li><p>Работник аппарата обязан:</p></li>
						<ol className="text__ol_strong">
							<li><p>соблюдать Конституцию Российской Федерации, федеральное законодательство, Конституцию (Основной закон) Республики Саха (Якутия), законодательство Республики Саха (Якутия);</p></li>
							<li><p>исполнять должностной регламент;</p></li>
							<li><p>исполнять поручения соответствующих руководителей;</p></li>
							<li><p>соблюдать служебный распорядок Государственного Собрания;</p></li>
							<li><p>поддерживать уровень квалификации, необходимый для надлежащего исполнения должностных обязанностей;</p></li>
							<li><p>не разглашать сведения, ставшие ему известными в связи с исполнением должностных обязанностей, в том числе сведения, касающиеся частной жизни и здоровья граждан или затрагивающие их честь и достоинство;</p></li>
							<li><p>беречь государственное имущество, в том числе предоставленное ему для исполнения должностных обязанностей;</p></li>
							<li><p>соблюдать требования к служебному поведению;</p></li>
							<li><p>иные обязанности в соответствии с законодательством.</p></li>
						</ol>
						<li><p>Работник аппарата несет ответственность за нарушение требований федерального законодательства и законодательства Республики Саха (Якутия), за ненадлежащее исполнение должностного регламента и настоящего Положения в порядке, установленном законодательством о государственной гражданской службе.</p></li>
					</ol>
					</Accordion>

					<Accordion title="Заключительные положения">
					<ol className="text__ol_strong pt-5">
					<li><p>Финансовое обеспечение деятельности аппарата осуществляется за счет средств государственного бюджета Республики Саха (Якутия).</p></li>
					<li><p>Условия и порядок материально-технического, социально-бытового, медицинского и иного обеспечения работников аппарата устанавливаются в соответствии с законодательством и коллективным договором аппарата.</p></li>
					</ol>
					</Accordion>
					<div className="pt-3 pb-5">
					<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
						<p className="pt-0">
							<a href={document}>Положение об аппарате ГС (ИТ) РС (Я)</a> (MS Word, 33 Кб)
						</p>
						</div>
			</Accordion>

			);
		}
}
