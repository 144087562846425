import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useBreadcrumbs, useSetRelationFilter, useSetRootPath } from '../../utils/hooks';
import List from './List';
import store from '../../store';
import './styles.scss';
import { MediaPreview } from '../../components';

const GalleryPage = props => {
	useBreadcrumbs('Фоторепортажи', '/photogallery');
	useSetRootPath('/photogallery');
	useSetRelationFilter({ limit: 5 });
	useEffect(() => {
		return () => store.ui.setDateRange();
	}, []);
	return (
		<>
			<div style={{ minHeight: '80vh' }}>
				<Switch>
					<Route exact path={`/photogallery`} component={() => <List {...props} />} />
					<Route exact path={`/photogallery/:id`} component={MediaPreview} />
				</Switch>
			</div>
		</>
	);
};

export default GalleryPage;
