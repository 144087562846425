import { useEffect } from 'react';
import store from 'root/site/store';

const { ui } = store;

/*

	Установка хлебных крошек

*/

export const useBreadcrumbs = (name, link) => {
	useEffect(() => {
		ui.setBreadcrumbs([...ui.breadcrumbs, { name, link }]);
		return () => {
			ui.setBreadcrumbs(ui.breadcrumbs.slice(0, -1));
		};
	}, []);
};
