import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import store from '../../../../store';
import { fioShort } from '../../../../utils';
@observer
export default class List extends React.Component {
	@observable dispatches = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const dispatchRecipients = await store.model.DispatchRecipient.find({
			where: { deputyId: store.model.user.deputyId },
			order: 'createdAt DESC',
			include: [
				{
					relation: 'dispatch',
					scope: {
						include: {
							relation: 'owner',
						},
					},
				},
			],
		});
		const dispatches = dispatchRecipients.filter(r => r.dispatch.sended);
		this.dispatches = dispatches;
		this.isLoading = false;
	};

	render() {
		let content = '...';
		if (!this.isLoading) {
			if (this.dispatches.length === 0) {
				content = 'У вас еще нет уведомлений';
			} else {
				const dispatches = this.dispatches.map(dispatch => {
					const {
						dispatch: { id, name, createdAt, owner },
						checked,
					} = dispatch;
					return (
						<tr key={`dispatch-${id}`}>
							<td className="id">{id}</td>
							<td className="date">{format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}</td>
							<td className="actions">{checked ? name : <b>{name}</b>}</td>
							<td className="actions">{fioShort(owner)}</td>
							<td className="actions">
								<Link to={`/profile/dispatches/${id}`}>Открыть</Link>
							</td>
						</tr>
					);
				});
				content = (
					<table className="items-list">
						<thead>
							<tr>
								<th>Номер</th>
								<th>Дата</th>
								<th>Заголовок</th>
								<th>Отправитель</th>
								<th />
							</tr>
						</thead>
						<tbody>{dispatches}</tbody>
					</table>
				);
			}
		}

		return <div className="appeals-page">{content}</div>;
	}
}
