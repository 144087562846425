import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Subscription } from 'root/site/components';

import WorkPlan from './WorkPlan';
import ChairpersonArticles from './ChairpersonArticles';
import { useBreadcrumbs } from 'root/site/utils/hooks';
import GovernanceSingle from 'root/site/pages/governance/GovernanceSingle';
import LastUpdated from '../../components/last-updated';
import { Page } from '../../components/page';

import store from '../../store';

const ChairpersonPage = () => {
	const [deputy, setDeputy] = useState(null);

	useEffect(() => {
		(async function() {
			const dep = await store.model.Deputy.findById(13, {
				include: ['articles'],
			});
			setDeputy(dep);
		})();
	}, []);

	useBreadcrumbs('Председатель', '/chairperson');

	return (
		<>
			<div className="container pb-5 pt-5">
				<div className="row align-items-center">
					<div className="col-12">
						<h2 className="header-2" style={{ textAlign: 'center' }}>
							Председатель Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)
						</h2>
					</div>
				</div>
				<LastUpdated dateStr="23 декабря 2019" className="pt-5" />
			</div>

			<div className="background-page-light-grey">
				<div className="container pb-5 pt-5">
					<GovernanceSingle match={{ params: { id: 5 } }} />
				</div>
			</div>
			{/*
			<div className="container pb-5 pt-5">
				<div className="row align-items-center">
					<div className="col-12">
						<h2 className="header-2" style={{ textAlign: 'center' }}>Примерный план работы Председателя
							Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)</h2>
					</div>
				</div>
			</div>
			<div className="background-page-light-grey">
				<div className="container pb-5 pt-5">
					<WorkPlan />
					<Page code="chairman-plans" contentOnly />
				</div>
			</div>
*/}
			<div className="container pb-5 pt-5">
				<div className="row align-items-center">
					<div className="col-12">
						<h2 className="header-2" style={{ textAlign: 'center' }}>
							Выступление на первом пленарном заседании VI созыва
						</h2>
					</div>
				</div>
			</div>
			<div className="background-page-light-grey">
				<div className="container pb-5 pt-5">
					<Page code="chairman-speech" contentOnly applyBreadcrumbs={false} />
					{/*<ChairpersonArticles />*/}
				</div>
			</div>
			<div className="background-page-light-grey">
				<div className="container pb-5 pt-5">
					{/* {deputy && deputy.articles().length > 0 && <div className="related-articles">
						<p><strong>Статьи:</strong></p>
						{deputy.articles().map(article => <div key={article.id} className="article-preview">
							<img alt="" className="image" src={article.imported ? `/api/containers/articles/download/${article.image}` : article.downloadFile('thumbnail')} />
							<div className="date">{formatDateStr(article.publishAt)}</div>
							<Link to={`/news/${article.id}`}>{article.name}</Link>
						</div>)}
					</div>} */}
				</div>
			</div>
		</>
	);
};

export default ChairpersonPage;
