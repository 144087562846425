import React from 'react';
import { Breadcrumbs, Subscription } from 'root/site/components';
import GovernanceList from './GovernanceList';
import { useBreadcrumbs } from 'root/site/utils/hooks';
import LastUpdated from '../../components/last-updated';

const GovernancePage = () => {
	useBreadcrumbs('Руководство', '/governance');
	return (
			<>
				<div className="container pb-5 pt-5">
					<div className="row align-items-center">
						<div className="col-12">
							<h2 className="header-2" style={{textAlign: 'center'}}>Руководство Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)</h2>
						</div>
                    </div>
					<LastUpdated dateStr="23 декабря 2019" className="pt-5" />
				</div>
                
                <div className="background-page-light-grey">
                <div className="container pb-5 pt-5">
                    <GovernanceList />
			    </div>
			    </div>
            </>
        );
};

export default GovernancePage;