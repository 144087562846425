export const LAW = '0.2F161.2F163.2F18W.';
export const LAW_Y = '0.2F161.2F163.6311Y.';
export const DECREE = '0.2F161.2F169.48TR8.';
export const DECREE_Y = '0.2F161.2F169.63184.';
export const LAW_PASSPORT = '0.2F161.2F16X.2F17F.';
export const DECREE_PASSPORT = '0.2F161.2F16X.2F17H.';

export const groupIds = {
	LAW: LAW,
	LAW_Y: LAW_Y,
	DECREE: DECREE,
	DECREE_Y: DECREE_Y,
	LAW_PASSPORT: LAW_PASSPORT,
	DECREE_PASSPORT: DECREE_PASSPORT,
};

export const groups = {
	[LAW]: 'Закон',
	[LAW_Y]: 'Закон на якутском',
	[DECREE]: 'Постановление',
	[DECREE_Y]: 'Постановление на якутском',
	[LAW_PASSPORT]: 'Законопроект',
	[DECREE_PASSPORT]: 'Проект постановления',
};

export const plural = {
	[LAW]: 'Законы',
	[LAW_Y]: 'Законы на якутском',
	[DECREE]: 'Постановления',
	[DECREE_Y]: 'Постановления на якутском',
	[LAW_PASSPORT]: 'Законопроекты',
	[DECREE_PASSPORT]: 'Проекты постановлений',
};
