import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import endOfDay from 'date-fns/endOfDay';

import { groups, plural, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT } from './groups';
import store from '../../store';
import { downloadFile, formatDate, trimmedName } from './tools';
import './style.scss';
const YAK_LANG_ID = 10218749;
@observer
export default class Passport extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable docWho = [];
	@observable who = [];
	@observable error = null;
	@observable steps = [];
	@observable data = {};

	constructor(props) {
		super(props);
		this.record = props.record;
		this.init();
	}

	@action init = async () => {
		try {
			const yakLanguage = await store.model.DocFileType.findOne({ where: { externalId: YAK_LANG_ID } });
			this.yakLanguageId = yakLanguage.id;
		} catch (e) {
			console.log(e);
		}
		this.steps = await store.model.RefLink.find({
			where: { documentId: this.record.id, childId: { neq: null } },
			order: 'orderNum asc',
			include: [
				{
					relation: 'child',
					scope: {
						include: [
							{
								relation: 'refFiles',
								scope: {
									// fields: { id: true, description: true, filesize: true, docFileId: true },
									include: ['docFile'],
								},
							},
							{
								relation: 'refLinks',
								scope: {
									include: [
										{
											relation: 'child',
											scope: {
												include: [
													{
														relation: 'refFiles',
														scope: {
															// fields: { id: true, name: true, description: true, filesize: true, docFileId: true },
															include: ['docFile'],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		});

		this.steps.forEach(step => console.log('-', step.orderNum, step.name));

		await this.getDataFromSteps();

		this.isLoading = false;
	};

	cutExtension = filename =>
		filename && filename.search(/\./) !== -1
			? filename
					.split('.')
					.slice(0, -1)
					.join('.')
			: filename || '';

	docFiles = (document, onlyYakLanguage = false) => {
		if (!document) {
			return 'No files!';
		}
		let files = document.refFiles().filter(refFile => {
			if (onlyYakLanguage) return refFile.docFile.typeId === this.yakLanguageId;
			else return refFile.docFile.typeId !== this.yakLanguageId;
		});

		files = files.map(refFile => {
			const docFile = refFile.docFile;
			if (!docFile)
				return (
					<div className="doc-file" key={refFile.id}>
						No file! (refFile.id: {refFile.id}, {refFile.docFileId || 'undefined'})
					</div>
				);
			const url = docFile.downloadFile('contents');
			return (
				<div className="doc-file" key={refFile.id}>
					<a target="_blank" href={url}>
						{this.cutExtension(docFile.contents || refFile.description)}
					</a>
				</div>
			);
		});
		return files;
	};

	refLinkFiles = refLink => <ul className="doc-files">{this.docFiles(refLink.child)}</ul>;

	getReadingNum = str => {
		if (/1, 2, 3 чтени/.test(str)) return 'в 1, 2, 3 чтениях';
		if (/1 чтени/.test(str)) return 'в 1 чтении';
		if (/2 чтени/.test(str)) return 'во 2 чтении';
		if (/3 чтени/.test(str)) return 'в 3 чтении';
		return null;
	};

	getDataFromSteps = async () => {
		const incoming = this.steps.filter(refLink => /Входящие документы/i.test(refLink.name));

		let num = null;

		/** Заключение государственно-правового управления */

		let conclusion = null;
		for (let refLink of incoming) {
			const refLinks = refLink.child.refLinks();
			conclusion = refLinks.find(refLink => /Заключения государственно - правового управления/i.test(refLink.name));
			if (conclusion) break;
		}
		console.log('conclusion', conclusion);

		/** Заседание комитета */

		let committee = null;
		const _committee = this.steps.find(refLink => /комитет/i.test(refLink.name));
		if (_committee) {
			committee = {
				decision: this.steps.find(refLink => /Решение комитет/i.test(refLink.name)),
				meeting: this.steps.find(refLink => /Заседание комитет/i.test(refLink.name)),
			};
		}
		console.log('committee', committee);

		/** Пленарное заседание */

		const decree = this.steps.find(refLink => /Постановление Государственного Собрания/i.test(refLink.name));
		let plenary = null;
		if (decree && decree.child) {
			const date = decree.child.date;
			num = decree.child.freeNum;
			if (date) {
				const _date = new Date(date);
				const date0 = startOfDay(subDays(_date, 1));
				const date1 = endOfDay(_date);
				const res = await store.model.Document.find({
					where: {
						and: [{ docGroup: '0.2F161.2F169.2F16B.' }, { date: { gte: date0 } }, { date: { lte: date1 } }],
						order: 'date desc',
					},
				});
				const session = res.length > 0 ? res[0] : null;
				const fileName = `Постановление Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) № ${decree.child.freeNum}`;
				const docFile = decree.child.refFiles().length > 0 ? decree.child.refFiles()[0].docFile : null;
				const fileUrl = docFile.downloadFile('contents');
				if (session) plenary = { date, session, fileName, fileUrl };
			}
		}
		console.log('plenary', plenary);

		this.data = {
			incoming,
			conclusion,
			committee,
			plenary,
			decree,
		};
	};

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		const { incoming, conclusion, committee, plenary, decree } = this.data;

		const renderTree = (yakLang = false) => (
			<div>
				{incoming && incoming.length > 0 && (
					<div className="step">
						<div className="step-name">
							<div className="step-line" />
							<div className="step-point" />
							<strong>Внесение проекта постановления в Государственное Собрание</strong>
						</div>
						<div key={incoming[0].id} className="substep">
							<div className="date">
								{incoming[0].child ? formatDate(incoming[0].child.date) : ''}
								<div className="substep-point" />
								<div className="substep-line" />
							</div>
							<div className="content">
								<strong>Регистрация проекта постановления и материалов к нему</strong>
								{this.docFiles(incoming[0].child, yakLang)}
							</div>
						</div>
						{incoming.length > 1
							? incoming.slice(1).map(ref => (
									<div key={ref.id} className="substep">
										<div className="date">
											{ref.child ? formatDate(ref.child.date) : ''}
											<div className="substep-point" />
											<div className="substep-line" />
										</div>
										<div className="content">
											<strong>Дополнительно</strong>
											{this.docFiles(ref.child, yakLang)}
										</div>
									</div>
							  ))
							: null}

						{conclusion && (
							<div key={conclusion.id} className="substep">
								<div className="date">
									{conclusion.child ? formatDate(conclusion.child.date) : ''}
									<div className="substep-point" />
									<div className="substep-line" />
								</div>
								<div className="content">
									<strong>Заключение государственно-правового управления</strong>
									{this.docFiles(conclusion.child, yakLang)}
								</div>
							</div>
						)}
					</div>
				)}

				<div className="step">
					<div className="step-name">
						<div className="step-line" />
						<div className="step-point" />
						<strong>Рассмотрение проекта постановления</strong>
					</div>
					{committee && committee.decision && committee.meeting && (
						<div className="substep">
							<div className="date">
								{committee.decision.child ? formatDate(committee.decision.child.date) : ''}
								<div className="substep-point" />
								<div className="substep-line" />
							</div>
							<div className="content">
								<strong>Рассмотрение проекта постановления ответственным комитетом</strong>
								<div>
									{committee.meeting.child.name} № {committee.meeting.child.freeNum}
								</div>
								{this.docFiles(committee.decision.child, yakLang)}
							</div>
						</div>
					)}
					{plenary && (
						<div className="substep">
							<div className="date">
								{plenary.date ? formatDate(plenary.date) : ''}
								<div className="substep-point" />
								<div className="substep-line" />
							</div>
							<div className="content">
								<strong>Рассмотрение законопроекта на пленарном заседании</strong>
								<div>{plenary.session.name}</div>
								<div className="doc-file">
									<a target="_blank" href={plenary.fileUrl}>
										{plenary.fileName}
									</a>
								</div>
							</div>
						</div>
					)}
				</div>

				{decree && decree.child && (
					<div className="step">
						<div className="step-name">
							<div className="step-line" />
							<div className="step-point" />
							<strong>Опубликование</strong>
						</div>
						<div className="substep">
							<div className="date">
								<div className="substep-point" />
								<div className="substep-line" />
							</div>
							<div className="content">
								<strong>Официальный сайт Государственного Собрания (Ил Тумэн)</strong>
								<div className="doc-file">
									<Link to={`/documents/${decree.child.id}`}>
										Постановление Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от {formatDate(decree.child.date)}{' '}
										№ {decree.child.freeNum} "{this.record.name}"
									</Link>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);

		const hasYakFiles = !!this.record.refFiles().find(refFile => refFile.docFile && refFile.docFile.typeId === this.yakLanguageId);

		return (
			<>
				<div className="doc-name">
					<strong>{trimmedName(this.record.name)}</strong>
				</div>
				<div className="passport">
					{renderTree()}
					{hasYakFiles && renderTree(true)}
				</div>
			</>
		);
	}
}
