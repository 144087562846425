import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import store from '../../../../store';

@observer
export default class List extends React.Component {

	@observable appeals = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.appeals = await store.model.Appeal.find({
			where: { deputyId: store.model.user.deputyId },
			include: ['deputy'],
			order: 'id desc',
		});
		this.isLoading = false;
	};
	
	render() {
		let content = '...';
		
		if (!this.isLoading) {
			if (this.appeals.length === 0) {
				content = 'У вас еще нет обращений';
			}
			else {
				const appeals = this.appeals.map(appeal => <tr key={appeal.id}>
					<td className="id">{appeal.id}</td>
					<td className="from">{appeal.lastName} {appeal.firstName} {appeal.middleName}</td>
					<td className="date">{format(new Date(appeal.createdAt), 'dd.MM.yyyy HH:mm')}</td>
					{/*<td className="file">{appeal.attachment ? <a href={appeal.downloadFile('attachment')}>{appeal.attachment}</a> : ''}</td>*/}
					<td className="actions"><Link to={`/profile/appeals/${appeal.id}`}>Открыть</Link></td>
				</tr>);
				content = <table className="items-list">
					<thead>
					<tr>
						<th>Номер обращения</th>
						<th>От</th>
						<th>Дата</th>
						{/*<th>Файл</th>*/}
						<th/>
					</tr>
					</thead>
					<tbody>
					{appeals}
					</tbody>
				</table>
			}
		}
		
		return <div className="appeals-page">
			{content}
		</div>;
	}
	
}
