import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export const VerifySuccess = () => {
	return (
		<div className="verify-success">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 note-page-title mb-md-5 mt-5">
						<h1>Спасибо за подтверждение адреса электронной почты</h1>
					</div>
				</div>
			</div>
            <div className="mt-5">
                <Link to="/profile">
                    <h2 className="verify-success__link">Перейти в личный кабинет</h2>
                </Link>
            </div>
		</div>
	);
};
