import React from 'react';
import { ElementItem } from '..';
import { dateAndTime } from '../../utils/date-format';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import './styles.scss';
import { Badges } from './Badges';

export default ({ article, getItemLink, showCounter }) => {
	if (!article) return <div className='mid-article' />;
	const { id, name, publishAt } = article;
	let thumbnail = article.downloadFile('thumbnail');
	const views = article.views && article.views() && article.views().totalCount;
	return (
		<div key={`${article.MODEL.INFO.name}-${id}`} className='mid-article'>
			<div className='badges'>
				<Badges article={article} width={120} />
			</div>
			<div className='image-wrapper'>
				<img
					className='image'
					width='100%'
					height='auto'
					alt="thumbnail"
					src={article.imported ? `/api/containers/articles/download/${article.image}` : `${thumbnail}?image=[{"resize":{"width":300}}]`}
				/>
			</div>
			<div className="info">
				{getItemLink(article)}
				<div className="bottom">
					<ElementItem grey small icon={calendar} text={dateAndTime(publishAt)} top="2px" />
					{showCounter && views && <ElementItem grey small icon={eye} text={views} top='2px' />}
				</div>
			</div>
		</div>
	);
};
