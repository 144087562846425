import React from 'react';
import './styles.scss';
import ReactSVG from 'react-svg';
import whatsapp from 'root/site/img/whatsapp.svg';
import telegram from 'root/site/img/telegram.svg';
import twitter from 'root/site/img/twitter.svg';
import facebook from 'root/site/img/facebook.svg';
import vk from 'root/site/img/vk.svg';



export default class QuoteNews extends React.Component {
	render() {
		return (
			
			
				<div className="container">
					<div className="QuoteNews">
					<span className="QuoteNews_text">
					<span className="QuoteNews_text_bold">«Хотелось бы услышать, насколько изменилась ситуация и какова радиационная обстановка в Якутии сегодня», 
					</span>- обозначил цели и задачи правительственного часа парламентарий.
					</span>
					</div>
				</div>
			
		);
	}
}
