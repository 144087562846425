import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class PageWithTabs extends React.Component {
	
	static propTypes = {
		path: PropTypes.string.isRequired, // путь по которому должна быть доступна страница
		code: PropTypes.string.isRequired, // код страницы в админке
		renderer: PropTypes.func, // рендерер
	};
	
	@observable isLoading = true;
	@observable page = null;
	@observable subpages = [];
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const res = await store.model.Page.find({
			where: {
				route: this.props.code,
				// hide: true,
			},
		});
		this.page = res.length > 0 ? res[0] : null;
		if (this.page) {
			this.subpages = await store.model.Page.find({
				where: {
					hide: true,
					parentId: this.page.id,
				},
				fields: ['id', 'order', 'title', 'route'],
				order: 'order asc, id asc'
			});
		}
		this.isLoading = false;
	};
	
	render() {
		const { path, renderer } = this.props;
		if (this.isLoading) return <div className="base-page container"/>;
		
		const breadCrumbs = this.page ? [{ name: this.page.title, link: this.page.route }] : [];
		
		return <div className="base-page container page-with-menu">
			{renderer(path, this.subpages, breadCrumbs)}
		</div>;
	}
	
}
