import store from '../../../store';

export default async sessionId => {
	const plenarySession = await store.model.PlenarySession.findById(sessionId, {
		include: [
			{
				relation: 'meeting',
				scope: {
					include: [
						{
							relation: 'questions',
							scope: {
								where: { statusId: 1 },
								order: 'sort asc',
								include: [
									{
										relation: 'documents',
										scope: {
											include: [
												{
													relation: 'file',
													scope: {
														order: 'sort asc'
													}
												}
											]
										}
									}
								]
							}
						}
					]
				}
			}
		]
	});
	
	if (plenarySession.meeting) {
		return plenarySession.meeting.questions().map(question => {
			// console.log('question', question.documents());
			return {
				id: question.id,
				name: question.title,
				sort: question.sort,
				files: question.documents().map(document => ({
					id: document.id,
					name: document.name,
					pages: document.file().map(file => file.path)
				}))
			};
		});
	}
	return [];
}
