import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';

import PageTree from './PageTree';
import proccessAccordeons from './accordeons';
import proccessLinks from './links';
import proccessLightbox from './lightbox';
import store from '../../store';

@observer
export default class PageForRoute extends React.Component {
	
	@observable page = null;
	@observable error = null;
	@observable lightboxImages = [];
	@observable showImages = false;
	@observable imageIndex = 0;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.route !== this.props.route) {
			this.init();
		}
	}
	
	init = async () => {
		const { error, page } = await store.model.Page.findPage({ route: this.props.route });
		console.log({ error, page });
		if (!error) {
			this.page = page;
		}
		else {
			this.error = error;
		}
	};
	
	onMutation = (mutationsList, observer) => {
		proccessLinks(this.el);
		proccessAccordeons(this.el);
	};

	onMount = el => {
		// console.log('onMount', el);
		if (el) {
			this.el = el;
			const observer = new MutationObserver(this.onMutation);
			observer.observe(el, {
				// attributes: true,
				childList: true,
				subtree: true
			});
			proccessLinks(el);
			proccessAccordeons(el);
			proccessLightbox(el, this.onImageClick);
		}
	};
	
	@action onImageClick = (images, index) => {
		this.lightboxImages = images;
		this.imageIndex = index;
		this.showImages = true;
	};
	
	hideImages = () => this.showImages = false;
	
	render() {
		if (!this.page && this.error) return this.error;
		if (!this.page) return null;
		
		return <>
			<PageTree tree={this.page} onMount={this.onMount}/>
			{this.showImages &&
			<Lightbox
				mainSrc={this.lightboxImages[this.imageIndex].href}
				imageTitle={<div className="image-title">{this.lightboxImages[this.imageIndex].title}</div>}
				nextSrc={this.lightboxImages[(this.imageIndex + 1) % this.lightboxImages.length].href}
				prevSrc={this.lightboxImages[(this.imageIndex + this.lightboxImages.length - 1) % this.lightboxImages.length].href}
				onCloseRequest={this.hideImages}
				onMovePrevRequest={() => this.imageIndex =  (this.imageIndex + this.lightboxImages.length - 1) % this.lightboxImages.length}
				onMoveNextRequest={() => this.imageIndex = (this.imageIndex + 1) % this.lightboxImages.length}
			/>
			}
		</>;
	}
	
}
