import React, { useEffect, useState, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import ReactToPrint from 'react-to-print';

import store from 'root/site/store';
import { useGetRecord, useCheckViews } from 'root/site/utils/hooks';
import { dateAndTime } from 'root/site/utils/date-format';

import { Loader, HtmlValue, Button } from '@smartplatform/ui';

import eye from 'root/site/img/eye.svg';

import calendar from 'root/site/img/calendar.svg';

import SimilarNews_Sidebar from './SimilarNews_Sidebar';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import logo from 'root/site/img/print-logo.png';

@observer
class ArticleToPrint extends React.Component {
	@observable record;
	async componentDidMount() {
		const { id } = this.props;
		const record = await store.model.Article.findById(id, { include: ['views'] });
		const views = await record.views().length;
		const publishAt = dateAndTime(record.publishAt);
		const thumbnail = await record.downloadFile('thumbnail');
		this.record = { ...record, views, publishAt, thumbnail };
	}

	render() {
		if (!this.record) {
			return <div className="container base-page"><Loader /></div>;
		}

		const { name, thumbnail, contentTop, contentBottom, quote, views, publishAt } = this.record;
		const article = this.record;
		return (
			<div className="print-version-wrapper">
				<div className="print-version__header">
					<img className="print-version__header-logo" src={logo}></img>
					<span>
						<span className="header__title-big">Государственное Собрание (ИЛ ТУМЭН) </span>
						<span className="header__title-small">Республики Саха (Якутия)</span>
						<span className="header__title-small">Официальный сайт</span>
					</span>
				</div>

				<div className="print-version__title pb-5 pt-5">
					<h1>{name}</h1>
				</div>

				<div>
					<div className="print-version__img">
						<img
							alt={name}
							src={article.imported ? `/api/containers/articles/download/${article.image}` : thumbnail}
						/>
					</div>

					<div className="print-version__text">
						<HtmlValue value={contentTop} />
						<HtmlValue value={quote} className="quote-news__text" />
						<HtmlValue value={contentBottom} />
					</div>
					<div className="print-version__text print-version__info">
						{/* <div className="news-views">
							<ReactSVG className="simple-icon simple-icon-dark" src={eye} />
							<span className="note-page__date">{views}</span>
						</div> */}

						<div className="news-date">
							{/* <ReactSVG className="simple-icon simple-icon-dark" src={calendar} /> */}
							<span className="note-page__date">Дата публикации новости: {publishAt}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const PrintVersion = ({ match }) => {
	const componentRef = useRef();
	const { id } = match.params;
	return (
		<div>
			<ReactToPrint
				trigger={() => (
					<div className="print-version__button">
						<Button label="Распечатать" />
					</div>
				)}
				content={() => componentRef.current}
			/>

			<ArticleToPrint ref={componentRef} id={id} />
		</div>
	);
};
export default withRouter(PrintVersion);
