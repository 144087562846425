import React from 'react';
import { YMaps, Map, Placemark, FullscreenControl } from 'react-yandex-maps';

const mapState = { center: [62.025870, 129.730741], zoom: 16,  }

const coord = [62.025870, 129.730741];


    const placeMark = {
	geometry: [62.025870, 129.730741],
	modules: 
        ['geoObject.addon.hint']
	,
    properties: {
		
        hintContent: 'Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)'
    }
	
}


export default class ContactMap extends React.Component {
	render() {
		return (
			
			<YMaps>
			<Map state={mapState} width="100%" className="contact-map">
                    <Placemark {...placeMark} />
					<FullscreenControl />
                </Map>
		</YMaps>
	);}
		}
