import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';

import { Pager, Select, RecordSelect, DatePicker, Loader } from '@smartplatform/ui';
import { groups, plural, groupIds, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT } from './groups';
import { trimmedName, formatDate } from './tools';
import StatTable from './StatTable';
import store from '../../store';
import Initiators from './Initiators';

const PER_PAGE = 10;

@observer
export default class List extends React.Component {
	@observable isLoading = true;
	@observable records = [];
	@observable page = 1;
	@observable sortBy = 'date desc';

	@observable group = null;
	@observable number = '';
	@observable name = '';

	@observable stadia = null;
	@observable status = null;
	@observable direction = null;

	@observable dateFrom = null;
	@observable dateTo = null;
	@observable committee = null;
	@observable isRefCorresp = true;

	// @observable initiator = null;
	@observable initiator = '';
	@observable themeBlock = null;
	@observable where = {};

	first = true;

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Нормативно правовые акты', link: '/documents' }]);
		this.doSearch = debounce(this.doSearch, 250, { leading: false, trailing: true });
	}

	componentDidMount() {
		this.firstInit();
	}

	firstInit = async () => {
		await this.getParams();
		await this.init();
	};

	@action getParams = async () => {
		if (!store.route.params) store.route.params = {};
		const { group, page, name, num, date0, date1, com, sort } = store.route.params;
		if (group) this.group = group;

		if (sort) this.sortBy = sort;
		if (page) this.page = parseInt(page);
		if (name) this.name = decodeURIComponent(name);
		if (num) this.number = decodeURIComponent(num);
		if (date0) this.dateFrom = parse(date0, 'dd.MM.yyyy', new Date());
		if (date1) this.dateTo = parse(date1, 'dd.MM.yyyy', new Date());
		if (com) this.committee = await store.model.Committee.findById(com);
	};

	@action init = async () => {
		this.isLoading = true;
		this.isRefCorresp = true;
		let includedDegree = this.group === 'DECREE' ? { nlike: 'З%V%' } : undefined;
		const where =
			this.group === null
				? {
						docGroup: { inq: Object.values(groupIds) },
						and: [{ name: { neq: null } }, { date: { gt: new Date(2013, 9, 1) } }],
				  }
				: {
						and: [
							{ docGroup: groupIds[this.group] },
							{ name: { neq: null } },
							{ freeNumSearch: includedDegree },
							{ date: { gt: new Date(2013, 9, 1) } },
						],
				  };

		const number = this.number.trim();
		if (number.length > 0) {
			where.and.push({ freeNum: { like: `%${number}%` } });
		}

		const name = this.name.trim();
		if (name.length > 0) {
			where.and.push({ name: { regexp: `/${name}/i` } });
		}

		if (this.dateFrom) {
			where.and.push({ date: { gte: this.dateFrom } });
		}

		if (this.dateTo) {
			where.and.push({ date: { lte: this.dateTo } });
		}

		if (this.committee) {
			where.and.push({ duePerson: this.committee.externalId });
		}

		if (this.initiator) {
			const searchWords = this.initiator.split(` `).filter(word => word.length > 3);

			const and = [];
			if (searchWords.length > 0) and.push({ lastName: { regexp: '/' + searchWords[0] + '/gi' } });
			if (searchWords.length > 1) and.push({ firstName: { regexp: '/' + searchWords[1] + '/gi' } });
			if (searchWords.length > 2) and.push({ middleName: { regexp: '/' + searchWords[2] + '/gi' } });

			const deputy = await store.model.Deputy.find({
				where: { and },
			});

			console.log('> deputy', deputy);

			const organiz = await store.model.OrganizCl.find({
				where: {
					or: [
						{
							deputyId: { inq: deputy.map(o => o.id) },
						},
						{
							and: [{ deputyId: { eq: null } }, { name: { regexp: '/' + searchWords.join(`|`) + '/gi' } }],
						},
					],
				},
			});

			console.log('> organiz', organiz);

			const refCorresp = await store.model.RefCorresp.find({
				where: {
					organizationId: {
						inq: organiz.map(o => o.id),
					},
					documentId: { neq: null },
				},
				include: [
					{
						relation: 'document',
						scope: {
							fields: ['id'],
							include: [
								{
									relation: 'refLinks',
									scope: {
										fields: ['id', 'childId'],
										/*where: {
											name: { ilike: '%Закон Республики Саха (Якутия)%' },
										},*/
										include: [
											{
												relation: 'child',
												scope: {
													fields: ['id', 'name', 'year', 'date', 'freeNum'],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			});
			if (refCorresp.length > 0) {
				const laws = [];
				refCorresp.forEach(r => {
					if (r.document && r.document.refLinks() && r.document.refLinks().length > 0) {
						const refLink = r.document.refLinks()[0];
						if (refLink.child && isAfter(new Date(refLink.child.date), new Date(2018, 8, 24))) {
							laws.push(refLink.child);
						}
					}
				});
				where.and.push({ id: { inq: laws.map(law => law.id) } });
			} else where.and.push({ id: { inq: [] } });
			this.isRefCorresp = !!refCorresp.length;
			console.log('initiator', deputy, organiz, refCorresp);
		}

		this.records = await store.model.Document.find({
			where,
			include: [{ relation: 'committee', scope: { fields: { id: true, name: true } } }],
			order: this.sortBy,
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});

		if (!this.isRefCorresp) {
			this.records = [];
			this.records.totalCount = 0;
		}
		this.where = where;

		if (!store.route.params) store.route.params = {};

		// store.route.params.group = this.group !== 'LAW' ? this.group : undefined;
		// убрал эту строчку , так как заказчик хочет чтобы группа "законы" сохраналась в роуте
		// возможно что-то поломается. заменил на:
		store.route.params.group = this.group;
		//
		//
		store.route.params.name = name.length > 0 ? encodeURIComponent(name) : undefined;
		store.route.params.num = number.length > 0 ? encodeURIComponent(number) : undefined;
		store.route.params.page = this.page > 1 ? this.page : undefined;
		store.route.params.sort = this.sortBy !== 'date desc' ? this.sortBy : undefined;
		store.route.params.date0 = this.dateFrom ? format(new Date(this.dateFrom), 'dd.MM.yyyy') : undefined;
		store.route.params.date1 = this.dateTo ? format(new Date(this.dateTo), 'dd.MM.yyyy') : undefined;
		store.route.params.com = this.committee ? this.committee.id : undefined;

		if (!this.first) store.route.push();
		this.first = false;

		this.isLoading = false;
	};

	onGroupChange = groupId => (this.group = groupId);
	onNumberChange = e => (this.number = e.target.value);
	onNameChange = e => (this.name = e.target.value);
	onStadiaChange = record => (this.stadia = record);
	onStatusChange = record => (this.status = record);
	onDirectionChange = record => (this.direction = record);
	onCommiteeChange = record => (this.committee = record);
	onDateFromChange = date => (this.dateFrom = date);
	onDateToChange = date => (this.dateTo = date);
	// onInitiatorChange = record => this.initiator = record;
	onInitiatorChange = e => (this.initiator = e.target.value);
	onThemeBlockChange = record => (this.themeBlock = record);

	reset = e => {
		e.preventDefault();
		this.initiator = '';
		this.group = '-';
		this.name = '';
		this.number = '';
		this.committee = null;
		this.dateFrom = null;
		this.dateTo = null;
		this.sortBy = 'date desc';
		this.init();
		store.route.params = {};
		store.route.push();
	};

	submit = async () => {
		this.page = 1;
		this.init();
	};

	@action onPageChange = page => {
		this.page = page;
		this.init();
		// store.route.params.page = page;
		// store.route.push();
	};

	onSearch = e => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		this.init();
	};

	onIsnClick = document => {
		navigator.clipboard.writeText(document.id).then(
			function() {
				console.log('copied to clipboard', document.id);
			},
			err => {
				console.error('could not copy text', err);
			}
		);
	};

	docGroup = document => groups[document.docGroup];

	sortByNum = value => {
		console.log('sortByNum', value);
		this.sortBy = value;
		this.page = 1;
		this.init();
	};

	render() {
		const dateStr = this.group === 'LAW_PASSPORT' || this.group === 'DECREE_PASSPORT' ? 'Дата регистрации' : 'Дата принятия';

		return (
			<div className={'doc-list' + (this.isLoading ? ' is-loading' : '')}>
				<h1>Нормативные правовые акты</h1>

				<div className="filters">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<Select
								items={Object.entries(groupIds).map(([key, value]) => ({
									label: plural[value],
									value: key,
								}))}
								noSearch={true}
								value={this.group}
								onChange={this.onGroupChange}
								empty={<span className="empty">Тип документа</span>}
							/>
						</div>
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<input type="text" value={this.number} onChange={this.onNumberChange} placeholder="Номер документа" />
						</div>
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<input type="text" value={this.name} onChange={this.onNameChange} placeholder="Название" />
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<DatePicker value={this.dateFrom} onChange={this.onDateFromChange} placeholder={`${dateStr} (от)`} />
						</div>
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<DatePicker value={this.dateTo} onChange={this.onDateToChange} placeholder={`${dateStr} (до)`} />
						</div>
						<div className="col-12 col-sm-12 col-md-4 col-lg-4">
							<RecordSelect
								model={store.model.Committee}
								noSearch={true}
								property="name"
								value={this.committee}
								showValue={this.committee ? this.committee.name : <span className="empty">Ответственный комитет</span>}
								onChange={this.onCommiteeChange}
								filter={{ where : {deactivated: false}}}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-4">
							<input type="text" value={this.initiator} onChange={this.onInitiatorChange} placeholder="Инициатор" />
						</div>
						<div />
						<div />
					</div>
					<button className="red-btn" onClick={this.submit}>
						Найти
					</button>
					<a className="reset" href="#" onClick={this.reset}>
						Сбросить фильтры
					</a>
				</div>

				<div className="results">
					<div className="count">
						Результаты поиска: <strong>{this.records.totalCount}</strong>
					</div>
				</div>

				{!this.isLoading && <StatTable where={this.where} group={this.group} />}

				<div className="sorting row">
					<div className="title col-12 col-sm-2 col-md-2 col-lg-2">Сортировать по:</div>
					<div className="sort-by col-12 col-sm-3 col-md-3 col-lg-3">
						<Select
							items={[
								{ label: <span>Номер документа &darr;</span>, value: 'freeNum desc' },
								{ label: <span>Номер документа &uarr;</span>, value: 'freeNum asc' },
							]}
							value={/freeNum/.test(this.sortBy) ? this.sortBy : null}
							onChange={this.sortByNum}
							empty={<span className="empty">Номер документа</span>}
						/>
					</div>
					<div className="sort-by col-12 col-sm-3 col-md-3 col-lg-3">
						<Select
							items={[
								{ label: <span>{dateStr} &darr;</span>, value: 'date desc' },
								{ label: <span>{dateStr} &uarr;</span>, value: 'date asc' },
							]}
							value={/date/.test(this.sortBy) ? this.sortBy : null}
							onChange={this.sortByNum}
							empty={<span className="empty">Дата принятия</span>}
						/>
					</div>
					<div className="sort-by col-12 col-sm-3 col-md-3 col-lg-3">
						<Select
							items={[
								{ label: <span>По названию &darr;</span>, value: 'name desc' },
								{ label: <span>По названию &uarr;</span>, value: 'name asc' },
							]}
							value={/name/.test(this.sortBy) ? this.sortBy : null}
							onChange={this.sortByNum}
							empty={<span className="empty">По названию</span>}
						/>
					</div>
				</div>

				{this.isLoading ? (
					<div className="loading">
						<Loader />
					</div>
				) : (
					<>
						{this.records.map(record => (
							<div key={record.id} className="document-item">
								<div className="left">
									<Link to={`/documents/${record.id}`}>
										<span className="number">{record.freeNum}</span>
										<span className="name">{trimmedName(record.name)}</span>
									</Link>
								</div>
								<div className="right">
									<div className="group">
										<em>{groups[record.docGroup]}</em>
									</div>
									{record.committee && (
										<div className="committee">
											<em>Ответственный комитет</em>: {record.committee.name}
										</div>
									)}
									<div className="date">
										{record.docGroup === LAW_PASSPORT || record.docGroup === DECREE_PASSPORT
											? 'Дата регистрации'
											: 'Дата принятия'}
										: <em>{formatDate(record.date)}</em>
									</div>
									<Initiators record={record} />
								</div>
							</div>
						))}
						<Pager
							totalCount={this.records.totalCount}
							itemsPerPage={PER_PAGE}
							current={this.page}
							onChange={this.onPageChange}
							directChange={true}
						/>
					</>
				)}
			</div>
		);
	}
}
