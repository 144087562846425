import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Tab, Tabs } from '../../components/route-tabs';
import { formatDateStr } from '../../utils/dateUtils';
import store from '../../store';
import DeputyCard from './DeputyCard';
import empty from '../../img/user.png';
import './style.scss';

@withRouter
@observer
export default class Convocation extends React.Component {
	@observable isLoading = true;
	@observable convocation = null;
	@observable depConv = [];
	@observable deputies = [];

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Депутаты', link: '/deputies' }]);
		this.init();
	}

	@action init = async () => {
		this.depConv = await store.model.DeputyConvocation.find({
			where: { convocationId: this.props.id },
			include: [
				{ relation: 'convocation' },
				{ relation: 'deputy' },
				{ relation: 'fraction', scope: { fields: { id: true, shortName: true } } },
				{ relation: 'division', scope: { fields: { id: true, name: true, number: true } } },
				{ relation: 'divisionGroup', scope: { fields: { id: true, name: true } } },
			],
		});
		/*
		const ids = this.depConv.map(dc => dc.deputyId);
		this.deputies = await store.model.Deputy.find({
			where: { id: { inq: ids }},
			include: [
				{ relation: 'fraction', scope: { fields: { id: true, shortName: true }}},
				{ relation: 'division', scope: { fields: { id: true, name: true }}},
				{ relation: 'divisionGroup', scope: { fields: { id: true, name: true }}},
			],
			order: 'lastName asc',
		});
*/
		this.isLoading = false;
	};

	gotoDeputy = deputy => this.props.history.push(`/deputies/${deputy.id}`);

	render() {
		if (this.isLoading) return null;

		// return this.depConv.map(dc => dc.deputyId).join(', ');

		const deputies = this.depConv
			.filter(dc => !!dc.deputy)
			.sort((a, b) => {
				if (a.deputy.lastName > b.deputy.lastName) return 1;
				if (a.deputy.lastName < b.deputy.lastName) return -1;
				return 0;
			});

		return (
			<>
				{!!this.depConv.length && this.depConv[0].convocation && (
					<p className="convocation-validity">
						{' '}
						<span>Период работы:</span> <br />
						{this.depConv[0].convocation.validity}{' '}
					</p>
				)}
				{deputies.map((depConv, i) => (
					<DeputyCard key={depConv.id} depConv={depConv} />
				))}
			</>
		);
	}
}
