import React, { useEffect } from 'react';
import moment from 'moment';
import ReactSVG from 'react-svg';
import calendar from 'root/site/img/calendar.svg';
import docs from 'root/site/img/docs.svg';
import { Breadcrumbs, Subscription, Accordion } from '../../components';

import media_attention from './docs/media-attention.jpg';
import list_media from './docs/Список_аккредитованных_СМИ.doc';
import rule_media from './docs/Правила_аккредитации_СМИ.docx';
import { useBreadcrumbs, useGetRecords } from '../../utils/hooks';
import { Loader } from '@smartplatform/ui';

const MediaPage = () => {
	useBreadcrumbs('Аккредитация СМИ', '/media-accreditation');

	const options = {
		include: ['journalists'],
		order: 'name asc',
	};

	const [data] = useGetRecords({ model: 'MediaAccreditation', options: { include: 'attachments' } });

	const [medias] = useGetRecords({ model: 'MediaTitle', options, perPage: 1000 });

	if (!data || !medias) {
		return <Loader />;
	}

	const updatedAt = data[0] ? data[0] : null;
	const attachments = data[0] ? data[0].attachments() : [];

	return (
		<>
			<div className="note-page-border-bottom">
				<div className="container">
					<div className="row justify-content-center mb-4">
						<div className="col-12">
							<div className="note-page-title mb-md-5 mt-5">
								<h1>Аккредитация СМИ при Государственном Собрании (Ил Тумэн) Республики Саха (Якутия)</h1>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-12 col-md-6 pt-3">
							<span className="note-page__date note-page__date-grey">Дата последнего изменения</span>
						</div>

						<div className="col-12 col-md-6">
							<span className="note-page__date note-page__date-right">
								<ReactSVG className="simple-icon simple-icon-dark" src={calendar} /> {moment(updatedAt).format('DD MMMM YYYY')}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="container pt-mb-5 pb-5">
				<div className="row">
					<div className="col-12">
						{attachments.map(attachment => (
							<div className="pt-md-5" key={`media-accreditation-attachments-${attachment.id}`}>
								<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
								<p>
									<a href={attachment.downloadFile('filename')}>{attachment.name}</a>
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="container container_pb_90 container_pt_40">
				<div className="row">
					<div className="col-12 pb-5">
						{medias.map(media => (
							<Accordion title={media.name} key={`media-title-${media.id}`}>
								<ol className="text__ol_strong pt-5">
									{media.journalists().map(journalist => (
										<li key={`journalist-${journalist.id}`}>
											<p>
												{journalist.name} - {journalist.position}
											</p>
										</li>
									))}
								</ol>
							</Accordion>
						))}
					</div>
				</div>
			</div>
		</>
	);
};
export default MediaPage;
