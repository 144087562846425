import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

import { formatDateStr } from '../profile/tools';
import { sortDep } from '../../utils/getCurrentDeputies';
import store from '../../store';
import './style.scss';

export const positions = {
	0: 'член фракции',
	1: 'руководитель фракции',
	2: 'заместитель руководителя',
};

@withRouter @observer
export default class Fractions extends React.Component {
	
	@observable depConv = [];
	@observable articles = [];
	
	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Депутатские объединения', link: '/fractions' }]);
		this.init();
	}
	
	init = async () => {
		this.depConv = await store.model.DeputyConvocation.find({
			where: {
				and: [
					{ fractionId: this.props.fraction.id },
					{ convocationId: store.currentConvocation.id },
				]
			},
			include: [
				{
					relation: 'deputy',
					scope: {
						fields: {
							id: true,
							lastName: true,
							firstName: true,
							middleName: true,
							photo: true,
						}
					}
				}
			]
			
		});
		
		this.articles = await this.props.fraction.articles();
		console.log('articles', this.articles);
		
	};
	
	gotoDeputy = deputy => this.props.history.push(`/deputies/${deputy.id}`);
	
	render() {
		const { fraction } = this.props;
		
		const image = fraction.image ? <img src={fraction.downloadFile('image')} /> : <span className="empty" />;
		
		let depConv = this.depConv.sort((a, b) => sortDep(a.deputy, b.deputy));
		depConv = depConv.filter(depConv => !depConv.endDate);
		
		const members = depConv.filter(depCom => !depCom.positionNum);
		const chairman = depConv.filter(depCom => depCom.positionNum === 1);
		const zams = depConv.filter(depCom => depCom.positionNum === 2);
		
		depConv = [...chairman, ...zams, ...members];
		
		const deputies = depConv.filter(depConv => !!depConv.deputy).map(depConv => {
			const deputy = depConv.deputy;
			const photo = deputy.photo ? <img src={deputy.downloadFile('photo')} /> : <span className="empty" />;
			const positionNum = parseInt(depConv.positionNum);
			return <div key={deputy.id} className="deputy col-lg-2 col-md-2 col-sm-6 col-6" onClick={() => this.gotoDeputy(deputy)}>
				<div className="photo">{photo}</div>
				<div className="name">{deputy.lastName}<br />{deputy.firstName}<br />{deputy.middleName}</div>
				{positionNum && positionNum > 0 ? <div className="position">{positions[positionNum]}</div> : ''}
			</div>;
		});
		
		return <div key={fraction.id} className="fraction">
			<div className="main">
				<div className="image">{image}</div>
				<div className="about">
					<div className="name">{fraction.fractionName}</div>
					<div className="website"><a target="_blank" href={fraction.websiteUrl}>{fraction.website}</a>
					</div>
					<div className="file">
						<span className="icon"><FontAwesomeIcon icon={faFileAlt} /></span>
						<a href={fraction.downloadFile('file')}>{fraction.fileName}</a>
					</div>
				</div>
			</div>
			<div className="deputies row">{deputies}</div>
			{this.articles.length > 0 && <div className="related-articles">
				<p><strong>Статьи:</strong></p>
				{this.articles.map(article => <div key={article.id} className="article-preview">
					<img alt="" className="image" src={article.imported ? `/api/containers/articles/download/${article.image}` : article.downloadFile('thumbnail')} />
					<div className="date">{formatDateStr(article.publishAt)}</div>
					<Link to={`/news/${article.id}`}>{article.name}</Link>
				</div>)}
			</div>}
		
		</div>;
		
	}
	
}
