import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/ПОЛОЖЕНИЕ об  аттестации госслужащих.doc';


export default class Contests extends React.Component {
    render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pt_40">
				    <div className="row">
				        <div className="col-12 pt-3">
                            <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                                Конкурсы, аттестации, квалификационные экзамены
					        </h2>

                   
                        <Accordion title="Конкурсная комиссия для проведения конкурса на замещение вакантных должностей государственной гражданской службы в аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)">
                        <p>Состав конкурсной и аттестационной комиссии Государственного Собрания (Ил Тумэн) :</p>
                        <ul className="text__ul ml-5">
                            <li><span><b>Антонен Андрей Иванович</b> - и.о. руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (председатель комиссии);</span></li>
                            <li><span><b>Захарова Татьяна Айаровна</b> - старший референт государственно-правового управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), (секретарь комиссии);</span></li>
                            <li><span>Руководитель или заместитель руководителя структурного подразделения, в котором проводится конкурс на замещение вакантной должности государственной гражданской службы;  </span></li>
                            <li><span><b>Гуляева Нюргустана Юрьевна</b> - начальнк отдела государственной службы и кадров аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия);</span></li>
                            <li><span><b>Ермолаева Лена Саввична</b> - руководитель отдела правового обеспечения государственной и муниципальной службы Департамента кадровой политики Администрации Главы и Правительства Республики Саха (Якутия);</span></li>
                            <li><span><b>Скрябина Мария Владимировна</b> - директор Центра тестирования Высшей школы инновационного менеджмента при Главе Республики Саха (Якутия), (независимый эксперт);</span></li>
                            <li><span><b>Алексеев Вячеслав Петрович</b> - председатель республиканского комитета профсоюза работников государственных учреждений и общественного обслуживания Республики (Саха) Якутия, (независимый эксперт).</span></li>
                        </ul>
                        </Accordion>
                        <Accordion title="Методика проведения конкурса на замещение вакантных должностей государственной гражданской службы в аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)">
                        <p><strong>1. Общие положения</strong></p>
						<ol className="text__ol_strong">
							<li><p>Настоящая Методика проведения конкурсов на замещение вакантных должностей государственной гражданской службы Республики Саха (Якутия) в аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее - Методика и конкурс соответственно) разработана в соответствии с Федеральным законом от 27 июля 2004 г. № 79–ФЗ «О государственной гражданской службе Российской Федерации» (далее - Федеральный закон № 79-ФЗ), Положением о конкурсе на замещение вакантной должности государственной гражданской службы Российской Федерации, утвержденным Указом Президента Российской Федерации от 01 февраля 2005 г. № 112 «О конкурсе на замещение вакантной должности государственной гражданской службы Российской Федерации».</p></li>
							<li><p>Методика определяет порядок и условия проведения конкурсов на замещение вакантных должностей государственной гражданской службы Республики Саха (Якутия) (далее - должности гражданской службы).</p></li>
							<li><p>Конкурс обеспечивает конституционное право граждан Российской Федерации на равный доступ к государственной гражданской службе республики Саха (Якутия) (далее - гражданская служба), а также право государственных гражданских служащих (далее - гражданские служащие) на должностной рост на конкурсной основе.</p></li>
							<li><p>Конкурс заключается в оценке профессионального уровня претендентов на замещение вакантной должности гражданской службы, их соответствия установленным квалификационным требованиям к этой должности.</p></li>
						</ol>
                        <p><strong>2. Организация проведения конкурса</strong></p>
                        <ol className="text__ol_strong">
                            <li><p>Конкурс в аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) объявляется по решению руководителя аппарата либо представителя руководителя, осуществляющих полномочия нанимателя от имени аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее - представитель нанимателя), при наличии вакантной (не замещенной гражданским служащим) должности гражданской службы.</p></li>
                            <li><p>Назначение на должность гражданской службы осуществляется по результатам конкурса, если иное не установлено статьей 22 Федерального закона № 79 – ФЗ.</p></li>
                            <li><p>Конкурс в соответствии со статьей 12 Закона Республики Саха (Якутия) от 30 марта 2005 г. 214 – 3 № 433 – III "О государственной гражданской службе Республики Саха (Якутия)" не проводится:</p></li>
                            <ul className="text__ul ml-5">
                                <li><span>при назначении на замещаемые на определенный срок полномочий должности гражданской службы категорий "руководители" и "помощники (советники)";</span></li>
                                <li><span>при заключении срочного служебного контракта;</span></li>
                                <li><span>при назначении гражданского служащего на иную должность гражданской службы в случаях, предусмотренных частью 2 статьи 28, частями 1, 2 и 3 статьи 31 Федерального закона от 27 июля 2004 г. N 79-ФЗ "О государственной гражданской службе Российской Федерации";</span></li>
                                <li><span>при назначении на должность гражданской службы гражданского служащего (гражданина), состоящего в кадровом резерве, сформированном на конкурсной основе.</span></li>
                                <li><span>при назначении на должности гражданской службы, относящиеся к группе младших должностей гражданской службы, по решению представителя нанимателя.</span></li>
                            </ul>
                            <li><p>Право на участие в конкурсе имеют граждане Российской Федерации, достигшие возраста 18 лет, владеющие государственным языком Российской Федерации и соответствующие установленным законодательством Российской Федерации о государственной гражданской службе квалификационным требованиям к вакантной должности гражданской службы.</p>
                                <p>Гражданский служащий вправе на общих основаниях участвовать в конкурсе независимо от того, какую должность он замещает на период проведения конкурса.</p></li>
                            <li><p>Конкурс проводится в два этапа.</p>
                                <p>На первом этапе публикуется объявление о приеме документов для участия в конкурсе, размещается информация о проведении конкурса на сайте Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в информационно-телекоммуникационной сети общего пользования, обеспечивается прием и регистрация документов от претендентов, проверка полноты представленных документов и соответствие их оформления предъявляемым требованиям.</p>
                                <p>На втором этапе проводится оценка претендентов на основании представленных ими документов, а также на основе иных конкурсных процедур.</p></li>
                        </ol>

                        <p><strong>3. Проведение первого этапа конкурса</strong></p>
                        <ol className="text__ol_strong">
                            <li><p>Объявления о приеме документов для участия в конкурсе публикуется в республиканских газетах «Ил Тумэн» и «Якутия». В день опубликования объявления в газетах информация размещается на сайте Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в информационно-телекоммуникационной сети общего пользования.</p></li>
                            <li><p>В публикуемом объявлении о проведении конкурса указываются наименование вакантной должности гражданской службы в соответствии со штатным расписанием аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), требования, предъявляемые к претенденту на замещение этой должности, место и время приема документов, подлежащих представлению, срок, до истечения которого принимаются указанные документы, а также сведения об источнике подробной информации о конкурсе (телефон, факс, электронная почта, электронный адрес сайта).</p></li>
                            <li><p>Ответственным за организацию размещения информации о проведении конкурса является отдел государственной службы и кадров аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
                            <li><p>Тексты объявлений о проведении конкурса на замещение вакантной должности государственной гражданской службы подлежат согласованию с Департаментом кадровой политики, государственной и муниципальной службы Администрации Президента и Правительства Республики Саха (Якутия).</p></li>
                            <li><p>Гражданин Российской Федерации, изъявивший желание участвовать в конкурсе, представляет следующие документы:</p></li>
                            <ul className="text__ul ml-5">
                                <li><span>личное заявление на имя представителя нанимателя, которое регистрируется в журнале регистрации участников конкурса;</span></li>
                                <li><span>собственноручно заполненную и подписанную анкету установленной формы, с приложением фотографии 6 х 4;</span></li>
                                <li><span>копию паспорта или заменяющего его документа (всех заполненных страниц). Соответствующий документ предъявляется лично по прибытии на конкурс;</span></li>
                                <li><span>документы, подтверждающие необходимое профессиональное образование, стаж работы и квалификацию:</span>
                                    <span>копию трудовой книжки (за исключением случаев, когда служебная (трудовая) деятельность осуществляется впервые) или иные документы, подтверждающие трудовую (служебную) деятельность гражданина (копии приказов о приеме на работу, справки с предыдущего места работы и другие);</span>
                                    <span>копии документов о профессиональном образовании, а также по желанию гражданина - о дополнительном профессиональном образовании, о присвоении ученой степени, ученого звания, заверенные нотариально или кадровыми службами по месту работы (службы);</span></li>
                                <li><span>документ об отсутствии у гражданина заболевания, препятствующего поступлению на гражданскую службу или ее прохождению;</span></li>
                                <li><span>иные документы, предусмотренные Федеральным законом от 27 июля 2004 г. N 79-ФЗ "О государственной гражданской службе Российской Федерации", другими федеральными законами, указами Президента Российской Федерации и постановлениями Правительства Российской Федерации.</span></li>
                            </ul>
                            <li><p>Документы, указанные в пункте 3.4 настоящей Методики, представляются в аппарат Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в течение 30 дней со дня объявления об их приеме.</p>
                                <p>Несвоевременное представление документов, представление их не в полном объеме или с нарушением правил оформления без уважительной причины являются основанием для отказа гражданину в их приеме.</p>
                                <p>При несвоевременном представлении документов, представлении их не в полном объеме или с нарушением правил оформления по уважительной причине представитель нанимателя вправе перенести сроки их приема.</p></li>
                            <li><p>В случае необходимости с согласия гражданина (гражданского служащего) проводится процедура оформления его допуска к сведениям, составляющим государственную и иную охраняемую законом тайну, если исполнение должностных обязанностей по должности гражданской службы, на замещение которой претендует гражданин (гражданский служащий), связано с использованием таких сведений.</p></li>
                            <li><p>Достоверность сведений, представленных гражданином на имя представителя нанимателя, подлежит проверке.</p>
                                <p>Проверка достоверности сведений, представленных гражданским служащим, осуществляется только в случае его участия в конкурсе на замещение вакантной должности гражданской службы, относящейся к высшей группе должностей гражданской службы.</p></li>
                            <li><p>Гражданин (гражданский служащий) не допускается к участию в конкурсе в связи с его несоответствием квалификационным требованиям к вакантной должности гражданской службы, а также в связи с ограничениями, установленными законодательством Российской Федерации о государственной гражданской службе для поступления на гражданскую службу и ее прохождения, в связи с установлением в ходе проверки обстоятельств, препятствующих в соответствии с федеральными законами и другими нормативными правовыми актами Российской Федерации поступлению гражданина на гражданскую службу. О причинах отказа в участии в конкурсе гражданин (гражданский служащий) информируется представителем нанимателя письменно или под роспись в журнале учета участников конкурса.</p></li>
                            <li><p>Претендент на замещение вакантной должности гражданской службы, не допущенный к участию в конкурсе, вправе обжаловать это решение в соответствии с законодательством Российской Федерации.</p></li>
                            <li><p>Если по истечении 30 дней со дня объявления конкурса ни один кандидат не подал документы (были поданы документы только одним кандидатом), то представитель нанимателя принимает решение о повторном объявлении конкурса. Информация о том, что конкурс не состоялся, размещается на сайте Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
                        </ol>
                        <p><strong>4. Проведение второго этапа конкурса</strong></p>
                        <ol className="text__ol_strong">
                            <li><p>Решение о дате, месте и времени проведения второго этапа конкурса принимается представителем нанимателя после проверки достоверности сведений, представленных претендентами на замещение вакантной должности гражданской службы, а также после оформления в случае необходимости допуска к сведениям, составляющим государственную и иную охраняемую законом тайну.</p></li>
                            <li><p>Представитель нанимателя не позднее чем за 15 дней до начала второго этапа конкурса направляет уведомление о дате, месте и времени его проведения гражданам (гражданским служащим), допущенным к участию в конкурсе (далее - кандидаты).</p></li>
                            <li><p>При проведении конкурса конкурсная комиссия оценивает кандидатов на основании представленных ими документов об образовании, прохождении гражданской или иной государственной службы, осуществлении другой трудовой деятельности, а также на основе конкурсных процедур с использованием не противоречащих федеральным законам и другим нормативным правовым актам Российской Федерации методов оценки профессиональных и личностных качеств кандидатов на вакантную должность.</p>
                            <p>При оценке профессиональных и личностных качеств кандидатов конкурсная комиссия исходит из соответствующих квалификационных требований к вакантной должности гражданской службы и других положений должностного регламента по этой должности, а также иных положений, установленных законодательством Российской Федерации и Республики Саха (Якутия) о государственной гражданской службе.</p>
                            <p>Конкурсная комиссия может применять следующие процедуры:</p></li>
                            <ul className="text__ul ml-5">
                                <li><span>тестирование;</span></li>
                                <li><span>анкетирование;</span></li>
                                <li><span>проведение групповых дискуссий;</span></li>
                                <li><span>написание реферата;</span></li>
                                <li><span>индивидуальное собеседование;</span></li>
                            </ul>
                           <p>Применение всех перечисленных процедур не является обязательным. Необходимость, а также очередность применения указанных методов при проведении конкурса определяется конкурсной комиссией. В случае выявления победителя конкурса на вакантную должность только одним методом (например, тестированием) конкурс считается завершенным.</p>
                            <p>В целях создания равных условий для кандидатов при проведении конкурсных процедур (тестирование, анкетирование, проведение групповой дискуссии, написание реферата) всем кандидатам на должность, по которой проводится конкурс, выдается одинаковое задание и устанавливается одно и то же время (срок) на его подготовку.</p>
                            
                            <li>
                                <p><b>Тестирование.</b></p>
                                <p>Тестированием обеспечивается проверка знаний кандидатом Конституции Российской Федерации, Конституции Республики Саха (Якутия), федерального и республиканского законодательства о государственной гражданской службе, других нормативных правовых актов применительно к исполнению должностных обязанностей по вакантной должности, на которую проводится конкурс, положений должностного регламента.</p>
                                <p>Тестирование кандидатов проводится по единому перечню теоретических вопросов.</p>
                                <p>Тест состоит из двух частей и должен содержать не более 50 и не менее 20 вопросов.</p>
                                <p><b>Первая часть</b> теста составляется отделом государственной службы и кадров на основе Конституции Российской Федерации, Конституции Республики Саха (Якутия) и действующего федерального и республиканского законодательства о государственной гражданской службе.</p>
                                <p><b>Вторая часть</b> теста составляется структурным подразделением, в котором проводится конкурс, на основе нормативных правовых актов, регулирующих деятельность аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), а также положений должностного регламента по этой должности.</p>
                                <p>Тест утверждается председателем конкурсной комиссии.</p>
                                <p>Оценка теста проводится конкурсной комиссией по количеству правильных ответов.</p>
                                <p>При подведении итогов по тестированию преимущество в конкурсном отборе получает кандидат, получивший наибольшее количество баллов за правильные ответы на вопросы. При равных результатах тестирования проводится голосование конкурсной комиссией.</p>
                            </li>
                            <li>
                                <p><b>Анкетирование.</b></p>
                                <p>Анкетирование проводится по вопросам, составленным исходя из полномочий, задач и функций аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), а также требований, установленных должностным регламентом к уровню знаний и навыков, предъявляемых к должности, по которой проводится конкурс. При разработке анкеты возможно включение вопросов, раскрывающих информацию о профессиональном образовании, стаже и опыте работы по специальности, о задачах и функциях, реализованных проектах на предыдущем месте работы, о перспективах работы, о рекомендациях и (или) рекомендательных письмах, которые могут быть даны кандидату, о его хобби, увлечениях.</p>
                                <p>Вопросы анкеты готовятся структурным подразделением, в котором проводится конкурс, совместно с отделом государственной службы и кадров и утверждаются председателем конкурсной комиссии.</p>
                                <p>Анкета самостоятельно заполняется кандидатом в течение времени, определенного конкурсной комиссией.</p>
                                <p>Оценка кандидатов и отбор победителя на основе результатов анкетирования осуществляется конкурсной комиссией путем голосования.</p>
                            </li>
                            <li>
                                <p><b>Групповая дискуссия.</b></p>
                                <p>Проведение групповой дискуссии позволяет выявить наиболее подготовленных, активных, информированных, логично рассуждающих, обладающих необходимыми профессиональными и личностными качествами кандидатов.</p>
                                <p>Тема для проведения групповой дискуссии готовится структурным подразделением, в котором проводится конкурс, и согласовывается с председателем конкурсной комиссии, Тема для проведения групповой дискуссии определяется исходя из полномочий аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) или его структурного подразделения и представляет собой практический вопрос - конкретную ситуацию, которую необходимо обсудить или решить.</p>
                                <p>Кандидаты в течение установленного конкурсной комиссией времени готовят устный (письменный) ответ. Затем конкурсная комиссия проводит дискуссию с кандидатом.</p>
                                <p>Оценка кандидата и отбор победителя на вакантную должность гражданской службы с учетом результатов ответа и участия в дискуссии осуществляется конкурсной комиссией путем голосования.</p>
                            </li>
                            <li>
                                <p><b>Реферат.</b></p>
                                <p>Для написания реферата используются вопросы, связанные с полномочиями структурного подразделения, в котором проводится конкурс, и исполнением должностных обязанностей по вакантной должности гражданской службы. Тема реферата определяется структурным подразделением, в котором проводится конкурс, и согласовывается с председателем конкурсной комиссии.</p>
                                <p>Реферат должен соответствовать следующим требованиям: объем реферата от 3 до 10 страниц, шрифт 14, Times New Roman, через 1,5 интервала, наличие стандартных ссылок на использованные источники литературы.</p>
                                <p>На реферат дается заключение руководителем или заместителем руководителя (курирующим данное структурное подразделение) аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p>
                                <p>При подготовке заключения используется балльная система оценки по следующим критериям (от 1 до 5 баллов за каждый критерий):</p>
                            </li>
                            <ul className="text__ul ml-5">
                                <li><span>соответствие формальным требованиям (объем, сроки, оформление);</span></li>
                                <li><span>раскрытие темы, умение анализировать и дать оценку сложившейся ситуации по указанной теме;</span></li>
                                <li><span>обоснованность и практическая реализуемость предложений по совершенствованию данного вида деятельности;</span></li>
                                <li><span>логическая последовательность;</span></li>
                                <li><span>умение выделять главное, делать выводы;</span></li>
                                <li><span>видение сути проблемы;</span></li>
                                <li><span>видение альтернативных вариантов решения той или иной проблемы;</span></li>
                                <li><span>наличие ссылок на нормативные правовые акты, инструкции, положения.</span></li>
                            </ul>
                            <p>При подведении итогов преимущество в конкурсном отборе получает кандидат, получивший наибольшее количество баллов за реферат. При равных результатах проводится голосование конкурсной комиссией.</p>
                            <li><p>Индивидуальное собеседование.</p>
                                <p>Индивидуальное собеседование заключается в устных ответах на вопросы, охватывающие основные интересующие конкурсную комиссию темы (о самооценке кандидатом его уровня профессиональных знаний и навыков, планах их совершенствования, мотивах служебной деятельности и т. д.)</p>
                                <p>Конкурсная комиссия при подведении итогов по собеседованию выбирает победителя путем голосования.</p>
                            </li>
                            <li><p>Решение конкурсной комиссии, результаты голосования оформляются в виде протокола заседания конкурсной комиссии, который подписывается всеми членами комиссии, присутствовавшими на заседании. В протоколе отражается проведение индивидуального собеседования, групповой дискуссии или прикладываются результаты тестирования, анкетирования, рефераты. Протокол оформляется в течение 10 дней со дня проведения конкурса.</p></li>
                        </ol>
                        <p><strong>5. Заключительные положения</strong></p>
                        <ol className="text__ol_strong">
                            <li><p>О результатах конкурса кандидаты, участвовавшие в конкурсе, уведомляются в письменной форме в течение месяца со дня его завершения. Уведомление подписывается председателем конкурсной комиссии или заместителем председателя конкурсной комиссии.</p></li>
                            <li><p>Информация о результатах конкурса в течение 15 дней со дня проведения конкурса размещается на сайте Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
                            <li><p>Если в результате проведения конкурса не были выявлены кандидаты, отвечающие квалификационным требованиям к вакантной должности гражданской службы, на замещение которой он был объявлен, представитель нанимателя может принять решение о проведении повторного конкурса.</p></li>
                            <li><p>По результатам конкурса на замещение вакантной должности гражданской службы издается правовой акт о назначении победителя конкурса на вакантную должность гражданской службы и с ним заключается служебный контракт.</p></li>
                            <li><p>Документы претендентов на замещение вакантной должности гражданской службы, не допущенных к участию в конкурсе, и кандидатов, участвовавших в конкурсе, могут быть им возвращены по письменному заявлению в течение трех лет со дня завершения конкурса. До истечения этого срока документы хранятся в отделе государственной службы и кадров, после чего подлежат уничтожению.</p></li>
                            <li><p>Оплата труда независимых экспертов осуществляется в соответствии с распоряжением Правительства Республики Саха (Якутия) от 04 марта 2006 г. № 190-р «О порядке оплаты труда независимых экспертов, включаемых в составы аттестационной и конкурсной комиссий, а также комиссии по соблюдению требований к служебному поведению государственных гражданских служащих Республики Саха (Якутия) и урегулированию конфликта интересов, образуемых органами государственной власти Республики Саха (Якутия)» на основании договора.</p></li>
                            <li><p>Расходы, связанные с участием в конкурсе (проезд к месту проведения конкурса и обратно, наем жилого помещения, проживание, пользование услугами связи и другие), осуществляются кандидатами за счет собственных средств.</p></li>
                            <li><p>Решение конкурсной комиссии может быть обжаловано кандидатом в соответствии с законодательством Российской Федерации.</p></li>
                        </ol>
                        </Accordion>
                        <Accordion title="Порядок обжалования результатов конкурса на замещение вакантных должностей государственной гражданской службы в аппарате Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)">
                            <p>В соответствии со статьей 22 Федерального закона «О государственной гражданской службе Российской Федерации» претенденту на замещение должности государственной гражданской службы может быть отказано в допуске к участию в конкурсе в связи с несоответствием квалификационным требованиям к вакантной должности государственной гражданской службы, а также в связи с ограничениями, установленными Федеральным законом для поступления на государственную гражданскую службу и ее прохождения.</p>
                            <p>Претендент на замещение должности государственной гражданской службы, не допущенный к участию в конкурсе, вправе обжаловать это решение в соответствии с Федеральным законом.</p>
                            <p>Претендент на замещение должности государственной гражданской службы вправе обжаловать решение конкурсной комиссии (результаты конкурса) в соответствии с Федеральным законом.</p>
                            <p>В соответствии со статьей 70 Федерального закона государственный гражданский служащий либо гражданин, поступающий на государственную гражданскую службу, может обратиться в комиссию по служебным спорам в трехмесячный срок со дня, когда он узнал или должен был узнать о нарушении своего права.</p>
                            <p>В случае пропуска по уважительным причинам установленного срока, комиссия по служебным спорам может восстановить этот срок и рассмотреть служебный спор по существу. Поступившее в комиссию по служебным спорам письменное заявление государственного гражданского служащего либо гражданина, поступающего на государственную гражданскую службу, подлежит обязательной регистрации указанной комиссией в день его подачи.</p>
                            <p>Комиссия по служебным спорам обязана рассмотреть служебный спор в течение десяти календарных дней со дня подачи письменного заявления.</p>
                            <p>Решение комиссии по служебным спорам может быть обжаловано любой из сторон в суд в десятидневный срок со дня вручения ей копии решения комиссии. В случае пропуска по уважительным причинам установленного срока суд может восстановить этот срок и рассмотреть служебный спор по существу.</p>
                            <p>В судах рассматриваются служебные споры по письменным заявлениям гражданина, поступающего на государственную гражданскую службу, представителя нанимателя или представителя выборного профсоюзного органа данного государственного органа, если хотя бы один из них не согласен с решением комиссии по служебным спорам либо если государственный гражданский служащий или представитель нанимателя обращается в суд без обращения в комиссию по служебным спорам, а также по заявлению прокурора, если решение комиссии по служебным спорам не соответствует федеральным законам или иным нормативным правовым актам Российской Федерации.</p>
                            <p>Непосредственно в судах рассматриваются также служебные споры:</p>
                            <ul className="text__ul ml-5">
                                
                                <li><span>о неправомерном отказе в поступлении на государственную гражданскую службу;</span></li>
                                <li><span>по письменным заявлениям государственных гражданских служащих, считающих, что они подверглись дискриминации.</span></li>
                                </ul>
                        </Accordion>

                  

                        <div className="pt-5">
                        <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                        <p>
                        <a href={document}>Положение о проведении аттестации государственных гражданских служащих аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) </a></p></div>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}
