import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/Положение_о депутатской_этике.doc';

import LastUpdated from '../../components/last-updated';

export default class RegulationsEthics extends React.Component {
	
	accordions = [];
	state = { allClosed: true };
	
	accInstance = instance => {
		this.accordions.push(instance);
	};
	
	toggleAll = e => {
		e.preventDefault();
		if (this.state.allClosed) {
			this.accordions.forEach(accordion => accordion.open());
		}
		else {
			this.accordions.forEach(accordion => accordion.close());
		}
		this.setState({ allClosed: !this.state.allClosed });
	};
	
	render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pb_90 container_pt_40">
                    <div className="row">
                        <div className="col-12 pt-3 pb-5">
                            <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>Положение о депутатской этике</h2>

	                        <LastUpdated dateStr="23 декабря 2019" /><br /><br />
	
	                        <div className="pb-5" style={{textAlign : 'center'}}>
                        <p>Утверждено постановлением Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от 16 июня 2004 года ГС № 654-III</p>
                        </div>
                        <div className="pb-5" style={{textAlign : 'center'}}>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 11 ноября 2010 года ГС № 889-IV)</em></p>
                        </div>
                        
                        <div className="history-text-page">
                            <p>
                            Настоящее Положение определяет основные правила поведения народных депутатов Республики Саха (Якутия) (далее – депутат) в соответствии с общепринятыми этическими нормами, призвано обеспечивать уважение к Государственному Собранию (Ил Тумэн) Республики Саха (Якутия) (далее – Государственное Собрание) как высшему представительному, законодательному и контрольному органу государственной власти  Республики Саха (Якутия), а также устанавливает порядок разрешения возможных этических конфликтов между депутатами и меры ответственности за нарушение этических норм и требований, предусмотренных настоящим Положением.
                            </p>
                            <p className="pb-5">
                            Настоящее Положение обязательно для депутатов.
                            </p>
                        </div>

                        <p><a href="#" onClick={this.toggleAll}>{this.state.allClosed ? 'Развернуть все' : 'Свернуть все'}</a><br/><br/></p>

                     <Accordion getInstance={this.accInstance} title="1. Общие положения ">
                    <p></p>
                    <ol className="text__ol_strong">
                        <li><p>В своей деятельности депутат, руководствуясь преданностью своему Отечеству, должен защищать его интересы, неуклонно соблюдать Конституцию Российской Федерации, Конституцию (Основной закон) Республики Саха (Якутия), федеральные законы и законы Республики Саха (Якутия). Каждый депутат должен стремиться к тому, чтобы служить образцом исполнения своих депутатских полномочий, должен обладать безупречной репутацией, высокими профессиональными качествами, твердой гражданской позицией.</p></li>
                        <li><p>Деятельность депутата должна быть направлена на достижение благополучия и процветания Российской Федерации и Республики Саха (Якутия). Сознавая ответственность за свою Родину перед нынешним и будущими поколениями, депутат в своей деятельности руководствуется целями экономического, социального и культурного развития государства.</p></li>
                        <li><p>Депутат должен воздерживаться от деятельности, которая может нанести ущерб его авторитету, а также авторитету Государственного Собрания и Республики Саха (Якутия).</p></li>
                        <li><p>Депутат должен в равной мере сохранять собственное достоинство и уважать достоинство других депутатов, должностных лиц и граждан.</p></li>
					</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="2. Правила депутатской этики на пленарных заседаниях Государственного Собрания, заседаниях Совета Государственного Собрания, постоянных комитетов, комиссий Государственного Собрания, фракций политических партий, представленных в Государственном Собрании, депутатских групп">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Отношения между депутатами строятся на основе равноправия. Каждый депутат обязан лояльно относиться к другим депутатам независимо от их социального статуса и политической ориентации. Депутаты должны строить свою работу на основе свободного коллективного обсуждения и непредвзятого принятия решений по рассматриваемым вопросам, уважения к многообразию мнений, не комментировать выступления коллег и не допускать конфликтов, искать пути преодоления разногласий среди депутатов путем дискуссий. Депутат не может навязывать свою позицию посредством угроз, ультиматумов и иных подобных мер.</p></li>
							<li><p>Депутат обязан принимать участие в пленарных заседаниях Государственного Собрания, заседаниях Совета Государственного Собрания, постоянных комитетов, комиссий Государственного Собрания, членом которых он является, фракций политических партий, представленных в Государственном Собрании (далее – фракция), депутатских групп. Отсутствие депутата на заседаниях допускается только по уважительной причине; при этом депутат обязан действовать строго в соответствии с порядком, предусмотренным Регламентом Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
							<li><p>Депутат обязан добросовестно выполнять решения и поручения должностных лиц Государственного Собрания. Депутат обязан подчиняться принятому порядку работы Государственного Собрания. Не допускаются индивидуальные и коллективные действия депутатов, направленные на срыв проведения заседания: уход из зала заседания по мотивам, не признанным уважительными, выступления без предоставления слова председательствующим, выступления не по вопросам принятого на заседании  порядка работы, выкрики, прерывание выступающих, препятствование выступлениям депутатов, которым председательствующий на заседании предоставил слово.</p>
                                <p>Участвуя в пленарных заседаниях Государственного Собрания, заседаниях Совета Государственного Собрания, постоянных комитетов, комиссий Государственного Собрания, фракций, депутатских групп, депутат должен проявлять вежливость, тактичность и уважение к председательствующему на заседании, другим депутатам и иным лицам, присутствующим на заседании. Выступающий не должен использовать в своей речи грубые и некорректные выражения.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="2.1. Правила депутатской этики при работе фракций, депутатских групп ">
                    <p></p>
                    <ol className="text__ol_strong">
                        <li><p>Члены фракций, депутатских групп должны соблюдать правила депутатской этики, установленные настоящим Положением.</p></li>
                        <li><p>В случае поступления письменного обращения о нарушении членом фракции, депутатской группы правил депутатской этики, установленных настоящим Положением, руководитель фракции, депутатской группы вносит данное обращение на рассмотрение фракции, депутатской группы и ответ по результатам его рассмотрения направляет гражданину в установленные законодательством сроки.</p></li>
                        <li><p>Мандатная и регламентная комиссия Государственного Собрания (далее – комиссия) доводит до сведения регионального отделения политической партии письменное обращение о нарушении правил депутатской этики депутатом, избранным в составе республиканского списка кандидатов, выдвинутого данным региональным отделением.</p></li>
							</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="3. Правила депутатской этики при работе с избирателями">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Депутат несет ответственность перед своими избирателями за обязательства, данные в период предвыборной компании.</p></li>
							<li><p>Депутат должен поддерживать связи с избирателями, рассматривать обращения избирателей, вести прием граждан, вносить предложения, направленные на решение поставленных гражданами задач, в соответствующие органы государственной власти, органы местного самоуправления, общественные организации и объединения.</p></li>
							<li><p>В периоды, установленные Регламентом Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), депутат должен работать с избирателями.</p></li>
							<li><p>Депутат должен принимать все предусмотренные законодательством Российской Федерации и Республики  Саха (Якутия) меры, направленные на обеспечение прав, свобод и законных интересов своих избирателей и других граждан. При личном общении с избирателями депутат должен стремиться к тому, чтобы быть образцом профессионализма, порядочности и справедливости.</p></li>
							<li><p>Депутат через средства массовой информации и во время встреч с избирателями обязан информировать их о своей депутатской деятельности, в том числе связанной с обращениями избирателей. Ответ на обращения должен быть дан заявителю в течение 30 календарных дней со дня обращения. Информация, предоставляемая депутатом избирателям, должна быть полной, достоверной, объективной и корректной.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="4. Правила депутатской этики при взаимодействии с государственными органами, иными юридическими лицами ">
                    <p>При взаимодействии с государственными органами, иными юридическими лицами депутат не вправе использовать свой статус в личных целях, а также для деятельности, не связанной с исполнением депутатских полномочий.</p>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="5. Правила депутатской этики при взаимодействии со средствами массовой информации ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Депутат информирует избирателей о своей депутатской деятельности через средства массовой информации. Депутат имеет право выступать по вопросам своей депутатской деятельности в государственных средствах массовой информации в порядке, предусмотренном федеральным законом о порядке освещения деятельности органов государственной власти в государственных средствах массовой информации.</p></li>
							<li><p>Публичные выступления депутата должны быть корректными и взвешенными, направленными на укрепление государственности в Российской Федерации и Республике  Саха (Якутия). Депутат не вправе допускать в своих выступлениях высказывания, дискредитирующие работу Государственного Собрания.</p></li>
							<li><p>В случае, если в публичных выступлениях или заявлениях депутата содержатся высказывания, унижающие честь и достоинство граждан, депутат обязан публично признать некорректность или недостоверность таких высказываний и принести извинения гражданину, чьи честь и достоинство были затронуты, если законодательство Российской Федерации и Республики Саха (Якутия) не предусматривает иных последствий.</p></li>
							<li><p>Депутат, являющийся членом фракции, депутатской группы, обязан заявлять об этом при  взаимодействии со средствами массовой информации.</p></li>
                            </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="6. Финансовые и имущественные требования в связи с исполнением депутатом своих полномочий ">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Депутат не вправе использовать свой статус в личных целях, а также для деятельности, не связанной с исполнением депутатских полномочий, должен избегать ситуаций, которые ставят под сомнение его объективность и независимость.</p></li>
							<li><p>Депутат представляет сведения о размере и об источниках доходов, а также об имуществе, принадлежащем ему на праве собственности, в порядке, предусмотренном налоговым законодательством Российской Федерации. Информация о нарушениях, связанных с умышленным сокрытием депутатом доходов и выявленных налоговыми органами Российской Федерации и Республики Саха (Якутия), подлежит опубликованию в официальном печатном издании Государственного Собрания – газете «Ил Тумэн».</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="7. Организация работы и полномочия комиссии">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Вопросы, связанные с нарушением депутатами депутатской этики, рассматриваются комиссией. Деятельность комиссии осуществляется в соответствии с Законом Республики Саха (Якутия) от 15 июня 2004 года З № 279-ІІІ «О внесении изменений и дополнений в Закон Республики Саха (Якутия) «О статусе народного депутата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)», Регламентом Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) и Положением о постоянных комитетах и комиссии Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
							<li><p>Комиссия осуществляет:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>подготовку предложений по совершенствованию законодательства Республики Саха (Якутия) в области депутатской этики;</p></li>
                                <li><p>взаимодействие с законодательными (представительными) органами государственной власти  субъектов Российской Федерации по вопросам, касающимся депутатской этики;</p></li>
                            </ol>
                            <li><p>разработку и представление на утверждение Государственного Собрания документов, необходимых для совершенствования депутатской этики и обеспечения ее соблюдения депутатами;</p></li>
							<li><p>рассмотрение представлений Председателя Государственного Собрания и его заместителей, председателей постоянных комитетов, комиссий Государственного Собрания, руководителей фракций, депутатских групп, а также заявлений депутатов, касающихся нарушений депутатами депутатской этики;</p></li>
							<li><p>рассмотрение жалоб граждан в связи с нарушением депутатами депутатской этики.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="8. Ответственность за несоблюдение депутатской этики">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Нарушение депутатом требований настоящего Положения рассматривается на заседании комиссии.</p></li>
							<li><p>Комиссия вправе применить к депутату следующие меры воздействия:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>вынести депутату устное предупреждение;</p></li>
                                <li><p>рекомендовать депутату принять меры по исключению случаев нарушения депутатской этики;</p></li>
                                <li><p>при совершении депутатом поступка, способного нанести ущерб чести и достоинству другого депутата, авторитету Государственного Собрания, фракции, депутатской группы, вынести данный вопрос на обсуждение Государственного Собрания, фракции, депутатской группы и через средства массовой информации проинформировать избирателей о недостойном поведении депутата;</p></li>
                                <li><p>рекомендовать депутату принести публичное извинение через средства массовой информации.</p></li>
                            </ol>
						</ol>
                    </Accordion>
                    </div>
					</div>


                    <div className="row pt-3 pb-5 pl-3">
                    <div className="col-12 ">
                        <div className="pt-3 pb-5">
                        <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                        <p><a href={document}>Положение о депутатской этике </a> (MS Word, 54 Кб)</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}
