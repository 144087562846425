import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class DocumentHide extends React.Component {
	@observable hide = null;
	@observable hidden = false;
	@observable hiddenDoc = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const hiddenRes = await this.props.doc.hide();
		this.hide = hiddenRes.length > 0 ? hiddenRes[0] : null;
		this.hidden = hiddenRes.length > 0;
	};

	toggle = async () => {
		this.hidden = !this.hidden;
		if (this.hidden) {
			const hide = new store.model.QuestionDocumentHide();
			hide.documentId = this.props.doc.id;
			this.hiddenDoc = hide;
			await hide.save();
		} else if (this.hide) {
			await this.hide.delete();
			this.hide = null;
		} else if (this.hiddenDoc) {
			await this.hiddenDoc.delete();
			this.hiddenDoc = null;
		}
	};

	render() {
		return (
			<>
				<input type="checkbox" className="toggle-hide" checked={this.hidden} onChange={this.toggle} />
				{this.hidden ? 'документ скрыт' : ''}
			</>
		);
	}
}
