import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Appeal from './Appeal';

export default class Appeals extends React.Component {

	render() {
		return <Switch>
			<Route path="/profile/appeals" exact component={List} />
			<Route path="/profile/appeals/:id" component={Appeal} />
		</Switch>;
	}
	
}
