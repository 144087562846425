import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { formatDateStr } from '../../../utils';
import Votes from './Votes';
import VotesFromView from './VotesFromView';
import voting from '../store-site';

@observer
export default class Results extends React.Component {

	@observable editingStage = null;
	
	renderVotes = stage => this.props.useView ?
		<VotesFromView stage={stage} />
		:
		<Votes stage={stage} />;
	
	onListMount = (el) => {
		setTimeout(() => {
			if (this.last) {
				// console.log('onListMount', el, this.last);
				this.last.scrollIntoView();
			}
		}, 500);
	};
	
	onStageMount = (el, isLast) => {
		if (isLast) {
			this.last = el;
			if (this.last) {
				this.last.scrollIntoView();
			}
			// console.log('onStageMount', el);
		}
	};
	
	render() {
		const { question, exlude } = this.props;
		
		// if (!voting.isParticipant) return <div className="simple-user"/>;
		if (!question.stage) return <div className="no-stage"/>;

		if (voting.isProtocolist || voting.isAdmin) {
			const stages = question.stages().filter(stage => voting.isAdmin ? true : !stage.canceledAt);
			return stages.length > 0 ? this.renderVotes(stages[0]) : null;
		}

		// const validStages = question.stages().filter(stage => !stage.canceledAt);
		
		const stages = question.stages()
			.filter(stage => {
				if (stage.canceledAt) return false;
				if (!stage.voteEnd) return false;
				if (exlude && exlude.id === stage.id) return false;
				return true;
			})
			.slice().reverse();
		
		const stagesList = <ul className="stages-list" ref={this.onListMount}>

			{stages.map((stage, i) => {
				let protocolVisible = false;
				if ((voting.deputy || voting.isStaff) && (stage.publish === 'deputy' || stage.publish === 'all')) protocolVisible = true;
				if (!voting.isParticipant && stage.publish === 'all') protocolVisible = true;

				return <li className="stage-item" key={stage.id} ref={el => this.onStageMount(el, i === stages.length - 1)}>
					<div className="name">
						{/*{stage.statusId === 1 ? <span className="disc green" /> : <span className="disc red" />}*/}
						<div className="content">
							{voting.getStageName(stage)}{' '}
							{/*{protocolVisible && <a href={`/active-session/${stage.sessionId}/protocol/${stage.id}`} target="_blank">Протокол</a>}*/}
						</div>
					</div>
					{this.renderVotes(stage)}
				</li>
			})}
		</ul>;
		
		return <>
			{/*{this.renderVotes(question.stage)}*/}
			{/*{validStages.map(s => <div key={s.id}>{s.id} {s.canceledAt}</div>)}*/}
			{/*{validStages.length > 0 ? this.renderVotes(validStages[0]) : null}*/}
			{stages.length > 0 && <div className="stages">
				<div className="stages-title">Голосования</div>
				{stagesList}
			</div>}
		</>;
	}
	
}
