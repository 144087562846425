import React from 'react';
import img from './img/krstol 210318.jfif';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
const PublishingActivity = () => {

	return (
		<>
        <div className="container">
            <p><img  align="left" src="/api/containers/attachments/download/3e8bf794660456438fbb91f74d3da411.jpg" width="150" height="200" alt=""  /><strong>Материалы выездного круглого стола, состоявшегося 21 марта 2018 года в п. Нижний Бестях Мегино-Кангаласского района.</strong><b> 2018 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>В данной брошюре представлены материалы круглого стола постоянного комитета Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) по бюджету, финансам, налоговой и ценовой политике, вопросам собственности и приватизации на тему «Об изменениях в тарифной политике Республики Саха (Якутия) в 2017 году. О налоговой политике в Республике Саха (Якутия). О разъяснении расчета среднего уровня расчетной бюджетной обеспеченности городских и сельских поселений по методике, утвержденной Законом Республики Саха (Якутия) от 26.12.2007 523-З№ 1091-Ш «О наделении органов местного самоуправления муниципальных районов Республики Саха (Якутия) отдельными государственными полномочиями по выравниванию бюджетной обеспеченности поселений».</p>
            <p>
                <a href="/api/containers/attachments/download/2fb633a84a414182c4490a55d1bc82d3.pdf"><strong>Скачать</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img  alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/faff72f4065c87dbed86984237d7406b.jpg"  /><b>Дачная амнистия: пояснения, ответы на вопросы по оформлению прав на землю. 2009 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>Данное издание поможет многим в соответствии с законом оформить в собственность земельные участки, а также сэкономить время при  регистрации права собственности на приусадебные, дачные, сенокосные участки и стать хозяином на своем земельном участке.</p>
            <p>
                <a href="/api/containers/attachments/download/9da31ed27601101e6a1342a2b5765e7a.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/03c4d53b25b741271521e0968c2e1014.jpg"  /><b>Государственные пособия и выплаты отдельным категориям граждан в Республике Саха (Якутия). 2009 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>В данном пособии предоставлена информация по государственным пособиям и выплатам отдельным категориям граждан в соответствии с законодательством Республики Саха (Якутия), к которым относятся пособия на детей, участникам и инвалидам ВОВ, выплаты некоторым группам населения и другие.</p>
            <p>
                <a href="/api/containers/attachments/download/d4a69e4d1d7aa9d21c365016a37c96e9.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> <img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/c171dcb6f3b69a34a9395b45e4060a9a.jpg"  />                                      </p>
            <p><b>Кодекс Республики Саха (Якутия) об административных правонарушениях. (издание дополненное 2011 год)</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>Кодекс Республики Саха (Якутия) об административных правонарушениях, направленный на кодификацию существующих в Республике Саха (Якутия) административно-правовых норм, устанавливает новые виды административных правонарушений с учетом потребностей населения, а также правовые и организационные основы деятельности уполномоченного государственного надзорного органа, на который возложены функции по административному надзору в Республике Саха (Якутия).</p>
            <p><a href="/api/containers/attachments/download/ff61dcf7f2b6456195903ecd4d982276.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/db9c01b946fb3e8b75c0896e50f02e0e.jpg"  /></p>
            <p><b>Государственная поддержка работников социальной сферы. 2009 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>В данном пособии предоставлена информация по предоставлению социальной поддержки работникам социальной сферы в республике. Определены основные виды субсидий и субвенций в Республике Саха (Якутия).</p>
            <p>
                <a href="/api/containers/attachments/download/d0f69341ae419dd74c84ecd9ded383a6.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/f7780342245001ec5e0f4d7f4e3737e3.jpg"  /><b>Государственная поддержка сельскохозяйственных товаропроизводителей и организаций агропромышленного комплекса. 2009 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>Данное пособие содержит информацию о мерах и условиях предоставления средств государственной поддержки</p>
            <p>сельскохозяйственным товаропроизводителям и организациям агропромышленного комплекса на основании Постановления Правительства Республики Саха (Якутия) от 5 февраля 2009 года № 39.</p>
            <p> <br /><a href="/api/containers/attachments/download/d090fbaaacd6ed5a5badc0f4fdfa1314.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" src="/api/containers/attachments/download/e61e1b05c4ca4ab671140e48c509ef73.jpg"  /><b>ВОПРОСЫ ДЕПУТАТУ. Справочные материалы по наиболее часто задаваемым во время приемов граждан вопросам.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>  </p>
            <p> <br /><a href="/api/containers/attachments/download/cf45f4e37a881da6fba9ee43f5e30241.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" src="/api/containers/attachments/download/232851e824d63c7b0904d914b190b921.jpg"  /><b>Пенсии, пособия и социальные выплаты в Республике Саха (Якутия)</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>  </p>
            <p> <br /><a href="/api/containers/attachments/download/04c578c6d85d128b3eede7cf6c272948.pdf"><strong>Скачать книгу</strong></a> (формат PDF)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" src="/api/containers/attachments/download/f85bea949532d2e8c3662c636e6f2150.jpg"  /></p>
            <p><b>Конституция Российской Федерации: дальнейшее развитие российского федерализма: материалы научно-практической конференции, посвященной 15-летию Конституции Российской Федерации. 2009 год.</b> </p>
            <p>Администрация Президента и Правительства Республики Саха (Якутия), Государственное Собрание (Ил Тумэн) Республики Саха (Якутия), Конституционный суд Республики Саха (Якутия)</p>
            <p>В сборник вошли материалы научно-практической конференции «Конституция Российской Федерации: дальнейшее развитие российского федерализма» (г. Якутск, 23 декабря 2008г.), посвященной 15-летию Конституции Российской Федерации. Авторы статей рассматривают актуальные проблемы развития конституционных основ федерализма в Российской Федерации, затрагивают политологический, социологический, экономический и юридический аспекты механизма конституционно-правового регулирования.</p>
            <p>Книга рассчитана на специалистов и читателей, интересующихся вопросами государственности.</p>
            <p>
                <a href="/api/containers/attachments/download/0f9db648ac8fc29e012d6fb0511462f3.doc"><strong>Скачать книгу</strong></a> (формат MS Word)</p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/7eb022c6d28ad4fa115c275dbd4c527e.jpg"  /><b>Государственное Собрание (Ил Тумэн) - парламент Республики Саха (Якутия). 2009 год.</b> </p>
            <p>Д.Н. Миронов</p>
            <p>В монографии рассмотрены вопросы становления, развития и внутренней организации Государственного Собрания (Ил Тумэн) как парламента Республики Саха (Якутия); систематизированы вопросы организации парламента; проанализированы формы и средства парламентской деятельности; обобщены данные правотворчества Государственного Собрания (Ил Тумэн).</p>
            <p>Рассмотрены вопросы внутренней организации парламента Республики Саха (Якутия).</p>
            <p>Книга предназначена для народных депутатов Государственного Собрания (Ил Тумэн), работников аппарата парламента республики, студентов и преподавателей высших учебных заведений юридического профиля.</p>
            <p>
                <a href="/api/containers/attachments/download/b36ef01fe7f6196aec156c6a05440b94.doc"><strong>Скачать книгу</strong></a> (формат MS Word)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/de7bcda563856471ddb911171d879d06.jpg"  /><b>Развитие государственности Республики Саха (Якутия) в составе Российской Федерации в постсоветский период: Материалы научно-практической конференции, приуроченной к 70-летнему юбилею первого Президента Республики Саха (Якутия) М.Е.Николаева. 2007 год.</b> </p>
            <p>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</p>
            <p>В сборник вошли материалы научно-практической конференции, приуроченной к 70-летию со дня рождения первого Президента Республики Саха (Якутия) Михаила Ефимовича Николаева (г. Якутск, 12 ноября 2007 года). Авторы статей рассматривают этапы и содержание государственности республики в постсоветский период, перспективы дальнейшего ее развития, проблемы развития местного самоуправления как необходимого элемента конституционного механизма народовластия, вопросы федерации, федеративных отношений и федеративного устройства, роль личности в истории народа.</p>
            <p>Книга рассчитана на специалистов и читателей, интересующихся вопросами развития государственности.</p>
            <p><a href="/api/containers/attachments/download/dcc3a8100d9d9c906918c312d389fb07.doc"><strong>Скачать книгу</strong></a> (формат MS Word)</p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/7229dc355d9904dadb80d37d1d4a18d9.jpg"  /><b>Якутская Степная дума - первый опыт областного самоуправления. 2007 год.</b> </p>
            <p>Материалы научно-практической конференции (г. Якутск, 3 апреля 2007 г.)</p>
            <p>В настоящий сборник вошли материалы научно-практической конференции «Якутская Степная дума - первый опыт областного самоуправления», состоявшейся 3 апреля 2007 г. Статьи ученых, парламентариев, представителей общественности посвящены вопросам истории и развития местного самоуправления вплоть до наших дней. В них на основе анализа представлены важнейшие выводы теоретического характера и конкретные практические рекомендации, которые могут быть использованы в современной практике.</p>
            <p>Для ученых, специалистов, занимающихся вопросами реформирования местного самоуправления. Материалы конференции также могут быть использованы в учебном процессе.</p>
            <p>
                <a href="/api/containers/attachments/download/3c83232fb61a5c6f0dd77510280fa070.doc"><strong>Скачать книгу</strong></a> (формат MS Word)</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p><img alt="" align="left" width="150" height="200" src="/api/containers/attachments/download/1a7f1f016a6f873fb7221fb9e9a14bf0.jpg"  /><b>Законодательная (представительная) власть: история и современность. 2006 год.</b> </p>
            <p>Материалы республиканской научно-практической конференции, посвященной 100-летию Государственной Думы России.</p>
            <p>В сборник вошли материалы республиканской научно-практической конференции «Законодательная и представительная власть: история и современность» (г. Якутск, 20 апреля 2006 г.), посвященной 100-летию Государственной Думы России. Авторы статей рассматривают историю становления, современные проблемы и перспективы развития парламентаризма в России, затрагивают политологический, социологический, экономический и юридический аспекты формирования и функционирования законодательных органов государственной власти Российской Федерации.</p>
            <p>Книга рассчитана на специалистов и читателей, интересующихся вопросами государственности.</p>
            <p>
                <a href="/api/containers/attachments/download/24fd0eecfcfb270956889f8dcbd9fd69.doc"><strong>Скачать книгу</strong></a> (формат MS Word)</p>
            <p> </p>
            <div>
                <p> </p>
                <p><img alt="" align="left" width="150" height="221" src="/api/containers/attachments/download/7ebee2ec88d3f5f8bc755a7a735b8817.jpg"  /><b>Доклад Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) "О состоянии законодательства Республики Саха (Якутия) в 2003-2007 годах". 2008 г.</b> </p>
                <p> </p>
                <p>Доклад Государственного Собрания (Ил Тумэн) "О состоянии законодательства Республики Саха (Якутия) в 2003-2007 годах" стал первым опытом обобщения и анализа парламентом правовой системы республики и своеобразной формой подведения итогов деятельности законодательного, представительного и контрольного органа государственной власти Республики Саха (Якутия) третьего созыва. Это новое направление парламентской деятельности в Республике Саха (Якутия) и, без преувеличения, знаковое событие, свидетельствующее о зрелости республиканской законодательной власти.</p>
                <p>В Докладе отражены все напарвления законодательного регулирования общественных отношений, явившегося итогом плодотворного взаимодействия всех участников законотворческого процесса: Президента Республики Саха (Якутия), народных депутатов Республики Саха (Якутия), Конституционного суда Республики Саха (Якутия), Правительства Республики Саха (Якутия), органов местного самоуправления, общественных объединений и граждан. </p>
            </div>
            <div> </div>
            <div><a href="/api/containers/attachments/download/34ae3f97d5638c59a0104abfb861ff0c.doc"><strong>Скачать доклад</strong></a> (формат MS Word, 1.20 Мб)</div>
            <div> </div>

        </div>
        </>
    );

};
export default PublishingActivity;
