import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Pager from "react-pager";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import parse from 'date-fns/parse';

import { HtmlValue } from "@smartplatform/ui";
import { dateAndTime } from "../../utils/date-format";
import { PER_PAGE } from "../../utils/constants";
import { dateFormat } from './Search';
import store from '../../store';

export default function SearchList(props) {
	const [list, setList] = useState();
	const [total, setTotal] = useState(0);
	const [current, setCurrent] = useState(0);
	const [sortType, setSortType] = useState('relevance');
	const [dateSortType, setDateSortType] = useState('descending');
	const query = replaceSpecChars(store.route.params.query);
	const { date0, date1 } = store.route.params || {};

	function replaceSpecChars(str) {
		let res = str.replace(/([;\\/:*?\"<>|&'\)\(])/, '\\$1');
		res = res.replace(')', '\\)');
		return res;
	}

	useEffect(() => {
		search()
	}, [query]);

	async function search(userFilter, page = 0) {
		const regexp = new RegExp(query, 'gi');
		const regexpFirst = new RegExp(`(${query})`, 'i');
		const filter = { where: { and: [] }, limit: PER_PAGE, skip: (userFilter && userFilter.skip) || 0, order: props.order };

		if (props.model === 'Article') {
			const founds = await store.model.Article.search({
				search: query, limit: PER_PAGE, skip: (userFilter && userFilter.skip) || 0, order: props.order, newsType: props.filter.values[0].value
			});
			const articlesList = founds.articles;
			const list3 = [];

			articlesList.map((article) => {
						let relevance = 0;
						let text;

						relevance += article.contenttop.match(regexp) ? article.contenttop.match(regexp).length / 30 : 0;
						if (!text) {
							const div = document.createElement("div");
							div.innerHTML = article.contenttop;
							const plainText = div.textContent || div.innerText || "";
							const index = plainText.match(regexpFirst) !== null ? plainText.match(regexpFirst).index : 130;
							text = plainText.substring(index - 130, index + 130).replace(regexpFirst, '<strong>$1</strong>');
							if (text) {
								text = `...${text}...`;
							}
						}

						return list3.push({
							path: `news/${article.id}`,
							title: article.name,
							date: article.publishat,
							relevance,
							text
						});
					});

					setTotal(founds.totalCount);
					list3.sort(compareRel);
					return setList(list3);
		}

		if (userFilter && userFilter.model === props.model) {
			//const and = {} ;
			//and[userFilter.field] = userFilter.value;
			filter.where.and.push(userFilter.filter);
		}

		const _date0 = date0 ? parse(date0, dateFormat, new Date()) : null;
		const _date1 = date1 ? parse(date1, dateFormat, new Date()) : null;
		if (_date0) filter.where.and.push({ [props.dateField]: { gte: _date0 }});
		if (_date1) filter.where.and.push({ [props.dateField]: { lte: _date1 }});

		const fields = props.fields;
		const ors = [];
		const searchWords = query.split(` `).filter((word) => word.length > 3);
		for (const field of props.titleFields.concat(fields)) {
			const or = {};
			or[field] = { regexp: '/' + searchWords.join(`|`) + '/i' };
			ors.push(or)
		}
		filter.where.and.push({ or: ors });
		//console.log(JSON.stringify(filter.where));
		const founds = await store.model[props.model].find(filter);
		const total = await store.model[props.model].count(filter.where);
		setTotal(total);

		const list3 = [];
		for (const found of founds) {
			let relevance = 0;
			let title = '';
			for (const field of props.titleFields) {
				if (found[field].match(regexp)) {
					relevance++;
				}
				title += ` ${found[field]}`;
			}
			let text;
			for (const field of fields) {
				if (!found[field] || !found[field].match(regexp)) continue;
				relevance += found[field].match(regexp).length / 30;
				if (!text) {
					const div = document.createElement("div");
					div.innerHTML = found[field];
					const plainText = div.textContent || div.innerText || "";
					const index = plainText.match(regexpFirst) !== null ? plainText.match(regexpFirst).index : 130;
					text = plainText.substring(index - 130, index + 130).replace(regexpFirst, '<strong>$1</strong>');
					if (text) {
						text = `...${text}...`;
					}
				}

			}
			const date = found[props.dateField];
			const path = `/${props.path}/${found.id}`;
			list3.push({ title, path, text, relevance, date });
		}
		list3.sort(compareRel);
		setList(list3)
	}

	function compareRel(a, b) {
		return a.relevance < b.relevance ? 1 : -1;
	}

	function compareDate(a, b) {
		return new Date(a.date) < new Date(b.date) ? 1 : -1;
	}

	const onPageClick = (page) => {
		setCurrent(page);
		setSortType(`relevance`);
		search({ skip: page * PER_PAGE }, page);
	};

	function sort(type) {
		if (type === 'relevance') {
			setDateSortType('descending');
			list.sort(compareRel);
		} else {
			list.sort(compareDate);
		}
		setSortType(type);
		setList(list)
	}

	const dateSort = (type) => {
		if (type === 'descending') {
			list.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
		} else {
			list.sort((a, b) => new Date(a.date) < new Date(b.date) ? -1 : 1);
		}
		setDateSortType(type);
		setList(list);
	}

	if (!list) return null;

	return <div className="search-block">
		<strong>{props.title}</strong>
		<hr />

		{props.model !== `Deputy` && total !== 0 ?
		<span className={'search-sort'}>
            Сортировка: {' '}
			<span className={`sort-link ${sortType==='relevance' && 'active'}`} onClick={()=>sort('relevance')}>Релевантность</span>{' '}
			<span className={`sort-link ${sortType==='date' && 'active'}`} onClick={()=>sort('date')}>Дата</span>
			{sortType === `date` ?
				<span className='date-sort-wrapper'>
					<span className={`date-sort ${dateSortType === 'descending' && 'active'}`} onClick={()=>dateSort('descending')}>По убыванию</span>
					<span className={`date-sort ${dateSortType === 'ascending' && 'active'}`} onClick={()=>dateSort('ascending')}>По возрастанию</span>
				</span>
				: ``}
        </span>
			: ``}

		{/*{list.length ? <SearchFilterForm onChange={search} {...props}/> : <div>Ничего не найдено</div>}
*/}

		{list.map((item, i) => <div key={i} className="search-result">
			<Link to={item.path} className={'search-link'}>{item.title}</Link>
			<div className="date">{item.date && dateAndTime(item.date)}</div>
			<div className={'search-text'}>
				<HtmlValue value={item.text} />
			</div>
		</div>)}
		{total === 0 && <div>Ничего не найдено.</div>}
		{total > PER_PAGE && <Pager
			total={Math.ceil(total / PER_PAGE)}
			current={current}
			visiblePages={5}
			titles={{
				prev: <FontAwesomeIcon icon={faAngleLeft} />,
				next: <FontAwesomeIcon icon={faAngleRight} />,
				first: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
				last: <FontAwesomeIcon icon={faAngleDoubleRight} />,
			}}
			className="m-2"
			onPageChanged={onPageClick}
		/>}
	</div>
}
