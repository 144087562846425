import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SingleArticle } from '../../components';

const NewsSingle = ({ match }) => {
	const { id } = match.params;

	return <SingleArticle id={id} />;
};

export default withRouter(NewsSingle);
