import format from 'date-fns/format';

export const userName = user => {
	if (!user.lastName) return user.username || `user#${user.id}`;
	const firstName = user.firstName ? ' ' + user.firstName : '';
	const middleName = user.firstName && user.middleName ? ' ' + user.middleName : '';
	return `${user.lastName}${firstName}${middleName}`;
};

export const formatDateStr = (dateStr, _format = 'dd.MM.yyyy HH:mm') => dateStr ? format(new Date(dateStr), _format) : '-';
