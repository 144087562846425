import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatDateStr } from '../../tools';
import store from '../../../../store';

@observer
export default class Appeal extends React.Component {
	
	@observable appeal = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.id = this.props.match.params.id;
		this.appeal = await store.model.Appeal.findById(this.id, {
			include: ['deputy'],
		});
	};
	
	render() {
		if (!this.appeal) return null;
		
		return <div className="appeal-page">
			<p>Обращение № <strong>{this.id}</strong> от <strong>{formatDateStr(this.appeal.createdAt)}</strong></p>
			
			<div><label>От:</label> {this.appeal.lastName} {this.appeal.firstName} {this.appeal.middleName}</div>
			<div><label>E-mail:</label> <a href={`mailto:${this.appeal.email}`}>{this.appeal.email}</a></div>
			{this.appeal.phone && <div><label>Контактный телефон:</label> {this.appeal.phone}</div>}
			{this.appeal.address && <div><label>Адрес места проживания:</label> {this.appeal.address}</div>}
			
			<br />
			
			<p>
				<label>Текст обращения:</label><br />
				{this.appeal.text}
			</p>
			
			{this.appeal.attachment && <p>
				<label>Приложенный файл:</label><br />
				<a href={this.appeal.downloadFile('attachment')}>{this.appeal.attachment}</a>
			</p>}
		</div>;
	}
	
}
