import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/Положение_об_официальном_сайте.doc';

import LastUpdated from '../../components/last-updated';

export default class RegulationsWebsite extends React.Component {
	
	accordions = [];
	state = { allClosed: true };
	
	accInstance = instance => {
		this.accordions.push(instance);
	};
	
	toggleAll = e => {
		e.preventDefault();
		if (this.state.allClosed) {
			this.accordions.forEach(accordion => accordion.open());
		}
		else {
			this.accordions.forEach(accordion => accordion.close());
		}
		this.setState({ allClosed: !this.state.allClosed });
	};
	
	render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pb_90 container_pt_40">
				<div className="row">
				<div className="col-12 pt-3 pb-5">
                <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                Положение об официальном сайте Государственного Собрания (Ил Тумэн) в информационно-телекоммуникационной сети Интернет
					</h2>
					
					<LastUpdated dateStr="23 декабря 2019" /><br /><br />
					
					<div className="pb-5" style={{textAlign : 'center'}}>
					<p>Утверждено постановлением Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от 29 марта 2012 года ГС № 1300-IV</p>
					</div>
					
					<p><a href="#" onClick={this.toggleAll}>{this.state.allClosed ? 'Развернуть все' : 'Свернуть все'}</a><br/><br/></p>

                     <Accordion getInstance={this.accInstance} title="1. Общие положения">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Настоящее Положение разработано в соответствии с Федеральным законом от 9 февраля 2009 года № 8-ФЗ «Об обеспечении доступа к информации о деятельности государственных органов и органов местного самоуправления» и Законом Республики Саха (Якутия) от 14 апреля 2010 года 812-З № 507-IV «О порядке утверждения перечней информации о деятельности государственных органов Республики Саха (Якутия), размещаемой в информационно-телекоммуникационной сети Интернет».</p></li>
							<li><p>Настоящее Положение определяет цели и задачи создания и функционирования официального сайта Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в информационно-телекоммуникационной сети Интернет (далее – официальный сайт), основные принципы обеспечения доступа к информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), а также порядок организационно-технического сопровождения и информационного обеспечения официального сайта.</p></li>
							<li><p>Официальный сайт создается в целях обеспечения прав граждан и организаций на доступ к информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
							<li><p>Функционирование официального сайта направлено на решение следующих задач:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>обеспечение доступа граждан и организаций к информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия);</p></li>
                                <li><p>обеспечение оперативного и объективного информирования о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия);</p></li>
                                <li><p>расширение информационного взаимодействия Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) с гражданами, государственными органами, органами местного самоуправления и организациями;</p></li>
                                <li><p>развитие информатизации в Республике Саха (Якутия);</p></li>
                                <li><p>повышение правовой культуры населения в Республике Саха (Якутия);</p></li>
                                <li><p>снижение объема бумажного документооборота.</p></li>
                            </ol>
                            <li><p>Основными принципами обеспечения доступа к информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) являются:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>открытость и доступность информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), за исключением случаев, предусмотренных законодательством;</p></li>
                                <li><p>достоверность информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) и своевременность ее предоставления;</p></li>
                                <li><p>свобода поиска, получения, передачи и распространения информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) любым законным способом.</p></li>
                            </ol>
                            <li><p>Официальный сайт является официальным электронным представительством Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в информационно-телекоммуникационной сети Интернет.</p></li>
							<li><p>Электронный адрес официального сайта: <a style={{fontWeight: 'bold'}} href="http://iltumen.ru/" target= "_blank">www.iltumen.ru</a>.</p></li>
							<li><p>Права на доменное имя официального сайта принадлежат аппарату Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
							<li><p>При использовании материалов официального сайта ссылка на источник информации обязательна.</p></li>
							<li><p>Официальный сайт не является средством массовой информации.</p></li>
                    </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="2. Перечень информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), размещаемой на официальном сайте">
                    <p></p>
                    <ol className="text__ol_strong">
                        <li><p>Перечень информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), размещаемой на официальном сайте, утвержден постановлением Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от 27 мая 2010 года ГС № 752-IV.</p></li>
                        <li><p>В перечень информации о деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), размещаемой на официальном сайте, могут быть внесены изменения.</p></li>
                    </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="3. Разделы официального сайта">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Перечень наименований разделов официального сайта с указанием их содержания утверждается приказом руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
							<li><p>В перечень наименований разделов официального сайта могут быть внесены изменения.</p></li>
                    </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="4. Организационно-техническое сопровождение и информационное обеспечение официального сайта">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Управление информационно-технологического обеспечения аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) осуществляет организационно-техническое сопровождение официального сайта, в том числе обеспечивает:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>работоспособность и развитие комплексных технических, программных, информационных и телекоммуникационных ресурсов официального сайта;</p></li>
                                <li><p>защиту информации, размещенной на официальном сайте;</p></li>
                                <li><p>проведение регламентных работ на сервере, ведение постоянного мониторинга состояния системы безопасности сервисов, необходимых для корректной работы официального сайта.</p>
                                    <p>Для выполнения сложных или специализированных задач по сопровождению официального сайта и защиты размещенной на нем информации могут привлекаться на договорной основе специалисты сторонних организаций.</p></li>
                            </ol>
                            <li><p>Аналитическое управление аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) координирует работу по администрированию и сопровождению официального сайта, взаимодействию со сторонними организациями, обеспечивающими сопровождение официального сайта в рамках заключенных соглашений и договоров, осуществляет контроль за размещением информации на официальном сайте.</p></li>
                            <li><p>Перечень наименований структурных подразделений аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), ответственных за размещение информации на официальном сайте, утверждается приказом руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
                            <li><p>Размещение информации на официальном сайте осуществляется в соответствии с законодательством Российской Федерации об охране авторских прав.</p></li>
                            <li><p>Запрещается размещение на официальном сайте следующей информации:</p></li>
                            <ol className="text__ol_strong ml-5">
                                <li><p>информации, доступ к которой ограничен в соответствии с законодательством;</p></li>
                                <li><p>информации, являющейся коммерческой рекламой;</p></li>
                                <li><p>информации, не связанной с деятельностью Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p></li>
                            </ol>
                            <li><p>Запрещается использование официального сайта в предвыборной агитации, размещение на  нем любых предвыборных агитационных материалов.</p></li>
                            <li><p>Руководители структурных подразделений аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия), подготовивших информацию для размещения на официальном сайте, несут ответственность за ее содержание, актуальность и достоверность в соответствии с законодательством.</p></li>
                            <li><p>Руководитель аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) осуществляет контроль за функционированием официального сайта.</p></li>
                    </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="5. Финансовое обеспечение функционирования и модернизации официального сайта">
                    <p>Средства на финансирование расходов, связанных с функционированием и модернизацией официального сайта, предусматриваются в смете расходов Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) на очередной финансовый год.</p>
                    </Accordion>
                    
                    

                    </div>
					</div>


                    <div className="row pt-3 pb-5 pl-3">
                    <div className="col-12 ">
                        <div className="pt-3 pb-5">
                        <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                        <p><a href={document}>Положение об официальном сайте Государственного Собрания (Ил Тумэн) в информационно-телекоммуникационной сети Интернет  </a> (MS Word, 54 Кб)</p></div>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}
