import React from 'react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.isNewUser = props.location.state.isNewUser;
		store.ui.setBreadcrumbs([{ name: 'Интернет-приемная', link: '/appointments' }]);
		// this.init();
	}

	init = async () => {
		this.record = await store.model.Appointment.findById(this.props.match.params.id, { fields: { id: true } });
		this.isLoading = false;
	};

	render() {
		return (
			<div className="container reception">
				<h1>Интернет-приемная</h1>
				{this.isNewUser ? (
					<>
						<p>
							Ваше обращение отправлено. <br />
							Следить за ходом рассмотрения вашего обращения можно в личном кабинете.  Для завершения создания личного кабинета необходимо подтвердить адрес электронной почты. На указанную почту было отправлено письмо с подтверждением.
						</p>
					</>
				) : (
					<>
						<p>Ваше обращение успешно отправлено.</p>
						{/*<p>Номер вашей заявки: №<strong>{this.props.match.params.id}</strong></p>*/}
						<p>
							<Link to="/profile/">Вернуться в профиль</Link>
						</p>
					</>
				)}

				{/*
			{this.isLoading ?
				''
				:
				(this.record ? <>
						<p>Ваше заявка была успешно зарегистрирована.<br />Номер вашей заявки: №<strong>{this.record.id}</strong></p>
						<p><Link to="/">Вернуться на главную</Link></p>
					</>
					:
					'Заявка не найдена'
				)
			}
*/}
			</div>
		);
	}
}
