export default email => {
	if (email.length === 0) {
		return { valid: false, message: 'Введите E-mail'};
	}
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const valid = re.test(String(email).toLowerCase());
	if (!valid) {
		return { valid, message: 'Некорректный E-mail'};
	}
	return { valid, message: null };
};
