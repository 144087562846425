import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import { Button } from '../button';
import voting from '../store-site';

@observer
export default class Protocolist extends React.Component {
	
	@observable editingStage = null;
	@observable customType = '';
	
	onPublishChange = async (stage, value) => {
		stage.publish = value;
		await stage.save();
	};
	
	editStageName = (e, stage) => {
		e.preventDefault();
		this.customType = stage.customType;
		this.editingStage = stage.id;
	};
	
	onStageNameChange = (e, stage) => {
		this.customType = e.target.value;
	};
	
	saveStage = async stage => {
		this.editingStage = null;
		stage.customType = this.customType;
		await stage.save();
		this.customType = '';
	};
	
	render() {
		const { question, status } = this.props;
		
		const stages = question.stages()
			.filter(stage => {
				if (stage.canceledAt) return false;
				if (!stage.voteEnd) return false;
				return true;
			})
			.slice().reverse();
		
		return <ul className="stages-list">
			{stages.length > 0 && !voting.isAdmin && !voting.isProtocolist && <div className="hdr">Итоги голосования:</div>}
			{stages.map(stage => {
				return <li key={stage.id}>
					<div className="name">
						{/*{stage.statusId === 1 ? <span className="disc green" /> : <span className="disc red" />}*/}
						<div className="content">
							{voting.getStageName(stage)}
							{((voting.isAdmin || voting.isProtocolist) && stage.typeId === null) && <span className="edit-stage-name">
								<a href="#" onClick={e => this.editStageName(e, stage)}>редактировать</a>
								{stage.id === this.editingStage && <span className="editing-area">
									<input type="text" value={this.customType} onChange={e => this.onStageNameChange(e, stage)} />
									<Button onClick={() => this.saveStage(stage)} size={24}>Ok</Button>
								</span>}
							</span>}
						</div>
					</div>
					<div className="result">
						<div className="result-name">
							{stage.statusId === 1 ? <span className="green-text">Принято</span> : <span className="red-text">Не принято</span>}.
						</div>
						<span className="right">
							<a href={`/active-session/${stage.sessionId}/protocol/${stage.id}`} target="_blank">Протокол</a>
							
							{(stage.voteEnd && (voting.isAdmin || voting.isProtocolist)) && <div className="visibility">
								<Select
									items={[
										{ label: <div className="vis-item none"><span />не виден</div>, value: null },
										{ label: <div className="vis-item deputy"><span />виден депутатам</div>, value: 'deputy' },
										{ label: <div className="vis-item all"><span />виден всем</div>, value: 'all' },
									]}
									value={stage.publish}
									onChange={value => this.onPublishChange(stage, value)}
									isRequired
									noSearch
									className="drop-down-xs"
								/>
							</div>}
						</span>
					</div>
				</li>
			})}
		</ul>;
	}
	
}
