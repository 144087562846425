import React from 'react';
import ReactSVG from 'react-svg';
import QuoteNews from './QuoteNews';
import SimilarNews_Sidebar from './SimilarNews_Sidebar';
import SimilarNews from './SimilarNews';
import './styles.scss';
import img from './img/imgnews2.jpg';
import { Subscription, ShareInSocial } from '../../components';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import print from 'root/site/img/print.svg';

export default class NewSinglePage extends React.Component {
	render() {
		return (
			<>
				<div className="background-page-light-grey">
					<div className="container">
						<div className="row justify-content-center align-items-center mb-4 mt-3">
							<div className="col-6">
								<ShareInSocial />
							</div>
							<div className="col-6">
								<div className="subscribe_news"> Подписаться на новости </div>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="Name_news">
						В Ил Тумэне рассмотрен ход реализации закона республики о радиационной безопасности
					</div>
				</div>

				<div className="background-page-light-grey">
					<div className="container">
						<div className="row mb-4 mt-3">
							<div className="col-2">
								<div className="news-views">
									<ReactSVG className="GalleryPhoto__icon session-icon" src={eye} />
									<span className="GalleryVideo__text">122</span>
								</div>
							</div>

							<div className="col-2">
								<div className="news-date">
									<ReactSVG className="GalleryPhoto__icon session-icon" src={calendar} />
									06 марта 2019
								</div>
							</div>
							<div className="col-8"></div>
						</div>

						<div className="wrapper-img-news mb-4">
							<img className="img-news_main" src={img}></img>
							<span> Название фотографии </span>
						</div>

						<div className="row">
							<div className="col-6">
								<SimilarNews_Sidebar />
							</div>
							<div className="col-6">
								<div className="text-news">
									<p>
										В Государственном Собрании (Ил Тумэн) 6 марта 2018 года состоялся правительственный час о ходе
										реализации закона «О радиационной безопасности населения и окружающей среды Республики Саха
										(Якутия)».
									</p>
									<p>
										Работа правительственного часа прошла под руководством председателя постоянного комитета
										Государственного Собрания (Ил Тумэн) по земельным отношениям, природным ресурсам и экологии
										Владимира Прокопьева.
									</p>
									<p>
										Также в качестве модераторов выступили председатель Контрольного комитета Государственного Собрания
										(Ил Тумэн) Евгений Перфильев и заместитель председателя правительства Якутии Денис Белозеров.
										Участие в правительственном часе приняли народные депутаты республики, представители профильных
										министерств и ведомств.
									</p>
									<p>
										Открывая заседание, Владимир Прокопьев отметил, что профильный комитет парламента Якутии в начале
										работы пятого созыва организовал заседание о ходе реализации ряда законопроектов по вопросу
										радиационной безопасности в республике. Кроме того, под пристальным вниманием парламента Якутии
										находятся разработки новых месторождений в регионе.
									</p>
									<p>
										Так, в 2018 году был рассмотрен вопрос освоения Томторского месторождения. Тогда по настоянию
										профильного комитета Ил Тумэна, народных депутатов, правительства республики, и по обоюдному
										согласию с промышленным предприятием, была существенно изменена схема транспортировки руды.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<QuoteNews />
				<div className="background-page-light-grey container-news-single">
					<div className="container">
						<div className="row mt-5">
							<div className="col-6">
								<div className="text-news">
									<p>
										«Хотелось бы услышать, насколько изменилась ситуация и какова радиационная обстановка в Якутии
										сегодня», - обозначил цели и задачи правительственного часа парламентарий.
									</p>
									<p>
										В качестве основного докладчика выступил министр экологии, природопользования и лесного хозяйства
										Сахамин Афанасьев. Он рассказал о мерах, принимаемых в республике по вопросам обеспечения
										радиационной безопасности.
									</p>
									<p>
										Было отмечено, что данная сфера регулируется в регионе несколькими основными федеральными законами
										«Об использовании атомной энегии», «О радиационной безопасности населения», «Об обращении с
										радиоактивными отходами», а также законом республики «О радиационной безопасности населения и
										окружающей среды РС (Я)».
									</p>
									<p>
										Одним из основных полномочий Республики Саха (Якутия) в данном вопросе является государственный учет
										и контроль радиоактивных веществ (РВ) и радиоактивных отходов (РАО). Министерство экологии,
										природопользования и лесного хозяйства РС (Я) с 2001 года осуществляет функции регионального
										информационно-аналитического центра федеральной системы государственного учета и контроля РВ и РАО и
										направляет оперативную информацию в Национальный оператор по обращению с РАО Госкорпорации
										«Росатом». Надзор за системой госучета и контроля радиоактивных веществ и радиоактивных отходов в
										Республике Саха (Якутия) осуществляет Хабаровский отдел надзора за радиационной безопасностью
										Межрегионального территориального управления по надзору за ядерной и радиационной безопасностью
										Сибири и Дальнего Востока.
									</p>
									<p>
										Ежегодно проводится радиационно-гигиеническая паспортизация территории республики. По итогам 2018
										года радиационно-гигиенические паспорта представили 134 организации, в том числе 72 медицинских
										учреждения. Численность персонала, работающего с источниками ионизирующего излучения, составила 1554
										человека, в том числе 1385 группы А и 169 группы Б.
									</p>
									<p>
										Основной вклад в дозу облучения, как обычно, вносят природные источники ионизирующего излучения
										(82,78%) и медицинские рентгенодиагностические процедуры (17,02%). Вклад за счет деятельности
										предприятий, глобальных выпадений и радиационных аварий прошлых лет составляет 0,2%. Радиационная
										ситуация на территории региона по результатам радиационно-гигиенической паспортизации за прошедший с
										2002 года период оценивается, как удовлетворительная. Дополнительных загрязненных участков на
										территории республики не зарегистрировано.
									</p>
									<p>
										Сахамин Афанасьев также представил подробную информацию о работах, проводимых по экологической
										реабилитации территорий проведения мирных подземных ядерных взрывов в Якутии, произведенных в
										прошлые годы. Как известно, на территории республики в народно-хозяйственных целях в период с 1974
										по 1987 годы было осуществлено 12 подземных ядерных взрывов, 2 из них были признаны аварийными –
										объекты «Кристалл» и «Кратон-3» в Мирнинском районе. Сегодня на данных объектах выполняется контроль
										за радиационной обстановкой, выполнена оценка загрязнения искусственными радионуклидами подземных
										вод в зоне объекта «Кристалл», в 2016 году. Повторно исследовано содержание радионуклидов трития и
										стронция-90 в бассейне реки Мархи.
									</p>
									<p>
										Активные исследования сегодня проводятся в связи с освоением Томторского редкометального,
										Эльконского урановорудного и Торгойского торий-уранового месторождений. В настоящее время в
										федеральных органах исполнительной власти в рамках согласования рассматривается федеральный проект
										«Обеспечение долговременной радиоэкологической безопасности бесхозяйных объектов мирных ядерных
										взрывов», в который включены все объекты, в том числе «Кристалл» и «Кратон-3». В соответствии с этим
										документом Госкорпорация «Росатом» планирует проведение изоляционно-ликвидационных работ, а также
										необходимых мониторинговых исследований состояния радиационной обстановки.
									</p>
									<p>
										Народные депутаты республики Юрий Николаев, Гаврил Алексеев, Владимир Прокопьев, Евгений Перфильев,
										Андрей Николаев, Алена Атласова, Сулустаана Мыраан задали ряд вопросов, касающихся реабилитационной
										работы, контроля за экологическим состоянием водоемов, почвы территорий, расположенных рядом с
										местами проведения мирных подземных взрывов на территории Вилюйской зоны районов и Мирнинского
										района, проведения реабилитационных работ, ведения реестра захоронения опасных отходов, медицинского
										облучения, использования бюджетных средств по подпрограмме «Обеспечение радиационной безопасности»,
										а также наличия в открытом доступе отчетов и информации по остальным мирным подземным взрывам, кроме
										«Кристалла» и «Кратона-3».
									</p>
									<p>
										Участники правительственного часа отметили, что полномочия республики в области обеспечения
										радиационной безопасности населения и окружающей среды Республики Саха (Якутия) реализуются на
										достаточном уровне.
									</p>
									<p>
										«Сегодня мы рассмотрели очень важный вопрос. В будущем будем усиливать контроль и мониторинг за
										исполнением республиканского и федерального законодательства по радиационной безопасности населения
										и окружающей среды», - заключил Владимир Прокопьев.
									</p>
									<p className="p-bold">Пресс-служба Ил Тумэна</p>
								</div>
							</div>
							<div className="col-6">
								<div className="wrapper-img-news mb-5">
									<img className="img-news_additional" src={img}></img>
									<span> Название фотографии </span>
								</div>
								<div className="wrapper-img-news mb-5">
									<img className="img-news_additional" src={img}></img>
									<span> Название фотографии </span>
								</div>
								<div className="wrapper-img-news mb-5">
									<img className="img-news_additional" src={img}></img>
									<span> Название фотографии </span>
								</div>
								<div className="wrapper-img-news mb-5">
									<img className="img-news_additional" src={img}></img>
									<span> Название фотографии </span>
								</div>
								<div className="wrapper-img-news mb-5">
									<img className="img-news_additional" src={img}></img>
									<span> Название фотографии </span>
								</div>
							</div>
						</div>

						<div className="row justify-content-center align-items-center mb-5 mt-3">
							<div className="col-6">
								<div className="print-version">
									{' '}
									<a>
										<ReactSVG className="GalleryPhoto__icon session-icon print__icon" src={print} />
										<span>Версия для печати</span>
									</a>{' '}
								</div>
							</div>
							<div className="col-6">
								<div className="ShareInSocial-right">
									<ShareInSocial />
								</div>
							</div>
						</div>
					</div>
				</div>
				<SimilarNews />
				
			</>
		);
	}
}
