import React from 'react';
import './style.scss';

//test 
const y = 'https://www.youtube.com/watch?v=ONrMjaTNAKo';
const a = 'https://www.iltumen.ru/api/containers/media/download/841-video';
const iframe =
	'<iframe src="https://vk.com/video_ext.php?oid=-133441491&id=456267730&hash=9989dabcf50f7f6f&hd=2" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>';

export const VideoPlayer = ({ url, width = 640, height = 390, show = true, autoPlay = true, title, controls = true }) => {
	if (!show) return null;
	if (!url) return <span className="error">no video url</span>;
	let content = (
		<video width={width} height={height} controls={controls} autoPlay={autoPlay}>
			<source src={url} />
		</video>
	);

	const IFRAME_REG_EXP = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;
	const isIframe = IFRAME_REG_EXP.test(url);
	// iframe in url case
	if (isIframe) {
		content = <div className="iframe-wrapper" style={{ width, height }} dangerouslySetInnerHTML={{ __html: url }} />;
	} else {
		// youtube url case
		const YOUTUBE_REG_EXP = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
		const isYouTubeUrl = YOUTUBE_REG_EXP.test(url);
		if (isYouTubeUrl) {
			const youtubeGetId = url => {
				url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
				return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
			};
			const videoID = youtubeGetId(url);
			autoPlay = autoPlay ? '?autoplay=1' : '';
			const youtubeUrl = 'https://www.youtube.com/embed/' + videoID + autoPlay;

			content = <iframe id="ytplayer" type="text/html" allow="autoplay" width={width} height={height} src={youtubeUrl} frameBorder="0" />;
		}
	}

	return (
		<div className="video-player-wrapper">
			{title && <div className="title">{title}</div>}
			{content}
		</div>
	);
};
