import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Footer } from '../../components';
import store from 'root/site/store';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import './styles.scss';
import { Breadcrumbs } from '../Breadcrumbs';

const { ui } = store;
@withRouter
@observer
export default class MainLayout extends React.Component {
	scrolled = 0;
	
	constructor(props) {
		super(props);
		// this.handleScroll = debounce(this.handleScroll, 250, { trailing: true });
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant',
			});
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		// console.log('handleScroll');
		const currentScrollPos = window.pageYOffset;
		// this.scrolled++;

		currentScrollPos > 40 ? ui.setSmallMenu(true) : ui.setSmallMenu(false);
/*
		if (this.scrolled > 60) {
			ui.hideMenu();
			this.scrolled = 0;
		}
*/
	};

	render() {
		return (
			<>
				<Header small={ui.smallMenu} />

				<div className="main-content">
					<Breadcrumbs />
					{this.props.children}
				</div>
				<Footer />
			</>
		);
	}
}
