import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'
import UserNotifications from './UserNotifications'
import store from '../../store';

const userName = user => {
	if (!user.lastName) return user.username || `user#${user.id}`;
	const fLetter = user.firstName ? ' ' + user.firstName.substr(0, 1) + '.' : '';
	const mLetter = user.firstName && user.middleName ? user.middleName.substr(0, 1) + '.' : '';
	return `${user.lastName} ${fLetter}${mLetter}`;
};

export const UserInfo = () => {
	// const [ active, setActive ] = useState(false);

	// const onClick = () => setActive(true);
	const isSmall = useMediaQuery({ maxWidth: 991 });

	const onClick = () => {
		store.ui.hideMenu();
		store.route.path = '/profile';
		store.route.push();
	};

	const login = () => {
		store.ui.hideMenu();
		store.route.path = '/login';
		store.route.push();
	};
	return store.model.isInitialized && store.model.isAuthorized ?
		<div className={`header__user ${isSmall ? 'header__user-white' : ''}`} onClick={onClick} title="Личный кабинет">
			{isSmall ?
				<FontAwesomeIcon className="user-name-icon" icon={faUser}/>
				:
				<span className="user-name-full">{userName(store.model.user)}
					<UserNotifications />
				</span>
			}
		</div>
		:
		<div className={`header__login ${isSmall ? 'header__login-white' : ''}`} onClick={login} title="Войти в личный кабинет">
			{isSmall ?
				<FontAwesomeIcon className="user-name-icon" icon={faUser}/>
				:
				'Вход'
			}
		</div>;
};
