import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import { useBreadcrumbs, useSetRelationFilter, useSetRootPath } from '../../utils/hooks';
import store from '../../store';
import './styles.scss';
import {VideoPreview} from "../../components/media-preview";

const NewsPage = () => {
	useBreadcrumbs('Видеоматериалы', '/videogallery');
	useSetRootPath('/videogallery');

	useSetRelationFilter({ limit: 4 });

	useEffect(() => {
		return () => store.ui.setDateRange();
	}, []);
	return (
		<>
			<div style={{ minHeight: '80vh' }}>
				<Switch>
					<Route exact path={'/videogallery/'} component={() => <List />} />
					<Route exact path={`/videogallery/:id`} component={VideoPreview} />
				</Switch>
			</div>
		</>
	);
};
export default NewsPage;
