import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Form from './Form';
import Single from './Single';
import './style.scss';

export default () => (
	<Switch>
		<Route path="/appointments" exact component={Form} />
		<Route path="/appointments/:id" component={Single} />
	</Switch>
);
