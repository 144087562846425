import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';

import './style.scss';

import img from './img/history_1.jpg';
import img2 from './img/history_2.jpg';
import img3 from './img/history_3.jpg';
import img4 from './img/history_4.jpg';
import img5 from './img/history_5.jpg';
import img6 from './img/history_6.jpg';
import img7 from './img/history_7.jpg';
import img8 from './img/history_8.jpg';
import img9 from './img/history_9.jpg';
import img10 from './img/history_10.jpg';
import img11 from './img/history_11.jpg';
import img12 from './img/history_12.jpg';
import img13 from './img/history_13.jpg';
import img14 from './img/history_14.jpg';
import img15 from './img/history_15.jpg';
import img16 from './img/history_16.jpg';
import img17 from './img/history_17.jpg';
import img18 from './img/history_18.jpg';
import img19 from './img/history_19.jpg';
import img20 from './img/history_20.jpg';
import img21 from './img/history_21.jpg';
import img22 from './img/history_22.jpg';
import img23 from './img/history_23.jpg';
import img24 from './img/history_24.jpg';
import img25 from './img/history_25.jpg';

const shownImages = [
	{ image: img, title: 'Город Якутск. Гравюра из книги Н. Витзена "Северная и Восточная Тартария, 18 век"'},
	{ image: img2, title: '"Кангаласский князец Мазары Бозеков на приеме у царя Федора Алексеевича. 1677 г." И.Ю. Пестряков' },
	{ image: img3, title: 'Cемья Западно-Якутского князца В.Н. Ксенофонтова' },
	{ image: img4, title: 'Делегация Якутской области, прибывшая в г.Иркутск для участия в церемонии встречи Наследника Цесаревича и Великого Князя Николая Александровича. Иркутск, июнь 1891 г' },
	{ image: img5, title: 'Участники заседания Западно-Кангаласской инородной управы Якутского округа Якутской области с исправником Поповым. Сентябрь 1898 г.' },
	{ image: img6, title: 'Чиновники Якутского областного управления и улусные головы Якутской области: сидит четвертый слева политссыльный Пекарский Э.К.' },
	{ image: img7, title: 'Якутск в начале ХХ века. И.Ю. Пестряков' },
	{ image: img8, title: 'Семья головы Сунтарского улуса Якутской области Егора Николаевича Попова (сидит третий слева). Начало ХХ века.' },
	{ image: img9, title: 'Первые летние курсы учителей начальных школ 1914 г.' },
	{ image: img10, title: 'С.М. Аржаков слева и М.К. Аммосов справа с руководством партии и правительства на XIII cъезде РКПб' },
	{ image: img11, title: 'Члены Совнаркома Якутской АССР с амнистированными руководителями повстанцев 1925 г.' },
	{ image: img12, title: 'XVII Съезд ВКПб. Делегация Якутской АССР. Москва, 1934 г.' },
	{ image: img13, title: 'Делегация ЯАССР на II сессии ВЦИК 16-го созыва с председателем М.И. Калининым (сидит третий слева направо). Москва, февраль 1936 г.' },
	{ image: img14, title: 'Председатель Президиума ВС ЯАССР П.В.Аммосов (в центре) в Кремле во время вручения ордена "Знак Почета" с Председателем Президиума ВС СССР Ворошиловым К.Е. - советским государственным и военным деятелем, Маршалом Советского Союза, Героем Советского Союза, Героем Социалистического Труда т.Горкиным. Москва, 1954 г.' },
	{ image: img15, title: 'Депутаты Верховного Совета СССР 5-го созыва от ЯАССР: (слева направо) учитель средней школы № 2 г.Якутска А.Ф.Охлопкова, секретарь Булунского райкома КПСС В.Х.Вырдлин, главный врач республиканского детского костно-туберкулёзного санатория Т.П.Дмитриева и Герой Соцтруда доярка колхоза "Победа" Усть-Алданского района Е.И.Бурцева. Москва, 23 декабря 1958 г. Автор съемки - В.Соболев.' },
	{ image: img16, title: 'Генеральный секретарь Итальянской коммунистической партии Пальмиро Тольятти беседует с секретарем Нюрбинского райкома КПСС Якутской АССР Лыглаевым. Москва, 20 октября 1961 г.' },
	{ image: img17, title: 'Народный депутат СССР, воин-афганец А.С. Колодезников (слева) и народный депутат СССР из Калмыкии, Герой СССР полковник Очиров. Москва, декабрь 1989 г. Фото В.Семенова.' },
	{ image: img18, title: 'Народные депутаты РСФСР на Красной площади: (слева направо) Н.А. Чаусский, З.А. Корнилова, В.Н. Колодезников, А.В. Кривошапкин, Д.С. Бубякин. Москва, 19 мая 1990 г. Фото В.Яковлева.' },
	{ image: img19, title: 'Председатель Верховного Совета ЯАССР К.Е.Иванов выступает на торжественном собрании общественности г.Якутска, посвященном первой годовщине принятия Декларации о государственном суверенитете Республики Саха. Якутск, сентябрь 1991 г. Фото И.А. Винокурова.' },
	{ image: img20, title: 'Депутаты Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) I созыва у здания Государственной Думы Российской Федерации. Москва, май 1996 года. Фото В.Яковлева.' },
	{ image: img21, title: 'Депутаты Государственного Собрания (Ил Тумэн) РС(Я) I созыва в Совете Федерации Федерального Собрания РФ' },
	{ image: img22, title: 'Депутаты Государственного Собрания (Ил Тумэн) РС(Я) II созыва на встрече с главами администраций городов и улусов республики' },
	{ image: img23, title: 'Депутаты Государственного Собрания (Ил Тумэн) РС(Я) III созыва' },
	{ image: img24, title: 'Депутаты Государственного Собрания (Ил Тумэн) РС(Я) IV созыва' },
	{ image: img25, title: 'Депутаты Государственного Собрания  (Ил Тумэн) РС(Я) V созыва' },
];

@observer
export default class HistoricalImg extends React.Component {
	
	@observable showImages = false;
	@observable index = 0;
	
	showImage = (e, index) => {
		e.preventDefault();
		this.index = index;
		this.showImages = true;
	};
	
	hideImages = () => this.showImages = false;
	
	render() {
		return <>
			<div className="container_pt_90">
				{shownImages.map(({ image, title }, i) => <div key={i} className="wrapper-img-history">
					<a href="#" onClick={e => this.showImage(e, i)}>
						<img src={image} alt="" />
						<p>{title}</p>
					</a>
				</div>)}
			</div>
			{this.showImages &&
				<Lightbox
					mainSrc={shownImages[this.index].image}
					imageTitle={<div className="image-title">{shownImages[this.index].title}</div>}
					nextSrc={shownImages[(this.index + 1) % shownImages.length].image}
					prevSrc={shownImages[(this.index + shownImages.length - 1) % shownImages.length].image}
					onCloseRequest={this.hideImages}
					onMovePrevRequest={() => this.index =  (this.index + shownImages.length - 1) % shownImages.length}
					onMoveNextRequest={() => this.index = (this.index + 1) % shownImages.length}
				/>
			}
		</>;
	}
}
