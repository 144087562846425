import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Single from './Single';

export const Discussions = () => (
	<Switch>
		<Route path="/discussions" exact component={List} />
		<Route path="/discussions/:id" component={Single} />
	</Switch>
);
