import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export default class PlenarySession extends React.Component {

	static propTypes = {};
	static defaultProps = {};
	
	constructor(props) {
		super(props);
	}
	
	render() {
		return <div className="container base-page">
			PlenarySession
		</div>;
	}
	
}
