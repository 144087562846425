import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import Session from '../sessions/Session';
import CommitteeSessionDocument from './CommitteeSessionDocument';
import store from '../../store';

@observer
export default class CommitteeSession extends React.Component {
	@observable session = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const committeeSessionId = parseInt(this.props.match.params.id);
		if (committeeSessionId) {
			const res = await store.model.Session.find({
				where: { committeeSessionId },
				fields: ['id'],
			});
			this.session = res.length > 0 ? res[0] : null;
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <div className="container base-page">...</div>;
		if (this.session && !this.session.end && this.session.committeeSessionId) return <Redirect to={`/active-session/${this.session.id}`} />;

		return (
			<div className="container base-page">
				{this.session ? <Session id={this.session.id} /> : <CommitteeSessionDocument id={this.props.match.params.id} onCreate={this.init} />}
			</div>
		);
	}
}
