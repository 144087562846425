import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from '../../store';
import { trimmedName } from './tools';
import { getCommitteeQuestions, canCreateVotingForSession, createVotingForSession } from '../sessions';
import './style.scss';

@observer
export default class Document extends React.Component {
	@observable isLoading = true;
	@observable canCreateVoting = false;
	@observable record = null;
	@observable error = null;
	@observable isCreating = false;
	@observable questions = [];

	constructor(props) {
		super(props);
		this.init();
	}

	@action init = async () => {
		this.record = await store.model.Document.findById(this.props.id, {
			fields: ['id', 'name'],
		});
		const { canCreate } = await canCreateVotingForSession(this.record);
		this.canCreateVoting = canCreate;
		this.questions = await getCommitteeQuestions(this.props.id);
		this.isLoading = false;
	};

	createVoting = async () => {
		this.isCreating = true;
		await createVotingForSession(this.record);
		this.props.onCreate && this.props.onCreate();
	};

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		return (
			<div className="committee-session-document">
				<div className="name">
					<strong>{trimmedName(this.record.name)}</strong>
				</div>
				{this.canCreateVoting && (
					<div style={{ marginBottom: 15 }}>
						<button className="red-btn" disabled={this.isCreating} onClick={this.createVoting}>
							Создать голосование
						</button>
					</div>
				)}
				<ol className="questions">
					{this.questions.map(question => (
						<li key={question.id} className="question">
							<strong>{question.name}</strong> ({question.order})
							<ul className="files">
								{question.files.map(file => {
									if (!file.url && !file.pages) return null;
									let link = file.url ? (
										<a target="_blank" href={file.url}>
											{file.name}
										</a>
									) : (
										<>
											{file.name}:&nbsp;
											{file.pages.map((page, i) => (
												<span key={'page' + i}>
													<a target="_blank" href={'https://epz.iltumen.ru/uploads/' + page}>
														стр. {i + 1}
													</a>
													&nbsp;
												</span>
											))}
										</>
									);
									return <li key={file.id}>{link}</li>;
								})}
							</ul>
						</li>
					))}
				</ol>
			</div>
		);
	}
}
