import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { HtmlValue } from '@smartplatform/ui';

import { ElementItem } from 'root/site/components';

import { dateAndTime } from 'root/site/utils/date-format';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import { useShowCounter } from '../../utils/hooks';
import { Badges } from 'root/site/components/article/Badges';

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 768 });
	return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile ? children : null;
};

const ArticleList = ({ records, type }) => {
	const showCounter = useShowCounter();
	return records.filter((article) => article).map((article, index) => {
		// console.log('---- article', article);
		const { views, thumbnail, id, name, contentTop, publishAt, content, subheader } = article;
		const field = content ? content : contentTop ? contentTop : subheader;
		const imgSrc = article.imported
			? `/api/containers/articles/download/${article.image}`
			: article.downloadFile('thumbnail');
		// : article.downloadFile
		// ? article.downloadFile('thumbnail')
		// : thumbnail;
		let contentSeparator;

		if (field) {
			contentSeparator = field.match(/<p(.*?)\/p>/i)
				? field.match(/<p(.*?)\/p>/i)[0]
				: field.match(/<div(.*?)\/div>/i) && field.match(/<div(.*?)\/div>/i)[0];
		}

		return (
			<div key={index} className="container-news">
				<div className="container">
					<div className="row">
						<Desktop>

							<div className="col-12 col-md-5 col-lg-4">
								<img className="img-news" alt="thumbnail" src={imgSrc}></img>
								<div className='badges'>
									<Badges article={article} width={120} />
								</div>
							</div>
							<div className="col-12 col-md-7 col-lg-8">
								<div className="row datelist">
									{showCounter && (
										<div className="col-4">
											<div className="news-views">
												<ElementItem
													textColor={'var(--color-gray-light-text)'}
													fill="dark"
													icon={eye}
													text={typeof views === 'function' ? views().length : views}
													top="2px"
												/>
											</div>
										</div>
									)}
									<div className="col-8">
										<div className="news-date">
											<ElementItem
												textColor={'var(--color-gray-light-text)'}
												fill="dark"
												icon={calendar}
												text={dateAndTime(publishAt)}
												top="2px"
											/>
										</div>
									</div>
								</div>
								<div className="news-title">
									<Link to={`/${type}/${id}`}>{name}</Link>
								</div>
								<MediaQuery minWidth={991}>
									<div className="news-desc">
										<HtmlValue value={contentSeparator} />
									</div>
								</MediaQuery>
							</div>
						</Desktop>
						<Mobile>
							<div className="col-12">
								<div className="gallery-photo">
									<div className="gallery-photo__overlay">
										<img alt={name} className="gallery-photo__images" src={imgSrc} />
										<div className="gallery-photo__content-small">
											<div className="gallery-photo__title gallery-photo__title-small">
												<Link to={`/${type}/${id}`}>{name}</Link>
												<div className="gallery-photo__date">
													<ElementItem medium icon={calendar} text={dateAndTime(publishAt)} fill="white" center />
												</div>
											</div>
											<div className="gallery-photo__count">
												{showCounter && <ElementItem medium icon={eye} text={views} fill="white" bigIcon />}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Mobile>
					</div>
				</div>
			</div>
		);
	});
};

export default ArticleList;
