import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class BlindPopup extends React.Component {
	@observable params = {
		size: localStorage.getItem('poorVision-size') ? localStorage.getItem('poorVision-size').trim() : localStorage.setItem('poorVision-size', 'font-size-small') || 'font-size-small',
		style: localStorage.getItem('poorVision-style') ? localStorage.getItem('poorVision-style').trim() : localStorage.setItem('poorVision-style', 'font-style-serif') || 'font-style-serif',
		color: localStorage.getItem('poorVision-color') ? localStorage.getItem('poorVision-color').trim() : localStorage.setItem('poorVision-color', 'color-normal') || 'color-normal',
		string: localStorage.getItem('poorVision-string') ? localStorage.getItem('poorVision-string').trim() : localStorage.setItem('poorVision-string', 'string-interval-small') || 'string-interval-small',
		word: localStorage.getItem('poorVision-word') ? localStorage.getItem('poorVision-word').trim() : localStorage.setItem('poorVision-word', 'word-interval-small') || 'word-interval-small',
		images: localStorage.getItem('poorVision-images') ? localStorage.getItem('poorVision-images').trim() : localStorage.setItem('poorVision-images', 'true') || true
	};

	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		document.documentElement.className =
			`poor-vision ${this.params.size.trim()} ${this.params.style.trim()} ${this.params.color.trim()} ${this.params.string.trim()} ${this.params.word.trim()} ${JSON.parse(this.params.images) ? 'images-on' : 'images-off'}`;
	}

	componentWillUnmount() {
		localStorage.setItem('poorVision-size', 'font-size-small');
		localStorage.setItem('poorVision-style', 'font-style-serif');
		localStorage.setItem('poorVision-color', 'color-normal');
		localStorage.setItem('poorVision-string', 'string-interval-small');
		localStorage.setItem('poorVision-word', 'word-interval-small');
		localStorage.setItem('poorVision-images', 'true');
	}

	setCurrentValue = (evt, key) => {
		localStorage.setItem(`poorVision-${key}`, evt.currentTarget.className.slice(0, -6).trim());
		if (!evt.currentTarget.classList.contains(`active`)) {
			this.params[key] = evt.currentTarget.className;
		}
	}

	render() {
		return (
			<section className='blind-popup'>
				<div className='blind-popup-font-size-wrapper'>
					<p className='font-size-header'>Размер</p>
					<div className='label-wrapper'>
						<label className={`font-size-small ${this.params.size.trim() === 'font-size-small' ? 'active' : ''}`}
								onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Стандартный размер шрифта'>
							<input className='visually-hidden' type='radio' name='font-size'/>
						</label>
						<label className={`font-size-medium ${this.params.size.trim() === 'font-size-medium' ? 'active' : ''}`}
							   onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Увеличенный размер шрифта'>
							<input className='visually-hidden' type='radio' name='font-size'/>
						</label>
						<label className={`font-size-big ${this.params.size.trim() === 'font-size-big' ? 'active' : ''}`}
							   onClick={(evt) => this.setCurrentValue(evt, `size`)} title='Крупный размер шрифта'>
							<input className='visually-hidden' type='radio' name='font-size'/>
						</label>
					</div>
				</div>

				<div className='blind-popup-font-style-wrapper'>
					<p className='font-style-header'>Тип</p>
					<div className='label-wrapper'>
						<label className={`font-style-serif ${this.params.style.trim() === 'font-style-serif' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `style`)} title='Шрифт с засечками'>
							<input className='visually-hidden' type='radio' name='font-style'/>
						</label>
						<label className={`font-style-sans-serif ${this.params.style.trim() === 'font-style-sans-serif' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `style`)} title='Шрифт без засечек'>
							<input className='visually-hidden' type='radio' name='font-style'/>
						</label>
					</div>
				</div>

				<div className='blind-popup-color-wrapper'>
					<p className='color-header'>Цвета</p>
					<div className='label-wrapper'>
						<label className={`color-normal ${this.params.color.trim() === 'color-normal' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Черный текст на белом фоне'>
							<input className='visually-hidden' type='radio' name='color'/>
						</label>
						<label className={`color-inversion ${this.params.color.trim() === 'color-inversion' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Белый текст на черном фоне'>
							<input className='visually-hidden' type='radio' name='color'/>
						</label>
						<label className={`color-blue ${this.params.color.trim() === 'color-blue' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `color`)} title='Черный текст на голубом фоне'>
							<input className='visually-hidden' type='radio' name='color'/>
						</label>
					</div>
				</div>

				<div className='blind-popup-string-interval-wrapper'>
					<p className='string-interval-header'>Строки</p>
					<div className='label-wrapper'>
						<label className={`string-interval-small ${this.params.string.trim() === 'string-interval-small' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Стандартный интервал между строк'>
							<input className='visually-hidden' type='radio' name='string-interval'/>
						</label>
						<label className={`string-interval-medium ${this.params.string.trim() === 'string-interval-medium' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Увеличенный интервал между строк'>
							<input className='visually-hidden' type='radio' name='string-interval'/>
						</label>
						<label className={`string-interval-big ${this.params.string.trim() === 'string-interval-big' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `string`)} title='Крупный интервал между строк'>
							<input className='visually-hidden' type='radio' name='string-interval'/>
						</label>
					</div>
				</div>

				<div className='blind-popup-word-interval-wrapper'>
					<p className='word-interval-header'>Буквы</p>
					<div className='label-wrapper'>
						<label className={`word-interval-small ${this.params.word.trim() === 'word-interval-small' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Стандартный интервал между букв'>
							<input className='visually-hidden' type='radio' name='word-interval'/>
						</label>
						<label className={`word-interval-medium ${this.params.word.trim() === 'word-interval-medium' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Увеличенный интервал между букв'>
							<input className='visually-hidden' type='radio' name='word-interval'/>
						</label>
						<label className={`word-interval-big ${this.params.word.trim() === 'word-interval-big' ? 'active' : ''}`}
							onClick={(evt) => this.setCurrentValue(evt, `word`)} title='Крупный интервал между букв'>
							<input className='visually-hidden' type='radio' name='word-interval'/>
						</label>
					</div>
				</div>

				<div className='blind-popup-images-wrapper'>
					<p className='images-header'>Изображения</p>
					<input className='images-input' type='checkbox'
						   checked={JSON.parse(this.params.images)}
						   onChange={() => {
							   this.params.images = !JSON.parse(this.params.images);
							   localStorage.setItem('poorVision-images', this.params.images);
						   }}
						 title='Включить/выключить изображения'
					/>
				</div>
			</section>
		);
	}
}
