import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Popconfirm } from '@smartplatform/ui';
import voting from '../../store-site';
import './style.scss';

@observer
export default class Requests extends React.Component {
	
	clearAll = async e => {
		e.preventDefault();
		if (confirm('Очистить?')) {
			await voting.removeAllQueues();
		}
	};

	render() {
		return <div className="speech-requests">
			<label>Запросы на выступление
				{voting.isAdmin && voting.speechRequests.length > 0 && <>. <a href="#" className="red-text" onClick={this.clearAll}>Очистить</a></>}
			</label>
			{voting.speechRequests.length > 0 ?
				<>
					<div className="counts">
						По ведению: <em>{voting.speechRequests.filter(q => q.typeId === 2).length}</em>.&nbsp;
						Выступление: <em>{voting.speechRequests.filter(q => q.typeId === 1).length}</em>.&nbsp;
						На вопросы: <em>{voting.speechRequests.filter(q => q.typeId === 3).length}</em>.
					</div>
					<div className="requests-list">
						{voting.speechRequests
							.sort((a, b) => {
								let pa = a.typeId === 2 ? 2 : a.typeId === 1 ? 1 : 0;
								let pb = b.typeId === 2 ? 2 : b.typeId === 1 ? 1 : 0;
								return pb - pa;
							})
							.map(queue => {
								const isCurrent = voting.session.activeQueueId === queue.id;
								return <div key={queue.id} className="request">
									<div className="name">{queue.deputy ? voting.fioShort(queue.deputy) : voting.fioShort(queue.user)}</div>
									<div className="type">{queue.type ? queue.type.name : ''}</div>
									<div className="actions">
										{!isCurrent ?
											<span className="req-btn req-accept" onClick={() => voting.setActiveQueue(queue)} title="Дать слово">
												<FontAwesomeIcon icon={faMicrophone} />
											</span>
											:
											<span className="req-btn req-active" title="Выступает">
												<FontAwesomeIcon icon={faMicrophone} />
											</span>
										}
										<span className="req-btn req-remove" onClick={() => voting.removeQueue(queue)} title="Завершить">
											<FontAwesomeIcon icon={faTimes} />
										</span>
									</div>
								</div>;
							})}
					</div>
				</>
				:
				<div className="no-requests">Запросов нет.</div>
			}
		</div>;
	}
	
}
