import React from 'react';
import './styles.scss';
import ReactSVG from 'react-svg';
import whatsapp from 'root/site/img/whatsapp.svg';
import telegram from 'root/site/img/telegram.svg';
import twitter from 'root/site/img/twitter.svg';
import facebook from 'root/site/img/facebook.svg';
import vk from 'root/site/img/vk.svg';

const ShareInSocial = ({ text }) => {
	const url = window.location.href;
	return (
		<div className="share_on_social row align-items-center">
			<div className="col-12 col-md-4 col-lg-6">
			<span>Поделиться в соцсети</span>
			</div>
			<div className="col-12 col-md-8 col-lg-6">
			<div className="d-flex">
			<a target="_blank" rel="noopener noreferrer" href={`https://telegram.me/share/url?url=${url}&text=${text || ''}`}>
				<ReactSVG className="news__icon" src={telegram} />
			</a>
			<a className="d-flex align-self-center" target="_blank" rel="noopener noreferrer" href={`https://vk.com/share.php?url=${url}&title=${text || ''}`}>
				<ReactSVG className="news__icon vk__icon" src={vk} />
			</a>
			</div>
		</div>
		</div>
	);
};

export default ShareInSocial;
