import React from 'react';

export class Accordion extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = { open: false, class: 'accor' };
		if (props.getInstance) {
			props.getInstance({
				open: this.open,
				close: this.close,
			});
		}
	}
	
	open = () => {
		console.log('open');
		this.setState({ open: true, class: 'accor active' });
	};

	close = () => {
		this.setState({ open: false, class: 'accor' });
	};

	handleClick = () => {
		this.setState(this.state.open ? { open: false, class: 'accor' } : { open: true, class: 'accor active' });
	};

	render() {
		if (this.state.open) {
			return (
				<div className={this.state.class}>
					<div className="accor__head" onClick={this.handleClick}>
						{this.props.title}
					</div>

					<div className="accor__body row justify-content-center">
						<div className="col-10 align-self-center">{this.props.children}</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={this.state.class}>
					<div className="accor__head" onClick={this.handleClick}>
						{this.props.title}
					</div>
				</div>
			);
		}
	}
}
