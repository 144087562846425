import { takeScreenshot } from '.';
import store from '../store';

async function urltoFile (url, filename, mimeType) {
	const res = await fetch(url);
	const buf = await res.arrayBuffer();
	return new File([buf], filename, { type: mimeType });
}

export default async function debugLog(description, level = 'info') {
	if (!store.model.DebugLog) return;
	const log = new store.model.DebugLog();
	log.description = description;
	log.level = level;
	await log.save();
	const screenshot = await takeScreenshot();
	const file = await urltoFile(screenshot, 'screenshot.jpg', 'image/jpeg');
	// console.log('file', file);
	await log.uploadFile('screenshot', file);
	log.screenshot = 'screenshot.jpg';
	await log.save();
}
