import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from '../../store';

@observer
export default class Initiators extends React.Component {
	@observable isLoading = true;
	@observable passport = null;
	@observable initiators = [];

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.init();
	}

	init = async () => {
		const passports = await this.record.refLinks.find({
			include: ['child'],
		});
		this.passport = passports.length > 0 ? passports[0] : null;

		if (this.passport) {
			this.initiators = await store.model.RefCorresp.find({
				where: { documentId: this.passport.child.id },
				include: [{ relation: 'organization' }],
			});
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		if(this.initiators.length <= 0) return null;

		return (
			<div className="initiators">
				<em>Инициаторы</em>:
				<ul>
					{this.initiators.map(initiator => {
						const name = initiator.organization.name.replace(/[-,]/, '').replace(/народный депутат.+/, '');
						return (
							<li key={initiator.id}>
								{initiator.organization ? (
									initiator.organization.deputyId ? (
										<Link to={`/deputies/${initiator.organization.deputyId}`}>{name}</Link>
									) : (
										name
									)
								) : null}
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}
