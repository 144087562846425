import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import ReactSVG from 'react-svg';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import store from 'root/site/store';

import { Popup, Loader } from '@smartplatform/ui';

import { useCheckViews } from 'root/site/utils/hooks';

import sliderArrow from 'root/site/img/sliderArrow.svg';
import picture from 'root/site/img/picture.svg';
import cross from 'root/site/img/cross.svg';

import './styles.scss';

const PrevArrow = ({ onClick }) => (
	<ReactSVG className="media-slider__arrow-prev" src={sliderArrow} onClick={onClick} />
);
const NextArrow = ({ onClick }) => (
	<ReactSVG className="media-slider__arrow-next" src={sliderArrow} onClick={onClick} />
);
const settings = {
	dots: false,
	infinite: false,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1224 });
	return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 1223 });
	return isMobile ? children : null;
};

@observer
export class ImagesSlider extends React.Component {
	@observable currentImg = 0;
	@observable nav1;
	@observable nav2;

	componentDidMount() {
		this.nav1 = this.slider1;
		this.nav2 = this.slider2;
	}

	@action afterChange = index => {
		this.currentImg = index;
	};

	getImageSrc(image) {
		return image.importUid ? '/api/containers/attachments/download/' + image.filename : image.downloadFile('filename');
	}

	render() {
		const { images, onClose, description } = this.props;

		const descriptionStyle = {
			color: 'white',
			textAlign:'center',
			margin:'10px auto 0 auto'
		};

		return (
			<>
				<Desktop>
					<div className="media-slider__header">
						<div className="media-slider__text" style={{ flexGrow: 1 }}>
							<ReactSVG className="simple-icon simple-icon-white" src={picture} />
							<span>
								{this.currentImg + 1} / {images.length}
							</span>
						</div>
						<a href={this.getImageSrc(images[this.currentImg])} download className="simple-icon simple-icon-white">
							<FontAwesomeIcon size="2x" icon={faDownload} />
						</a>
						<ReactSVG className="simple-icon simple-icon-white" src={cross} onClick={onClose} />
					</div>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 align-self-center">
								<Slider
									asNavFor={this.nav2}
									ref={slider => (this.slider1 = slider)}
									nextArrow={<NextArrow />}
									prevArrow={<PrevArrow />}
									afterChange={this.afterChange}
								>

									{images.map(image => {
										const { id, name } = image;
										return (
											<div key={`image-${id}`} className="media-slider ">
												<div
													className="media-slider__box"
													style={{ background: `url(${this.getImageSrc(image)}) center/cover` }}
												>
													{/* <img src={this.getImageSrc(image)} alt={name} className="media-slider__img" /> */}

													<div className={name && "media-slider__title"}>
														<h5>{name}</h5>
													</div>
												</div>
											</div>
										);
									})}

								</Slider>
								<h4 style={descriptionStyle}>{description}</h4>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-8 align-self-center">
								<Slider
									asNavFor={this.nav1}
									ref={slider => (this.slider2 = slider)}
									slidesToShow={5}
									swipeToSlide={true}
									focusOnSelect={true}
									arrows={false}
									className="media-slider__navi"
									centerMode
								>
									{images.map(image => {
										const { id, name } = image;
										return (
											<div key={`image-${id}`} className="media-slider__dots">
												<img
													src={this.getImageSrc(image)}
													alt={name}
													className={`media-slider__dots-${images.length < 4 ? 'img-small' : 'img'}`}
												/>
											</div>
										);
									})}
								</Slider>
							</div>
						</div>
					</div>
				</Desktop>

				<Mobile>
					<div className="media-slider__header">
						<a href={this.getImageSrc(images[this.currentImg])} download className="simple-icon simple-icon-white">
							<FontAwesomeIcon size="2x" icon={faDownload} />
						</a>
						<ReactSVG className="simple-icon simple-icon-white" src={cross} onClick={onClose} />
					</div>
					<div className="container media-slider-mobile">
						<div className="row justify-content-center">
							<div className="col-12 align-self-center">
								<Slider ref={slider => (this.slider1 = slider)} afterChange={this.afterChange} {...settings}>
									{images.map(image => {
										const { id, name } = image;
										return (
											<div key={`image-${id}`} className="">
												<div className="media-slider-mobile">
													<div className="media-slider__text ">
														<ReactSVG className="simple-icon simple-icon-white" src={picture} />
														<span>
															{this.currentImg + 1} / {images.length}
														</span>
													</div>
													<img src={this.getImageSrc(image)} alt={name} className="media-slider__img" />
													<div className={name && "media-slider__title-mobile"}>
														<h5>{name}</h5>
													</div>
												</div>
											</div>
										);
									})}
								</Slider>
								<h4 style={descriptionStyle}>{description}</h4>
							</div>
						</div>
					</div>
				</Mobile>
			</>
		);
	}
}

const MediaPreview = ({ galleryId, gallery, onClose }) => {
	const { id } = useParams();
	const pushPhotogalleryPath = () => store.history.push(`/photogallery`);
	const [images, setImages] = useState();
	const [description, setDescription] = useState('');
	useCheckViews(galleryId ? galleryId : id, 'Gallery');

	useEffect(() => {
		console.log('got images', images);
		init();
	}, [gallery]);

	const init = async () => {
		if (gallery) {
			gallery.images.find().then(res => {
				// почему здесь было gallery._images? что это?
				setImages(res);
			});
		} else {
			const data = await store.model.Gallery.findById(galleryId ? galleryId : id, { include: ['images'] });
			const res = await data.images.find();
			data?.description && setDescription(data.description);
			setImages(res);
		}
	};

	return (
		<div>
			<Popup portalClassName="media-preview"
				   onClose={onClose ? onClose : store.history.goBack} showCloseButton={false}>

				{/* {!images && <Loader />} */}
				<div className="media-preview-popup">
					{images ? <ImagesSlider images={images} description={description}
											onClose={onClose ? onClose : store.history.goBack} /> : <Loader />}
				</div>
			</Popup>
		</div>
	);
};

export default MediaPreview;
