import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useBreadcrumbs, useSetRelationFilter, useSetRootPath } from '../../utils/hooks';
import store from '../../store';

import List from './List';
import Single from './Single';

import './styles.scss';
import './single.scss';

const DirectSpeech = () => {
	const link = '/direct-speech';
	const type = 'direct-speech';
	useSetRootPath('/direct-speech');
	useBreadcrumbs('Прямая речь', link);
	useSetRelationFilter({
		where: {
			and: [{ type }, { publish: true }, { publishAt: { lte: Date.now() } }],
		},
	});

	useEffect(() => {
		return () => store.ui.setDateRange();
	}, []);

	return (
		<>
			<div style={{ minHeight: '80vh' }}>
				<Route exact path={`${link}`} component={() => <List type={type} />} />
				<Route exact path={`${link}/:id`} component={() => <Single />} />
			</div>
		</>
	);
};
export default DirectSpeech;
