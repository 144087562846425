export default (el, cb) => {
	const links = el.getElementsByClassName('lightbox');
	const imageLinks = [];
	for (let link of links) {
		if (link.tagName.toLowerCase() === 'a') imageLinks.push(link);
	}
	imageLinks.forEach((link, i) => {
		link.onclick = e => {
			e.preventDefault();
			// console.log('click', link.href, link.title);
			if (cb) cb(imageLinks, i);
		};
	});

};