import React from 'react';
import ReactSVG from 'react-svg';
import img from './img/next.jfif';
import sliderArrow from './img/SliderArrow.svg';
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1224 })
	return isDesktop ? children : null
  }

export default class PreviousPlenary extends React.Component {
	render() {
		return (
            <Desktop>
			<div className="row justify-content-between pt-5">
                <div className="col-6">
                    <div className="previous__wrap">
                        <img className="previous__img" src={img}/>
                        <div className="previous__content previous__content-left">
                            <p className="previous__content-left-text">1 (очередное) пленарное заседание Государственного собрания (Ил Тумэн) Республики Саха (Якутия)</p>
                        </div>
                        <div className="previous__content previous__content-left-arrow">
                        <ReactSVG src={sliderArrow} className="previous__content-arrow previous__content-arrow-left" />
                        </div>
                    </div>
                </div>

			<div className="col-6">
                <div className="previous__wrap">
                    <img className="previous__img" src={img}/>
                    <div className="previous__content previous__content-right">
                    <p className="previous__content-right-text">3 (очередное) пленарное заседание Государственного собрания (Ил Тумэн) Республики Саха (Якутия)</p>
                    </div>
                    <div className="previous__content previous__content-right-arrow">
                    <ReactSVG src={sliderArrow} className="previous__content-arrow previous__content-arrow-right" />
                    </div>
                </div>
                
            </div>
		</div>
        </Desktop>
		);
	}
}
