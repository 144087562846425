import React from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab } from '../../components/route-tabs';
import PageWithNav from './PageWithNav';
import Page from './Page';
import { showTitle } from './tools'

const renderer = (path, pages, breadCrumbs) => <Tabs>
	{pages.map((page, i) => (
		<Tab key={page.id} exact={i === 0} path={i === 0 ? path : `${path}/${page.route}`} title={page.title}>
			{showTitle(page)}
			<Page code={page.route} contentOnly breadCrumbs={breadCrumbs} />
		</Tab>
	))}
</Tabs>;

const PageWithTabs = ({ path, code }) => <PageWithNav path={path} code={code} renderer={renderer}/>;

PageWithTabs.propTypes = {
	path: PropTypes.string.isRequired, // путь по которому должна быть доступна страница
	code: PropTypes.string.isRequired, // код страницы в админке
};
	
export default PageWithTabs;
