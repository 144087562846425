import React, { useState, useEffect } from 'react';
import { ShareInSocial } from '../';

import SubscriptionForm from './SubscriptionForm';
import SubscriptionMessage from './SubscriptionMessage';
import { useSubscription, delay } from './useSubscription';

import './styles.scss';

const ShareAndSubscribe = ({ type }) => {
	const [showForm, setShowForm] = useState();
	const [values, setValues] = useState();
	const message = useSubscription({ values, type });

	useEffect(() => {
		if (message && message.ok) {
			setTimeout(() => {
				setShowForm();
			}, delay);
		}
	}, [message]);

	return (
		<>
			<div className='background-page-light-grey'>
				<div className='container pb-4 pt-4'>
					<div className='row justify-content-center align-items-center '>
						<div className='col-12 col-md-6'>
							<ShareInSocial />
						</div>
						<div className='col-12 col-md-6 pt-5 pt-md-0' style={{ position: 'relative' }}>
							<div className='subscribe' onClick={() => setShowForm(true)}>
								Подписаться
							</div>
							{showForm && (
								<div className='subscribe-form'>
									{message ? <SubscriptionMessage message={message} /> : <SubscriptionForm onSubmit={setValues} />}

									<button
										className='btn subscription-form__button subscribe-form__cancel-button'
										onClick={() => setShowForm()}
									>
										Отмена
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShareAndSubscribe;
