import React from 'react';
import Slider from 'react-slick';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import img from './img/newimg.jpg';
import { dateAndTime } from 'root/site/utils/date-format';

import sliderArrow from './img/sliderArrow.svg';

const PrevArrow = ({ onClick }) => (
	<ReactSVG className="photo-slider__arrow-prev" src={sliderArrow} onClick={onClick} />
);
const NextArrow = ({ onClick }) => (
	<ReactSVG className="photo-slider__arrow-next" src={sliderArrow} onClick={onClick} />
);

export const LinkSlider = ({ records }) => {
	const Slide = ({ records }) => {
		const renderList = records =>
			records.map((record, index) => {
				const { id, name, link } = record;
				return (
					<div key={`links-${id}`}>
						<div className={`note-page__date pt-5 ${index + 1 === records.length ? 'pb-5' : ''}`}>
							{name}
							<a href={link} target="_blank" rel="noopener noreferrer">
								<div className="note-page__date-small">{link}</div>
							</a>
						</div>
					</div>
				);
			});

		const length = records.length;

		return (
			<div className="row">
				{length > 0 && (
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						{renderList(records.slice(0, length > 4 ? length / 2 : length))}
					</div>
				)}
				{length > length / 2 && length > 4 && (
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						{renderList(records.slice(length / 2, length))}
					</div>
				)}
			</div>
		);
	};

	const slidesToShow = 1;
	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1000,
		cssEase: 'linear',
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="container">
			{/*<Slider {...settings}>*/}
			<Slide records={records} />
			{/*</Slider>*/}
		</div>
	);
};

LinkSlider.propTypes = {
	// records: PropTypes.array.isRequired,
};
