import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import List from './List';
import Single from './Single';
import './style.scss';

export default () => <div className="container base-page itogi-page">
	<Switch>
		<Route path="/itogi" exact component={List} />
		<Route path="/itogi/:id" component={Single} />
	</Switch>
</div>;
