import React, { useEffect, useState } from 'react';
import moment from 'moment';
import WorkPlanTime from './WorkPlanTime';

const WorkPlanDay = ({ day }) => {
	const [times, setTimes] = useState();

	useEffect(() => {
		const getTimes = async () => {
			const times = await day.time();
			setTimes(times);
		};
		getTimes();
	}, []);
	if (!times) {
		return null;
	}

	const date = moment(day.date).format('D MMMM');
	const weekday = moment(day.date).format('dddd');
	const [firstTime, ...restTimes] = times;

	return (
		<>
			<tr>
				<td rowSpan={times.length || 1} valign="top" className="tabl-workplan-center">
					<p>
						<strong>{date} </strong>
						<br/>
						{weekday}
					</p>
				</td>
				<WorkPlanTime planTime={firstTime} />
			</tr>
			{restTimes.map(time => (
				<tr key={`workplanTime-${time.id}`}>
					<WorkPlanTime planTime={time} />
				</tr>
			))}
		</>
	);
};

export default WorkPlanDay;
