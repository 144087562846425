import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '../button';
import voting from '../store-site';

@observer
export default class VotingButtons extends React.Component {

	@observable disabled = false;
	
	vote = async result => {
		this.disabled = true;
		try {
			await voting.vote(this.props.question, result);
		}
		catch (e) {
			console.error(e);
		}
		this.disabled = false;
	};
	
	render() {
		return <div className="voting-panel">
			{/*<div>currentVote: {voting.currentVote ? <>{voting.currentVote.id}: {voting.interpretResult(voting.currentVote.result)}</> : '-'}</div>*/}
			{voting.canVote && voting.timeLeft > 0 && <>
				<div className="voting-buttons">
					<Button disabled={this.disabled} className={'vote-btn' + (voting.currentVote && voting.currentVote.result === true ? ' selected' : '')} variant="green" onClick={() => this.vote(true)}>За</Button>
					<Button disabled={this.disabled} className={'vote-btn' + (voting.currentVote && voting.currentVote.result === false ? ' selected' : '')} variant="danger" onClick={() => this.vote(false)}>Против</Button>
					<Button disabled={this.disabled} className={'vote-btn' + (voting.currentVote && voting.currentVote.result === null ? ' selected' : '')} variant="grey" onClick={() => this.vote(null)}>Воздержался</Button>
				</div>
				{voting.timeLeft >= 0 && <div className="time-left">Осталось времени: <em>{Math.min(voting.currentStage.period, voting.timeLeft)}</em> сек.</div>}
			</>}
			{voting.currentVote &&
				<div className="current-vote">
					{voting.currentVote.result === true && <>Вы проголосовали <strong className="green-text">ЗА</strong>.</>}
					{voting.currentVote.result === false && <>Вы проголосовали <strong className="red-text">ПРОТИВ</strong>.</>}
					{voting.currentVote.result === null && <>Вы <strong>ВОЗДЕРЖАЛИСЬ</strong>.</>}
				</div>
			}
		</div>
	}
	
}
