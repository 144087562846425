import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { HtmlValue, Button } from '@smartplatform/ui';
import empty from '../../img/user.png';
import store from '../../store';
import { sortDep } from '../../utils/getCurrentDeputies';
import LastUpdated from '../../components/last-updated';
import { formatDateStr } from '../profile/tools';
import print from 'root/site/img/print.svg';

export const positions = {
	0: 'член комитета',
	1: 'заместитель председателя',
	2: 'председатель комитета',
};

const positionsCommission = {
	0: 'член комиссии',
	1: 'заместитель председателя комиссии',
	2: 'председатель комиссии',
};

@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable error = null;
	@observable articlesPerPage = 5;

	constructor(props) {
		super(props);
		this.id = this.props.match.params.id;
		store.ui.setBreadcrumbs([{ name: 'Комитеты и комиссия', link: '/committees' }]);
		this.init();
	}

	init = async () => {
		try {
			this.record = await store.model.Committee.findById(this.id, {
				include: [
					{
						relation: 'deputies',
						scope: {
							order: 'id asc',
							where: { deputyId: { neq: null } },
							include: [
								{
									relation: 'deputy',
									fields: { id: true, lastName: true, firstName: true, middleName: true, photo: true },
								},
							],
						},
					},
					{
						relation: 'articles',
						scope: {
							fields: ['id', 'name', 'publishAt', 'imported', 'image', 'thumbnail'],
							order: 'createdAt DESC',
						},
					},
				],
			});

			store.ui.setBreadcrumbs([
				{ name: 'Комитеты и комиссия', link: '/committees' },
				{ name: this.record.name, link: '/committees/' + this.id },
			]);
		} catch (e) {
			this.error = e.message;
		}
	};

	gotoDeputy = deputy => this.props.history.push(`/deputies/${deputy.id}`);

	render() {
		if (!this.record && !this.error) return null;
		if (this.error) return <div className="container committee-page">{this.error}</div>;

		const sorted = this.record.deputies().sort((a, b) => sortDep(a.deputy, b.deputy));

		const members = sorted.filter(depCom => !depCom.positionNum);
		const chairman = sorted.filter(depCom => depCom.positionNum === 2);
		const zams = sorted.filter(depCom => depCom.positionNum === 1);

		const depComs = [...chairman, ...zams, ...members];

		const pos = this.record.id !== 15 ? positions : positionsCommission;

		const deputies = depComs.map(depCom => {
			const deputy = depCom.deputy;
			const photo = deputy.photo ? <img src={deputy.downloadFile('photo')} /> : <img src={empty} />;
			return (
				<div key={deputy.id} className="deputy">
					<div className="deputy-block" onClick={() => this.gotoDeputy(deputy)}>
						<div className="photo">{photo}</div>
						<div className="info">
							<div
								className="name"
								style={deputy.isDead ? { border: '2px solid black', display: 'inline-block', padding: '0 5px 0 5px' } : {}}
							>
								{deputy.lastName} {deputy.firstName} {deputy.middleName}
							</div>
							<div className="position">{pos[depCom.positionNum || 0]}</div>
							{deputy.contact && <HtmlValue className="comment" value={deputy.contact} />}
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className="container base-page committee-page">
				<div className="title">
					<h3>{this.record.name}</h3>
					<div className="print-version">
						<div>
							<a href={`/committees/${this.record.id}/print`} target="_blank" rel="noopener noreferrer">
								<ReactSVG className="simple-icon simple-icon-dark" src={print} />
								<span>Версия для печати</span>
							</a>
						</div>
					</div>
				</div>

				<LastUpdated date={new Date(this.record.updatedAt)} className="mb-5" />

				<div className="deputies">{deputies}</div>
				<HtmlValue value={this.record.about} />

				{this.record.articles().length > 0 && (
					<div className="related-articles">
						<p>
							<strong>Новости комитета</strong>
						</p>
						{this.record
							.articles()
							.slice(0, this.articlesPerPage)
							.map(article => (
								<div key={article.id} className="article-preview">
									<img
										alt=""
										className="image"
										src={
											article.imported
												? `/api/containers/articles/download/${article.image}`
												: article.downloadFile('thumbnail')
										}
									/>
									<div className="date">{formatDateStr(article.publishAt)}</div>
									<Link to={`/news/${article.id}`}>{article.name}</Link>
								</div>
							))}
						<Link to={`/committees/${this.record.id}/news`}>Показать все новости</Link>
					</div>
				)}
			</div>
		);
	}
}
