import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isAfter, isBefore } from 'date-fns';
import { PieChart } from 'react-minimal-pie-chart';

import { formatDateStr, fio } from '../../../utils';
import './style.scss';

const tick = <FontAwesomeIcon icon={faCheck} />;

@observer
export default class Protocol extends React.Component {
	voteFrom = str => {
		if (str === 'present') return 'от присутствующих';
		if (str === 'elected') return 'от избранных';
		if (str === 'fixed') return 'от установленного';
		return '?' + str;
	};

	questionName = question => {
		if (!question) return null;
		if (question.code === 'question')
			return (
				<>
					<strong>Вопрос №{question.order}</strong>: {question.name}
				</>
			);
		if (question.code === 'agenda') return 'Утверждение повестки';
		return 'Процедурный';
	};

	stageType = stage => {
		if (stage.customType) return stage.customType;
		return stage.type ? stage.type.name : '-';
	};

	render() {
		let { session, stage, allDeputies, allMembers, number, allFractions, allDepConvs } = this.props;

		window.addEventListener('beforeprint', () => {
			const votesDivs = document.querySelectorAll('div.votes-section-wrapper');
			votesDivs.forEach(votesDiv => votesDiv.classList.add('visually-hidden'));
		});

		window.addEventListener('afterprint', () => {
			const votesDivs = document.querySelectorAll('div.votes-section-wrapper');
			votesDivs.forEach(votesDiv => votesDiv.classList.remove('visually-hidden'));
		});

		let errors = [];

		const presentMembers = allMembers.filter(member => {
			if (!member.deputyId) return false;
			let _start = new Date(member.date).getTime();
			let _end = new Date(member.end).getTime();
			_start += member.delay;
			_end += member.delay;
			const start = new Date(_start);
			const end = new Date(_end);
			const voteStart = new Date(stage.voteStart);
			const voteEnd = new Date(stage.voteEnd);

			let isPresent = false;
			if (!member.end) {
				isPresent = isBefore(start, voteEnd);
			} else if (isBefore(start, voteStart)) {
				isPresent = isAfter(end, voteStart); // ---start---[---end?---]---end?---
			} else {
				isPresent = isBefore(start, voteEnd); // ---[---start---]---
			}
			return isPresent;
		});

		// console.log('presentMembers', presentMembers.map(m => m.deputyId).sort((a, b) => a - b));
		// const uniqueMembers = new Set(presentMembers.map(member => member.deputyId));
		// console.log('uniqueMembers', [...uniqueMembers].sort((a, b) => a - b));

		let presentCheck = 0;
		let yesCheck = 0;
		let noCheck = 0;
		let abstainCheck = 0;
		let noVoteCheck = 0;

		let removedDeputies = 0;
		let nomoreDeputies = 0;
		allDeputies = allDeputies.filter(deputy => {
			const no = isAfter(new Date(deputy.__startDate), new Date(stage.voteStart));
			if (no) {
				console.warn('>', stage.id, 'полномочия депутата еще не начались', fio(deputy));
				removedDeputies++;
				return false;
			}
			const present = presentMembers.find(member => member.deputyId === deputy.id);
			if (deputy.__endDate && !present) {
				// console.warn('>', stage.id, 'полномочия сложены + не присутствовал', fio(deputy));
				nomoreDeputies++;
				return false;
			}
			return true;
		});

		const deputies = allDeputies.map((deputy, i) => {
			const vote = stage.votes().find(vote => vote.deputyId === deputy.id);
			const present = !!vote || presentMembers.find(member => member.deputyId === deputy.id);
			// console.log('present', present, vote, presentMembers.find(member => member.deputyId === deputy.id));

			if (present) presentCheck++;
			if (vote && vote.result === true) yesCheck++;
			if (vote && vote.result === false) noCheck++;
			if (vote && vote.result === null) abstainCheck++;
			if (present && !vote) noVoteCheck++;

			return (
				<tr key={deputy.id}>
					<td className="number">{i + 1}</td>
					<td className="fio">{fio(deputy)}</td>
					<td className="present">{present ? tick : null}</td>
					<td className="yes">{vote && vote.result === true ? tick : null}</td>
					<td className="no">{vote && vote.result === false ? tick : null}</td>
					<td className="abstain">{vote && vote.result === null ? tick : null}</td>
					<td className="skipped">{present && !vote ? tick : null}</td>
				</tr>
			);
		});

		const fractions = allDeputies
			.map(deputy => {
				const presentDeputie = presentMembers.find(member => member.deputyId === deputy.id);
				if (presentDeputie) {
					const vote = stage.votes().find(vote => vote.deputyId === deputy.id);
					const depConv = allDepConvs
						.filter(depConv => depConv.deputyId === deputy.id)
						.find(depConv => depConv.fractionId);
					const fraction = allFractions.find(fraction => fraction.id === depConv?.fractionId);

					return {
						fractionName: fraction ? fraction.fractionName : 'Депутаты, не входящие во фракции',
						fractionDeputiesCount: fraction ? fraction.deputies().totalCount : null,
						vote: vote ? vote : { result: NaN },
					};
				}
				return null;
			})
			.filter(el => el);

		// if (+fractions.length < presentCheck) {
		// 	const neededVote = presentCheck - +fractions.length;
		// 	for (let i = 0; i < neededVote; i++) {
		// 		fractions.push({
		// 			fractionName: 'Депутаты, не входящие во фракции',
		// 			fractionDeputiesCount: null,
		// 			vote: {
		// 				result: NaN,
		// 			},
		// 		});
		// 	}
		// }

		const reducedFractions = fractions.reduce((acc, el) => {
			acc[el.fractionName] = (acc[el.fractionName] || 0) + 1;
			return acc;
		}, {});

		const assignedFractions = Object.entries(reducedFractions)
			.map(entry => {
				const currentFraction = fractions.find(fraction => fraction.fractionName === entry[0]);
				const filteredFractions = fractions.filter(fraction => fraction.fractionName === entry[0]);
				const reducedVotes = filteredFractions.reduce((acc, el) => {
					acc[el.vote.result] = (acc[el.vote.result] || 0) + 1;
					return acc;
				}, {});

				return {
					name: entry[0],
					votesCount: entry[1],
					deputiesCount: currentFraction.fractionDeputiesCount,
					votes: reducedVotes,
				};
			})
			.sort((a, b) => b.deputiesCount - a.deputiesCount);

		let result = stage.status && !stage.canceledAt ? stage.status.name.toUpperCase() : 'Отменено';

		if (presentCheck !== stage.present - removedDeputies) {
			errors.push(
				<span key="present">
					present: {presentCheck}/{stage.present - removedDeputies}{' '}
				</span>
			);
		}
		if (yesCheck !== stage.yesCount) {
			errors.push(
				<span key="yes">
					yes: {yesCheck}/{stage.yesCount}{' '}
				</span>
			);
		}
		if (noCheck !== stage.noCount) {
			errors.push(
				<span key="no">
					no: {noCheck}/{stage.noCount}{' '}
				</span>
			);
		}
		if (abstainCheck !== stage.abstainCount) {
			errors.push(
				<span key="abstain">
					abstain: {abstainCheck}/{stage.abstainCount}{' '}
				</span>
			);
		}
		if (noVoteCheck !== stage.noVote - removedDeputies) {
			errors.push(
				<span key="novote">
					noVote: {noVoteCheck}/{stage.noVote - removedDeputies}{' '}
				</span>
			);
		}

		if (errors.length > 0) {
			console.log(`error! ${stage.id} № ${number || '-'}. ${this.stageType(stage)}`);
		}

		const stageNumber = number ? <>№ {number}. </> : null;

		const fractionsVoting = assignedFractions.map((fraction, index) => {
			const yes = fraction.votes.true ? fraction.votes.true : 0;
			const no = fraction.votes.false ? fraction.votes.false : 0;
			const abst = fraction.votes.null ? fraction.votes.null : 0;
			const noVote = fraction.votes.NaN ? fraction.votes.NaN : 0;
			const votesSum = yes + no + abst + noVote;
			return (
				<div key={index} className="fraction-voting-wrapper">
					<strong>{fraction.name}:</strong>
					<br />
					<div className="votes-wrapper">
						<span className="vote-result yes-check">
							За:&nbsp;<strong>{yes}</strong>&nbsp;(
							{((yes * 100) / votesSum).toFixed(2)}%);
						</span>
						<span className="vote-result no-check">
							Против:&nbsp;<strong>{no}</strong>&nbsp;(
							{((no * 100) / votesSum).toFixed(2)}%);
						</span>
						<span className="vote-result abstain-check">
							Воздержалось:&nbsp;<strong>{abst}</strong>&nbsp;(
							{((abst * 100) / votesSum).toFixed(2)}%);
						</span>
						<span className="vote-result no-vote-check">
							Не голосовало:&nbsp;<strong>{noVote}</strong>&nbsp;(
							{((noVote * 100) / votesSum).toFixed(2)}%);
						</span>
					</div>
				</div>
			);
		});

		const yesCheckPercent = `${((yesCheck * 100) / presentCheck).toFixed(2)}%`;
		const noCheckPercent = `${((noCheck * 100) / presentCheck).toFixed(2)}%`;
		const abstainCheckPercent = `${((abstainCheck * 100) / presentCheck).toFixed(2)}%`;
		const noVoteCheckPercent = `${((noVoteCheck * 100) / presentCheck).toFixed(2)}%`;

		return (
			<div className="question-stage" key={stage.id}>
				<div className="question-title">
					<div className="top">
						<div className="left">
							<div className="session-name">
								<strong>{session.name}</strong>
							</div>
							<div>{this.questionName(stage.question)}</div>
							<div>
								<strong>
									{stageNumber}
									{this.stageType(stage)}
								</strong>
							</div>
						</div>
						<div className="right">
							<div>
								Дата:{' '}
								<strong>{formatDateStr(stage.voteStart || session.createdAt, 'dd.MM.yyyy')}</strong> [
								{stage.id}]
							</div>
							<div>
								Режим голосования:{' '}
								<strong>
									{stage.fraction} {this.voteFrom(stage.from)}
								</strong>
							</div>
							<div>
								Начало голосования: <strong>{formatDateStr(stage.voteStart, 'HH:mm:ss')}</strong>
							</div>
							<div>
								Окончание голосования: <strong>{formatDateStr(stage.voteEnd, 'HH:mm:ss')}</strong>
							</div>
							{/*<div>Зарегистрировано депутатов: <strong>{stage.present - removedDeputies}</strong></div>*/}
							<div>
								Зарегистрировано депутатов: <strong>{presentCheck}</strong>
							</div>
							<div>
								Результат голосования: <strong>{result}</strong>
							</div>
						</div>
					</div>
				</div>
				{!stage.canceledAt && (
					<table className="deputies-table">
						<thead>
							<tr>
								<th className="number">№</th>
								<th className="fio">Ф.И.О.</th>
								{/*<th className="present">Присутствует ({stage.present - removedDeputies})</th>*/}
								<th className="present">Присутствует ({presentCheck})</th>
								{/*<th className="yes">За ({stage.yesCount})</th>*/}
								<th className="yes">За ({yesCheck})</th>
								{/*<th className="no">Против ({stage.noCount})</th>*/}
								<th className="no">Против ({noCheck})</th>
								{/*<th className="abstain">Воздержался ({stage.abstainCount})</th>*/}
								<th className="abstain">Воздержался ({abstainCheck})</th>
								{/*<th className="skipped">Не голосовал ({stage.noVote - removedDeputies})</th>*/}
								<th className="skipped">Не голосовал ({noVoteCheck})</th>
							</tr>
						</thead>
						<tbody>{deputies}</tbody>
					</table>
				)}
				{!stage.canceledAt && (
					<div className="votes-section-wrapper">
						<div className="votes">
							<strong className="votes-title">Общее распределение голосов:</strong>
							<PieChart
								data={[
									{
										title: `За: ${yesCheckPercent} (${yesCheck})`,
										value: yesCheck,
										color: '#0fdb00',
									},
									{
										title: `Против: ${noCheckPercent} (${noCheck})`,
										value: noCheck,
										color: '#c41621',
									},
									{
										title: `Воздержалось: ${abstainCheckPercent} (${abstainCheck})`,
										value: abstainCheck,
										color: '#f5e50a',
									},
									{
										title: `Не голосовало: ${noVoteCheckPercent} (${noVoteCheck})`,
										value: noVoteCheck,
										color: '#858487',
									},
								]}
								style={{ height: '170px', width: '200px' }}
							/>
							<div className="votes-wrapper">
								Итоги голосования:
								<br />
								{/*За: <strong>{stage.yesCount}</strong>;&nbsp;*/}
								<span className="vote-result yes-check">
									За:&nbsp;<strong>{yesCheck}</strong>&nbsp;({yesCheckPercent});
								</span>
								{/*Против: <strong>{stage.noCount}</strong>;&nbsp;*/}
								<span className="vote-result no-check">
									Против:&nbsp;<strong>{noCheck}</strong>&nbsp;({noCheckPercent});
								</span>
								{/*Воздержалось: <strong>{stage.abstainCount}</strong>;*/}
								<span className="vote-result abstain-check">
									Воздержалось:&nbsp;<strong>{abstainCheck}</strong>&nbsp;({abstainCheckPercent});
								</span>
								{/*Не голосовало: <strong>{stage.noVote - removedDeputies}</strong>;*/}
								<span className="vote-result no-vote-check">
									Не голосовало:&nbsp;<strong>{noVoteCheck}</strong>&nbsp;({noVoteCheckPercent});
								</span>
							</div>
						</div>

						<div className="fraction-voting">
							<strong className="votes-title">Распределение голосов по фракциям:</strong>
							{fractionsVoting}
						</div>

						{/*{errors.length > 0 && <div className="hidden-error">{errors}</div>}*/}
					</div>
				)}
			</div>
		);
	}
}
