import store from '../../store';

export default el => {
	const links = el.getElementsByTagName('a');
	for (let link of links) {
		const sameOrigin = new RegExp(location.origin).test(link.href);
		const api = /api\//.test(link.href);
		// console.log('link', link.href, sameOrigin, api);
		if (/api/.test(link.href)) {
			// link.href = link.href.replace(/^.+(\/api.+)$/, '$1');
			// console.log('api', link.href);
		}
		if (sameOrigin && !api) {
			// console.log('link', link.href, sameOrigin, api);
			link.onclick = e => {
				// console.log('onClick', link);
				e.preventDefault();
				store.route.path = link.href.replace(location.origin, '');
				store.route.push();
			};
		}
	}
	
};