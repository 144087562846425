import React from 'react';
import { Badges } from './Badges';
import { ElementItem } from '..';
import { dateAndTime } from '../../utils/date-format';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import './styles.scss';

export default ({ article, getItemLink, showCounter }) => {
	if (!article)
		return (
			<div className="big-article">
				<div className="content" />
			</div>
		);

	const { publishAt } = article;
	const thumbnail = article.downloadFile('thumbnail');
	const views = article.views() && article.views().totalCount;
	const withImage = article.image || thumbnail;

	const content = (
		<div className="big-article">
			<div className="badges">
				<Badges article={article} />
			</div>
			{/* с фото */}
			{withImage ? (
				<div className="content">
					<img
						className="news__img"
						alt="thumbnail"
						src={
							article.imported ? `/api/containers/articles/download/${article.image}` : `${thumbnail}?image=[{"resize":{"width":662}}]`
						}
					/>
					<div className="bg" />

					<div className="info">
						<div className="title">{article.name}</div>
						<div className="date d-flex">
							<ElementItem fill="white" small icon={calendar} text={dateAndTime(publishAt)} top="2px" />
							{showCounter && <ElementItem fill="white" small icon={eye} text={views} top="2px" />}
						</div>
					</div>
				</div>
			) : (
				<div className="no-image">
					{/* без фото/пустая карточка */}
					<div className="img">нет фото</div>
					<div className="info">
						<div className="title">{article.name}</div>
						<div className="date">
							<ElementItem fill="black" small icon={calendar} text={dateAndTime(publishAt)} top="2px" />
							{showCounter && <ElementItem fill="black" small icon={eye} text={views} top="2px" />}
						</div>
					</div>
				</div>
			)}
		</div>
	);

	return getItemLink(article, content);
};
