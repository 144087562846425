import React from 'react';
import {observer} from "mobx-react";
import {observable} from "mobx";
import MediaQuery from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEye } from '@fortawesome/free-solid-svg-icons';
import { MainMenu } from './MainMenu';
import { Title } from './Title';
import { UserInfo } from './UserInfo';
import BlindPopup from "./BlindPopup";

import './styles.scss';

import store from 'root/site/store';

@observer
export default class Header extends React.Component {
	@observable isPoorVision = null;
	
	constructor(props) {
		super(props);
	}
	
	componentDidMount() {
		this.isPoorVision = JSON.parse(localStorage.getItem(`isPoorVision`));
	}
	
	doSearch() {
		store.route.path = `/search`;
		store.route.push();
	}
	
	openBlindPopup = () => {
		localStorage.setItem(`isPoorVision`, this.isPoorVision ? !this.isPoorVision : `true`);
		this.isPoorVision ? document.documentElement.className = '' : document.documentElement.classList.add('poor-vision');
		this.isPoorVision = !this.isPoorVision;
	}
	
	render() {
		const userBlock = <div className="col-lg-2 col-md-3 col-4 col-xs-3 header-col" style={{ textAlign: 'right' }}>
			<div className="poor-vision" title="Версия сайта для слабовидящих">
				<FontAwesomeIcon className={`eyes-btn ${this.isPoorVision ? 'active' : ''}`} icon={faEye} onClick={this.openBlindPopup} />
			</div>
			<div className="header__search" title='Поиск по сайту'>
				<FontAwesomeIcon className="search-btn" icon={faSearch} onClick={this.doSearch} />
			</div>
			<UserInfo />
		</div>;
		
		const content = <div className="container">
			{this.isPoorVision ? <div className="row">
				<div className="col-lg-1 col-md-1 col-1 col-xs-2 header-col">
					<MainMenu />
				</div>
				<div className="col-lg-9 col-md-8 col-7 col-xs-7 header-col text-center">
					<BlindPopup/>
				</div>
				{userBlock}
			</div> : <div className="row">
				<div className="col-lg-2 col-md-2 col-2 col-xs-3 header-col">
					<MainMenu />
				</div>
				<div className="col-lg-8 col-md-7 col-6 col-xs-6 header-col text-center">
					<Title/>
				</div>
				{userBlock}
			</div>}
		</div>;
		
		return (
			<MediaQuery maxWidth={991}>
				{(matches) =>
					matches || store.ui.smallMenu
						? <div className='header header-small'>{content}</div>
						: <div className='header header-big'>{content}</div>
				}
			</MediaQuery>
		);
	}
}