import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import store from '../../store';

@observer
export default class Login extends React.Component {

	@observable login = '';
	@observable password = '';
	@observable loginError = '';
	@observable passwordError = '';
	@observable isLoading = false;
	
	onLoginChange = e => this.login = e.target.value;
	onPasswordChange = e => this.password = e.target.value;
	onLoginFocus = () => this.loginError = '';
	
	checkLogin = async () => {
		this.loginError = '';
		try {
			const users = await store.model.User.find({
				where: {
					or: [
						{ username: this.login },
						{ email: this.login }
					],
				}
			});
			if (users.length === 0) {
				this.loginError = 'Пользователь не найден';
				return false;
			}
			return true;
		}
		catch(e) {
			console.warn(e);
			this.loginError = 'Ошибка связи';
		}
		return false;
	};
	
	@action doLogin = async e => {
		e.preventDefault();
		this.isLoading = true;
		const userExists = await this.checkLogin();
		if (userExists) {
			this.passwordError = '';
			try {
				await store.model.login(this.login, this.password);
			}
			catch (e) {
				console.warn(e);
				if (e.statusCode === 401) {
					this.passwordError = 'Введена неверная пара логин/пароль';
				}
				else {
					this.passwordError = e.message;
				}
			}
		}
		this.isLoading = false;
	};
	
	render() {
		return <form onSubmit={this.doLogin}>
			<div className={'login-page' + (this.isLoading ? ' is-loading' : '')}>
				<div className="form-field">
					<label>E-mail</label>
					<input type="text" value={this.login} onChange={this.onLoginChange} onFocus={this.onLoginFocus} onBlur={this.checkLogin} />
					<div className="form-error">{this.loginError}</div>
				</div>
				<div className="form-field">
					<label>Пароль</label>
					<input type="password" value={this.password} onChange={this.onPasswordChange} />
					<div className="form-error">{this.passwordError}</div>
				</div>
				<div className="form-buttons">
					{this.isLoading && <><Loader size={30} /> &nbsp;</>}
					<button className="white-btn" type="submit" disabled={this.isLoading}>Войти</button>
				</div>
			</div>
		</form>;
	}
	
}
