import {
	APPEAL_NEW,
	APPEAL_REGISTERED,
	APPEAL_ADDITIONAL,
	APPEAL_COMPETENCE,
	APPEAL_RESOLVED
} from '../reception/statuses';

export default function getStatusLabels(statusId) {
	let textLabel = 'Текст';
	let dateLabel = 'Дата';
	if (statusId === APPEAL_REGISTERED) {
		textLabel = 'Регистрационный номер';
		dateLabel = 'Дата регистрации';
	}
	else if (statusId === APPEAL_ADDITIONAL) {
		textLabel = 'Наименование органа, которому отправлен запрос';
		dateLabel = 'Дата запроса';
	}
	else if (statusId === APPEAL_COMPETENCE) {
		textLabel = 'Наименование органа, которому отправлено обращение';
		dateLabel = 'Дата направления';
	}
	else if (statusId === APPEAL_RESOLVED) {
		textLabel = 'Комментарий';
		dateLabel = 'Дата ответа';
	}
	return { textLabel, dateLabel };
};
