import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select, Checkbox, Loader } from '@smartplatform/ui';
import { Button } from '../button';
import Results from './Results';
import Requests from './speech-requests';
import store from '../../../store';
import { STATUS_CALCULATING, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED } from '../store-site';
import voting from '../store-site';

@observer
export default class Admin extends React.Component {

	@observable fraction = null;
	@observable from = null;
	@observable gotoVoting = false;
	
	constructor(props) {
		super(props);
	}
	
	onFractionChange = fraction => {
		voting.fraction = fraction;
	};
	
	onFromChange = from => {
		voting.from = from;
	};
	
	onPeriodChange = period => {
		voting.period = period;
	};
	
	onSecretChange = value => {
		voting.isSecret = value;
	}

	gotoVote = async () => {
		if (voting.currentQuestion.stage.activeQueue) {
			voting.currentQuestion.stage.activeQueue.finishedAt = new Date();
			await voting.currentQuestion.stage.activeQueue.save();
		}
		this.gotoVoting = true;
	};
	
	render() {
		const { question, status } = this.props;
		
		if (!voting.session.registrationStart) {
			return <div className="admin-panel">Ожидание регистрации</div>
		}
		
		if (!voting.isQuorum) {
			return <div className="admin-panel">Ожидание кворума</div>
		}

		if (status === STATUS_CANCELED) {
			return <div className="admin-panel">
				<div className="voting-progress">
					<label>Голосование отменено</label>
					<Button onClick={() => voting.gotoNextQuestion()}>Перейти к следующему вопросу</Button>
				</div>
			</div>;
		}
		
		if (status === STATUS_CALCULATING) {
			return <div className="admin-panel">
				<div className="calculating"><Loader size={20} /> Идет подсчет голосов...</div>
			</div>
		}

		if (status === STATUS_VOTED) {
			return <div className="admin-panel">
				<div className="voting-progress">
					<label>Голосование окончено</label>
					<Results useView question={question} />
					<Button onClick={() => voting.gotoNextQuestion()}>Перейти к следующему вопросу</Button>
				</div>
			</div>;
		}
		
		return <div className="admin-panel">
			{status === STATUS_DISCUSSED && <div>
				{!this.gotoVoting ?
					<Requests onGotoVote={this.gotoVote} />
					:
					<div className="voting-settings">
						<label>Установки голосования</label>
						<Select
							items={Object.keys(voting.voteFractions)}
							value={voting.fraction}
							onChange={this.onFractionChange}
							className="drop-down-md select-fraction"
							isRequired
							noSearch
						/>
						<Select
							items={Object.keys(voting.voteFrom).map(key => ({ label: voting.voteFrom[key].name, value: key }))}
							value={voting.from}
							onChange={this.onFromChange}
							className="drop-down-md select-from"
							isRequired
							noSearch
						/>
						<Select
							items={[
								{ label: '1 мин.', value: 1 },
								{ label: '1 мин. 30 сек.', value: 1.5 },
								{ label: '2 мин.', value: 2 },
								{ label: '2 мин. 30 сек.', value: 2.5 },
								{ label: '3 мин.', value: 3 },
								{ label: '3 мин. 30 сек.', value: 3.5 },
								{ label: '4 мин.', value: 4 },
								{ label: '4 мин. 30 сек.', value: 4.5 },
								{ label: '5 мин.', value: 5 },
							]}
							value={voting.period}
							onChange={this.onPeriodChange}
							className="drop-down-md select-from"
							isRequired
							noSearch
						/>
						<div className="is-secret">
							<Checkbox value={voting.isSecret} onChange={this.onSecretChange} /> тайное
						</div>
						<div className="actions"><Button onClick={() => voting.startVoting(question)}>Начать голосование</Button></div>
					</div>
				}
			</div>}
			
			{status === STATUS_VOTING && <div className="voting-progress">
				<label>Идет голосование</label>
				{voting.adminVotes && <div className="votes-count votes-count-admin">
					<div className="count"><em>За</em><span/><strong>{voting.adminVotes.yes || 0}</strong></div>
					<div className="count"><em>Против</em><span/><strong>{voting.adminVotes.no || 0}</strong></div>
					<div className="count"><em>Воздержалось</em><span/><strong>{voting.adminVotes.abstained || 0}</strong></div>
					<div className="count total"><em>Голосовало</em><span/><strong>{voting.adminVotes.yes + voting.adminVotes.no + voting.adminVotes.abstained}</strong></div>
				</div>}
				{voting.timeLeft >= 0 && <div className="time-left">Осталось времени: <em>{voting.timeLeft}</em> сек.</div>}
				<Button onClick={() => voting.stopVoting(question)}>Закончить голосование</Button>
			</div>}
			
		
			{status !== STATUS_VOTED && status !== STATUS_CANCELED && <div className="cancel">
				<Button variant="grey" onClick={() => voting.cancelQuestion(question)}>Отменить голосование</Button>
			</div>}
		</div>;
	}
	
}
