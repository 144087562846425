import React from 'react';
import ReactSVG from 'react-svg';
import arrow from 'root/site/img/arrow.svg';

export class DropDownList extends React.Component {
	constructor() {
		super();
		this.state = { open: false, class: 'arrow__icon' };
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.setState(
			this.state.open
				? { open: false, class: 'arrow__icon', color: '' }
				: { open: true, class: 'arrow__icon_active simple-icon-red', color: 'var(--color-red)' }
		);
	}

	render() {
		if (this.state.open) {
			return (
				<>
					<div className="drop-down-list__item drop-down-list__item_active">
						<a onClick={this.handleClick} style={{ color: this.state.color }}>
							{' '}
							{this.props.title}
							<ReactSVG className={this.state.class} src={arrow} />
						</a>
					</div>
					<div className="col-12 drop-down-list__item">{this.props.children}</div>
				</>
			);
		} else {
			return (
				<div className="drop-down-list__item">
					<a onClick={this.handleClick}>
						{' '}
						{this.props.title}
						<ReactSVG className={this.state.class} src={arrow} />
					</a>
				</div>
			);
		}
	}
}
