import React, { useState } from 'react';
import { Calendar, Subscription, Breadcrumbs, Separator, VideoPreview, ElementItem } from '../../components';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { dateAndTime } from 'root/site/utils/date-format';
import { Loader, Pager } from '@smartplatform/ui';
import ReactSVG from 'react-svg';
import calendar from 'root/site/img/calendar.svg';
import eye from 'root/site/img/eye.svg';
import button_play from 'root/site/img/button_play.svg';
import store from '../../store';
import moment from 'moment';
import { customFormatVideo } from './customFormatVideo';
import { Link } from "react-router-dom";
import { Badges } from 'root/site/components/article/Badges';

const PER_PAGE = 4;
const TYPE = 'media';

@observer
class VideoGalleryList extends React.Component {
	@observable records;
	@observable tags = store.ui.tags.filter(tag => tag.type === TYPE) || [];
	@observable page;
	@observable totalCount;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.init();
	}

	@action init = async () => {
		const { records, page, totalCount } = await store.ui.getTagsWithRecords({
			model: 'Media',
			type: TYPE,
			relation: 'medies',
			modelType: 'media',
			perPage: PER_PAGE,
			include: [{ relation: 'views', scope: {limit: 0} },{relation: 'tags'}],
			customFormat: customFormatVideo,
		});
		this.page = page;
		this.records = records;
		this.totalCount = totalCount;
	};

	renderGallery = () => {
		if (this.records.length === 0) {
			return (
				<div className="container">
					<h2> Материалы не найдены </h2>
				</div>
			);
		}

		return this.records.map((record, index) => {
			const { id, thumbnail, name, publishAt, video } = record;
			const views = record.views()?.totalCount || '';

			return (
				<div key={`gallery-${id}`} className="col-md-6 col-sm-12">
					<div className="grid-gallery-video">
						<div className="grid-gallery-video__overlay" style={{ backgroundImage: `url(${thumbnail}` }}>
							<div className='badges'>
								<Badges article={record} width={120} />
							</div>
							<div className="grid-gallery-video__title">
								<Link to={`videogallery/${id}`}>
									<ReactSVG
										className="grid-gallery-video__play-button"
										src={button_play}
									/>
								</Link>
							</div>
							<div className="grid-gallery-video__info">
								{/*<span className="grid-gallery-video__info-name">{name}</span>*/}
								<span className="grid-gallery-video__info-name" dangerouslySetInnerHTML={{ __html: name }} />
								<ElementItem
									textColor={JSON.parse(localStorage.getItem('isPoorVision')) ? 'var(--color-primary)' : 'var(--color-grey-dark-2)'}
									fill={JSON.parse(localStorage.getItem('isPoorVision')) ? 'white' : 'grey'}
									icon={calendar}
									text={dateAndTime(publishAt)}
									top="2px"
								/>
								<ElementItem
									textColor={JSON.parse(localStorage.getItem('isPoorVision')) ? 'var(--color-primary)' : 'var(--color-grey-dark-2)'}
									fill={JSON.parse(localStorage.getItem('isPoorVision')) ? 'white' : 'grey'}
									icon={eye}
									text={views}
									top="2px" />
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	render() {
		const { tags, records } = this;
		const {
			route,
			ui: { rootPath },
		} = store;
		return (
			<div className="background-page-light-grey">
				<div className="container mb-5 pt-5">
					<div className="row">
						<div className="col-6">
							<h3>Видео</h3>
						</div>
						<div className="col-6">
							<Calendar />
						</div>
					</div>
				</div>
				{tags && (
					<Separator
						tags={[{ name: 'Все видео', id: 'all' }, ...tags]}
						onTagSwitch={e => {
							route.push({ path: rootPath, params: { page: 1, cat: e } });
						}}
					/>
				)}
				{records ? (
					<>
						<div className="container">
							<div className="row">{this.renderGallery()}</div>
						</div>
						<div className="container pager-div">
							<Pager
								itemsPerPage={PER_PAGE}
								totalCount={this.totalCount}
								current={this.page}
								onChange={page => {
									route.push({ params: { ...route.params, page: page } });
								}}
								className="iltumen-pager"
								directChange={true}
							/>
						</div>
					</>
				) : (
					<Loader />
				)}
			</div>
		);
	}
}

export default VideoGalleryList;
