import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { RecordSelect } from '@smartplatform/ui';
import { sortDep } from '../../utils/getCurrentDeputies';
import DeputyCard from '../deputies/DeputyCard';
import empty from '../../img/user.png';
import store from '../../store';
import './style.scss';

@observer
export default class DeputySearch extends React.Component {

	@observable district = null;
	@observable city = null;
	@observable address = null;
	@observable division = null;
	@observable depConv = [];
	@observable allRepublic = [];
	@observable streetSearch = '';
	@observable addressSearch = '';
	@observable cityFilter = {};
	@observable addressFilter = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		// this.district = await store.model.City.findById(1);
		// this.city = await store.model.City.findById(1);
		const convs = await store.model.Convocation.find({ where: { current: true } });
		this.conv = convs.length > 0 ? convs[0] : null;
/*
		this.allRepublic = await store.model.DeputyConvocation.find({
			where: {
				and: [
					{ convocationId: this.conv.id },
					{ divisionGroupId: 22 },
				],
			},
			include: [
				{ relation: 'fraction', scope: { fields: { id: true, shortName: true }}},
				{ relation: 'divisionGroup', scope: { fields: { id: true, name: true }}},
				{ relation: 'division', scope: { fields: { id: true, name: true }}},
				{
					relation: 'deputy',
					scope: {
						order: 'lastName asc',
					}
				}
			]
		});
*/
	};
	
	
	onDistrictChange = async district => {
		this.district = district;
		this.city = null;
		this.division = null;
		this.depConv = [];
		this.address = null;
		this.addressSearch = '';
		this.addressFilter = null;
		this.cityFilter = district ? { where: { districtId: district.id }} : {};
	};
	
	onCityChange = city => {
		// console.log('onCityChange', city ? city.name : null);
		this.city = city;
		this.division = null;
		this.depConv = [];
		this.address = null;
		this.addressSearch = '';
		this.addressFilter = null;
		this.division = city && city.division ? city.division : null;
		this.findDeputies();
	};
	
	addressItem = address => `${address.street}, ${address.building}`;
	
	onAddressChange = async address => {
		this.address = address;
		this.depConv = [];
		this.division = address && address.division ? address.division : null;
		this.findDeputies();
	};
	
	findDeputies = async () => {
		if (!this.division || !this.conv) return;
		this.depConv = await store.model.DeputyConvocation.find({
			where: {
				and: [
					{ endDate: null },
					{ convocationId: this.conv.id },
					{ deputyId: { neq: null } },
					{
						or: [
							{ divisionId: this.division.id },
							{ divisionGroupId: this.division.divisionGroupId },
						]
					},
				],
			},
			include: [
				{ relation: 'fraction', scope: { fields: { id: true, shortName: true }}},
				{ relation: 'divisionGroup', scope: { fields: { id: true, name: true }}},
				{ relation: 'division', scope: { fields: { id: true, name: true }}},
				{
					relation: 'deputy',
					scope: {
						order: 'lastName asc',
					}
				}
			]
		});
		// console.log('findDeputies', this.division);
	};
	
	onAddressSearch = str => {
		// console.log('onAddressSearch', str);
		this.addressSearch = str;
		this.doAddressSearch();
	};
	
	doAddressSearch = async () => {
		console.log('this.addressSearch', this.addressSearch);
		const words = this.addressSearch.trim().replace(/,/, '').split(' ');
		console.log('words', words);
		const building = words.length > 1 ? words[words.length - 1] : undefined;
		if (building) words.splice(words.length - 1, 1);
		const or = [];
		words.forEach(word => {
			or.push({ street: { ilike: `${word}%`}});
		});
		let filter = building ?
			{
				and: [
					{ or },
					{ building: { ilike: `${building}%` }}
				]
			}
			:
			{ or };
		// console.log('filter', JSON.stringify(filter));
		this.addressFilter = filter;
	};
	
	render() {
		let where = undefined;
		if (this.city && !this.city.divisionId) {
			where = {
				and: [
					{ cityId: this.city.id },
				]
			};
			if (this.addressFilter) where.and.push(this.addressFilter);
		}
		
		const ondoMandat = this.depConv.filter(dc => !!dc.divisionId);
		const group = this.depConv.filter(dc => !!dc.divisionGroupId);
		const depConv = [...ondoMandat, ...group];
		
		return <div className="container base-page deputy-search">
			<h1>Поиск депутата</h1>
			<div className="row">
				<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 form-field">
					<label>Выберите город/улус:</label>
					<RecordSelect
						model={store.model.District}
						property="name"
						value={this.district}
						onChange={this.onDistrictChange}
						searchFields={['name']}
						filter={{
							order: 'id asc'
						}}
					/>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 form-field">
					{this.district && <>
						<label>Выберите населенный пункт:</label>
						<RecordSelect
							model={store.model.City}
							property="name"
							value={this.city}
							onChange={this.onCityChange}
							filter={{
								...this.cityFilter,
								include: ['division'],
								order: 'id asc'
							}}
						/>
					</>}
				</div>
				<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 form-field">
				{this.city && !this.city.divisionId && <>
					<label>Введите улицу и номер дома:</label>
					{/*<input type="text" value={this.streetSearch} onChange={this.onStreetChange} />*/}
					<RecordSelect
						model={store.model.Address}
						property="street"
						computed={this.addressItem}
						value={this.address}
						onChange={this.onAddressChange}
						onSearch={this.onAddressSearch}
						// searchFields={[{ name: 'street', start: true }]}
						filter={{
							where,
							include: ['division'],
							order: 'street asc'
						}}
					/>
				</>}
				</div>
			</div>
			{this.division && <div className="found-division">Округ: <strong>{this.division.name}</strong></div>}
			{depConv.length > 0 && <div className="deputies">
				<div className="title">Депутаты:</div>
				{[...depConv].map(depConv => <DeputyCard key={depConv.id} depConv={depConv}/>)}
			</div>}
		</div>;
	}
	
}
