import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faList, faFile, faImage } from '@fortawesome/free-solid-svg-icons';

import { Loader, HtmlValue } from '@smartplatform/ui';
import { canCreateVotingForSession, createVotingForSession } from '../sessions';
import { formatDateStr } from './index';
import store from '../../store';
import { VideoItem } from 'root/site/pages/sessions-page/VideoItem';

@withRouter
@observer
export default class Single extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable error = null;
	@observable canCreateVoting = false;
	@observable isCreating = false;

	constructor(props) {
		super(props);
		this.model = props.type === 'plenary' ? store.model.PlenarySession : store.model.CouncilSession;
		store.ui.setBreadcrumbs([
			{ name: 'Заседания', link: `/sessions` },
			{ name: props.type === 'plenary' ? 'Пленарные заседания' : 'Заседания Совета', link: `/sessions/${props.type}` },
			{ name: 'Заседание', link: `/sessions/${props.type}/${props.match.params.id}` },
		]);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;
		try {
			this.record = await this.model.findById(this.id);
			let { canCreate } = await canCreateVotingForSession(this.record);
			const existingSessions = await store.model.Session.find({
				where: { plenarySessionId: this.id },
			});
			if (existingSessions.length > 0) canCreate = false;
			this.canCreateVoting = canCreate;
		} catch (e) {
			console.error(e);
			this.error = e.message;
		}
		this.isLoading = false;
	};

	getYoutubeId = url => {
		let youtubeId = null;
		if (url) {
			let match = url.match(/^https:\/\/youtu\.be\/(.+)$/);
			if (match && match.length > 1) youtubeId = match[1];
			if (!youtubeId) {
				let match = url.match(/^https:\/\/www.youtube.com\/watch\?v=([\w\d]+)/);
				if (match && match.length > 1) youtubeId = match[1];
			}
		}
		return youtubeId;
	};

	createVoting = async () => {
		this.isCreating = true;
		await createVotingForSession(this.record);
		this.props.onCreate && this.props.onCreate();
	};

	render() {
		const { type } = this.props;

		if (this.isLoading)
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		if (this.error) return this.error;

		const session = this.record;

		return (
			<div className="session-single" key={session.id}>
				<div className="top">
					{/*
				<div className="thumbnail">
					{session.thumbnail ? <img src={session.downloadFile('thumbnail')} /> : <span className="empty"/>}
				</div>
*/}
					<div className="info">
						<div className="date">
							<FontAwesomeIcon icon={faCalendarAlt} /> {formatDateStr(session.date, 'dd.MM.yyyy')}
						</div>
						<div className="name">{session.name}</div>
						<div className="session-link">
							<span className="icon">
								<FontAwesomeIcon icon={faList} />
							</span>
							<Link to={`/sessions/${type}/${session.id}/questions`}>Повестка заседания</Link>
							{type === 'plenary' && this.canCreateVoting && (
								<div style={{ marginTop: 15, marginBottom: 15 }}>
									<button className="red-btn" disabled={this.isCreating} onClick={this.createVoting}>
										Создать голосование
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
				<HtmlValue className="text" value={session.text} />
				{session.videos && session.videos.map((url, index) => <VideoItem url={url} key={index} />)}
				<div className="bottom">
					{session.protocol && (
						<div className="session-link">
							<span className="icon">
								<FontAwesomeIcon icon={faFile} />
							</span>
							<a href={session.downloadFile('protocol')}>Протокол</a>
						</div>
					)}
					{session.newsletter && (
						<div className="session-link">
							<span className="icon">
								<FontAwesomeIcon icon={faFile} />
							</span>
							<a href={session.downloadFile('newsletter')}>Информационный бюллетень</a>
						</div>
					)}
					{session.transcript && (
						<div className="session-link">
							<span className="icon">
								<FontAwesomeIcon icon={faFile} />
							</span>
							{!session.transcript2 ? (
								<a href={session.downloadFile('transcript')}>Стенограмма</a>
							) : (
								<>
									Стенограмма <a href={session.downloadFile('transcript')}>1 день</a>,&nbsp;
									<a href={session.downloadFile('transcript2')}>2 день</a>
								</>
							)}
						</div>
					)}
					{session.galleryId && (
						<div className="session-link">
							<span className="icon">
								<FontAwesomeIcon icon={faImage} />
							</span>
							<Link to={`/sessions/${type}/${session.id}/gallery`}>Фоторепортаж</Link>
							{/*<Link to={`/photogallery/${session.galleryId}`}>Фоторепортаж</Link>*/}
						</div>
					)}
				</div>
			</div>
		);
	}
}
