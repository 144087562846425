import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Dispatch from './Dispatch';

export default class Appeals extends React.Component {
	render() {
		return (
			<Switch>
				<Route path="/profile/dispatches" exact component={List} />
				<Route path="/profile/dispatches/:id" component={Dispatch} />
			</Switch>
		);
	}
}
