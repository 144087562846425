import React from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from '../../components/route-menu';
import PageWithNav from './PageWithNav';
import Page from './Page';
import { showTitle } from './tools'

const renderer = (path, pages, breadCrumbs) => <Menu>
	{pages.map((page, i) => (
		<MenuItem key={page.id} exact={i === 0} path={i === 0 ? path : `${path}/${page.route}`} title={page.title}>
			{showTitle(page)}
			<Page code={page.route} contentOnly breadCrumbs={breadCrumbs} />
		</MenuItem>
	))}
</Menu>;

const PageWithMenu = ({ path, code }) => <PageWithNav path={path} code={code} renderer={renderer} />;

PageWithMenu.propTypes = {
	path: PropTypes.string.isRequired, // путь по которому должна быть доступна страница
	code: PropTypes.string.isRequired, // код страницы в админке
};

export default PageWithMenu;
