import React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Select } from '@smartplatform/ui';
import { getPlenaryQuestionData, getCommitteeQuestionData } from '../helpers';
import { Panel } from '../panels';
import RequestButtons from '../panels/RequestButtons';
import store from '../../../store';
import { QuestionContent, QuestionStatus } from '../question-card';
import voting from '../store-site';
import './style.scss';

@observer
export default class CurrentQuestion extends React.Component {
	
	@observable name = '';
	@observable files = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
	}
	
	showFiles = () => {
		console.log('showFiles');
	};
	
	render() {
		const className = classNames('current-question-panel');
		
		return <div className={className}>
			<div className="left">
				{voting.currentQuestion ?
					<>
						<QuestionStatus key={voting.currentStage ? voting.currentStage.id : 'none'} question={voting.currentQuestion} />
						<QuestionContent key={voting.currentStage ? 'c-' + voting.currentStage.id : 'c-none'} question={voting.currentQuestion} />
					</>
					:
					'Ожидание...'
				}
			</div>
			<div className="right">
				{voting.currentQuestion ?
					<Panel key={voting.currentStage ? voting.currentStage.id : ''} question={voting.currentQuestion} />
					:
					voting.member ? <RequestButtons /> : null
				}
			</div>
		</div>;
	}
	
}
