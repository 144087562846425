import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import store from 'root/site/store';
import { Loader, HtmlValue, Button } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class QuestionsDocuments extends React.Component {
	@observable record;
	async componentDidMount() {
		console.log(this.props)
		const { id } = this.props;
		this.record = await store.model.QuestionDocumentFile.find({
			where: { documentId: id }
		});
	};

	render() {
		if (!this.record) {
			return <div className="container base-page"><Loader /></div>;
		}
		return (
			<div className='print-list'>
				{this.record.map(document => {
					return <img key={document.id}
						src={'https://epz.iltumen.ru/uploads/' + document.path}
						// печатает не на весь лист , оригинальный файл 768х960
						className='image'></img>
				})}
			</div>
		);
	}
}

const PrintQuestionsDocuments = ({ match }) => {
	const componentRef = useRef();
	const { id } = match.params;
	return (
		<div>
			<ReactToPrint
				trigger={() => (
					<div className="print-version__button">
						<Button label="Распечатать" />
					</div>
				)}
				content={() => componentRef.current}
			/>
			<QuestionsDocuments ref={componentRef} id={id} />
		</div>
	);
};
export default withRouter(PrintQuestionsDocuments);
