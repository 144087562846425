import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCheckViews } from '../../utils/hooks';
import { Popup, Loader } from '@smartplatform/ui';
import store from 'root/site/store';

import './styles.scss';
import { VideoPlayer } from 'root/site/components';

const VideoPreview = ({ record, onClose }) => {
	const { id } = useParams();
	const [video, setVideo] = useState();
	useCheckViews(record ? record.id : id, 'Media');

	useEffect(() => {
		init();
	}, [id]);

	const init = async () => {
		if (id) {
			const record = await store.model.Media.findById(id);
			const { name, videoUrl } = record;
			const src = record.importUid ? '/api/containers/media/download/' + record.importUid + '.mp4' : record.downloadFile('video');
			setVideo({ name, src, videoUrl });
		} else {
			record = await store.model.Media.findById(record.id);
			const { videoUrl, name } = record;
			const src = record.importUid
				? '/api/containers/media/download/' + record.importUid + '.mp4'
				: record.video && record.video.match(/\/api\/containers\/media\/download/)
				? record.video
				: record.video && record.downloadFile('video');
			setVideo({ name, src, videoUrl });
		}
	};
	if (!video) return null;
	return (
		<div>
			<Popup onClose={onClose ? onClose : store.history.goBack} className="media-preview-popup">
				{video?.name ? <div className="title">{video.name}</div> : ''}
				{video ? <VideoPlayer url={video.videoUrl || video.src} controls autoPlay /> : <Loader />}
			</Popup>
		</div>
	);
};

export default VideoPreview;
