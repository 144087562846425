import React from 'react';
import styled from 'styled-components';
import store from 'root/site/store';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import arrow from 'root/site/img/arrow.svg';
import { ElementItem } from 'root/site/components';
import './styles.scss';

const { ui } = store;

const BreadcrumbsBox = styled.div`
	background: var(--color-grey-light-2);
	color: var(--color-primary-black);
	top: ${props => (props.smallMenu ? 'var(--header-position-sm)' : 'var(--header-position-lg)')};
	width: 100%;
	height: 7rem;
	font-style: normal;
	font-weight: 300;
	font-size: 1.7rem;
	line-height: 2.1rem;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (min-width: 768px) and (max-width: 991px) {
		top: 8rem;
	}
`;

@observer
export class Breadcrumbs extends React.Component {
	render() {
		const { breadcrumbs } = ui;

		if (store.route.path === '/') {
			return null;
		}

		return (
			<MediaQuery minWidth={768}>
				<div className="background-page-light-grey">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<BreadcrumbsBox smallMenu={ui.smallMenu}>
									<Link className="breadcrumbs__text" to="/">
										Главная
									</Link>
									{breadcrumbs.length > 0 &&
										breadcrumbs.map((el, index) => {
											const { name, link } = el;

											if (index !== breadcrumbs.length - 1 && link) {
												return (
													<Link key={`breacrumb-${index}`} className="breadcrumbs__item" to={link}>
														<ElementItem className="breadcrumbs__item" icon={arrow} text={name} />
													</Link>
												);
											} else {
												return (
													<ElementItem
														key={`breacrumb-${index}`}
														className="breadcrumbs__item breadcrumbs__item-last element-item__fill-grey-light"
														icon={arrow}
														text={name}
													/>
												);
											}
										})}
								</BreadcrumbsBox>
							</div>
						</div>
					</div>
				</div>
			</MediaQuery>
		);
	}
}
