import React from 'react';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';

{/* Все стили находятся в plenary session  */}

export default class SolutionsComp extends React.Component {
	render() {
		return (
				<>
				<div className="col-12 col-lg-4 pt-5">
					<div className="name-block-plenary pb-5">Решение</div>
                <div className="pb-4 solutions__item">
					<div className="pb-4 pl-md-4">
					<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Принятые нормативные правовые акты</a></p>
					</div>
					<div className="pb-4 pl-md-4">
					<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Принятые нормативные правовые акты</a></p>
					</div>
					<div className="pb-4 pl-md-4">
					<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Принятые нормативные правовые акты</a></p>
					</div>
					
		        </div>
		        </div>
		 
			</>
		);
	}
}
