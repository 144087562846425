import React from 'react';
import MediaQuery from 'react-responsive';

import { PhotoSlider } from './PhotoSlider';
import { Separator, Section } from '../../components';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import store from '../../store';

@observer
class Gallery extends React.Component {
	@observable tags;
	@observable records;

	galleryOptions = {
		where: {
			publish: true,
			publishAt: { lte: Date.now() },
		},
		order: 'publishAt DESC',
		include: [{ relation: 'tags', scope: { fields: ['id', 'image'] } }],
		limit: 5,
	};

	componentDidMount() {
		this.init();
	}

	init = async () => {
		const tags = await store.model.Tag.find({
			where: { type: 'gallery' },
			order: 'weight desc, id asc',
		});

		const records = await tags[0].galleries.find(this.galleryOptions);

		this.tags = tags;
		this.records = records;
	};

	@action onTagSwitch = async tag => {
		const records = await this.tags.filter(e => e.id === +tag)[0].galleries.find(this.galleryOptions);
		this.records = records;
	};

	render() {
		const { tags, records } = this;
		return (
			<>
				<div className="container">
					<Section title="Фотогалерея" link="/photogallery" />
				</div>
				{tags && <Separator tags={[...tags]} onTagSwitch={this.onTagSwitch} />}

				<div className="container">
					{records ? <PhotoSlider records={records} /> : <div className="gallery-placeholder" />}
				</div>
			</>
		);
	}
}
export default Gallery;
