import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';
import t from '../../../i18n';

const VIDEO_DOMAINS = {
	youtube: ['youtu.be', 'www.youtube.com'],
	rutube: ['rutube.ru'],
	vk: ['vkvideo.ru', 'vk.com'],
	dzen: ['dzen.ru'],
	platform: ['plvideo.ru'],
};

@observer
export class VideoItem extends React.Component {
	@observable iframeUrl = null;

	constructor(props) {
		super(props);
		this.getVideoId(props.url);
	}

	getVideoId = () => {
		const { url } = this.props;
		const parsedUrl = new URL(url);
		const host = parsedUrl.host;
		let source;
		for (const sourceKey in VIDEO_DOMAINS) {
			if (VIDEO_DOMAINS[sourceKey].includes(host)) {
				source = sourceKey;
				break;
			}
		}
		if (source) {
			if (this.hasOwnProperty(`${source}SetIframeUrl`)) {
				this[`${source}SetIframeUrl`](parsedUrl);
			}
		}
	};

	platformSetIframeUrl = urlObject => {
		let videoId = urlObject.searchParams.get('v');
		if (!videoId) {
			const pathNameItems = urlObject.pathname.split('/');
			if (pathNameItems?.[1] === 'embed' && pathNameItems?.[2]) videoId = pathNameItems[2];
		}
		if (videoId) this.iframeUrl = `https://plvideo.ru/embed/${videoId}`;
	};

	dzenSetIframeUrl = urlObject => {
		let videoId;
		const pathNameItems = urlObject.pathname.split('/');
		if (pathNameItems?.[1] === 'embed' && pathNameItems?.[2]) videoId = pathNameItems[2];
		if (videoId) this.iframeUrl = `https://dzen.ru/embed/${videoId}`;
	};

	vkSetIframeUrl = urlObject => {
		let videoId = urlObject.searchParams.get('id');
		let oid = urlObject.searchParams.get('oid');
		if (oid) oid = oid.substring(1);
		if (!videoId && !oid) {
			const pathNameItems = urlObject.pathname.split('/');
			if (pathNameItems.length) {
				// В URL видео, если не через searchParams id видео хранится в формате "video-218471753_456239134", где последняя цифра id видео
				const videoParamsPathName = pathNameItems[1].split('-');
				if (videoParamsPathName?.[0] === 'video' && videoParamsPathName?.[1]) {
					const videoParams = videoParamsPathName[1].split('_');
					if (videoParams?.[0]) oid = videoParams[0];
					if (videoParams?.[1]) videoId = videoParams[1];
				}
			}
		}
		if (videoId && oid) this.iframeUrl = `https://vk.com/video_ext.php?oid=-${oid}&id=${videoId}`;
	};

	rutubeSetIframeUrl = urlObject => {
		let videoId;
		const pathNameItems = urlObject.pathname.split('/');
		if (pathNameItems?.[1] === 'video' && pathNameItems?.[2]) videoId = pathNameItems[2];
		if (pathNameItems?.[1] === 'play' && pathNameItems?.[2] === 'embed' && pathNameItems?.[3]) videoId = pathNameItems[3];
		if (videoId) this.iframeUrl = `https://rutube.ru/play/embed/${videoId}`;
	};

	youtubeSetIframeUrl = urlObject => {
		let videoId = urlObject.searchParams.get('v');
		if (!videoId) {
			const pathNameItems = urlObject.pathname.split('/');
			if (pathNameItems?.[1] === 'embed' && pathNameItems?.[2]) videoId = pathNameItems[2];
		}
		if (videoId) this.iframeUrl = `https://www.youtube.com/embed/${videoId}`;
	};

	render() {
		if (!this.iframeUrl) return null;

		return (
			<div className="session-video">
				<iframe
					width="560"
					height="315"
					src={this.iframeUrl}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
		);
	}
}
