import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFax } from '@fortawesome/free-solid-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

import { Page } from '../../components';

export default class ContactInf extends React.Component {
	render() {
		return <Page route="/contacts/contacts-info" contentOnly applyBreadcrumbs={false} />
/*
		return (
			<>
			
			<p className="pb-5"><strong>Государственное Собрание (Ил Тумэн) Республики Саха (Якутия)</strong></p>

			<div className="pb-5"><p><strong>Адрес:</strong></p>
			<p>677022, Республика Саха (Якутия), г. Якутск, ул. Ярославского, 24/1</p></div>
			
			<div className="pb-5"><p><strong>Часы работы:</strong></p>
			<p>ежедневно с 9:00 до 18:00, кроме субботы и воскресенья</p>
			<p>перерыв на обед с 13:00 до 14:00</p></div>
			
			<div className="pb-5"><p><strong>Приемная Председателя Государственного Собрания (Ил Тумэн):</strong></p>
			<p>8 (4112) 43-51-94 факс: 8 (4112) 43-53-33</p></div>
			
			<div className="pb-5"><p><strong>Приемная руководителя аппарата:</strong></p>
			<p>8 (4112) 43-53-88</p></div>
			
			<div className="pb-5"><p><strong>Общественная приемная:</strong></p>
			<p>8 (4112) 43-52-44</p></div>
			
			<div className="pb-5"><p><strong>Канцелярия:</strong></p>
			<p>8(4112) 43-53-25</p></div> 
			
			<p style={{color: 'var(--color-red)'}}>  gs@iltumen.ru</p>
			</>
		);
*/
	}
}
