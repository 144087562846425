import { useEffect, useState } from 'react';
import store from 'root/site/store';

const { ui } = store;

export const useGetRecord = ({ model, id, options, tag, setBreadcrumbs = false }) => {
	const [record, setRecord] = useState();

	useEffect(() => {
		tag && store.ui.switchTag(tag);
		let loading = true;
		console.log('options', options);
		getRecord()
			.then(res => {
				loading && setRecord(res);
			})
			.catch(err => {
				console.error('Fetch error', err);
			});
		return () => {
			setBreadcrumbs && ui.setBreadcrumbs(ui.breadcrumbs.slice(0, -1));
			loading = false;
		};
	}, []);

	const getRecord = async () => {
		let record;
		if (isNaN(id)) {
			record = new store.model[model]();
		} else {
			record = await store.model[model].findById(id, {
				...options,
			});
		}

		setBreadcrumbs && ui.setBreadcrumbs([...ui.breadcrumbs, { name: record.name, link: record.id }]);
		return record;
	};
	return record;
};
