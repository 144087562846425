import format from 'date-fns/format';

export { default as Sessions } from './Sessions';
export { default as Meeting } from './Meeting';
export { default as PlenarySession } from './PlenarySession';
export { default as PrintQuestionsDocuments } from './PrintQuestionsDocuments';
export { default as DocumentPreview } from './DocumentPreview';

export const formatDateStr = (dateStr, _format = 'dd.MM.yyyy HH:mm') => format(new Date(dateStr), _format);
export const formatTime = int => format(new Date(int * 1000), 'dd.MM.yyyy HH:mm');
