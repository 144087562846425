import React from 'react';
import './styles.scss';
import ReactSVG from 'react-svg';
import arrow from 'root/site/img/arrow.svg';



export default class LawState extends React.Component {
  constructor() {
		super();
		this.state = {
		arrow: true,
		show: false
	};
		this.Show = this.Show.bind(this);

	}

	
	Show() {
        this.setState({show: !this.state.show});
        this.setState({arrow: !this.state.arrow});
	}
    
    
	render() {
        
    const class1 = this.state.arrow ? 'arrow__icon' : 'arrow__icon_active';
		if (this.state.show) {

        return (
            <>
            <div className="background-page-light-grey-mobile">
                <div className="container pb-5 pt-5">
                <div className="row">
            <div className="col-12">
        <div className="print-version"> <a onClick={this.Show}>  <span> Стадия</span>
        <ReactSVG className={class1}  src={arrow} /></a>
        </div> </div></div></div></div>
        <div className="container pb-5 pt-5">
                <div className="row">
            <div className="col-12">
                <div className="document-view"></div>
                    </div>
                    </div>
                    </div>
               
            
            </>
         );
        }
        else {
          return (
              <>
              <div className="background-page-light-grey-mobile">
                <div className="container pb-5 pt-5">
                
              <div className="row">
                              
                              <div className="col-12">
                              <div className="print-version"> <a onClick={this.Show}>   <span>Стадия</span>
      <ReactSVG className={class1}  src={arrow} /></a>
      </div>
                              
                     </div></div></div></div>
                          
              </>
          );
      }
      
          }
      }

    