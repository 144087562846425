import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../../../store';
import { HtmlValue } from '@smartplatform/ui';
import { fio } from '../../../../utils';

@observer
export default class Dispatch extends React.Component {
	@observable dispatch = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		this.dispatch = await store.model.Dispatch.findById(this.id, { include: ['attachments', 'recipients', 'owner'] });
		const recipients = await this.dispatch.recipients.find({ where: { deputyId: store.model.user.deputyId } });
		const recipient = recipients[0];
		recipient.checked = true;
		await recipient.save();
		await store.ui.setUserMessages();
	};

	render() {
		if (!this.dispatch) return null;

		return (
			<div className="appeal-page">
				<p>
					Сообщение № <strong>{this.id}</strong>
				</p>
				<h3>{this.dispatch.name}</h3>

				<div style={{ marginTop: '2rem' }}>
					<br />
					<div>
						<HtmlValue className="text-news" value={this.dispatch.text} />
					</div>
				</div>

				{this.dispatch.attachments().length > 0 && (
					<div>
						<label>Приложения:</label>
						<br />
						{this.dispatch.attachments().map(a => (
							<p key={`attachment-${a.id}`}>
								<a download href={a.downloadFile('filename')} key={`attachment-${a.id}`}>
									{a.filename}
								</a>
							</p>
						))}
					</div>
				)}
				<div>
					<h4>Отправитель</h4>
					<p>
						<b>{fio(this.dispatch.owner)}</b>
					</p>
				</div>
			</div>
		);
	}
}
