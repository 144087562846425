import React, {useEffect, useState} from 'react';
import ReactSVG from 'react-svg';
import './styles.scss';
import {ShareInSocial} from '../../components';
import calendar from 'root/site/img/calendar.svg';
import play from 'root/site/img/Play.svg';
import WritItem from './WritItem';
import SolutionsComp from './SolutionsComp';
import PlenaryGallery from './PlenaryGallery';
import PreviousPlenary from './PreviousPlenary';
import print from 'root/site/img/print.svg';
import store from "root/site/store";
import Loader from "@smartplatform/ui/src/components/loader/Loader";
import {dateAndTime} from "root/site/utils/date-format";


export default function PlenarySinglePage(props) {
    const [plenary, setplenary] = useState()
    useEffect(() => {
        getplenary()
    }, [])

    async function getplenary() {
        const l = await store.model.PlenarySession.findById(props.match.params.id);
        setplenary(l)
    }

	if(!plenary) return <Loader/>
    return (
        <>
            <div className="note-page-border-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 note-page-title mb-5 mt-5">
                            <h1>${plenary.name}</h1>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
						<span className="note-page__date note-page__date-none">
									<ReactSVG className="simple-icon simple-icon-dark" src={calendar}/>{dateAndTime(plenary.publishAt)}
								</span>
                        </div>

                        <div className="col-12 col-md-6 pt-3 pt-md-0 ">
							<span className="note-page__date note-page__date-right">
								<a className="note-page__live-broadcast" href="">Прямая трансляция</a>
								<ReactSVG className="simple-icon simple-icon-red" style={{display: 'inline-block'}} src={play}/>
							</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container container_pb_90">
                <div className="row justify-content-center">
                    {/*<WritItem/>*/}
                    {/*<SolutionsComp/>*/}
                </div>

                <div className="row justify-content-center align-items-center ">
                    <div className="col-11 col-md-12">
	                    <img src={`/api/containers/plenarysessions/download/${plenary.id}-thumbnail`} alt={plenary.name}/>
                        {/*<PlenaryGallery/>*/}
                    </div>
                </div>


                <div className="row justify-content-center align-items-center pb-5 pt-3">
                    <div className="d-none d-md-block col-md-6">
                        <div className="print-version">
                            <div>
                                <ReactSVG className="simple-icon simple-icon-dark" src={print}/>
                                <span>Версия для печати</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center">
                        <ShareInSocial/>

                    </div>
                </div>
                <PreviousPlenary/>
            </div>

        </>
    );

}
