import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import { Header, Footer } from '../../../components';
import SessionWrapper from './SessionWrapper';
import SessionDebug from './SessionDebug';
import Log from './Log';
import store from '../../../store';
import voting from '../store-site';
import { isAdmin, getRoleNames } from '../helpers';

import './style.scss';

@observer
export default class ActiveSession extends React.Component {

	@observable isAdmin = false;
	
	constructor(props) {
		super(props);
		store.ui.smallMenu = true;
		// this.init();
	}
	
	init = async () => {
		this.isAdmin = await isAdmin();
		this.debugLog();
	};
	
	debugLog = async () => {
		if (store.model.user) {
			const roleNames = await getRoleNames();
			if (!voting.isTrueAdmin) {
				if (roleNames.includes('admin')) {
					await store.debugLog(`ActiveSession: админ не получил доступ к панели администратора. roles: ${roleNames}`, 'error');
				}
			}
			else {
				await store.debugLog(`ActiveSession: пользователь получил доступ к панели администратора. isAdmin: ${JSON.stringify(this.isAdmin)}, roles: ${roleNames}`, 'info');
			}
		}
	};
	
	componentDidMount() {
		store.ui.smallMenu = true;
	}
	
	componentWillUnmount() {
		store.ui.smallMenu = false;
	}
	
	showDebug = () => {
		store.local.sessionDebug = true;
		store.local.save();
	};
	
	closeDebug = () => {
		store.local.sessionDebug = false;
		store.local.save();
	};
	
	showRegLog = () => {
		store.local.regLog = true;
		store.local.save();
	};
	
	closeLog = () => {
		store.local.regLog = false;
		store.local.save();
	};
	
	render() {
		return <>
			<Header small={true} />
			<div className="active-session-layout">
				<Route path="/active-session/:id" exact component={SessionWrapper} />
				{voting.isTrueAdmin && <>
					{store.local.regLog ?
						<Log onClose={this.closeLog} />
						:
						<div className="show-reg-log" onClick={this.showRegLog}>Журнал сессии</div>
					}
					{store.local.sessionDebug ?
						<SessionDebug onClose={this.closeDebug} />
						:
						<div className="show-debug" onClick={this.showDebug}>Session debug</div>
					}
				</>}
				<div className={'session-loading' + (voting.isLoading ? ' active' : '')}><div className="text">Идет загрузка...</div></div>
			</div>
			<Footer />
		</>;
	}
	
}
