import React from 'react';
import { action, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Loader } from '@smartplatform/ui';
import store from '../../store';

@observer
export default class List extends React.Component {
	@observable isLoading = true;
	@observable records = [];

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Комитеты и комиссия', link: '/committees' }]);
		this.init();
	}

	@action init = async () => {
		this.records = await store.model.Committee.find({
			order: 'id asc',
			include: {
				relation: 'deputies',
				scope: {
					include: ['deputy'],
				},
			},
		});
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <Loader />;


		return (
			<div className="container committees-list" id="wrapper">
				{/* {this.records.slice(1, this.records.length).map(record => { */}
				{/* {this.records.sort((a,b) => a.deactivated - b.deactivated).map(record => { */}
				{this.records.filter(rec => !rec.deactivated).map(record => {
					const commitieeChief = record.deputies().filter(e => e.positionNum === 2)[0];
					const { lastName = '', firstName = '', middleName = '' } = commitieeChief ? commitieeChief.deputy : {};
					const chiefName = lastName ? `${lastName} ${firstName} ${middleName}` : 'Не назначен';
					const status = record.deactivated && <p className='deactivated'>Комитет расформирован</p>

					return (
						<Link to={`/committees/${record.id}`} key={record.id} className="committees-list__item">
							<h4 className="committees-list__item-title"> {record.name} </h4>
							{status}
							<div className="committees-list__item-chief">
								<p className="committees-list__item-chief-label">Председатель </p>
								<h5>{chiefName}</h5>

							</div>
						</Link>
					);
				})}
			</div>
		);
	}
}
