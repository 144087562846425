import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import isAfter from 'date-fns/isAfter';

import voting from '../store-site';
import { formatDateStr } from '../../../utils';

const memberSort = (a, b) => isAfter(new Date(a.__logged), new Date(b.__logged)) ? -1 : 1;

@observer
export default class RegLog extends React.Component {

	render() {
		const log = [
			...voting.allMembers.filter(m => m.end !== null).map(member => ({ member, __logged: member.end })),
			...voting.allMembers.map(member => ({ member, __logged: member.date})),
		];
		
		log.sort(memberSort);
		
		return <div className="reg-log">
			<strong>Журнал регистрации</strong>
			<div className="list">
				{log.map(({ member, __logged }) => {
					const isEnd = member.end && member.end === __logged;
					const time = new Date(__logged).getTime() + voting.delta;
					return <div key={(isEnd ? 'end-' : 'start-') + member.id}>
						<div className="name">{voting.fio(member.deputyId ? member.deputy : member.user)}</div>
						<div className={'status' + (isEnd ? ' end' : '')}>
							<em>{formatDateStr(new Date(time))}</em>
							<span>{isEnd ? 'снят с регистрации' : 'зарегистрирован'}</span>
						</div>
					</div>;
				})}
			</div>
			
		</div>;
	}
	
}
