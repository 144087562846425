import store from '../store';

export const sortDep = (a, b) => {
	if (!a || !b) {
		// console.log(a, b)
		return -1;
	}
	if (a.lastName > b.lastName) return 1;
	if (a.lastName < b.lastName) return -1;
	if (a.lastName === b.lastName) {
		if (a.firstName > b.firstName) return 1;
		if (a.firstName < b.firstName) return -1;
	}
	return 0;
};

export default async params => {
	const { ignoreEndDate = false, sessionDate } = params || {};

	let convWhere = { current: true };
	if (sessionDate) convWhere = { and: [{ startDate: { lt: sessionDate } }, { endDate: { gt: sessionDate } }] };
	const conv = (await store.model.Convocation.find({ where: convWhere }))[0];

	const where = {
		and: [{ convocationId: conv.id }, { deputyId: { neq: null } }],
	};
	if (!ignoreEndDate) {
		where.and.push({ endDate: null });
	}

	if (conv) {
		const depConvs = await store.model.DeputyConvocation.find({
			where,
			include: ['deputy'],
		});
		return depConvs
			.map(depConv => {
				const deputy = depConv.deputy;
				deputy.__startDate = depConv.startDate;
				deputy.__endDate = depConv.endDate;
				return deputy;
			})
			.sort(sortDep);
	}
	console.warn('No deputies! Current convocation:', conv);
	return [];
};
