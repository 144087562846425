import React from 'react';
import moment from 'moment';

const WorkPlanTime = ({ planTime }) => {
	if (!planTime) {
		return (
			<>
				<td></td>
				<td></td>
				<td></td>
			</>
		);
	}
	const { time, place, event, responsible } = planTime;
	const displayTime = time && moment(time).format('HH') !== '00' ? moment(time).format('HH:mm') : '';
	return (
		<>
			<td valign="top" className="tabl-workplan-center">
				<p>
					<strong>{displayTime}</strong>
				</p>
				<p>{place}</p>
			</td>
			<td valign="top">
				<p className="text-align-justify">{event}</p>
			</td>
			<td valign="top" className="tabl-workplan-center">
				<p>{responsible}</p>
			</td>
		</>
	);
};

export default WorkPlanTime;
