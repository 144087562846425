import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Question from './Question';
import voting from '../store-site';
import './style.scss';

@observer
export default class QuestionSelect extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		const disabled = voting.currentStage && voting.currentStage.voteStart && !voting.currentStage.voteEnd && !voting.currentStage.canceledAt;
		
		if (voting.questions.length === 0) return null;
		
		const proceduralIndex = voting.questions.findIndex(q => q.code === 'procedural');
		const agendaIndex = voting.questions.findIndex(q => q.code === 'agenda');
		
		const procedural = voting.questions[proceduralIndex];
		const agenda = voting.questions[agendaIndex];
		
		const questions = voting.questions.filter(q => q.code === 'question');
		
/*
		const allStages = [];
		voting.questions.forEach(question => {
			question.stages().forEach(stage => {
				stage.__question = question;
				allStages.push(stage);
			});
		});
*/
		
		return <div className={'admin-question-list' + (disabled ? ' disabled' : '')}>
			{procedural && <Question key={procedural.id} question={procedural}/>}
			<div className="list">
				{agenda && <Question key={agenda.id} question={agenda}/>}
				{questions.map(question => <Question key={question.id} question={question}/>)}
{/*
				<div className="history">
					{allStages.map(stage => <div key={stage.id}>
						<div className="question-name">
							{stage.__question.code === 'question' && <>Вопрос №{stage.__question.order}: </>}
							{stage.__question.name}
						</div>
						<div className="stage-name">{voting.getStageName(stage)}</div>
					</div>)}
				</div>
*/}
			</div>
		</div>;
	}
	
}
