import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatDateStr } from '../../utils/dateUtils';
import store from '../../store';

@observer
export default class Plenary extends React.Component {
	
	@observable activeSessions = [];
	@observable meetings = [];
	@observable activeIds = [];
	
	timer = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer);
	}
	
	init = async () => {
		const activeSessions = await store.model.Session.find({
			where: {
				and: [
					{ registrationStart: { neq: null }},
					{ plenarySessionId: { neq: null }},
					{ end: null },
				]
			},
			fields: ['id', 'name', 'registrationStart', 'end', 'plenarySessionId']
		})
		
		// const roleNames = await store.model.user.getRoleNames();
		// const isAdmin = roleNames.includes('admin');
		this.activeIds = activeSessions.map(session => session.plenarySessionId);
		
		this.meetings = await store.model.PlenarySession.find({
			order: 'id desc',
		});
		this.timer = setTimeout(this.init, 2000);
	};
	
	render() {
		return <div className="base-page profile-plenary">
			{/*<p><strong>Пленарные заседания</strong></p>*/}
			<div className="sessions-list">
				<strong>Пленарные заседания</strong>
				{this.meetings.length > 0 ?
					this.meetings.map(meeting => <div key={meeting.id} className="meeting">
						<small>{meeting.date ? formatDateStr(meeting.date) : '-'}</small>
						<Link to={`/plenary-session/${meeting.id}`}>{meeting.name}</Link>
						{this.activeIds.includes(meeting.id) && <span className="active-session">Идет заседание</span>}
					</div>)
					:
					'Заседаний нет.'
				}
			</div>
		</div>;
	}
	
}
