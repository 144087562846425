import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Appointment from './Appointment';

export default class Appointments extends React.Component {
	
	render() {
		return <Switch>
			<Route path="/profile/appointments" exact component={List} />
			<Route path="/profile/appointments/:id" component={Appointment} />
		</Switch>;
	}
	
}
