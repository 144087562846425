import store from '../../store';

export { default as ActiveSession } from './active-session-layout/ActiveSession';
export { default as SessionMembers } from './online/SessionMembers';
export { default as SessionProtocol } from './protocol/SessionProtocol';
export { default as QuestionProtocol } from './protocol/QuestionProtocol';

export const PLENARY = 'plenary';
export const COMMITTEE = 'committee';

export const getType = session => session.MODEL === store.model.PlenarySession ? PLENARY : COMMITTEE;

export * from './helpers';
