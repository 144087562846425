import React from 'react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.isNewUser = props.location.state.isNewUser;
		store.ui.setBreadcrumbs([{ name: 'Запись на личный прием', link: '/appointments' }]);
		this.init();
	}

	init = async () => {
		this.record = await store.model.Appointment.findById(this.props.match.params.id, {
			fields: { id: true, statusId: true },
			include: ['status'],
		});
		this.isLoading = false;
	};

	render() {
		return (
			<div className="container appointments">
				<h1>Запись на личный прием</h1>

				{this.isLoading ? (
					''
				) : this.record ? (
					this.isNewUser ? (
						<>
							<p>
								Ваша заявка на личный прием отправлена. Для завершения создания личного кабинета
								необходимо подтвердить адрес электронной почты. На указанную почту было отправлено
								письмо с подтверждением.
							</p>
						</>
					) : (
						<>
							<p>
								Ваша заявка на личный прием отправлена. Статус:{' '}
								{this.record.status ? this.record.status.name : '-'}.
							</p>
							{/*<p>Ваше заявка была успешно зарегистрирована.<br />Номер вашей заявки: №<strong>{this.record.id}</strong></p>*/}
							<p>
								<Link to="/profile/">Вернуться в профиль</Link>
							</p>
						</>
					)
				) : (
					'Заявка не найдена'
				)}
			</div>
		);
	}
}
