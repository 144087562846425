import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../../store';
import voting, { VOTEEND_INTERVAL } from '../store-site';
import Admin from './Admin';
import Deputy from './Deputy';
import Chairman from './Chairman';
import Participant from './Participant';
import Protocolist from './Protocolist';
import Inactive from './Inactive';

import {
	STATUS_INACTIVE,
	STATUS_DISCUSSED,
	STATUS_VOTING,
	STATUS_VOTED,
	STATUS_CANCELED,
	STATUS_CALCULATING,
	STATUS_FINISHED,
} from '../store-site';

@observer
export default class Panel extends React.Component {

	static propTypes = {};
	static defaultProps = {};
	
	constructor(props) {
		super(props);
	}
	
	render() {
		const { question } = this.props;
		const stage = question.stage;
		
		if (!stage) return null;
		
		let status = STATUS_INACTIVE;
		
		if (stage) {
			if (stage.voteEnd) {
				const timePassed = new Date().getTime() - new Date(stage.voteEnd).getTime();
				status = timePassed > VOTEEND_INTERVAL ? STATUS_VOTED : STATUS_CALCULATING;
			}
			else if (stage.voteStart) {
				status = stage.canceledAt ? STATUS_CANCELED : STATUS_VOTING;
			}
			else if (stage.discussionStart) {
				status = stage.canceledAt ? STATUS_FINISHED : STATUS_DISCUSSED;
			}
		}
		
		this.prevVoteEnd = stage.voteEnd;
		
		const isActive = status !== STATUS_INACTIVE && status !== STATUS_VOTED && status !== STATUS_CANCELED;
		const props = { ...this.props, status };

		let panel = <Inactive question={question} />;
		
		if (voting.quorum) {
			if (voting.isAdmin) {
				panel = <Admin {...props} />;
			}
			else if (voting.deputy) {
				panel = voting.isChairman ? <Chairman {...props} /> : <Deputy {...props} />;
			}
			else if (voting.isProtocolist) {
				panel = <Protocolist {...props} />;
			}
			else if (voting.isParticipant) {
				panel = <Participant {...props} />;
			}
		}
		
		return <div className="question-panel">
			<div style={{ display: 'none' }}>{voting.lastUpdateDate.getTime()}</div>
			{/*<div>status: {status}</div>*/}
			{panel}
		</div>;
	}
	
}
