import React from 'react';

import { LinkSlider } from './LinkSlider';
import { Separator } from 'root/site/components';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import store from '../../store';

@observer
class Links extends React.Component {
	@observable tags;
	@observable records;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		const tags = await store.model.Tag.find({
			where: { type: 'link' },
			order: 'weight desc, id asc',
		});

		const records = await tags[0].links.find();

		this.tags = tags;
		this.records = records;
	};

	@action onTagSwitch = async tag => {
		const records = await this.tags.filter(e => e.id === +tag)[0].links.find();
		this.records = records;
	};

	render() {
		const { records, tags } = this;
		return (
			<>
				{tags && <Separator tags={[...tags]} onTagSwitch={this.onTagSwitch} />}

				<div className="container links-container pt-4">
					{records ? <LinkSlider records={records} /> : <div className="links-placeholder" />}
				</div>
			</>
		);
	}
}
export default Links;
