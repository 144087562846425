import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Session from '../Session';

@observer
export default class SessionWrapper extends React.Component {

	render() {
		return <div className="container">
			<Session id={this.props.match.params.id} />
		</div>;
	}
	
}
