import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { DatePicker, RecordSelect } from '@smartplatform/ui';
import Images from './images';
import { formatDateStr } from './index';
import { isAdmin, isDeputy } from '../sessions/helpers';
import VotesFromView from '../sessions/panels/VotesFromView';
import store from '../../store';

const cutExtension = filename => filename.split('.').slice(0, -1).join('.');

@observer
export default class QuestionResult extends React.Component {
	
	@observable result = null;
	@observable sessionQuestion = null;
	@observable loading = true;
	@observable text = '';
	@observable date = null;
	@observable status = null;
	@observable files = [];
	@observable editing = false;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		// this.result = await this.props.question.result()[0] || null;
		const sessionQuestions = await store.model.SessionQuestion.find({
			where: { questionId: this.props.question.id },
			fields: ['id', 'name', 'publish', 'customType'],
			include: [
				{
					relation: 'stages',
					scope: {
						include: [
							{ relation: 'type', scope: { fields: [ 'id', 'name' ] }},
						]
					}
				},
			]
		});
		this.sessionQuestion = sessionQuestions.length > 0 ? sessionQuestions[0] : null;
		const r = await this.props.question.result.find({
			include: ['status']
		});
		this.result = r.length > 0 ? r[0] : null;
		this.files = this.result ? await this.result.images() : [];
		// console.log('files', this.files);
		if (this.result) {
			this.text = this.result.text;
			this.date = this.result.date;
			this.status = this.result.status;
		} else {
			this.date = new Date();
			// this.status = await store.model.QuestionStatus.findById(1);
		}
		this.loading = false;
	};
	
	edit = async e => {
		e.preventDefault();
		if (this.props.canWrite) {
			await this.init();
			this.editing = true;
		}
	};
	
	onTextChange = e => this.text = e.target.value;
	onDateChange = date => this.date = date;
	onStatusChange = status => this.status = status;
	
	save = async () => {
		if (!this.result) {
			this.result = new store.model.QuestionResult();
			this.result.questionId = this.props.question.id;
		}
		this.result.text = this.text;
		this.result.date = this.date;
		this.result.status = this.status;
		
		console.log('imageChanged', this.imageChanged);
		if (this.imageChanged) {
			try {
				if (this.image) {
					await this.result.uploadFile('image', this.image);
				} else {
					await this.result.deleteFile('image');
				}
				this.result.image = this.image ? this.image.name : null;
			} catch (e) {
				console.warn(e);
			}
		}
		
		try {
			await this.result.save();
		} catch (e) {
			console.warn(e);
		}
		
		this.editing = false;
		await this.init();
	};
	
	delete = async () => {
		if (this.result) {
			this.text = '';
			this.date = null;
			this.status = null;
			
			const files = await this.result.images();
			await this.result.delete();
			for (let file of files) {
				await file.deleteFile('filename');
			}
		}
		this.result = null;
		this.editing = false;
	};
	
	cancel = () => this.editing = false;
	
	render() {
		const { canWrite, canRead } = this.props;
		
		if (this.loading) return null;
		
		const stages = this.sessionQuestion && <div className="stages">
			{this.sessionQuestion.stages().map(stage => {
				const isVisible = stage.publish === 'all' || (isDeputy() && stage.publish === 'deputy');
				if (!isVisible) return null;
				const name = stage.customType || (stage.type ? stage.type.name : null);
				return <div key={stage.id} className="stage-results">
					{name && <div className="name">{name}</div>}
					<VotesFromView stage={stage}/>
				</div>;
			})}
		</div>;
		
		if (!canWrite) {
			return this.result ?
				<div className="result-container">
					<div className="public-result" style={{ backgroundColor: this.status ? this.status.color : '#eee' }}>
						<div className="res-text"
						     dangerouslySetInnerHTML={{ __html: this.text.replace(/\n/g, '<br/>') }}/>
						{this.date && <div className="res-date">{formatDateStr(this.date)}</div>}
						{canRead && <ul className="res-images">
							{this.files.map(file => <li key={file.id}>
								<a target="_blank"
								   href={file.downloadFile('filename')}>{cutExtension(file.filename)}</a>
							</li>)}
						</ul>}
					</div>
					{stages}
				</div>
				:
				<div className="result-container">
					<div className="public-result">
						<div className="res-text">Не рассматривается</div>
					</div>
				</div>
		}
		
		return <div className="result-container">
			<div className="public-result" style={{ backgroundColor: this.status ? this.status.color : '#eee' }}>
				{!this.editing ?
					(this.result ? <>
								<div className="res-text"
								     dangerouslySetInnerHTML={{ __html: this.text.replace(/\n/g, '<br/>') }}/>
								{/*<div className="res-text">{this.text.replace(/\n/g, '<br/>')}</div>*/}
								{this.date && <div className="res-date">{formatDateStr(this.date)}</div>}
								<ul className="res-images">
									{this.files.map(file => <li key={file.id}>
										<a target="_blank"
										   href={file.downloadFile('filename')}>{cutExtension(file.filename)}</a>
									</li>)}
								</ul>
								<div><a href="#" onClick={this.edit}>Редактировать</a></div>
							</>
							:
							<div><a href="#" onClick={this.edit}>Создать</a></div>
					)
					:
					<>
						<div className="res-text"><textarea value={this.text} onChange={this.onTextChange} rows={3}/>
						</div>
						<div className="res-status-date">
							<div className="res-status">
								<RecordSelect
									model={store.model.QuestionStatus}
									value={this.status}
									property="name"
									onChange={this.onStatusChange}
									filter={{ order: 'id asc' }}
									empty={<span className="empty">Статус</span>}
									className="drop-down-md"
									isRequired
									noSearch
									noTotal
								/>
							</div>
							<div className="res-date">
								<DatePicker value={this.date} placeholderText="Дата и время" showTimeSelect
								            onChange={this.onDateChange}/>
							</div>
						</div>
						<div className="res-image">
							{this.result && this.result.id ?
								<Images
									store={store.model}
									record={this.result}
									accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx"
									relation="images"
								/>
								:
								'Сохраните запись, чтобы прикрепить файлы'
							}
						</div>
						<div className="actions">
							<button className="white-btn" onClick={this.save}>Сохранить</button>
							<button className="white-btn" onClick={this.cancel}>Отмена</button>
							<FontAwesomeIcon icon={faTrash} className="delete-btn" onClick={this.delete}/>
						</div>
					</>
				}
			</div>
			{stages}
		</div>;
	}
	
}
