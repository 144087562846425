import React from 'react';
import moment from 'moment';
import { useGetRecords } from '../../utils/hooks';
import { Loader } from '@smartplatform/ui';

import WorkPlanDay from './WorkPlanDay';
import './styles.scss';

const WorkPlan = () => {
	const [workplan] = useGetRecords({ model: 'ChairpersonWorkplan', options: { include: ['day'] } });

	if (!workplan) {
		return <div className="container base-page"><Loader /></div>;
	}

	const plan = workplan[0];
	const { startDate, dueDate } = plan;
	const days = plan.day().slice(0, moment(dueDate).diff(startDate, 'days') + 1);
	const date = () => {
		const startMonth = moment(startDate).format('MMMM');
		const dueMonth = moment(dueDate).format('MMMM');
		return `с ${moment(startDate).format('D')} ${startMonth === dueMonth ? '' : startMonth} по ${moment(dueDate).format(
			'D MMMM YYYY'
		)} года`;
	};
	return (
		<div className="container base-page">
			<h1>Примерный план работы Председателя Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)</h1>
			<div className="row pb-5 pt-5">
				<div className="col-12">
					<p className="work-plan__header">{date()}</p>
					<table className="tabl-workplan">
						<tbody>
							<tr>
								<td>
									<p>
										<strong>Дата</strong>
									</p>
								</td>
								<td>
									<p>
										<strong>Время, место</strong>
									</p>
								</td>
								<td>
									<p>
										<strong>Мероприятие</strong>
									</p>
								</td>
								<td>
									<p>
										<strong>Ответственный</strong>
									</p>
								</td>
							</tr>
							{days.map(day => (
								<WorkPlanDay day={day} key={`workplan day-${day.id}`} />
							))}
						</tbody>
					</table>
					<p><br /></p>
					<div>Дата последнего изменения: {moment(plan.updatedAt).format('D MMMM YYYY, HH:mm')}</div>
				</div>
			</div>
		</div>
	);
};

export default WorkPlan;
