import React from 'react';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';

const tabl = [
    
    
	{
		name: 'Базовых',
		count: '0'
	},
	{
		name: 'Внесение изменений и дополнений',
		count: '0'
	},
	{
		name: 'О признании утратившими силу',
		count: '0'
	},
	{
		name: 'Базовых',
		count: '0'
	},
	{
		name: 'Внесение изменений и дополнений',
		count: '0'
	},
];


export default class WritItem extends React.Component {
	render() {
		return (
			<>
            <div className="col-12 col-lg-8 pt-5">
					<div className="name-block-plenary pb-5">Вопрос повестки</div>
                <div className="pb-4">
                <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
						<p><a>Повестка заседания</a></p>
		        </div>
                <table className="tabl-plenary">
                <thead>
                    <tr className="tabl-plenary-th-bold">
                        <th colSpan="3">Принято законов РС (Я) </th>
                        <th colSpan="2">Принято проектов законов РС (Я) в I, II чтениях</th>
                    </tr>
                    {/* <tr className="table-plenary-th-normal">
                        <th>Базовых </th>
                        <th>Внесение изменений и дополнений </th>
                        <th>О признании утратившими силу </th>
                        <th>Базовых </th>
                        <th>Внесение изменений и дополнений </th>
                    </tr> */}
                </thead>
                <tbody>
                    <tr className="table-plenary-tr__name">
                    {
                        tabl.map( function(item,index)
                            {
                                return(
                                    <td key={index}>{item.name}</td>
                                )
                            }
                        )
                    }
                    </tr>
                    <tr>
                    {
                        tabl.map( function(item,index)
                            {
                                return(
                                    <td data-label={item.name} key={index}>{item.count}</td>
                                )
                            }
                        )
                    }
                    </tr>
                </tbody>
            </table>
            </div>
		</>
		);
	}
}
