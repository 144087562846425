import React from 'react';
import { observable, computed, extendObservable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Select } from '@smartplatform/ui';
import { Button } from '../button';
import { getPlenaryQuestionData, getCommitteeQuestionData } from '../helpers';
import { Panel } from '../panels';
import Requests from '../panels/speech-requests';
import CreateQuestion from './CreateQuestion';
import store from '../../../store';
import { QuestionContent, QuestionStatus } from '../question-card';
import voting from '../store-site';
import './style.scss';

@observer
export default class AdminPanel extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		const className = classNames('current-question-panel');

		return <div className={className}>
			<div className="left">
				{voting.currentQuestion ?
					<>
						<QuestionStatus question={voting.currentQuestion} />
						<QuestionContent question={voting.currentQuestion} />
					</>
					:
					<CreateQuestion />}
			</div>
			<div className="right">
				{voting.currentQuestion ?
					<Panel question={voting.currentQuestion} />
					:
					<Requests />
				}
			</div>
		</div>;
	}
	
}
