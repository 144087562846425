import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faList, faFile, faImage } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '@smartplatform/ui';
import QuestionResult from './QuestionResult';
import DocumentHide from './DocumentHide';
import { formatTime, formatDateStr } from './index';
import { canCreateVotingForSession, createVotingForSession } from '../sessions';
import store from '../../store';
import ReactSVG from 'react-svg';
import 'react-image-lightbox/style.css';

@withRouter
@observer
export default class PlenaryQuestions extends React.Component {
	@observable session = null;
	@observable meeting = null;
	@observable isLoading = true;
	@observable error = null;
	@observable images = [];
	@observable index = 0;
	@observable canCreateVoting = false;
	@observable isCreating = false;
	@observable documentId = null;

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Пленарные заседания', link: `/sessions/plenary` }, { name: 'Повестка заседания' }]);
		this.init();
	}

	init = async () => {
		this.id = this.props.plenarySessionId || this.props.match.params.id;
		if (this.id) {
			try {
				this.session = await store.model.PlenarySession.findById(this.id);
				if (!this.session.meetingId) {
					this.error = 'Не найдена повестка заседания';
					return;
				}
				this.meeting = await store.model.Meeting.findById(this.session.meetingId, {
					include: [
						{
							relation: 'questions',
							scope: {
								order: 'sort asc',
								include: [
									{ relation: 'status' },
									{
										relation: 'documents',
										scope: {
											// field: { id: true, name: true },
											include: ['file', 'hide'],
											order: 'sort asc',
										},
									},
								],
							},
						},
					],
				});
				let { canCreate } = await canCreateVotingForSession(this.session);
				const existingSessions = await store.model.Session.find({
					where: { plenarySessionId: this.id },
				});
				if (existingSessions.length > 0) canCreate = false;
				this.canCreateVoting = canCreate;
			} catch (e) {
				console.error(e);
				this.error = e.message;
			}
		} else {
			this.error = 'Не задан id';
		}
		this.isLoading = false;
	};

	showImages = (e, files) => {
		this.documentId = files.length > 0 && files[0].documentId;
		console.log('showImages', files);
		this.images = files.map(file => 'https://epz.iltumen.ru/uploads/' + file.path);
	};

	hideImages = () => {
		this.images = [];
		this.index = 0;
		this.this.documentId = null;
	};

	toggleHide = (e, doc) => {
		const hide = e.target.checked;
		console.log('toggleHide', hide);
	};

	createVoting = async () => {
		this.isCreating = true;
		await createVotingForSession(this.session);
		this.props.onCreate && this.props.onCreate();
	};

	render() {
		const isDeputy = store.model.user && store.model.user.deputyId !== null;
		const canRead = store.model.QuestionResult.INFO.WRITE || isDeputy;
		const canWrite = store.model.QuestionResult.INFO.WRITE;
		const canHide = store.model.QuestionDocumentHide.INFO.WRITE;

		let content = null;
		if (this.isLoading) {
			return (
				<div className="session-questions">
					<Loader />
				</div>
			);
		}
		if (this.error) {
			return <div className="session-questions">{this.error}</div>;
		}
		return (
			<div className="session-questions">
				{this.meeting.startedAt && (
					<div className="date">
						<FontAwesomeIcon icon={faCalendarAlt} /> {formatTime(this.meeting.startedAt)}
					</div>
				)}
				<div className="name">{this.session.name}</div>
				{this.canCreateVoting && (
					<div style={{ marginBottom: 15 }}>
						<button className="red-btn" disabled={this.isCreating} onClick={this.createVoting}>
							Создать голосование
						</button>
					</div>
				)}
				<div className="questions">
					{this.meeting
						.questions()
						.filter(question => question.statusId !== 2)
						.map((question, i) => {
							const showDocuments = question.statusId === 1;
							const documents = question
								.documents()
								.filter(doc => doc.file().length > 0)
								.filter(doc => (canRead ? true : doc.hide().length === 0));
							return (
								<div className="question" key={question.id}>
									<div className="left">
										<div className="title">
											{i + 1} {question.title}
										</div>
										{question.description && (
											<div
												className="description"
												dangerouslySetInnerHTML={{ __html: question.description.replace(/[\r\n]+/g, '<br/>') }}
											/>
										)}
										{(showDocuments || canWrite) && (
											<ul className="files">
												{documents.map(doc => {
													return (
														<li className="file" key={doc.id}>
															<a
																href={`/questions/${this.documentId}/preview`}
																target="_blank"
																onClick={e => this.showImages(e, doc.file())}
															>
																{doc.name}
															</a>{' '}
															({doc.file().length} стр.)
															{canHide && <DocumentHide doc={doc} />}
														</li>
													);
												})}
											</ul>
										)}
									</div>
									<QuestionResult canWrite={canWrite} canRead={canRead} question={question} />
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}
