import store from '../../../store';
import { PLENARY, COMMITTEE, getSourceSessionParams, getQuestions } from '..';

export default async function createVotingForSession (source) {
	if (!source) {
		console.error('no session!', source);
		return;
	}
	const { type, foreignKey } = getSourceSessionParams(source);
	console.log({ type, foreignKey });
	
	const session = new store.model.Session();
	session.name = source.name;
	session.type = type;
	session[foreignKey] = source.id;
	
	try {
		await session.save();
		
		const questions = await getQuestions(session);
		console.log('questions', questions);
		
		{
			const question = new store.model.SessionQuestion();
			question.code = 'agenda';
			question.name = 'Утверждение повестки';
			question.sessionId = session.id;
			await question.save();
		}
		
		{
			const question = new store.model.SessionQuestion();
			question.code = 'procedural';
			question.name = 'Процедурный';
			question.sessionId = session.id;
			await question.save();
		}
		
		for (let i = 0; i < questions.length; i++) {
			const question = questions[i];
			const sessionQuestion = new store.model.SessionQuestion();
			sessionQuestion.name = question.name;
			sessionQuestion.code = 'question';
			sessionQuestion.sessionId = session.id;
			sessionQuestion.questionId = type === PLENARY ? question.id : null;
			sessionQuestion.documentId = type === COMMITTEE ? question.id : null;
			sessionQuestion.order = i + 1;
			sessionQuestion.sort = question.sort;
			await sessionQuestion.save();
		}

		session.initialized = true;
		await session.save();
	}
	catch(e) {
		console.error(e);
	}
};
