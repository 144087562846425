import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { groups, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT, LAW_Y, DECREE_Y } from './groups';
import Law from './Law';
import Passport from './Passport';
import Document from './Document';
import DecreePassport from './DecreePassport';
import store from '../../store';
import { formatDate } from './tools';
import './style.scss';
import { Button, Loader } from '@smartplatform/ui';

@observer
export default class Single extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable error = null;
	@observable isSyncing = false;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		store.ui.setBreadcrumbs([{ name: 'Нормативно правовые акты', link: '/documents' }]);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	@action init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;
		this.record = await store.model.Document.findById(this.id, {
			include: [
				{ relation: 'baseLaw' },
				{ relation: 'refLinks' },
				{
					relation: 'refFiles',
					scope: {
						include: ['docFile'],
					},
				},
			],
		});
		const userRoles =
			store.model.user && store.model.RoleMapping
				? await store.model.RoleMapping.find({
						where: { principalId: store.model.user.id },
				  })
				: null;
		this.checkUserRoleId = userRoles ? userRoles.find(role => role.roleId === 1) : null;

		store.ui.setBreadcrumbs([{ name: 'Нормативно правовые акты', link: '/documents' }, { name: groups[this.record.docGroup] }]);
		this.isLoading = false;
	};

	onSyncBtnClick = async () => {
		this.isSyncing = true;

		await store.model.Document.SYNC({ documentId: this.record.externalId });

		this.isSyncing = false;
	};

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		const dateStr = this.record.docGroup === LAW_PASSPORT || this.record.docGroup === DECREE_PASSPORT ? 'Зарегистрирован' : 'Принят';

		return (
			<div className="doc-page">
				<div className="doc-info">
					<div className="number">{this.record.freeNum}</div>
					{this.checkUserRoleId ? (
						<>
							{this.isSyncing && (
								<>
									<Loader />
								</>
							)}
							<Button className="red-btn" onClick={this.onSyncBtnClick} disabled={this.isSyncing}>
								Синхронизировать
							</Button>
						</>
					) : (
						''
					)}
					<div className="date">
						<FontAwesomeIcon icon={faCalendarAlt} /> {dateStr}: {formatDate(this.record.date)}
					</div>
				</div>
				{/* проп law чтобы отделить компонент постановлений от законов*/}
				{[LAW, LAW_Y].includes(this.record.docGroup) && <Law record={this.record} law />}
				{this.record.docGroup === LAW_PASSPORT && <Passport record={this.record} />}
				{[DECREE, DECREE_Y].includes(this.record.docGroup) && <Law record={this.record} />}
				{this.record.docGroup === DECREE_PASSPORT && <DecreePassport record={this.record} />}
				{!Object.keys(groups).includes(this.record.docGroup) && <Document record={this.record} />}
			</div>
		);
	}
}
