import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/Положение_о_Совете_ГС_ИТ_РСЯ_(ред_от_19_10_2018).doc';
import LastUpdated from '../../components/last-updated';


export default class RegulationsOnCouncil extends React.Component {
	
	accordions = [];
	state = { allClosed: true };
	
	accInstance = instance => {
		this.accordions.push(instance);
	};
	
	toggleAll = e => {
		e.preventDefault();
		if (this.state.allClosed) {
			this.accordions.forEach(accordion => accordion.open());
		}
		else {
			this.accordions.forEach(accordion => accordion.close());
		}
		this.setState({ allClosed: !this.state.allClosed });
	};
	
	render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pb_90 container_pt_40">
				<div className="row">
				<div className="col-12 pt-3 pb-5">
                <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                   Положение о Совете Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)
				</h2>
					<LastUpdated dateStr="23 декабря 2019" /><br /><br />
					
					<div className="pb-5" style={{textAlign : 'center'}}>
					<p>Утверждено постановлением Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) от 25 апреля 2003 года ГС № 128-III</p>
					</div>

                    <div className="pb-5" style={{textAlign : 'center'}}>
					<p><em>(в ред. постановлений Государственного Собрания (Ил Тумэн) РС(Я)</em></p>
					<p><em>от 19 февраля 2009 года ГС № 356-IV, от 27 ноября 2014 года ГС № 457-V,</em></p>
					<p><em>от 19 октября 2018 года ГС №37-VI)</em></p>
					</div>
					
					<p><a href="#" onClick={this.toggleAll}>{this.state.allClosed ? 'Развернуть все' : 'Свернуть все'}</a><br/><br/></p>

					<Accordion getInstance={this.accInstance} title="I. Статус Совета Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)">
                    <p></p>
                    <ol className="text__ol_strong">
							<li><p>Совет Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее – Совет Государственного Собрания) создается для предварительной подготовки и рассмотрения организационных вопросов деятельности Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) (далее – Государственное Собрание), оперативного рассмотрения вопросов, входящих в компетенцию Государственного Собрания, координации деятельности постоянных комитетов, комиссий Государственного Собрания, а также для рассмотрения проектов федеральных законов, законодательных инициатив и обращений законодательных (представительных) органов государственной власти субъектов Российской Федерации.</p></li>
							<li><p>Совет Государственного Собрания является постоянно действующим органом Государственного Собрания.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="II. Состав Совета Государственного Собрания">
                        <p>В состав Совета Государственного Собрания входят:</p>
                        <ol className="text__ol_strong ml-5">
							<li><p>Председатель Государственного Собрания;</p></li>
							<li><p>заместители Председателя Государственного Собрания;</p></li>
							<li><p><strong>утратил силу.</strong> - Постановление Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014 ГС № 457;</p></li>
							<li><p>председатели постоянных комитетов, комиссий Государственного Собрания;</p></li>
							<li><p>заместители председателей постоянных комитетов, комиссий Государственного Собрания;</p></li>
							<li><p>руководители (уполномоченные представители) депутатских групп и фракций Государственного Собрания.</p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="III. Заседания Совета Государственного Собрания">
                        <p></p>
                        <ol className="text__ol_strong">
							<li><p>Заседания Совета Государственного Собрания проводятся, как правило, не реже двух раз в месяц по инициативе Председателя Государственного Собрания.</p></li>
							<li><p>Заседания Совета Государственного Собрания являются открытыми. Совет Государственного Собрания может принять решение о проведении закрытого заседания.</p></li>
							<li><p>Заседание Совета Государственного Собрания правомочно, если на нем присутствует большинство от установленного числа членов Совета Государственного Собрания.</p></li>
							<li><p>Председательствует на заседаниях Совета Государственного Собрания Председатель Государственного Собрания.</p></li>
							<li><p>Председатель Государственного Собрания, заместители Председателя Государственного Собрания, председатели постоянных комитетов, комиссий Государственного Собрания, заместители председателей постоянных комитетов, комиссий Государственного Собрания, руководители (уполномоченные представители) депутатских групп и фракций Государственного Собрания участвуют в работе Совета Государственного Собрания с правом решающего голоса.</p>
							        <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014  ГС № 457-V)</em></p></li>
							<li><p>В случае отсутствия Председателя Государственного Собрания председательствует на заседании Совета Государственного Собрания один из его заместителей. В отсутствие руководителей депутатских групп и фракций Государственного Собрания по их поручению в заседании Совета Государственного Собрания принимают участие их уполномоченные представители.</p></li>
							<li><p>В заседаниях Совета Государственного Собрания вправе участвовать:</p></li>
                            <ol className="text__ol_strong ml-5">
                                    <li><p>полномочный представитель Главы Республики Саха (Якутия) и Правительства Республики Саха (Якутия) в Государственном Собрании;</p>
                                    <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014  ГС № 457-V)</em></p></li>
                                    <li><p>представители субъектов права законодательной инициативы, если на заседании Совета Государственного Собрания рассматривается вопрос о законопроектах, внесенных данными субъектами права законодательной инициативы;</p></li>
                                    <li><p>народные депутаты Республики Саха (Якутия).</p></li>
                            </ol>
                            <li><p>Протокол заседания Совета Государственного Собрания ведет аппарат Государственного Собрания.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014  ГС № 457-V)</em></p></li>
						</ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="IV. Полномочия Совета Государственного Собрания">
                        <p>Совет Государственного Собрания:</p>
                        <ol className="text__ol_strong ml-5">
                            <li><p>формирует проект примерной программы законопроектной работы Государственного Собрания на текущую сессию;</p></li>
                            <li><p>принимает решение о включении законопроекта в примерную программу законопроектной работы Государственного Собрания на текущую сессию;</p></li>
                            <li><p>в период между пленарными заседаниями координирует работу по подготовке постоянными комитетами, комиссиями Государственного Собрания вопросов, вносимых на пленарное заседание Государственного Собрания;</p></li>
                            <li><p>обсуждает степень подготовки вопросов, вносимых на пленарное заседание Государственного Собрания;</p></li>
                            <li><p>рассматривает проект повестки пленарного заседания Государственного Собрания;</p></li>
                            <li><p>формирует список лиц, приглашенных на пленарное заседание Государственного Собрания;</p></li>
                            <li><p>рекомендует Председателю Государственного Собрания проведение парламентских слушаний;</p></li>
                            <li><p>принимает решение о проведении «правительственного часа» в Государственном Собрании;</p></li>
                            <li><p>осуществляет контроль за своевременным рассмотрением и реализацией предложений и замечаний народных депутатов Республики Саха (Якутия), высказанных на пленарных заседаниях Государственного Собрания;</p></li>
                            <li><p>рассматривает вопросы межпарламентского сотрудничества Государственного Собрания;</p></li>
                            <li><p>принимает решение о направлении Главе Республики Саха (Якутия) представлений о награждении народных депутатов Республики Саха (Якутия) и работников аппарата Государственного Собрания государственными наградами Республики Саха (Якутия);</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014  ГС № 457-V)</em></p></li>
                            <li><p>рассматривает вопросы организационного, материально-технического и информационного обеспечения деятельности Государственного Собрания;</p></li>
                            <li><p>периодически заслушивает отчеты руководителя аппарата Государственного Собрания о работе аппарата Государственного Собрания;</p></li>
                            <li><p>рассматривает предложения по изменению структуры и штатного расписания аппарата Государственного Собрания;</p></li>
                            <li><p>рассматривает индивидуальные и коллективные обращения граждан, поступившие в Государственное Собрание, и принимает по ним соответствующие решения; формирует график приема граждан Председателем Государственного Собрания, заместителями Председателя Государственного Собрания, председателями и заместителями председателей постоянных комитетов, комиссий Государственного Собрания;</p></li>
                            <li><p>рассматривает иные вопросы, отнесенные к ведению Государственного Собрания.</p></li>
                        </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="V. Решения Совета Государственного Собрания">
                        <p></p>
                        <ol className="text__ol_strong">
                            <li><p>Решение Совета Государственного Собрания принимается большинством голосов от числа присутствующих на заседании членов Совета Государственного Собрания, обладающих правом решающего голоса.</p></li>
                            <li><p>Решения Совета Государственного Собрания оформляются в виде выписок из протокола заседания Совета Государственного Собрания, который подписывается председательствующим на заседании Совета Государственного Собрания.</p></li>
                            <li><p>Аппарат Государственного Собрания направляет решения Совета Государственного Собрания, документы, материалы и иную информацию народным депутатам Республики Саха (Якутия), в постоянные комитеты, комиссии Государственного Собрания, депутатские группы и фракции Государственного Собрания.</p>
                            <p><em>(в ред. постановления Государственного Собрания (Ил Тумэн) РС(Я) от 27.11.2014  ГС № 457-V)</em></p></li>
                        </ol>
                    </Accordion>
                     <Accordion getInstance={this.accInstance} title="VI. Рассмотрение проектов федеральных законов,  законодательных инициатив и обращений  законодательных (представительных) органов государственной власти субъектов Российской Федерации">
                    <p></p>
                        <ol className="text__ol_strong">
                            <li><p>Проекты федеральных законов рассматриваются в соответствии с Федеральным законом «Об общих принципах организации законодательных (представительных) и исполнительных органов государственной власти субъектов Российской Федерации», Регламентом Государственной Думы Федерального Собрания Российской Федерации и Регламентом Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) в сроки, установленные Государственной Думой Федерального Собрания Российской Федерации.</p></li>
                            <li><p>Для рассмотрения проектов федеральных законов в период между очередными пленарными заседаниями Государственного Собрания созывается расширенное заседание Совета Государственного Собрания, на которое приглашаются все народные депутаты Республики Саха (Якутия).</p></li>
                            <li><p>Решение о созыве расширенного заседания Совета Государственного Собрания по инициативе председателей (заместителей председателей) постоянных комитетов, комиссий Государственного Собрания принимает Председатель Государственного Собрания, а в его отсутствие – один из заместителей Председателя Государственного Собрания.</p></li>
                            <li><p>Решение по проекту федерального закона принимается большинством голосов от числа избранных народных депутатов Республики Саха (Якутия) и оформляется постановлением Государственного Собрания.
                                    В случае невозможности присутствовать на расширенном заседании Совета Государственного Собрания народный депутат Республики Саха (Якутия) имеет право проголосовать путем использования электронных средств связи (электронной почты или факса).</p></li>
                            <li><p>На расширенном заседании Совета Государственного Собрания могут приниматься решения о поддержке законодательных инициатив и обращений законодательных (представительных) органов государственной власти субъектов Российской Федерации.
                                        Указанные решения принимаются большинством голосов от числа избранных народных депутатов Республики Саха (Якутия) и оформляются постановлением Государственного Собрания.</p></li>
                            </ol>
                    </Accordion>

                    </div>
					</div>


                    <div className="row pt-3 pb-5 pl-3">
                    <div className="col-12 ">
                        <div className="pt-3 pb-5">
                        <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
                        <p><a href={document}>Положение о Совете Государственного Собрания (Ил Тумэн) (редакция от 19.10.2018) </a> (MS Word, 49 Кб)</p></div>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}
