import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { AppContainer } from 'react-hot-loader';
import 'isomorphic-fetch';

import store from './store';
import App from './App';

const locale = window.navigator.userLanguage || window.navigator.language;
// moment.locale(locale);
moment.locale('ru');
// ReactDOM.render(<App/>, document.getElementById('root'));

const render = Component => {
	ReactDOM.render(
		<AppContainer warnings={false}>
			<Component />
		</AppContainer>,
		document.getElementById('root')
	);
};

const getCurrentConvocation = async () => {
	const convs = await store.model.Convocation.find({ where: { current: true } });
	store.currentConvocation = convs.length > 0 ? convs[0] : null;
	if (!store.currentConvocation) {
		const convs = await store.model.Convocation.find({ order: 'id desc' });
		store.currentConvocation = convs.length > 0 ? convs[0] : null;
	}
	if (!store.currentConvocation) {
		console.error('Не найден текущий созыв!');
	}
};

const init = async () => {
	await store.init();
	await getCurrentConvocation();
	render(App);
};

init();

if (module.hot) {
	module.hot.accept();
/*
	module.hot.accept('./App.js', () => {
		const NextRootContainer = require('./App.js').default;
		render(<NextRootContainer />, document.getElementById('root'));
	})
*/

/*
	let lastHash;
	
	const upToDate = function upToDate() {
		return lastHash.indexOf(__webpack_hash__) >= 0;
	};
	
	const clientEmitter = require('webpack/hot/emitter');
	
	clientEmitter.on('webpackHotUpdate', function(currentHash) {
		lastHash = currentHash;
		if (upToDate()) return;
		store.fireHMR();
	})
*/
}
