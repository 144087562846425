import React from 'react';

import store from 'root/site/store';
import { MainPageList } from '../../components';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const TYPE = 'article';

@observer
class News extends React.Component {
	@observable tags = store.ui.tags.filter(tag => tag.type === TYPE) || [];
	@observable records;
	@observable currentTag = 'all';
	@observable newsOfDay = null;

	componentDidMount() {
		this.init();
	}

	articleOptions = {
		where: {
			and: [{ publish: true }, { publishAt: { lte: Date.now() } }, { or: [{ removeAt: null }, { removeAt: { gt: Date.now() } }] }],
		},
		order: 'publishAt DESC',
		include: [
			{ relation: 'views', scope: { fields: ['id', 'count'] } },
			{ relation: 'tags', scope: { fields: ['id', 'image'] } },
		],
		fields: ['id', 'publishAt', 'name', 'video'],
		limit: 10,
	};

	init = async () => {
		let newsOfDay = await store.model.Article.find({
			...this.articleOptions,
			where: { ...this.articleOptions.where, newsOfDay: true },
			limit: 1,
		});
		this.newsOfDay = newsOfDay.length > 0 ? newsOfDay[0] : null;

		const news = await store.model.Article.find({
			...this.articleOptions,
			where: {
				...this.articleOptions.where,
				type: 'news',
				id: this.newsOfDay ? { nin: [this.newsOfDay.id] } : undefined,
			},
		});

		const photos = await store.model.Gallery.find({
			...this.articleOptions,
			where: {
				...this.articleOptions.where,
			},
		});

		const videos = await store.model.Media.find({
			...this.articleOptions,
			where: {
				...this.articleOptions.where,
			},
		});

		this.records = [].concat(news.slice(), photos.slice(), videos.slice()).sort((a, b) => {
			const aDate = new Date(a.publishAt);
			const bDate = new Date(b.publishAt);
			return bDate - aDate;
		});
	};

	@action onTagSwitch = async tag => {
		if (tag === 'all') {
			await this.init();
			this.currentTag = tag;
			return;
		}
		const records = await this.tags.filter(e => e.id === +tag)[0].articles.find({ ...this.articleOptions });
		this.records = records;
		this.currentTag = tag;
	};

	handleAnchorLinkClick = (e) => {
		e.preventDefault();
		const targetId = e.target.getAttribute("href");
		const target = document.querySelector(targetId);
		target.scrollIntoView({
			behavior: "smooth",
			block:    "start" 
		});
	}

	render() {
		const button = (
			<button className="btn" onClick={() => store.history.push('/news/')}>
				Все новости
			</button>
		);
		let records = this.records;
		if (this.currentTag === 'all' && this.records && this.newsOfDay) {
			records = [this.newsOfDay, ...this.records];
		}

		return (
			<>
				<div className="container mt-5">
					<div className="section">
						<div className="section__heading">
							<Link to="/news/">
								<h2 className="header-2">Новости</h2>
							</Link>
							<div className="d-flex" id='mobile-hide'>
								<a href="#gallery" onClick={this.handleAnchorLinkClick} className="header-2 ml-3 mr-3">
									Фотогалерея
								</a>
								<a href="#video" onClick={this.handleAnchorLinkClick} className="header-2 ml-3 mr-3">
									Видео
								</a>
								<a href="#directSpeech" onClick={this.handleAnchorLinkClick} className="header-2 ml-3 mr-3">
									Прямая речь
								</a>
							</div>
							<div className="section__btn">{button}</div>
						</div>
					</div>
				</div>
				<MainPageList tag={this.currentTag} tags={this.tags} records={records} type="news" onTagSwitch={tag => this.onTagSwitch(tag)} />
			</>
		);
	}
}

export default News;
