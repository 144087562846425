import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader} from '@smartplatform/ui';
import store from '../../store';
import LastUpdated from '../../components/last-updated';

@observer
export default class List extends React.Component {

	@observable isLoading = true;
	@observable records = [];
	@observable page = 1;

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([
			{ name: 'Итоги деятельности', link: `/itogi` },
		]);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.records = await store.model.ItogiPeriod.find({
			order: 'createdAt desc, id asc',
		});
		this.isLoading = false;
	};

	render() {
		const content = this.records.length === 0 && this.isLoading ?
			<Loader />
			:
			<ul>
				{this.records.map(itog => <li key={itog.id}><Link to={`/itogi/${itog.id}`}>{itog.title}</Link></li>)}
			</ul>;

		return <div className="itogi-list">
			<LastUpdated dateStr="12 сентября 2019" className="mb-5" />

			{content}
		</div>;
	}

}
