import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { TabMenu, MenuItem } from '@smartplatform/ui';
import RegLog from './RegLog';
import VotesLog from './VotesLog';

const REG_TAB = 'reg';
const VOTES_TAB = 'votes';

@observer
export default class Log extends React.Component {
	
	@observable tab = REG_TAB;
	
	switchTab = tab => this.tab = tab;
	
	render() {
		return <div className="session-log">
			<FontAwesomeIcon icon={faTimes} className="close-btn" onClick={this.props.onClose} />
			<TabMenu>
				<MenuItem label="Регистрации" active={this.tab === REG_TAB} onClick={() => this.switchTab(REG_TAB)} />
				<MenuItem label="Голоса" active={this.tab === VOTES_TAB} onClick={() => this.switchTab(VOTES_TAB)} />
			</TabMenu>

			{this.tab === REG_TAB && <RegLog />}
			{this.tab === VOTES_TAB && <VotesLog />}
		</div>;
	}
	
}
