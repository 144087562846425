export const menuItems = [
	{
		title: 'Информация',
		button: {
			title: 'Интернет-приемная',
			link: '/reception',
		},
		items: [
			// {
			// 	name: 'Исторический обзор',
			// 	link: '/historical-review',
			// },
			// {
			// 	name: 'Полномочия Государственного Собрания',
			// 	link: '/credentials',
			// },
			// {
			// 	name: 'Статус',
			// 	link: '/status',
			// },
			// {
			// 	name: 'Регламент',
			// 	link: '/state-assembly-regulations',
			// },
			// {
			// 	name: 'Положения',
			// 	link: '/position',
			// },
			// {
			// 	name: 'Конституция РС (Я)',
			// 	link: '/constitution',
			// },
			// {
			// 	name: 'Официальная символика',
			// 	link: '/officialsymbols',
			// },
			// {
			// 	name: 'Награды',
			// 	link: '/awards',
			// },
			// {
			// 	name: 'Открытые данные',
			// 	link: '/opendata',
			// },
			// {
			// 	name: 'Корпоративный портал',
			// 	link: 'http://portal.iltumen.ru/',
			// 	external: true,
			// },
			// {
			// 	name: 'Виртуальная экскурсия',
			// 	link: 'http://old.iltumen.ru/tour/iltumen/',
			// 	external: true,
			// },
		// 	{
		// 		name: 'Контакты',
		// 		link: '/contacts',
		// 	},
		],
	},
	{
		title: 'Структура',
		button: {
			title: 'Запись на личный прием',
			link: '/appointments',
		},
		items: [
			// {
			// 	name: 'Председатель',
			// 	link: '/speaker',
			// },
			// {
			// 	name: 'Руководство',
			// 	link: '/governance',
			// },
			// {
			// 	name: 'Депутаты',
			// 	link: '/deputies',
			// },
			// {
			// 	name: 'Комитеты и комиссии',
			// 	link: '/committees',
			// },
			// {
			// 	name: 'Депутатские объединения',
			// 	link: '/fractions',
			// },
			// {
			// 	name: 'Совещательные органы при Государственном Собрании',
			// 	link: '/deliberative',
			// },
			// {
			// 	name: 'Представители РС (Я) в ФС РФ',
			// 	link: '/representatives',
			// },
			// {
			// 	name: 'Аппарат',
			// 	link: '/apparatus',
			// },
		],
	},
	{
		title: 'Деятельность',
		button: {
			title: 'Поиск своего депутата',
			link: '/deputy-search',
		},
		items: [
// 			{
// 				name: 'Заседания',
// 				link: '/sessions',
// 			},
// 			{
// 				name: 'Нормативные правовые акты',
// 				link: '/documents',
// 			},
// 			/*
// 			{
// 				name: 'Законы и постановления',
// 				link: '/documents/laws',
// 			},
// */
// 			{
// 				name: 'Статистика законотворческой деятельности',
// 				link: '/statistics',
// 			},
// 			{
// 				name: 'План работы Председателя',
// 				link: '/speaker-plan',
// 			},
// 			{
// 				name: 'Планы деятельности',
// 				link: '/plans',
// 			},
// 			{
// 				name: 'Итоги деятельности',
// 				link: '/itogi-deyatelnosti',
// 			},
// 			{
// 				name: 'Информационно-аналитическая деятельность',
// 				link: '/analytics',
// 			},
// 			{
// 				name: 'Представительские функции',
// 				link: '/representative-functions',
// 			},
// 			{
// 				name: 'Противодействие коррупции',
// 				link: '/anti-corruption',
// 			},
// 			{
// 				name: 'Использование бюджетных средств',
// 				link: '/budget-resources',
// 			},
// 			{
// 				name: 'Кадровое обеспечение',
// 				link: '/staffing',
// 			},
		],
	},
	{
		title: 'События',
		button: {
			title: 'Общественные обсуждения',
			link: '/discussions',
		},
		items: [
// 			{
// 				name: 'Новости',
// 				link: '/news',
// 			},
// 			{
// 				name: 'Прямая речь',
// 				link: '/direct-speech',
// 			},
// 			{
// 				name: 'Фоторепортажи',
// 				link: '/photogallery',
// 			},
// 			{
// 				name: 'Видеоматериалы',
// 				link: '/videogallery',
// 			},
// 			{
// 				name: 'Прямая трансляция',
// 				link: '/online',
// 			},
// 			{
// 				name: 'Аккредитация СМИ',
// 				link: '/media-accreditation',
// 			},
// 			{
// 				name: 'Государственный бюджет РС(Я)',
// 				link: '/budget',
// 			},
// 			/*
// 			{
// 				name: 'II Съезд депутатов МСУ',
// 				link: '/congress-msu',
// 				style: {
// 					color: '#f35e5e',
// 				},
// 			},
// */
// 			{
// 				name: 'COVID-19',
// 				link: '/covid-19',
// 				style: {
// 					color: '#f35e5e',
// 				},
// 			},
// 			{
// 				name: 'Музеи истории государственности',
// 				link: '/museum/1',
// 				style: {
// 					color: '#f35e5e',
// 				},
// 			},
		],
	},
];
