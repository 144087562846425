import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faWindowMaximize, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import throttle from 'lodash/throttle';

import voting from '../store-site';
import './style.scss';

const EPZ_URL = 'https://epz.iltumen.ru/uploads/';

@observer
export default class FilePages extends React.Component {

	@observable height = 0;
	@observable maximized = false;
	
	constructor(props) {
		super(props);
		this.calcHeight = throttle(this.calcHeight, 500, { leading: true, trailing: true });
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
		window.addEventListener('resize', this.calcHeight);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
		window.removeEventListener('resize', this.calcHeight);
	}
	
	onMount = el => {
		this.el = el;
		this.calcHeight();
	};

	calcHeight = () => {
		if (this.el) {
			const rect = this.el.getBoundingClientRect();
			this.height = window.innerHeight - rect.top - 40;
			console.log('this.height', this.height);
		}
	};
	
	onMouseDown = e => {
		if (this.el && !this.el.contains(e.target)) {
			this.close();
		}
	};
	
	maximize = () => {
		this.maximized = !this.maximized;
		setTimeout(() => this.calcHeight(), 0);
	};
	
	close = () => voting.currentFile = null;
	
	render() {
		if (!voting.currentFile) return null;
		
		return <div className={'file-pages' + (this.maximized ? ' maximized' : '')} ref={this.onMount}>
			<div className="file-info">
				<div className="name">{voting.currentFile.name}</div>
				<span className="maximize-btn" onClick={this.maximize}><FontAwesomeIcon icon={this.maximized ? faWindowMinimize : faWindowMaximize} /></span>
				<span className="close-btn" onClick={this.close}><FontAwesomeIcon icon={faTimes} /></span>
			</div>
			<div className="pages-frame" style={{ height: this.height }}>
				{voting.currentFile.pages.map((page, i) => <div key={i}>
					<img src={EPZ_URL + page} alt="" />
				</div>)}
			</div>
		</div>;
	}
	
}
