import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faList, faFile, faImage, faVideo } from '@fortawesome/free-solid-svg-icons';

import { formatDateStr } from './index';

@observer
export default class SessionCard extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		const { session, type } = this.props;
		
		return <div className="session-card" key={session.id}>
			<div className="left">
				<div className="thumbnail">
					<Link to={`/sessions/${type}/${session.id}`}>
						{session.thumbnail ? <img src={session.downloadFile('thumbnail')} /> : <span className="empty"/>}
					</Link>
				</div>
				<div className="info">
					<div className="date"><FontAwesomeIcon icon={faCalendarAlt} /> {formatDateStr(session.date, 'dd.MM.yyyy')}</div>
					<div className="name"><Link to={`/sessions/${type}/${session.id}`}>{session.name}</Link></div>
				</div>
			</div>
			<div className="right">
				{!!session.meetingId && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faList} /></span>
					<Link to={`/sessions/${type}/${session.id}/questions`}>Повестка заседания</Link>
				</div>}
				{session.protocol && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faFile} /></span>
					<a href={session.downloadFile('protocol')}>Протокол</a>
				</div>}
				{session.newsletter && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faFile} /></span>
					<a href={session.downloadFile('newsletter')}>Информационный бюллетень</a>
				</div>}
				{session.transcript &&
					<div className="session-link">
						<span className="icon"><FontAwesomeIcon icon={faFile} /></span>
						{!session.transcript2 ?
							<a href={session.downloadFile('transcript')}>Стенограмма</a>
							:
							<>
								Стенограмма <a href={session.downloadFile('transcript')}>1 день</a>,&nbsp;
								<a href={session.downloadFile('transcript2')}>2 день</a>
							</>
						}
					</div>
				}
				{session.galleryId && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faImage} /></span>
					<span className="links">
						<Link to={`/sessions/${type}/${session.id}/gallery`}>Фоторепортаж</Link>
						{/*<Link to={`/photogallery/${session.galleryId}`}>Фоторепортаж</Link>*/}
					</span>
				</div>}
				
				{session.videos && session.videos.length === 1 && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faVideo} /></span>
					<a target="_blank" href={session.videos[0]}>Видеозапись</a>
				</div>}

				{session.videos && session.videos.length > 1 && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faVideo} /></span>
					<span className="links">
						Видеозаписи: {session.videos.map((url, i) => <span key={i}>
							<a target="_blank" href={url}>видео {i + 1}</a>
							<>{i < session.videos.length - 1 ? ', ' : ''}</>
						</span>)}
					</span>
				</div>}
				{type === 'plenary' && session.date && <div className="session-link">
					<span className="icon"><FontAwesomeIcon icon={faList} /></span>
					<a href={`/documents?date0=${moment(session.date).format('DD.MM.YYYY')}`}>Принятые нормативные правовые акты</a>
				</div>}
			</div>
		</div>;
	}
	
}
