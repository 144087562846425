import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PollChart from './PollChart';

@observer
export default class PollResults extends React.Component {
	@observable fields;
	@observable data;

	constructor(props) {
		super(props);
		this.poll = props.poll;
		this.fields = this.poll.fields();
		this.init();
	}

	init() {
		this.formatData();
	}

	formatData = () => {
		let total = 0;
		this.fields.forEach(field => (total += field.votes().length));
		const data = [];
		this.fields.forEach(field => {
			const name = field.user ? 'Свой вариант' : field.name;
			const value = field.votes().length;
			const persent = ((value * 100) / total).toFixed(1);
			const id = `${name} (${persent} %)`;
			value && data.push({ label: name, id, value });
		});
		this.data = data;
	};

	render() {
		return (
			<div>
				<h3>Результаты голосования</h3>
				<p>{this.poll.name}</p>
				<div className="poll-chart">{this.data && <PollChart data={this.data} />}</div>
			</div>
		);
	}
}
