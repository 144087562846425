import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Slider } from '@smartplatform/ui';
import store from '../../store';

@observer
export default class NewsPhotoSlider extends React.Component {
	@observable article = null;
	@observable images = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.article = await store.model.Article.findById(this.props.articleId, {
			fields: ['id', 'imported'],
		});
		this.images = await this.article.images();
	};

	render() {
		if (!this.article) return null;

		const images = this.images.map(image => {
			const url = this.article.imported
				? '/api/containers/articles/download/' + image.filename
				: image.downloadFile('filename');
			return (
				<div className="image" key={image.id} style={{ cursor: 'zoom-in' }}>
					<img src={url} alt="" onClick={() => store.ui.setPreviewImgSrc(url)} />
				</div>
			);
		});

		return (
			<div className="base-page news-photo-slider">
				{images.length > 0 && (
					<Slider height={480} transition={250} dots={true}>
						{images}
					</Slider>
				)}
			</div>
		);
	}
}
