import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
// import format from 'date-fns/format';
import axios from 'axios';

import { HtmlValue } from '@smartplatform/ui';
import { groups, LAW } from '../documents/groups';
import Passport from '../documents/Passport';
import store from '../../store';
import { cutEmptyParagraphs } from '../../utils';
import Poll from './Poll';
import PollResults from './PollResults';
import Comments from './Comments';

// const formatDate = dateStr => format(new Date(dateStr), 'dd.MM.yyyy HH:mm');

@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable error = null;
	@observable group = LAW;
	@observable commentError = '';
	@observable done = false;
	@observable valid = false;
	@observable user;
	@observable userIP;
	@observable poll;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		this.error = null;
		if (this.id) {
			try {
				this.record = await store.model.Discussion.findById(this.id, {
					include: [
						{
							relation: 'document',
						},
						{
							relation: 'comments',
							scope: {
								where: { publish: true },
								order: 'id desc',
							},
						},
						{
							relation: 'attachments',
							scope: {
								where: { fromEditor: null },
							},
						},
					],
				});
				this.group = this.record.docGroup;
				this.user = store.model.user;
				const ip = await axios.get('https://api.ipify.org');
				this.userIP = ip.data;
				const poll =
					this.record.poll &&
					(await store.model.Poll.find({
						where: { discussionId: this.id },
						include: [
							{
								relation: 'fields',
								scope: {
									include: {
										relation: 'votes',
									},
								},
							},
						],
					}));
				this.poll = poll && poll[0];
				this.pollFields = poll && poll[0].fields();
				this.user && (this.valid = true);
				store.ui.setBreadcrumbs([
					{ name: 'Обсуждение', link: '/discussions' },
					{ name: this.record.name, link: `/discussions/${this.props.match.params.id}` },
				]);
			} catch (e) {
				this.error = e.message;
			}
		}
	};

	onGroupChange = group => {
		console.log('onGroupChange', group);
		this.group = group;
	};

	docGroup = document => groups[document.docGroup];

	back = e => {
		e.preventDefault();
		this.props.history.push('/discussions');
	};

	onCaptchaChange = res => (this.valid = res.success);
	onCaptchaExpired = () => (this.valid = false);

	@action
	onVote = async () => {
		console.log('onVote');
		await this.init();
	};

	render() {
		if ((!this.record || !this.userIP) && !this.error) return '...';
		if (this.error) return this.error;

		const { text, document } = this.record;
		const recordEndDate = new Date(this.record.endDate) > Date.now();

		const renderPoll = () => {
			if (!this.poll) {
				return;
			}
			console.log('----- render poll', this.poll);

			const voted = this.poll
				.fields()
				.map(field => field.votes().find(v => v.ip === this.userIP))
				.filter(v => v);
			console.log('voted', voted);

			if (this.poll && recordEndDate) {
				return <Poll poll={this.poll} user={this.user} discussionId={this.id} onVote={this.onVote} />;
			}
			return <PollResults poll={this.poll} />;
		};

		return (
			<div className="container base-page discussion">
				<h3>{this.record.name}</h3>
				{text && <HtmlValue className="text-news" value={cutEmptyParagraphs(text)} />}
				{this.record.attachments().length > 0 ? (
					<div>
						<h4>Прикрепленные файлы:</h4>
						<ul>
							{this.record.attachments().map(attach => {
								return (
									<li key={attach.id}>
										<a
											style={{ textDecoration: 'underline' }}
											href={`/api/containers/attachments/download/${attach.id}-filename`}
										>
											{attach.name ? attach.name : attach.filename.slice(0, -4)}
										</a>
										<br />
									</li>
								);
							})}
						</ul>
					</div>
				) : (
					<div />
				)}
				<div className="type">
					{this.record.document && (
						<>
							{this.docGroup(this.record.document)} {this.record.document.freeNum}
						</>
					)}
				</div>
				{document && <Passport record={this.record.document} />}
				{renderPoll()}

				<Comments user={this.user} discussionId={this.record.id} isEnded={recordEndDate} />
			</div>
		);
	}
}
