import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import { Button } from '../button';
import store from '../../../store';
import voting from '../store-site';
import './style.scss';

@observer
export default class AdminPanel extends React.Component {
	
	@observable stages = [];

	@observable selectedQuestion = null;
	@observable selectedStage = null;
	@observable questionName = '';
	@observable customType = '';
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.stages = await store.model.QuestionStage.find({
			order: 'id asc'
		});
	};
	
	onStageChange = stage => {
		this.selectedStage = stage;
	};
	
	onNameMount = el => {
		if (el) el.focus();
	};
	
	onCustomTypeChange = e => this.customType = e.target.value;
	
	onQuestionChange = question => {
		if (question !== 'new') {
			this.selectedQuestion = question;
		}
		else {
			const newQuestion = new store.model.SessionQuestion();
			newQuestion.sessionId = voting.session.id;
			console.log('newQuestion', newQuestion);
			this.selectedQuestion = newQuestion;
			if (this.nameInput) this.nameInput.focus();
		}
	};
	
	onQuestionNameChange = e => this.questionName = e.target.value;
	
	renderQuestionName = question => question.order ?
		<span title={question.name}>Вопрос №{question.order}: <em>{question.name}</em></span>
		:
		<span title={question.name}>Процедурный: <em>{question.name || `#${question.id}`}</em></span>;
	
	startDiscussion = async () => {
		if (!this.selectedQuestion.id) {
			this.selectedQuestion.name = this.questionName || null;
			await this.selectedQuestion.save();
		}
		const questionStage = new store.model.SessionQuestionStage();
		questionStage.questionId = this.selectedQuestion.id;
		questionStage.sessionId = this.selectedQuestion.sessionId;
		questionStage.type = this.selectedStage === 'new' ? null : this.selectedStage;
		questionStage.customType = this.customType;
		questionStage.isSecret = this.isSecret;
		questionStage.discussionStart = new Date();
		await questionStage.save();
		voting.session.questionId = this.selectedQuestion.id;
		await voting.session.save();
		voting.update();
		
		this.selectedQuestion = null;
		this.selectedStage = null;
		this.questionName = '';
		this.customType = '';
		this.isSecret = false;
	};
	
	render() {
		const questions = voting.questions.map(question => {
			const label = question.questionId || question.documentId ?
				this.renderQuestionName(question)
				:
				'Процедурный: ' + (question.name || `#${question.id}`);
			return { label, value: question };
		});
		
		questions.splice(0, 0, { label: 'Процедурный (новый)', value: 'new' });
		
		let canStart = true;
		if (!this.selectedQuestion) canStart = false;
		if (!this.selectedStage) canStart = false;
		if (this.selectedStage === 'new' && this.customType.trim().length === 0) canStart = false;
		
		const stages = this.stages.map(stage => ({ label: stage.name, value: stage }));
		stages.push({ label: 'другое', value: 'new' });
		
		return <div className="question-selection">
			<div className="block">
				<div className="selection">
					<label>Следующий вопрос</label>
					<Select
						items={questions}
						value={this.selectedQuestion}
						showValue={this.selectedQuestion ? this.selectedQuestion.id ? this.renderQuestionName(this.selectedQuestion) : 'Процедурный' : '-'}
						onChange={this.onQuestionChange}
						className="drop-down-md question-select"
						isRequired
						noSearch
					/>
				</div>
				{this.selectedQuestion && !this.selectedQuestion.id && <div className="selection">
				{/*{true && <div className="selection">*/}
					<label>Название вопроса</label>
					<input type="text" className="question-name" ref={this.onNameMount} value={this.questionName} onChange={this.onQuestionNameChange} />
				</div>}
			</div>
			<div className="block">
				<div className="selection">
					<label>Предмет голосования</label>
					<Select
						items={stages}
						value={this.selectedStage}
						onChange={this.onStageChange}
						className="drop-down-md stage-select"
						position="bottom"
						listMaxHeight={380}
						isRequired
						noSearch
					/>
				</div>
				{this.selectedStage === 'new' && <div className="selection">
					<label>Название</label>
					<input type="text" className="custom-type" ref={this.onNameMount} value={this.customType} onChange={this.onCustomTypeChange} />
				</div>}
			</div>
			<div>
				<Button onClick={this.startDiscussion} variant="primary" disabled={!canStart}>Перейти к обсуждению</Button>
			</div>
		</div>;
	}
	
}
