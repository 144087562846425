import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatTime, formatDateStr } from '../../utils/dateUtils';
import store from '../../store';

@observer
export default class Committees extends React.Component {

	@observable isLoading = true;
	@observable activeIds = [];
	@observable myCommittees = [];
	@observable committees = [];
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const activeSessions = await store.model.Session.find({
			where: {
				and: [
					// { registrationStart: { neq: null }},
					{ committeeSessionId: { neq: null }},
					{ end: null },
				]
			},
			fields: ['id', 'name', 'registrationStart', 'end', 'committeeSessionId']
		});
		
		const roleNames = await store.model.user.getRoleNames();
		const isAdmin = roleNames.includes('admin');
		this.activeIds = activeSessions.map(session => session.committeeSessionId);
		
		const depCom = !isAdmin ? await store.model.DeputyCommittee.find({
			where: { deputyId: this.props.user.deputyId },
			include: [{
				relation: 'committee',
				scope: { fields: ['id', 'docId', 'name']}
			}]
		}) : [];
		
		const committees = isAdmin ?
			await store.model.Committee.find({ fields: ['id', 'name', 'docId']})
			:
			depCom.map(dc => dc.committee);
		
		console.log('committees', committees);
		
		const allSessions = await store.model.Document.find({
			where: {
				or: [
					{ docGroup: { inq: committees.map(committee => `0.2F161.2F16J.${committee.docId}`) }},
					!isAdmin ? { id: { inq: this.activeIds }} : {}
				]
			},
			fields: ['id', 'name', 'date', 'docGroup'],
			order: 'date desc',
		});
		
		const coms = {};
		
		committees.forEach(committee => {
			if (!coms[committee.docId]) coms[committee.docId] = { committee, sessions: [] };
		});
		
		for (let session of allSessions) {
			const committeeId = session.docGroup.replace(/^0\.2F161\.2F16J\./, '');
			if (this.activeIds.includes(session.id) && (!coms[committeeId] || coms[committeeId].committee === null)) {
				if (!coms[committeeId]) {
					coms[committeeId] = { committee: null, sessions: [ session ] };
				}
				else {
					coms[committeeId].sessions.push(session);
				}
				console.log('active!', session.id);
			}
			else {
				coms[committeeId].sessions.push(session);
			}
		}
		
		this.myCommittees = Object.values(coms).sort((a, b) => !a.committee ? -1 : 1);
		this.isLoading = false;
	};
	
	render() {
		let content = '...';
		
		if (!this.isLoading) {
			content = <>
				<div className="committee-list sessions-list">
					{this.myCommittees.map(({committee, sessions}, i) => <div key={committee ? committee.id : 'com-' + i}>
						{committee && <strong>{committee.name}</strong>}
						<div>
							{sessions.length > 0 ?
								sessions.slice(0, 3).map(document => <div key={document.id} className="meeting">
									<small>{formatDateStr(document.date)}</small>
									<Link to={`/committee-session/${document.id}`}>{document.name}</Link>
									{this.activeIds.includes(document.id) && <span className="active-session">Идет заседание</span>}
								</div>)
								:
								'Заседаний нет.'
							}
						</div>
						{/*{committee && <div><br /></div>}*/}
					</div>)}
				</div>
			</>
		}
		
		return <div className="base-page profile-committee">
			{/*<p><strong>Заседания комитетов</strong></p>*/}
			{content}
		</div>;
	}
	
}
