import React from 'react';
import PropTypes from 'prop-types';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import cloneDeepWith from 'lodash/cloneDeepWith';
import isElement from 'lodash/isElement';

import { groupIds, groups } from './groups';
import store from '../../store';

const CONVOCATIONS = {
	13: 'VII',
	6: 'VI',
};

function customizer(value) {
	if (isElement(value)) {
		return value.cloneNode(true);
	}
}

@observer
export default class StatTable extends React.Component {
	@observable groups = {};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { where, group } = this.props;
		const groups = {};

		for (const groupName in groupIds) {
			if (groupName !== group) continue; // TODO: выключить, если понадобится поиск по всем группам
			groups[groupName] = {};
			for (const conv in CONVOCATIONS) {
				const _where = cloneDeepWith(toJS(where), customizer);
				if (_where && _where.and) {
					// let like = `%-${conv}`;
					// if (groupName === 'LAW_PASSPORT' || groupName === 'DECREE_PASSPORT') like += '-%';
					_where.and.push({ docGroup: groupIds[groupName] });
					// _where.and.push({ freeNum: { like }});
					_where.and.push({ convocationId: conv });
				}
				groups[groupName][conv] = await store.model.Document.count(_where);
				// console.log('>', groupName, conv, groups[groupName][conv]);
			}
		}
		this.groups = groups;
	};

	render() {
		const type = groups[groupIds[this.props.group]];

		return (
			<div className="stat-table">
				<table>
					<thead>
						<tr>
							<th>Вид документа</th>
							{Object.values(CONVOCATIONS).map(conv => (
								<th key={conv}>{conv} созыв</th>
							))}
						</tr>
					</thead>
					<tbody>
						<tr>
							<td key="type">{type}</td>
							{Object.keys(CONVOCATIONS).map(conv => (
								<td className="count" key={conv}>
									{this.groups[this.props.group] ? this.groups[this.props.group][conv] || '-' : ''}
								</td>
							))}
						</tr>
						{/*
				{Object.keys(groupIds).map(groupName => {
					const type = groups[groupIds[groupName]];
					return <tr key={groupName}>
						<td key="type">{type}</td>
						{CONVOCATIONS.map(conv => <td className="count" key={conv}>{this.groups[groupName] ? (this.groups[groupName][conv] || '-') : '...'}</td>)}
					</tr>;
				})}
*/}
					</tbody>
				</table>
			</div>
		);
	}
}
