import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import validateEmail from '../../utils/validateEmail';
import store from '../../store';

@observer
export default class Register extends React.Component {

	@observable lastName = '';
	@observable firstName = '';
	@observable middleName = '';
	@observable email = '';
	@observable password = '';
	@observable password2 = '';

	@observable lastNameError = '';
	@observable firstNameError = '';
	@observable middleNameError = '';
	@observable emailError = '';
	@observable passwordError = '';
	@observable password2Error = '';
	
	@observable isLoading = false;
	
	@action onLastNameChange = e => {
		this.lastName = e.target.value;
		this.lastNameError = '';
	};
	
	@action onFirstNameChange = e => {
		this.firstName = e.target.value;
		this.firstNameError = '';
	};
	
	@action onMiddleNameChange = e => {
		this.middleName = e.target.value;
		this.middleNameError = '';
	};
	
	@action onEmailChange = e => {
		this.email = e.target.value;
		this.emailError = '';
	};
	
	@action onPasswordChange = e => {
		this.password = e.target.value;
		this.passwordError = '';
		this.password2Error = '';
	};
	
	@action onPassword2Change = e => {
		this.password2 = e.target.value;
		this.passwordError = '';
		this.password2Error = '';
	};
	
	@action checkLastName = () => {
		this.lastName = this.lastName.trim();
		const valid = this.lastName.length > 0;
		if (!valid) this.lastNameError = 'Введите фамилию';
		return valid;
	};
	
	@action checkFirstName = () => {
		this.firstName = this.firstName.trim();
		const valid = this.firstName.length > 0;
		if (!valid) this.firstNameError = 'Введите имя';
		return valid;
	};
	
	@action checkMiddleName = () => {
		this.middleName = this.middleName.trim();
		const valid = this.middleName.length > 0;
		if (!valid) this.middleNameError = 'Введите отчество';
		return valid;
	};
	
	@action checkEmail = () => {
		this.email = this.email.trim();
		const { valid, message } = validateEmail(this.email);
		this.emailError = message || '';
		return valid;
	};
	
	@action checkPassword = () => {
		this.password = this.password.trim();
		const valid = this.password.length > 0;
		if (!valid) this.passwordError = 'Введите пароль';
		return valid;
	};
	
	@action checkPassword2 = () => {
		this.password2 = this.password2.trim();
		if (this.password2.length === 0) {
			this.password2Error = 'Повторите пароль';
			return false;
		}
		else if (this.password.length > 0 && this.password2 !== this.password) {
			this.password2Error = 'Пароли не совпадают';
			return false;
		}
		return true;
	};
	
	@action register = async e => {
		e.preventDefault();
		this.isLoading = true;

		this.lastNameError = '';
		this.firstNameError = '';
		this.middleNameError = '';
		this.emailError = '';
		this.passwordError = '';
		this.password2Error = '';

		let errors = 0;

		if (!this.checkLastName()) errors++;
		if (!this.checkFirstName()) errors++;
		if (!this.checkMiddleName()) errors++;
		if (!this.checkPassword()) errors++;
		if (!this.checkPassword2()) errors++;
		
		if (!this.checkEmail()) {
			errors++;
		}
		else {
			const users = await store.model.User.find({
				where: {
					email: this.email,
				},
			});
			if (users.length > 0) {
				this.emailError = 'Такой E-mail уже зарегистрирован';
				errors++;
			}
		}

		if (errors === 0) {
			try {
				await store.model.register({
					firstName: this.firstName,
					middleName: this.middleName,
					lastName: this.lastName,
					email: this.email,
					password: this.password,
				});
				await store.model.login(this.email, this.password);
			}
			catch(e) {
				console.warn(e);
			}
		}

		this.isLoading = false;
	};
	
	render() {
		return <form onSubmit={this.register}>
			<div className={'register-page row' + (this.isLoading ? ' is-loading' : '')}>
				<div className="col-lg-6 col-md-6 col-sm-12 col-12">
					<div className="form-field">
						<label className="required">Фамилия</label>
						<input type="text" value={this.lastName} onChange={this.onLastNameChange} onBlur={this.checkLastName} />
						<div className="form-error">{this.lastNameError}</div>
					</div>
					<div className="form-field">
						<label className="required">Имя</label>
						<input type="text" value={this.firstName} onChange={this.onFirstNameChange} onBlur={this.checkFirstName} />
						<div className="form-error">{this.firstNameError}</div>
					</div>
					<div className="form-field">
						<label className="required">Отчество</label>
						<input type="text" value={this.middleName} onChange={this.onMiddleNameChange} onBlur={this.checkMiddleName} />
						<div className="form-error">{this.middleNameError}</div>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12 col-12">
					<div className="form-field">
						<label className="required">E-mail</label>
						<input type="text" value={this.email} autoComplete="new-password" onChange={this.onEmailChange} onBlur={this.checkEmail} />
						<div className="form-error">{this.emailError}</div>
					</div>
					<div className="form-field">
						<label className="required">Пароль</label>
						<input type="password" value={this.password} autoComplete="new-password" onChange={this.onPasswordChange} onBlur={this.checkPassword} />
						<div className="form-error">{this.passwordError}</div>
					</div>
					<div className="form-field">
						<label className="required">Повторите пароль</label>
						<input type="password" value={this.password2} onChange={this.onPassword2Change} onBlur={this.checkPassword2} />
						<div className="form-error">{this.password2Error}</div>
					</div>
					<div className="form-buttons">
						{this.isLoading && <><Loader size={30} /> &nbsp;</>}
						<button className="white-btn" type="submit" disabled={this.isLoading}>Зарегистрироваться</button>
					</div>
				</div>
			</div>
		</form>;
	}
	
}
