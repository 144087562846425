import React from 'react';
import { Link } from 'react-router-dom';

export const Section = ({ title, button, children, className, link }) => {
	return (
		<div className={`section ${className}`}>
			{(title || button) && (
				<div className="section__heading">
					{link ? (
						<Link to={link}>
							<h2 className="header-2"> {title} </h2>
						</Link>
					) : (
						<h2 className="header-2"> {title} </h2>
					)}
					<div className="section__btn">{button} </div>
				</div>
			)}
			{children}
		</div>
	);
};
