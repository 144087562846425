import React from 'react';
import ReactSVG from 'react-svg';
import './styles.scss';

import { Subscription, ShareInSocial, Breadcrumbs } from '../../components';
import calendar from 'root/site/img/calendar.svg';
import print from 'root/site/img/print.svg';
import PassportDetails from './PassportDetails';
import LawState from './LawState';



export default class OnePageLaw extends React.Component {
	render() {
		return (
			
			<>
			<div className="note-page-border-bottom"> 
					
					<div className="container">
						<div className="row justify-content-center mb-4">
							<div className="note-page-title mb-5 mt-5">
								<div className="col-12">
								<h1>Закон Республики Саха (Якутия) от 21 ноябрь 2018 года 2058-3 №27-VI «О внесении изменения в статью 8 Закона Республики Саха (Якутия) “О муниципальном жилищном контроле в Республике Саха (Якутия)»</h1>
							</div>
							</div>
							</div>
							<div className="row align-items-center">
								
							<div className="col-12  col-md-6 order-md-first pt-3">
								<span className="note-page-title note-page-title_left"> 
									<h1>2058-3 №27-VI</h1>
								</span>
							</div>

							<div className="col-12 order-first col-md-6">
								<span className="note-page__date note-page__date-right">
									<ReactSVG className="simple-icon simple-icon-dark" src={calendar} /> Опубликован 18 февраля 2019
								</span>
							</div>
						</div>
						
					</div> 
				</div>
			
			<PassportDetails />
			<LawState />
			<div className="background-page-light-grey-mobile">
			<div className="container pb-5 pt-5">
			<div className="row justify-content-center align-items-center pb-5 pt-3">
						<div className="d-none d-md-block col-md-6">
							<div className="print-version">
								<div>
									{/* <a href={`/news/${id}/print`} target="_blank" rel="noopener noreferrer"> */}
										<ReactSVG className="simple-icon simple-icon-dark" src={print} />
										<span>Версия для печати</span>
									{/* </a> */}
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 d-flex justify-content-center">
							
								<ShareInSocial />
							
						</div>
					</div>
					</div>
				</div>
			
			
			
			</>
		);
	}
}
