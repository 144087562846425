import React from 'react';
import MediaQuery from 'react-responsive';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import store from 'root/site/store';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import logo from './img/logo.png';
import { UserInfo } from './UserInfo';

import { menuItems } from './menuItems';
import { autorun, observable } from 'mobx';

const { ui } = store;

const MenuHeader = styled.h3`
	font-family: 'Roboto Slab', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 2.5rem;
	padding-bottom: 1.8rem;
	margin-bottom: 5rem;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 5rem;
		left: 0;
		height: 1px;
		width: 50%;
		background: var(--color-primary-light);
	}
`;

@observer
export class MainMenu extends React.Component {
	@observable menu = []
	componentDidMount() {
			this.menu = menuItems
			for (let menuItem of store.ui.menuItems) {
				this.menu[menuItem.column - 1].items.push(menuItem)
			}
		}
	

	renderMenu = () => {
		return (
			<div className="main-menu__items-box main-menu__items-box-small" >
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-3 col-4 col-xs-4 menu-col">
							<div className="main-menu" onClick={() => ui.hideMenu()}>
								<span className="close-menu">
									<FontAwesomeIcon icon={faTimes} className="close-menu-icon" />
									<span className="close-menu-word">Закрыть</span>
								</span>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-4 col-xs-4 menu-col text-center">
							<div className="header__logo-box">
								<Link to="/" onClick={() => ui.hideMenu()}>
									<figure className="header__logo">
										<img
											style={{ display: 'block', margin: 'auto' }}
											src={logo}
											alt="logo"
											className="header__logo-image"
										/>
									</figure>
								</Link>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-4 col-xs-4 menu-col" style={{ textAlign: 'right' }}>
							<UserInfo color={'white'} />
						</div>
					</div>

					<div className="menu-content">
						<div className="row">
							{this.menu.map((menuItem, i) => {
								const { title, button, items } = menuItem;
								return (
									<div key={i} className="main-menu__column">
										<MenuHeader> {title}</MenuHeader>
										<ul className="main-menu__items-list">
											{items &&
												items.map(item => (
													<React.Fragment key={item.id}>
														<li className="main-menu__item" onClick={() => ui.hideMenu()}>
															{item.link.includes('http') ? (
																<a
																	href={item.link}
																	className="main-menu__link"
																	target="_blank"
																	rel="noopener noreferrer"
																	style={!JSON.parse(localStorage.getItem(`isPoorVision`)) ? { color: item.color } : {}}
																>
																	{item.name}
																</a>
															) : (
																<Link to={item.link} className="main-menu__link" style={!JSON.parse(localStorage.getItem(`isPoorVision`)) ? { color: item.color } : {}}>
																	{item.name}
																</Link>
															)}
														</li>
													</React.Fragment>
												))}
										</ul>

										<div className="information">
											<div className="information d-flex flex-column justify-content-end">
												<Link to={button.link} onClick={() => ui.hideMenu()} className="information-btn-white">
													{button.title}
												</Link>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	};
	render() {
		return (
			<>
				<div className="main-menu main-menu-red" onClick={() => ui.toggleMenu()}>
					<span className="menu-btn">
						<FontAwesomeIcon icon={faBars} className="menu-btn-icon" />
						<span className="menu-btn-word">МЕНЮ</span>
					</span>
				</div>
				{ui.menu && this.renderMenu()}
			</>
		);
	}
}
