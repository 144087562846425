import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Participant from './Participant';
import Admin from './Admin';
import voting from '../store-site';
import './style.scss';

@observer
export default class CurrentQuestion extends React.Component {

	render() {
		return voting.isAdmin ? <Admin /> : <Participant />;
	}
	
}
