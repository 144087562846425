import React from 'react';
import './styles.scss';
import ReactSVG from 'react-svg';
import img from './img/imgnews2.jpg';
import calendar from 'root/site/img/calendar.svg';



export const SimilarNewsFile = [
	{
		image: img,
		title: 'Петр Гоголев: Послание главы государства ориентировано на человека, на общество',
        date: '26 февраля 2019',
        time: '17:19',
        link: '#',
	},
	{
		image: img,
		title: 'Петр Гоголев: Послание главы государства ориентировано на человека, на общество',
        date: '26 февраля 2019',
        time: '17:19',
        link: '#',
    },
];

const SimilarNewsList = SimilarNewsFile.map((SimilarNewsFile, index) => (
	
	<div key={index} className="SimilarNews__wrapper">
		<div className="SimilarNews_img">
			<img src={SimilarNewsFile.image}></img>
		</div>
		<div className="SimilarNews_title mb-2">
		<a>	{SimilarNewsFile.title}</a>
		</div>
		<div className="SimilarNews_date mb-5">
			<ReactSVG className="GalleryPhoto__icon session-icon" src={calendar} />
			{SimilarNewsFile.date}, {SimilarNewsFile.time}
		</div>
	</div>
 ));


export default class SimilarNews_Sidebar extends React.Component {
	render() {
		return (
			<>
			<div className="name-page mb-4 mt-3">Похожие новости</div>
			{SimilarNewsList}
			</>
		);
	}
}
