import store from '../../../store';
import { PLENARY, COMMITTEE } from '..';

export default sourceSession => {
	const type = sourceSession.MODEL === store.model.PlenarySession ?
		PLENARY
		:
		sourceSession.MODEL === store.model.Document ? COMMITTEE : null;
	const foreignKey = type === PLENARY ? 'plenarySessionId' : type === COMMITTEE ? 'committeeSessionId' : '';
	return { type, foreignKey };
}
