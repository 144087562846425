import React from 'react';
import { Accordion  } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
import document from './docs/Объявление2018-1.docx'
import document2 from './docs/Объявление2018-2.docx'

export default class VacancyInformation extends React.Component {
    render(){
        return (
            <div className="background-page-light-grey">
				<div className="container container_pt_40">
				<div className="row">
				<div className="col-12 pt-3">
                <h2 className="header-2 pb-5" style={{textAlign : 'center'}}>
                Сведения о вакансиях, результаты конкурсов
					</h2>

                    <div className="pb-5" style={{textAlign : 'center'}}>
					<p>Конкурсы 2018 года</p>
					</div>
                    <Accordion title="Объявление о конкурсе на включение в кадровый резерв от 29 марта 2018 года">
                    <p>
                        <a href={document2}> Скачать Объявление о конкурсе на включение в кадровый резерв от 29 марта 2018 года </a>
                    </p>
                    </Accordion>
                    <Accordion title="Результаты конкурса на включение в кадровый резерв от 29 марта 2018 года">
                    <p>29 марта 2018 года состоялось заседание конкурсной комиссии на включение в кадровый резерв аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p>
                    <p>Конкурсная комиссия приняла следующее решение:</p>
                    <p>Для участия в конкурсе на включение в кадровый резерв аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) для замещения должности старшего референта государственно-правового управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) допустить:</p>
                    <ol className="text__ol_strong">
                        <li><p>Семенову Сияну Степановну </p></li>
                        <li><p>Павлову Светлану Андреевну</p></li>
                    </ol>
                    <p>10 мая 2018 года состоялось заседание конкурсной комиссии на включение в кадровый резерв аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p>
                    <p>Конкурсная комиссия приняла следующее решение:</p>
                    <p>Включить в кадровый резерв аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) на должность главного специалиста гсоударственно-правового управления:</p>
                    <ol className="text__ol_strong">
                        <li><p>Павлову Светлану Андреевну.</p></li>
                    </ol>
                    </Accordion>
                    <Accordion title="Объявление о конкурсе на замещение вакантных должностей от 29 марта 2018 года">
                        
                        <p>
                            <a href={document}> Скачать Объявление о конкурсе на замещение вакантных должностей от 29 марта 2018 года</a>
                        </p>
                    
                    </Accordion>
                    <Accordion title="Результаты конкурса на замещение вакантных должностей от 29 марта 2018 года">
                        <p>19 апреля 2018 года состоялось заседание конкурсной комиссии на замещение вакантных должностей государственной гражданской службы аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p>
                        <p>Конкурсная комиссия приняла следующее решение:</p>
                        <ol className="text__ol_strong">
                            <li><p>Для участия в конкурсе на замещение вакантной должности старшего референта отдела по работе с представительными органами местного самоуправления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) допустить:</p></li>
                            <ol className="text__ol_strong">
                                <li><p>Шадрина Вадима Алексеевича</p></li>
                                <li><p>Драгунову Саргылану Афанасьевну</p></li>
                                <li><p>Соркомова Матвея Семеновича</p></li>
                                <li><p>Аммосову Марианну Викторовну</p></li>
                                <li><p>Степанова Романа Алексеевича </p></li>
                            </ol>
                            <li><p>Для участия в конкурсе на замещение вакантной должности главного специалиста документационного управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) допустить:</p></li>
                            <ol className="text__ol_strong">
                                <li><p>Васильева Дмитрия Семеновича</p></li>
                                <li><p>Рожину Сардаану Викторовну</p></li>
                                <li><p>Олесову Марию Аркадьевну</p></li>
                                <li><p>Охлопкову Ренату Александровну</p></li>
                                <li><p>Осипову Галину Софроновну</p></li>
                            </ol>
                            <li><p>Для участия в конкурсе на замещение вакантной главного специалиста документационного управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) не допустить в связи с несоответствием квалификационным требованиям к вакантной должности на замещение вакантной главного специалиста документационного управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия):</p></li>
                            <ol className="text__ol_strong">
                                <li><p>Сон Викторию Викторовну</p></li>
                                <li><p>Аммосову Марианну Викторовну</p></li>
                            </ol>
                        </ol>
                        <p>10 мая 2018 года состоялось заседание заседание конкурсной комиссии на замещение вакантных должностей государственной гражданской службы аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия).</p>
                        <p>Конкурсная комиссия приняла следующее решение:</p>
                        <ol className="text__ol_strong">
                            <li><p>Признать победителем конкурса на замещение вакантной должности старшего референта отдела по работе с представительными органами местного самоуправления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) Соркомова Матвея Семеновича.</p></li>
                            <li><p>Признать победителем конкурса на замещение вакантной должности главного специалиста документационного управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) Васильева Дмитрия Семеновича.</p></li>
                            <li><p>Включить в кадровый резерв аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) на должность главного специалиста документационного управления Рожину Сардану Викторовну.</p></li>
                        </ol>
                    </Accordion>
                    

                </div>
                </div>
                </div>
                </div>
        );
    }
}
