import { useEffect } from 'react';
import store from 'root/site/store';
import axios from 'axios';

/* 

Проверка просмотреров и добавление новго IP пользователя если он уникален

*/
export const useCheckViews = (id, model) => {
	useEffect(() => {
		axios.get('https://api.ipify.org').then(res => {
			store.model.View.checkViews(+id, model, res.data);
		});
	}, []);
};
