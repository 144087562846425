import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import store from '../../store';
import './style.scss';

const LOGIN_TAB = 1;
const REGISTER_TAB = 2;

@observer
export default class Auth extends React.Component {

	@observable tab = LOGIN_TAB;
	
	componentDidMount() {
		store.ui.setBreadcrumbs([{ name: 'Вход', link: '/login' }]);
	}

	loginTab = () => this.tab = LOGIN_TAB;
	registerTab = () => this.tab = REGISTER_TAB;
	
	render() {
		if (store.model.isAuthorized) return <Redirect to="/profile" />;
		
		return <div className="container login-register">
			<div className="login-tabs">
				<div className={'login-tab' + (this.tab === LOGIN_TAB ? ' active' : '')} onClick={this.loginTab}>
					<strong>Вход</strong>
					<small>в личный кабинет</small>
				</div>
				{/* <div className={'login-tab' + (this.tab === REGISTER_TAB ? ' active' : '')} onClick={this.registerTab}>
					<strong>Регистрация</strong>
				</div> */}
			</div>
			
			<div className="tab-content">
				{this.tab === LOGIN_TAB && <Login />}
				{/* {this.tab === REGISTER_TAB && <Register />} */}
			</div>
			
		</div>;
	}
	
}
