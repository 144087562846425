import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader, Pager} from '@smartplatform/ui';
import { formatDateStr } from './index';
import { Tabs, Tab } from '../../components/route-tabs';
import SessionCard from './SessionCard';
import store from '../../store';

const PER_PAGE = 10;

@observer
export default class List extends React.Component {

	@observable isLoading = true;
	@observable records = [];
	@observable page = 1;
	
	constructor(props) {
		super(props);
		this.model = props.type === 'plenary' ? store.model.PlenarySession : store.model.CouncilSession;
		store.ui.setBreadcrumbs([
			{ name: 'Заседания', link: `/sessions` },
			{ name: props.type === 'plenary' ? 'Пленарные заседания' : 'Заседания Совета', link: `/sessions/${props.type}` },
		]);
		this.init();
	}
	
	init = async () => {
		this.isLoading = true;
		const where = {
			publish: true,
		};
		if (this.props.type === 'plenary') where.meetingId = { neq: null };
		
		this.records = await this.model.find({
			where,
			order: 'id desc',
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});
		this.isLoading = false;
	};
	
	onPageChange = page => {
		this.page = page;
		this.init();
	};
	
	render() {
		const content = this.records.length === 0 && this.isLoading ?
			<Loader />
			:
			<>
				<div className="plenary-list">
					{this.records.map(session => <SessionCard key={session.id} type={this.props.type} session={session} />)}
				</div>
				{this.page === Math.ceil(this.records.totalCount / PER_PAGE) ?
				<div className="old-version">
					<span>Архив пленарных заседаний прошлых созывов доступен на
						<a className="old-version-link" href="http://old.iltumen.ru/sessions/archive">старой версии сайта.</a>
					</span>
				</div> : ``}
				<Pager totalCount={this.records.totalCount} current={this.page} itemsPerPage={PER_PAGE} onChange={this.onPageChange}/>
			</>;
		
		return <div className="sessions-list">
			{content}
		</div>;
	}
	
}
