import store from '../../store';

export const customFormatVideo = async recordsList => {
	const records = []; 

	for (const record of recordsList) {
		const thumbnailFile = record.thumbnail ? await record.downloadFile('thumbnail') : '';
		const thumbnailAttachment = record.thumbnailAttachment ? await record.thumbnailAttachment : '';
		const videoFile = record.video ? await record.downloadFile('video') : '';
		const videoAttachment = record.videoAttachment ? await record.videoAttachment : '';

		const video = videoFile
			? videoFile
			: videoAttachment
			? '/api/containers/media/download/' + videoAttachment.filename
			: null;

		const thumbnail = thumbnailFile
			? thumbnailFile
			: thumbnailAttachment
			? '/api/containers/attachments/download/' + thumbnailAttachment.filename
			: null;

		records.push(new store.model.Media({ ...record, video, thumbnail }));
	}

	return records;
};
