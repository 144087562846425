import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../../store';
import voting from '../store-site';
import Protocolist from '../panels/Protocolist';
import Results from '../panels/Results';
import QuestionStatus from './QuestionStatus';
import QuestionContent from './QuestionContent';
import './style.scss';

@observer
export default class QuestionCard extends React.Component {

	render() {
		const { question } = this.props;
		
		return <div className="question-card">
			<div className="left">
				<QuestionStatus key={'status-' + question.id} question={question} />
				<QuestionContent key={'content' + question.id} question={question} />
			</div>
			<div className="right">
				<div className="question-panel">
					{voting.isProtocolist || voting.isAdmin ?
						<Protocolist question={question} />
						:
						<Results useView question={question} />
					}
				</div>
			</div>
		</div>;
	}
	
}
