import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { useGetRecord, useCheckViews, useShowCounter } from '../../utils/hooks';
import { dateAndTime } from 'root/site/utils/date-format';
import { Loader, HtmlValue } from '@smartplatform/ui';
import { ElementItem, ShareInSocial, ImagePreview, ShareAndSubscribe } from '../../components';
import store from '../../store';
import eye from 'root/site/img/eye.svg';
import print from 'root/site/img/print.svg';
import calendar from 'root/site/img/calendar.svg';
import SimilarNews from './SimilarNews';
import NewsPhotoSlider from './NewsPhotoSlider';
import { cutEmptyParagraphs } from '../../utils';

const NewsSingle = () => {
	const { ui } = store;
	const { id } = useParams();
	const { location } = useHistory()
	const options = {
		include: [
			'views',
			'images',
			'tags',
			{ relation: 'deputies', scope: { fields: { id: true, lastName: true, firstName: true, middleName: true } } },
			{ relation: 'fractions', scope: { fields: { id: true, shortName: true } } },
		],
	};
	const record = useGetRecord({ model: 'Article', id, options });

	const [article, setArticle] = useState();
	const [similarArticles, setSimilarArticles] = useState();

	let imgClass;
	useCheckViews(id, 'Article');

	const showCounter = useShowCounter();
	useEffect(() => {
		const init = async () => {
			await formatData();
			await getSimilar();
		};
		if (record) {
			init();
			ui.setBreadcrumbs([...ui.breadcrumbs, { link: `news/${record.id}`, name: record.name }]);
			return () => {
				ui.setBreadcrumbs(ui.breadcrumbs.slice(0, -1));
			};
		}
	}, [record]);

	const formatData = async () => {
		const views = await record.views().length;
		const thumbnail = record.thumbnail ? record.downloadFile('thumbnail') : '';
		const imagesList = await record.images();
		const publishAt = dateAndTime(record.publishAt);
		const images = [];
		for (const image of imagesList) {
			const { name, id } = image;
			const imageFile = image.importUid
				? `/api/containers/articles/download/${image.filename}`
				: await image.downloadFile('thumbnail');
			images.push({ file: imageFile, name, id });
		}
		const regexImage = new RegExp('property="og:image"');
		const regexDescription = new RegExp('property="og:description"');

		const metaTagImage = Array.from(document.querySelector('head').querySelectorAll('meta')).find((it) => it.outerHTML.match(regexImage));
		const metaTagDescription = Array.from(document.querySelector('head').querySelectorAll('meta')).find((it) => it.outerHTML.match(regexDescription));

		if (metaTagImage && metaTagDescription) {
			if (thumbnail) {
				metaTagImage.setAttribute('content', `https://iltumen.ru${thumbnail}?image=[{"resize":{"width":600}}]`);
			}
			else if (imagesList.length > 0) {
				metaTagImage.setAttribute('content', `https://iltumen.ru/api/containers/articles/download/${imagesList[0].filename}?image=[{"resize":{"width":600}}]`);
			}
			metaTagDescription.setAttribute('content', record.name);
		}
		setArticle({ ...record, views, images, thumbnail, publishAt, imagesList });
	}

	const getSimilar = async () => {
		const tags = record.tags({ include: ['articles'] });
		let similarNews;

		if (tags.length === 0) {
			similarNews = await store.model.Article.find({
				where: {
					and: [{ publishAt: { lte: Date.now() } }, { publish: true }, { type: 'news' }],
				},
				fields: ['id', 'name', 'thumbnail', 'imported', 'mainPage', 'image', 'publishAt'],
				limit: 10,
				order: 'publishAt DESC',
			});
		} else {
			for (const tag of tags) {
				similarNews = await tag.articles();
			}
		}

		const len = similarNews.length;
		const similarToShow = [];
		let i = 0;
		while (similarToShow.length < 2 && i < 20) {
			i++;
			const ArticleToAdd = similarNews[Math.floor(len * Math.random())];
			if (!similarToShow.find(el => el === ArticleToAdd) && ArticleToAdd.id !== +id) {
				similarToShow.push(ArticleToAdd);
			}
		}

		setSimilarArticles(similarToShow);
	};

	const onMount = el => {
		if (!el) {
			return;
		}
		const images = el.getElementsByTagName('img');
		for (const img of images) {
			img.style.cursor = 'zoom-in';
			img.onclick = () => {
				store.ui.setPreviewImgSrc(img.src);
			};
		}
	};

	if (!article && !similarArticles) {
		return (
			<div className="container base-page">
				<Loader />
			</div>
		);
	}
	const { thumbnail, views, images, name, contentTop, publishAt } = article;

	if (!record.publish) return <div className="container base-page static-page">Страница не найдена: {location.pathname}</div>

	return (
		<div>
			<ImagePreview />
			<ShareAndSubscribe type="news" />

			<div className="container">
				<div className="note-page-title pb-5 pt-5">
					<h1>{name}</h1>
				</div>
			</div>
			<div className="background-page-light-grey">
				<div className="container">
					<div className="row pb-4 pt-4">
						{showCounter && (
							<div className="col-2">
								<div className="news-views">
									<ElementItem
										textColor={'var(--color-gray-light-text)'}
										fill="dark"
										icon={eye}
										text={views}
										top="2px"
									/>
								</div>
							</div>
						)}

						<div className="col-auto">
							<div className="news-date">
								<ElementItem
									textColor={'var(--color-gray-light-text)'}
									fill="dark"
									icon={calendar}
									text={publishAt}
									top="2px"
								/>
							</div>
						</div>
					</div>
					<div className="news-img__wrapper">
						<img
							alt={name}
							className="news-img__image"
							src={article.imported ? `/api/containers/articles/download/${article.image}` : thumbnail}
						/>
					</div>
					<div className="row news-wrapper">
						<div className="d-md-block" ref={onMount}>
							{contentTop && <HtmlValue className="text-news" value={cutEmptyParagraphs(contentTop)} />}
						</div>
					</div>
					{images && (
						<div className="news-images">
							<div className="container">
								<NewsPhotoSlider articleId={record.id} />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="background-page-light-grey note-page-border-bottom" style={{ marginTop: -15 }}>
				<div className="container">
					<div className="row pt-5">
						<div className={`col-12`}>
							<div className="content-bottom">
								{record.fractions().length > 0 && (
									<div className="article-fractions">
										<strong>Фракции:</strong>
										{record.fractions().map(fraction => (
											<Link key={fraction.id} to={`/fractions/${fraction.id}`}>
												{fraction.shortName}
											</Link>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center pb-5 pt-3">
						<div className="d-none d-md-block col-md-6">
							<div className="print-version">
								<div>
									<a href={`/news/${id}/print`} target="_blank" rel="noopener noreferrer">
										<ReactSVG className="simple-icon simple-icon-dark" src={print} />
										<span>Версия для печати</span>
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 d-flex justify-content-center">
							<ShareInSocial text={name} />
						</div>
					</div>
					<div className="content-bottom">
						{record.deputies().length > 0 && (
							<div className="article-deputies">
								<h2 className="header-2">Депутаты:</h2>
								{record.deputies().map(deputy => (
									<Link key={deputy.id} to={`/deputies/${deputy.id}`} style={{ textDecoration: 'none' }}>
										{deputy.lastName} {deputy.firstName} {deputy.middleName}
									</Link>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<SimilarNews articles={similarArticles} />
		</div>
	);
};

export default NewsSingle;
