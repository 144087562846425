import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

import { Loader } from '@smartplatform/ui';
import { Button } from '../button';
import Results from './Results';
import RequestButtons from './RequestButtons';
import VotingButtons from './VotingButtons';
import store from '../../../store';
import { STATUS_INACTIVE, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED, STATUS_CALCULATING, STATUS_FINISHED } from '../store-site';
import voting from '../store-site';

@observer
export default class Deputy extends React.Component {
	
	@observable isLoading = false;
	
	render() {
		const { question, status } = this.props;
		// console.log('status', status);

		if (voting.session.end) {
			return <div className="deputy-panel">
				<Results useView question={question}/>
			</div>;
		}
		
		if (!voting.session.registrationStart) {
			return <div className="deputy-panel">Ожидание регистрации</div>
		}
		
		const quorumStatus = !voting.isQuorum ? <div className="deputy-panel">Ожидание кворума</div> : null;
		
		const currentStage = voting.currentStage ? <div className="current-stage">{voting.getStageName(voting.currentStage)}</div> : null;
		
		if (!voting.member) {
			return <div className="deputy-panel">
				{quorumStatus}
				{currentStage}
				<span className="red-text">Зарегистрируйтесь на голосование</span>
				<Results useView question={question}/>
			</div>
		}
		
		if (status === STATUS_CANCELED) {
			return <div className="deputy-panel">
				{quorumStatus}
				{voting.isQuorum && <RequestButtons />}
				{currentStage}
				Голосование отменено
				<Results useView question={question}/>
			</div>;
		}
		
		if (status === STATUS_FINISHED) {
			return <div className="deputy-panel">
				{quorumStatus}
				{voting.isQuorum && <RequestButtons />}
				{currentStage}
				Завершено
				<Results useView question={question}/>
			</div>;
		}
		
		if (status === STATUS_DISCUSSED) {
			return <div className="deputy-panel">
				{quorumStatus}
				{voting.isQuorum && <RequestButtons />}
				{currentStage}
				Идёт обсуждение
				<Results useView question={question}/>
			</div>
		}
		
		if (status === STATUS_VOTING) {
			return <div className="deputy-panel">
				{quorumStatus}
				{currentStage}
				{voting.isQuorum && <VotingButtons question={question} />}
				<Results useView question={question}/>
			</div>
		}

		if (status === STATUS_CALCULATING) {
			return <div className="deputy-panel">
				{quorumStatus}
				{voting.isQuorum && <RequestButtons />}
				{currentStage}
				<div className="calculating"><Loader size={20} /> Идет подсчет голосов...</div>
				<Results useView question={question} exlude={voting.currentStage} />
			</div>
		}
		
		return <div className="deputy-panel">
			{quorumStatus}
			{voting.isQuorum && <RequestButtons />}
			{currentStage}
			<Results useView question={question}/>
		</div>;
	}
	
}
