import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class Edit extends React.Component {
	
	@observable user = null;
	@observable lastNameError = null;
	@observable firstNameError = null;
	@observable middleNameError = null;
	@observable password = '';
	@observable password2 = '';
	@observable passwordError = '';
	@observable password2Error = '';

	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.user = await store.model.User.findById(store.model.user.id);
	};
	
	@action onPasswordChange = e => {
		this.password = e.target.value;
		this.passwordError = '';
		this.password2Error = '';
	};
	
	@action onPassword2Change = e => {
		this.password2 = e.target.value;
		this.passwordError = '';
		this.password2Error = '';
	};

	@action checkPassword = () => {
		if (this.password.length === 0 && this.password2.length === 0) return true;
		this.password = this.password.trim();
		const valid = this.password.length > 0;
		if (!valid) this.passwordError = 'Введите пароль';
		return valid;
	};
	
	@action checkPassword2 = () => {
		if (this.password.length === 0 && this.password2.length === 0) return true;
		this.password2 = this.password2.trim();
		if (this.password2.length === 0) {
			this.password2Error = 'Повторите пароль';
			return false;
		}
		else if (this.password.length > 0 && this.password2 !== this.password) {
			this.password2Error = 'Пароли не совпадают';
			return false;
		}
		return true;
	};

	onSubmit = async e => {
		e.preventDefault();
		
		this.user.lastName = this.user.lastName.trim();
		this.user.firstName = this.user.firstName.trim();
		this.user.middleName = this.user.middleName.trim();
		
		let errors = 0;
		
		if (this.user.lastName.length === 0) {
			this.lastNameError = 'Введите фамилию';
			errors++;
		}
		
		if (this.user.firstName.length === 0) {
			this.firstNameError = 'Введите имя';
			errors++;
		}
		
		if (this.user.middleName.length === 0) {
			this.middleNameError = 'Введите отчество';
			errors++;
		}

		if (!this.checkPassword()) errors++;
		if (!this.checkPassword2()) errors++;
		
		if (errors > 0) {
			console.log('errors', errors);
			return false;
		}
		
		if (this.password.length > 0 && this.password2.length > 0) {
			this.user.password = this.password;
		}
		
		await this.user.save();
		store.route.path = '/profile';
		store.route.push();
	};
	
	onLoginChange = e => {
		this.user.username = e.target.value;
		this.usernameError = null;
	};
	
	onLastNameChange = e => this.user.lastName = e.target.value;
	onFirstNameChange = e => this.user.firstName = e.target.value;
	onMiddleNameChange = e => this.user.middleName = e.target.value;
	
	render() {
		return <div className="container profile-main">
			<div className="profile-header">
				<h2>Личный кабинет</h2>
			</div>
			
			{this.user ?
				<div className="profile-edit">
					<div className="info">E-mail: <strong>{this.user.email}</strong></div>
					
					<form onSubmit={this.onSubmit}>
{/*
						<div className="form-field">
							<label>Логин:</label>
							<input type="text" value={this.user.username || ''} onChange={this.onLoginChange} />
							{this.usernameError ?
								<div className="error">{this.usernameError}</div>
								:
								<div className="hint">Войти в личный кабинет можно как по логину, так и по E-mail</div>
							}
						</div>
*/}
						<div className="form-field">
							<label>Фамилия:</label>
							<input type="text" value={this.user.lastName || ''} onChange={this.onLastNameChange} />
							<div className="error">{this.lastNameError}</div>
						</div>
						<div className="form-field">
							<label>Имя:</label>
							<input type="text" value={this.user.firstName || ''} onChange={this.onFirstNameChange} />
							<div className="error">{this.firstNameError}</div>
						</div>
						<div className="form-field">
							<label>Отчество:</label>
							<input type="text" value={this.user.middleName || ''} onChange={this.onMiddleNameChange} />
							<div className="error">{this.middleNameError}</div>
						</div>
						<div className="form-field">
							<label className="required">Новый пароль (оставьте пустым, чтобы не менять пароль)</label>
							<input type="password" value={this.password} autoComplete="new-password" onChange={this.onPasswordChange} onBlur={this.checkPassword} />
							<div className="error">{this.passwordError}</div>
						</div>
						<div className="form-field">
							<label className="required">Повторите пароль</label>
							<input type="password" value={this.password2} onChange={this.onPassword2Change} onBlur={this.checkPassword2} />
							<div className="error">{this.password2Error}</div>
						</div>
						<div className="submit">
							<button type="submit" className="white-btn">Сохранить</button>
						</div>
					</form>
				</div>
				:
				null
			}
		</div>;
	}
	
}
