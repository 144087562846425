import React from 'react';
import MediaQuery from 'react-responsive';
import HistoricalRus from "./HistoricalRus";
import HistoricalYkt from "./HistoricalYkt";
import HistoricalImg from './HistoricalImg';
import { Tabs } from 'root/site/components';
import LastUpdated from '../../components/last-updated';

export default class HistoricalReviewList extends React.Component {
	render() {
		return (
			<>
			<MediaQuery minWidth={768}>
				<div className="">
					<div className="container pb-5 pt-5">
						<div className="row align-items-center">
							<div className="col-12 col-md-6">
								<h2 className="header-2">Исторический обзор</h2>
							</div>
						</div>
						<LastUpdated dateStr="23 декабря 2019" className="pt-5" />
					</div>
				</div>
			</MediaQuery>
			<div className="">
				<div className="container pb-5">
					<div className="row">
						<MediaQuery minWidth={768}>
							<div className="col-md-4">
								<HistoricalImg />
							</div>
							<div className="col-md-8">
								<Tabs>
									Исторический обзор <HistoricalRus />
									Историяны анааран көрдөххө <HistoricalYkt />
								</Tabs>
							</div>
						</MediaQuery>
						<MediaQuery maxWidth={769}>
							<div className="col-12 historical-mobile">
								<Tabs>
									Исторический обзор <HistoricalRus />
									Историяны анааран көрдөххө <HistoricalYkt />
									Историческая фотогалерея <HistoricalImg />
								</Tabs>
							</div>
						</MediaQuery>
					</div>
				</div>
			</div>
			</>		
		);
	}
}