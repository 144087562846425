import React from 'react';

export const Badges = ({ article, maxCount = 2, width = 200, style = { marginBottom: 10 } }) => {
	if (article.tags) {
	const tags = article.tags() || [];
	if (tags instanceof Promise || tags.length === 0) return null;
	const badges = tags.map(tag => tag.image && tag.downloadFile('image')).filter(image => !!image);
	return badges.slice(0, maxCount).map((badge, i) => <img style={{ maxWidth: width, ...style }} alt="" src={badge} key={i} />);
	} 
	return null;
};
