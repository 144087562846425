import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select, Checkbox, Loader } from '@smartplatform/ui';
import { STATUS_INACTIVE, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED, STATUS_CALCULATING } from '../store-site';
import { Button } from '../button';
import Results from '../panels/Results';
import VotingSettings from './VotingSettings';
import Requests from '../panels/speech-requests';
import { formatDateStr } from 'root/site/utils';
import voting from '../store-site';

@observer
export default class CurrentQuestion extends React.Component {

	@observable fraction = null;
	@observable from = null;
	@observable gotoVoting = false;

	constructor(props) {
		super(props);
	}
	
	gotoVote = async () => {
		if (voting.currentQuestion.stage.activeQueue) {
			voting.currentQuestion.stage.activeQueue.finishedAt = new Date();
			await voting.currentQuestion.stage.activeQueue.save();
		}
		this.gotoVoting = true;
	};
	
	render() {
		const { status } = this.props;
		if (!voting.currentQuestion) return '';
		
		if (!voting.session.registrationStart) {
			return <div>Ожидание регистрации</div>
		}
		
		if (!voting.isQuorum) {
			return <div>Ожидание кворума</div>
		}
		
		let content = null;
		
		if (status === STATUS_CALCULATING) {
			return <div className="voting-progress">
				<div className="calculating"><Loader size={20} /> Идет подсчет голосов...</div>
				{/*<Results useView question={voting.currentQuestion} />*/}
			</div>
		}
		
		if (status === STATUS_CANCELED) {
			content = <div className="voting-progress">
				<div>{voting.currentStage.voteStart ? 'Голосование отменено' : 'Завершено'}</div>
				{formatDateStr(voting.currentQuestion.stage.canceledAt)}
				{/*<Results useView question={voting.currentQuestion} />*/}
			</div>;
		}
		else if (status === STATUS_VOTED) {
			content = <div className="voting-progress">
				<label>Голосование окончено</label>
				<Results useView question={voting.currentQuestion} />
				{/*<Button onClick={() => voting.gotoNextQuestion()}>Перейти к следующему вопросу</Button>*/}
			</div>;
		}
		else if (status === STATUS_DISCUSSED) {
			content = <VotingSettings />;
		}
		else if (status === STATUS_VOTING || status === STATUS_CALCULATING) {
			content = <>
				<div className="voting-progress">
					<label>Идет голосование</label>
					<div className="votes-count votes-count-curpanel">
						<div className="count"><em>За</em><span/><strong>{voting.adminVotes ? (voting.adminVotes.yes || 0) : '0'}</strong></div>
						<div className="count"><em>Против</em><span/><strong>{voting.adminVotes ? (voting.adminVotes.no || 0) : '0'}</strong></div>
						<div className="count"><em>Воздержалось</em><span/><strong>{voting.adminVotes ? (voting.adminVotes.abstained || 0) : '0'}</strong></div>
						<div className="count total"><em>Голосовало</em><span/><strong>{voting.adminVotes ? (voting.adminVotes.yes + voting.adminVotes.no + voting.adminVotes.abstained) : '0'}</strong></div>
					</div>
				</div>
				
				{status === STATUS_VOTING && <div>
					{voting.timeLeft >= 0 && <div className="time-left">Осталось времени: <em>{voting.timeLeft}</em> сек.</div>}
					<Button onClick={() => voting.stopVoting(voting.currentQuestion)} disabled={voting.isEndingVoting}>Закончить голосование</Button>
					<div className="cancel">
						<Button variant="grey" onClick={() => voting.cancelQuestion(voting.currentQuestion)}>Отменить голосование</Button>
					</div>
				</div>}
				
				{status === STATUS_CALCULATING && <div>
					<Loader size={20} /> Идет подсчет голосов...
				</div>}
			</>;
		}
		
		return <div>
			{content}
			<Requests onGotoVote={this.gotoVote} />
		</div>;
	}
	
}
