import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { getCurrentDeputies, fio } from '../../../utils';
import voting from '../store-site';
import store from '../../../store';
import './style.scss';

@observer
export default class SessionMembers extends React.Component {

	@observable allDeputies = [];
	
	constructor(props) {
		super(props);
		voting.user = store.model.user;
		if (this.props.match.params.id) {
			voting.id = parseInt(this.props.match.params.id);
			voting.init('online, id: ' + voting.id);
		}
		else {
			voting.error = 'Не указан ID.';
		}
		// this.init();
	}
	
	init = async () => {
		this.allDeputies = await getCurrentDeputies();
	};
	
	render() {
		
		const members = [];
		
		voting.allDeputies.forEach(deputy => {
			const member = voting.allMembers.find(m => m.deputyId === deputy.id);
			const isOnline = member ? !member.end : false;
			members.push({
				key: 'deputy-' + deputy.id,
				fio: fio(deputy),
				isOnline,
				isDeputy: true,
				type: 'депутат',
				phone: deputy.phone,
				member,
			});
		});
		
		voting.allInvited.forEach(user => {
			const member = voting.allMembers.find(m => m.userId === user.id);
			const isOnline = member ? !member.end : false;
			members.push({
				key: 'invited-' + user.id,
				fio: fio(user),
				isOnline,
				isDeputy: false,
				type: 'приглашенный',
				member,
			});
		});
		
		members.sort((a, b) => {
			let ao = a.isOnline ? 1 : 0;
			let bo = b.isOnline ? 1 : 0;
			return bo - ao;
		});
		
		return <div className="online-session-members">
			<div className="summary">
				Всего онлайн: <strong>{members.filter(m => m.isOnline).length}</strong>.&nbsp;
				Депутатов онлайн: <strong>{members.filter(m => m.isOnline && m.isDeputy).length}</strong>.&nbsp;
				Всего депутатов: <strong>{voting.allDeputies.length}</strong>.&nbsp;
			</div>
			<div className="tables">
				{[...new Array(3)].map((v, col) => <div key={col} className="column">
					<table>
						<thead>
						<tr>
							<th className="number">№</th>
							<th className="fio">Ф.И.О.</th>
							<th className="member">Статус</th>
							<th className="status">Онлайн</th>
							{/*<th className="phone">Телефон</th>*/}
							{/*<th className="email">E-mail</th>*/}
						</tr>
						</thead>
						<tbody>
							{members.slice(col * 30, col * 30 + 30).map((memberObj, i) => {
								const { key, fio, isOnline, type, phone, member } = memberObj;
								return <tr key={key} className={isOnline ? 'online' : ''}>
									<td className="number">{i + 1 + col * 30}</td>
									<td className="fio">{fio}</td>
									<td className="member">{type}</td>
									<td className="status">{isOnline ? 'да' : 'нет'}</td>
									{/*<td className="phone">{phone || ''}</td>*/}
									{/*<td className="email">{member ? (member.user.email || '') : ''}</td>*/}
								</tr>;
							})}
						</tbody>
					</table>
				</div>)}
			</div>
			
		</div>;
	}
	
}
