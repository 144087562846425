import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Checkbox } from '@smartplatform/ui';
import { getRoleNames } from '../helpers';
import store from '../../../store';
import voting from '../store-site';

@observer
export default class SessionDebug extends React.Component {

	@observable invited = null;
	@observable staff = null;
	@observable protocolist = null;
	@observable isLoading = true;
	@observable search = '';
	
	clicksCount = 0;
	
	constructor(props) {
		super(props);
		this.debugLog();
		this.init();
	}

	debugLog = async () => {
		if (store.model.user) {
			const roleNames = await getRoleNames();
			if (!roleNames.includes('admin')) {
				console.log('>', roleNames, roleNames.includes('admin'));
				await store.debugLog(`SessionDebug: пользователь получил доступ к панели администратора, roles: ${roleNames}`, 'error');
			}
		}
	};
	
	init = async () => {
		// const testUser = await store.model.User.findById(5);
		// console.log('testUser', testUser);
		// setTimeout(() => voting.setUser(testUser), 1000);
		// this.invited = await voting.getUserForRole('invited');
		// this.staff = await voting.getUserForRole('staff');
		// this.protocolist = await voting.getUserForRole('protocolist');
		// console.log('invited', this.invited);
		// console.log('staff', this.staff);
		this.isLoading = false;
	};
	
	onDebugClick = () => {
		this.clicksCount ++;
		if (this.clicksCount === 3) {
			this.clicksCount = 0;
			store.local.showDebugInfo = !store.local.showDebugInfo;
			store.local.save();
		}
	};
	
	setAdmin = async () => {
		await voting.setUser(store.model.user);
	};
	
	setNone = async () => {
		await voting.setUser(null);
	};
	
	setProtocolist = async () => {
		await voting.setUser(this.protocolist);
	};
	
	setUserForDeputy = async deputy => {
		const res = await store.model.User.find({ where: { deputyId: deputy.id }});
		const user = res.length > 0 ? res[0] : null;
		if (user) await voting.setUser(user);
	};
	
	onSearchChange = e => {
		this.search = e.target.value;
	};
	
	filter = user => {
		const registered = user.userId ? voting.isDeputyRegistered(user) : voting.isUserRegistered(user);
		if (!store.local.showOfflineMembers && !registered) return false;
		const trimmed = this.search.trim();
		if (trimmed.length > 0) {
			const r = new RegExp(trimmed, 'ig');
			return r.test(user.lastName) || r.test(user.firstName) || r.test(user.middleName)
		}
		return true;
	};
	
	render() {
		if (this.isLoading) return null;
		
		return <div className="session-debug">
			<FontAwesomeIcon icon={faTimes} className="close-btn" onClick={this.props.onClose} />

			<div className="filter">
				<input type="text" value={this.search} onChange={this.onSearchChange} placeholder="Поиск по Ф.И.О." />
				<Checkbox value={store.local.showOfflineMembers} onChange={v => store.local.showOfflineMembers = v} />Оффлайн
			</div>
			
			<div className="user-select">
				
				<div
					key="admin"
					onClick={this.setAdmin}
					className={'user' + (voting.user && store.model.user && voting.user.id === store.model.user.id ? ' active' : '')}>
					Администратор
				</div>
				
{/*
				<div
					key="user"
					onClick={this.setNone} className={'user' + (!voting.user ? ' active' : '')}>
					Юзер
				</div>
				
				{this.protocolist && <div
					key="protocolist"
					onClick={this.setProtocolist} className={'user' + (!voting.user ? ' active' : '')}>
					Протоколист
				</div>}
*/}
				
				<div className="hdr">Протоколисты</div>
				{voting.allProtocolists
					.filter(this.filter)
					.sort((a, b) => {
						const ar = voting.isUserRegistered(a) ? 1 : 0;
						const br = voting.isUserRegistered(b) ? 1 : 0;
						return br - ar;
					})
					.map((user, i) => {
						const registered = voting.isUserRegistered(user);
						const className = classNames('user', {
							active: !!voting.user && voting.user.id === user.userId,
							registered,
						});
						return <div key={user.id} className={className} onClick={() => voting.setUser(user)}>
							<span className="num">{i + 1}</span>
							<span className="icon"><FontAwesomeIcon icon={registered ? faCheck : faMinus}/></span>
							<span className="fio" dangerouslySetInnerHTML={{ __html: voting.fio(user).replace(new RegExp(`(${this.search.trim()})`, 'ig'), '<em>$1</em>') }}/>
						</div>;
					})}
				
				<div className="hdr">Депутаты</div>
				{voting.allDeputies
					.filter(this.filter)
					.sort((a, b) => {
						const ar = voting.isDeputyRegistered(a) ? 1 : 0;
						const br = voting.isDeputyRegistered(b) ? 1 : 0;
						return br - ar;
					})
					.map((deputy, i) => {
						const registered = voting.isDeputyRegistered(deputy);
						const className = classNames('user', {
							active: !!voting.user && voting.user.id === deputy.userId,
							registered,
						});
						return <div key={deputy.id} className={className} onClick={() => this.setUserForDeputy(deputy)}>
							<span className="num">{i + 1}</span>
							<span className="icon"><FontAwesomeIcon icon={registered ? faCheck : faMinus}/></span>
							<span className="fio" dangerouslySetInnerHTML={{ __html: voting.fio(deputy).replace(new RegExp(`(${this.search.trim()})`, 'ig'), '<em>$1</em>') }}/>
						</div>;
					})}
				
				<div className="hdr">Приглашенные</div>
				{voting.allInvited
					.filter(this.filter)
					.sort((a, b) => {
						const ar = voting.isUserRegistered(a) ? 1 : 0;
						const br = voting.isUserRegistered(b) ? 1 : 0;
						return br - ar;
					})
					.map((user, i) => {
						const registered = voting.isUserRegistered(user);
						const className = classNames('user', {
							active: !!voting.user && voting.user.id === user.userId,
							registered,
						});
						return <div key={user.id} className={className} onClick={() => voting.setUser(user)}>
							<span className="num">{i + 1}</span>
							<span className="icon"><FontAwesomeIcon icon={registered ? faCheck : faMinus}/></span>
							<span className="fio" dangerouslySetInnerHTML={{ __html: voting.fio(user).replace(new RegExp(`(${this.search.trim()})`, 'ig'), '<em>$1</em>') }}/>
						</div>;
					})}

				<div className="hdr">Аппарат</div>
				{voting.allStaff
					.filter(this.filter)
					.sort((a, b) => {
						const ar = voting.isUserRegistered(a) ? 1 : 0;
						const br = voting.isUserRegistered(b) ? 1 : 0;
						return br - ar;
					})
					.map((user, i) => {
						const registered = voting.isUserRegistered(user);
						const className = classNames('user', {
							active: !!voting.user && voting.user.id === user.userId,
							registered,
						});
						return <div key={user.id} className={className} onClick={() => voting.setUser(user)}>
							<span className="num">{i + 1}</span>
							<span className="icon"><FontAwesomeIcon icon={registered ? faCheck : faMinus}/></span>
							<span className="fio" dangerouslySetInnerHTML={{ __html: voting.fio(user).replace(new RegExp(`(${this.search.trim()})`, 'ig'), '<em>$1</em>') }}/>
						</div>;
					})}

			</div>
			
		</div>;
	}
	
}
