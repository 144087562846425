import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

// import flowJS from '!!file-loader?outputPath=js!./flowplayer/flowplayer-3.2.0.min';
// import flowSWF from './flowplayer/flowplayer-3.2.1.swf';
// import './flowplayer/flowplayer.rtmp-3.2.0.swf';
// import './flowplayer/flowplayer.controls-3.2.0.swf';
// import './home2.gif';

import { Page, ShareInSocial } from '../../components';
import store from '../../store';

// const LOCAL_VALUE = 'config={"clip":{"url":"livestream","provider":"influxis"},"plugins":{"influxis":{"url":"/static/flowplayer.rtmp-3.2.0.swf","netConnectionUrl":"rtmpt://fms.iltumen.ru/live/_definst_"}},"canvas":{"background":"#000000 url(/static/home2.gif) no-repeat","backgroundGradient":"none"},"showErrors":false,"playerId":"player","playlist":[{"url":"livestream","provider":"influxis"}]}';
// const ESPD_VALUE = 'config={"clip":{"url":"livestream","provider":"influxis"},"plugins":{"influxis":{"url":"/static/flowplayer.rtmp-3.2.0.swf","netConnectionUrl":"rtmpt://172.27.23.2/live/_definst_"}},"canvas":{"background":"#000000 url(/static/home2.gif) no-repeat","backgroundGradient":"none"},"showErrors":false,"playerId":"player","playlist":[{"url":"livestream","provider":"influxis"}]}';

const FlowPlayer = ({ network }) => {
	return <>
		{/*<script type="text/javascript" src={flowJS}/>*/}

		<iframe width="480" height="320" style={{display: 'block', margin: '0 auto'}}
				src={`http://old.iltumen.ru/translation/online-${network}.html`} frameBorder="0"
		/>
		<p><br /></p>
		<div style={{ textAlign: 'left', maxWidth: 800, margin: '0 auto' }}>
			
			<p><small>Внимание! Для просмотра видеотрансляции необходимо установить последнюю версию <a target="_blank" href="http://get.adobe.com/flashplayer/?promoid=BUIGP">Adobe Flash Player</a>.</small></p>
			<p><small>Если у Вас установлен Adobe Flash, но видеотрансляция не воспроизводится, то, возможно, Adobe Flash у Вас отключен. Для включения следуйте инструкциям ниже:</small></p>
			<p><small>
				<a href="https://support.google.com/chrome/answer/6258784?co=GENIE.Platform%3DDesktop&amp;hl=ru"
			        target="_blank">Включение Adobe Flash в Google Chrome</a><br/>
				<a href="https://support.mozilla.org/ru/kb/pochemu-ya-dolzhen-kliknut-chtoby-aktivirovat-plag"
			        target="_blank">Включение Adobe Flash в Mozilla Firefox</a><br />
				<a href="https://helpx.adobe.com/ru/flash-player/kb/install-flash-player-windows.html"
			        target="_blank">Включение Adobe Flash в Internet Explorer</a></small>
			</p>
			<p><small>Видеотрансляция для органов государственной власти Республики Саха (Якутия) доступна по следующей <a href="http://espd.iltumen.ru">ссылке </a></small></p>
		</div>
	</>;
};

@observer
export default class Online extends React.Component {

	@observable network = 'other';
	@observable page = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		if (props.network) {
			this.network = props.network;
			this.isLoading = false;
		}
		else {
			this.init();
		}
	}
	
	init = async () => {
		try {
			const info = await store.model.User.getInfo();
			this.network = info.network;
			const res = await store.model.Page.find({ where: { route: '/online' }});
			this.page = res.length > 0 ? res[0] : null;
		}
		catch (e) {
			console.error(e);
		}
		console.log('network', this.network);
		this.isLoading = false;
	};
	
	render() {
		return <div className="base-page container text-center">
			{!this.isLoading && <div>
				{this.page ?
					<h1 className="text-center">{this.page.title}</h1>
					:
					<h1 className="text-center">Видеотрансляция пленарного заседания<br />Государственного Собрания (Ил Тумэн)<br />Республики Саха (Якутия)</h1>
				}
				<p><br/></p>
				{this.network === 'other' && this.page && <div dangerouslySetInnerHTML={{__html: this.page.text}} />}
				{this.network !== 'other' && <FlowPlayer network={this.network} />}
				<p><br/></p>
				<div style={{ maxWidth: 600, margin: '0 auto' }}>
					<ShareInSocial text="Онлайн трансляция пленарного заседания Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)" />
				</div>
			</div>}
		</div>;
	}
	
}
