import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../button';
import { Loader } from '@smartplatform/ui';
import { getCurrentDeputies, formatDateStr, fio } from '../../../utils';
import { getRoleNames } from '../helpers';
import store from '../../../store';
import Protocol from './Protocol';
import './style.scss';

@observer
export default class SessionProtocol extends React.Component {
	@observable session = null;
	@observable stages = [];
	@observable allDeputies = [];
	@observable allMembers = [];

	@observable isLoading = true;
	@observable error = null;
	@observable redirect = false;

	constructor(props) {
		super(props);
		this.id = parseInt(this.props.match.params.id);
		this.init();
	}

	init = async () => {
		try {
			if (this.id) {
				this.session = await store.model.Session.findById(this.id, {
					include: ['chairman'],
				});

				this.allDeputies = await getCurrentDeputies({ ignoreEndDate: true, sessionDate: this.session.registrationStart });

				this.allMembers = await store.model.SessionMember.find({
					where: { sessionId: this.id },
					order: 'id desc',
				});

				const where = {
					and: [{ sessionId: this.id }, { voteEnd: { neq: null } }, { statusId: { neq: null } }, { canceledAt: null }],
				};

				let roleNames = [];

				if (store.model.user) {
					roleNames = await getRoleNames();
				}

				if (roleNames.includes('deputy')) {
					where.and.push({
						or: [{ publish: 'deputy' }, { publish: 'all' }],
					});
				} else if (!roleNames.includes('admin') && !roleNames.includes('protocolist') && !roleNames.includes('staff')) {
					where.and.push({ publish: 'all' });
				}

				this.stages = await store.model.ViewQuestionStage.find({
					where,
					include: ['question', 'status', 'type', 'votes'],
					order: 'voteStart asc',
				});

				this.allFractions = await store.model.Fraction.find({ include: ['deputies'] });
				this.allDepConvs = await store.model.DeputyConvocation.find();
			} else {
				this.error = 'Неверный ID';
			}
		} catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	render() {
		if (this.redirect) return <Redirect to={`/active-session/${this.sessionId}`} />;
		if (this.isLoading)
			return (
				<div className="protocol-init">
					<Loader />
				</div>
			);
		if (this.error) return <div className="protocol-init error">Ошибка: {this.error}</div>;

		const stages = this.stages.map((stage, i) => {
			return (
				<Protocol
					key={stage.id}
					number={i + 1}
					session={this.session}
					stage={stage}
					allDeputies={this.allDeputies}
					allMembers={this.allMembers}
					allFractions={this.allFractions}
					allDepConvs={this.allDepConvs}
				/>
			);
		});

		return (
			<div className="session-protocol">
				<div className="controls">
					<Button onClick={() => window.print()}>
						<FontAwesomeIcon icon={faPrint} /> Печать
					</Button>
				</div>
				{stages}
			</div>
		);
	}
}
