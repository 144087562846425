import React from 'react';
import { Section, Separator, Subscription } from 'root/site/components';
import MediaQuery from 'react-responsive';
import './styles.scss';
import MainSlider from './MainSlider';
import Laws from './Laws';
import Services from './Services';
import News from './News';
import PlenaryMeeting from './PlenaryMeeting';
import DirectSpeech from './DirectSpeech';
import Gallery from './Gallery';
import Video from './Video';
import Links from './Links';
import Ticker from './Ticker';
import Banner from './Banner';
import BannerAnniversary from "root/site/pages/main/BannerAnniversary";

export default class MainPage extends React.Component {
	render() {
		return (
			<>
				<Ticker />
				<MainSlider />
				<News />

				<div className='wrapper-banners'>
					<BannerAnniversary />
					<div className="container" >
						<Banner />
					</div>
				</div>
				
				<MediaQuery minWidth={1223}>
					<div className="background-page-light-grey2 pb-5" style={{ paddingTop: 15 }}>
						<Laws />
					</div>
					<div className="container pb-5 pt-5">
						<Services />
					</div>
				</MediaQuery>
				<MediaQuery maxWidth={1223}>
					<div className="container pb-5 pt-5">
						<Services />
					</div>
					<div className="background-page-light-grey2 pt-5 pb-5">
						<Laws />
					</div>
				</MediaQuery>

				<PlenaryMeeting />
				<div id="directSpeech"/>
				<div className="container pb-5 pt-5">
					<DirectSpeech />
				</div>
				<Subscription showOnMain />
				<div id="gallery"/>
				<div className="background-page-light-grey2 gallery-dots pt-5">
					<Gallery />
				</div>
				<div id="video"/>
				<div >
					<Video />
				</div>
				<div className="pt-5">
					<Links />
				</div>
			</>
		);
	}
}
