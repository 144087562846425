import store from '../../../store';
import { getSourceSessionParams } from '..';
import { getRoleNames } from '../helpers';

const ENABLED_ROLES = ['admin'];

export default async (session) => {
	if (!store.model.user) return false;
	
	const roleNames = await getRoleNames();
	
	const canCreateVote = ENABLED_ROLES.some(roleName => roleNames.some(name =>  name === roleName));
	
	const { foreignKey } = getSourceSessionParams(session);
	
	const votes = await store.model.Session.find({
		where: {
			and: [
				{ [foreignKey]: session.id },
				{ end: null },
			],
		}
	});

	const sessionId = votes.length > 0 ? votes[0].id : null;
	const canCreate = canCreateVote && votes.length === 0;
	
	// console.log('canCreateVotingForSession', canCreate, 'canCreateVote', canCreateVote, 'sessionId', sessionId);
	
	return { canCreate, sessionId };
};
