import { useEffect } from 'react';
import store from 'root/site/store';

const { ui } = store;

/*

	Установка корневого пути
	
*/

export const useSetRootPath = path => {
	useEffect(() => {
		ui.setRootPath(path);
		return () => {
			ui.setRootPath();
		};
	}, []);
};
