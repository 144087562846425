export default el => {
	const accords = el.getElementsByClassName('accordeon');
	for (let accord of accords) {
		// console.log('accord', accord);
		const header = accord.getElementsByClassName('accordeon-header')[0];
		if (header) {
			header.addEventListener('click', () => {
				if (accord.classList.contains('active')) {
					accord.classList.remove('active');
				}
				else {
					accord.classList.add('active');
				}
			});
		}
	}
	
};