import React from 'react';
import { observer } from 'mobx-react';

import voting from '../store-site';

@observer
export default class Votes extends React.Component {

	render() {
		const { stage } = this.props;
		
		if (stage.statusId !== 3) {
			
			let result = <div className="result">
				{stage.statusId === 1 ? <span className="green">Принято</span> : <span className="red">Не принято</span>}
				{(voting.isAdmin || voting.isStaff || voting.isProtocolist) && <a href={`/active-session/${voting.session.id}/protocol/${stage.id}`} target="_blank">Протокол</a>}
			</div>;
			
			if (voting.onlyVotes && voting.currentStage && voting.currentStage.id === stage.id) result = null;
			
			return <div className="votes-count votes-count-results">
				{/*<div>id: {stage.id}</div>*/}
				{result}
				<div className="count"><em>За</em><span/><strong>{stage.yesCount || 0}</strong></div>
				<div className="count"><em>Против</em><span/><strong>{stage.noCount || 0}</strong></div>
				<div className="count"><em>Воздержалось</em><span/><strong>{stage.abstainCount || 0}</strong></div>
				<div className="count total"><em>Голосовало</em><span/><strong>{stage.yesCount + stage.noCount + stage.abstainCount}</strong></div>
			</div>;
		}

		return <div className="canceled">
			<strong>Голосование отменено</strong><br />
		</div>
	};
	
	
}
