import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Results from './Results';
import store from '../../../store';
import voting from '../store-site';

@observer
export default class Inactive extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		const { question } = this.props;
		
		return <div className="question-panel">
			<Results question={question}/>
		</div>;
	}
	
}
