import React from 'react';
import ReactSVG from 'react-svg';
import MediaQuery from 'react-responsive';
import store from '../../store';

import { Separator, ElementItem, Section, VideoPreview } from 'root/site/components';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { customFormatVideo } from '../videogallery/customFormatVideo';

import { dateAndTime } from 'root/site/utils/date-format';
import button_play from 'root/site/img/button_play.svg';
import calendar from 'root/site/img/calendar.svg';
import { Badges } from '../../components/article/Badges';

@observer
class Video extends React.Component {
	@observable records;
	@observable tags;
	@observable showVideo;

	videoOptions = {
		where: {
			publish: true,
			publishAt: { lte: Date.now() },
		},
		order: 'publishAt DESC',
		include: [{ relation: 'tags', scope: { fields: ['id', 'image'] } }],
		limit: 3,
	};

	componentDidMount() {
		this.init();
	}

	init = async () => {
		const tags = await store.model.Tag.find({
			where: { type: 'media' },
			order: 'weight desc, id asc',
		});

		const records = await tags[0].medies.find(this.videoOptions);

		this.tags = tags;
		this.records = await customFormatVideo(records);
	};

	@action setShowVideo = video => {
		this.showVideo = video;
	};

	@action onTagSwitch = async tag => {
		const records = await this.tags.filter(e => e.id === +tag)[0].medies.find(this.videoOptions);
		this.records = await customFormatVideo(records);
	};

	render() {
		const { records, tags, showVideo, setShowVideo } = this;
		return (
			<MediaQuery minWidth={1223}>
				{showVideo && <VideoPreview record={showVideo}
											onClose={() => {
												history.pushState({}, ``, `/`);
												setShowVideo();
											}}
											onClick={history.pushState({}, ``, `/videogallery/${showVideo.id}`)}/>}

				<div className="container pt-5">
					<Section title="Видео" link="/videogallery"/>
				</div>
				<div className="sep-white">{tags ? <Separator tags={[...tags]} onTagSwitch={this.onTagSwitch} /> : null}</div>

				{!records && <div className="video-placeholder" />}

				{records && records.length === 0 && (
					<div>
						<h4>Видеоматериалы не найдены </h4>
					</div>
				)}

				{records && records.length > 0 && (
					<Section>
						<div className="container mb-5">
							<div className="row">
								<div className="col-8">
									{records && records.length > 0 && (
										<div className="main-video">
											<div className="main-video__overlay">
											<div className="badges">
													<Badges article={records[0]} width={200}/>
												</div>
												<div className="main-video__title">
													<img
														alt=""
														src={
															records[0].imported
																? '/api/containers/attachments/download/' + records[0].thumbnailImported
																: records[0].thumbnail
														}
														className="main-video-img"
													/>

													<ReactSVG
														className="main-video__play-button"
														src={button_play}
														onClick={() => setShowVideo(records[0])}
													/>
												</div>
											</div>
											<div className="direct-speech__info">
												<h1 dangerouslySetInnerHTML={{ __html: records[0].name }} />
												<ElementItem grey small text={dateAndTime(records[0].publishAt)} icon={calendar} top="2px" />
											</div>
										</div>
									)}
								</div>
								<div className="col-4">
									{records &&
										records.length > 1 &&
										records.slice(1, records.length).map(record => {
											const { id, name, publishAt, thumbnail, imported, thumbnailImported } = record;
											return (
												<div className="main-video" key={`video-${id}`}>
													<div className="main-video__overlay">
													<div className="badges">
															<Badges article={record} width={150} />
														</div>
														<div className="main-video__title">
															<ReactSVG
																className="main-video__play-button main-video__play-button-small"
																src={button_play}
																onClick={() => setShowVideo(record)}
															/>
															<img
																src={imported ? '/api/containers/attachments/download/' + thumbnailImported : thumbnail}
																className="main-video-img"
															/>
														</div>
													</div>
													<div className="direct-speech__side-item-text video__text-right mb-2">
														<h4 className="mb-3 mt-3">{name}</h4>
														<ElementItem grey end small icon={calendar} text={dateAndTime(publishAt)} top="2px" />
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</Section>
				)}
			</MediaQuery>
		);
	}
}

export default Video;
