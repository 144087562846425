import { useEffect } from 'react';
import store from '../../store';

import { PER_PAGE } from '../../utils/constants';

export const useSetRelationFilter = filter => {
	const { setRelationFilter, switchTag, setLimit, setDefaultLimit } = store.ui;
	useEffect(() => {
		setRelationFilter({ ...filter, limit: filter.limit ? filter.limit : PER_PAGE });
		setDefaultLimit(filter && filter.limit ? filter.limit : PER_PAGE);
		return () => {
			console.log('============ clanUP');
			setRelationFilter();
			switchTag();
			setDefaultLimit();
		};
	}, []);
};
