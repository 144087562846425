import React from 'react';
import ReactSVG from 'react-svg';
import store from 'root/site/store';

// Элемент иконки с теством
export const ElementItem = ({
	text,
	icon,
	textColor,
	link,
	className,
	end,
	center,
	small,
	medium,
	big,
	fill,
	bigIcon,
	top
}) => {
	const handleClick = () => {
		link && store.history.push(link);
	};
	return (
		<div
			className={`element-item ${className} ${fill ? `element-item__fill-${fill}` : ''} ${end ? 'element-item-end' : ''}
			${center ? 'element-item-center' : ''}
			}`}
			onClick={() => handleClick()}
		>
			<ReactSVG className={`element-item-icon  ${bigIcon ? 'element-item-icon-big' : ''}`} style={{top : top}} src={icon} />
			<span
				className={`element-item-text
					${link ? 'element-item-text-link' : ''}
					${small ? 'element-item-text-small' : ''}
					${medium ? 'element-item-text-medium' : ''}
					${big ? 'element-item-text-big' : ''}
				`}
				style={{ color: textColor ? textColor : '' }}
			>
				{text}
			</span>
		</div>
	);
};
