import React from 'react';
import ReactSVG from 'react-svg';

import service from './img/service.svg';
import store from '../../store';
import { Section } from 'root/site/components';

const gotoRecetion = () => {
	store.route.path = '/reception';
	store.route.push();
};

const gotoAppointments = () => {
	store.route.path = '/appointments';
	store.route.push();
};

const gotoDiscussions = () => {
	store.route.path = '/discussions';
	store.route.push();
};

const gotoDeputySearch = () => {
	store.route.path = '/deputy-search';
	store.route.push();
};

const Services = (props) => {
	return (
		<Section title="Сервисы">
			<div className="row mb-5">
			
			<div className="col-12 col-md-6 mb-md-5">
				<div className="services__btn services__btn-white mb-5" onClick={gotoRecetion}>
					<ReactSVG src={service} className="services__icon" />
					<span className="services__text">Интернет-приемная</span>
				</div>
				<div className="services__btn services__btn-red mb-5" onClick={gotoDiscussions}>
					<ReactSVG src={service} className="services__left" />
					<span className="services__text">Общественное обсуждение законопроектов</span>
				</div>
				</div>
				<div className="col-12 col-md-6 mb-md-5">
				<div className="services__btn services__btn-red mb-5" onClick={gotoDeputySearch}>
					<ReactSVG src={service} className="services__icon" />
					<span className="services__text">Поиск своего депутата</span>
				</div>
				<div className="services__btn services__btn-white mb-5">
					<ReactSVG src={service} className="services__left" />
					<span className="services__text" onClick={gotoAppointments}>Запись на прием к депутату</span>
				</div>
			</div>
			</div>
		</Section>
	);
};

export default Services;
