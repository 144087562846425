import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import Session from '../sessions/Session';
import PlenaryQuestions from './PlenaryQuestions';
import { isAdmin, isDeputy } from '../sessions/helpers';
import store from '../../store';

@observer
export default class Meeting extends React.Component {
	
	@observable session = null;
	@observable isAdmin = false;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const plenarySessionId = parseInt(this.props.match.params.id);
		if (plenarySessionId) {
			const res = await store.model.Session.find({
				where: { plenarySessionId },
				fields: ['id', 'end']
			});
			this.session = res.length > 0 ? res[0] : null;
		}
		this.isAdmin = await isAdmin();
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return <div className="container base-page">...</div>;
		if ((this.isAdmin || isDeputy()) && this.session && !this.session.end && this.session.plenarySessionId) {
			return <Redirect to={`/active-session/${this.session.id}`} />
		}
		
		return <div className="container base-page">
			{this.session ?
				<Session id={this.session.id} />
				:
				<PlenaryQuestions plenarySessionId={this.props.match.params.id} onCreate={this.init} />
			}
		</div>;
	}
	
}
