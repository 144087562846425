import React from 'react';
import { useGetRecords } from '../../utils/hooks';
import { Loader } from '@smartplatform/ui';

const Ticker = () => {
	const [records] = useGetRecords({
		model: 'Ticker',
	});

	if (!records || records.length === 0) return null;
	const ticker = records[0];
	if (!ticker.show) return null;

	return (
		<div className="ticker">
			<div>
				<span>{ticker ? ticker.text : 'Добавьте информацию'}</span>
			</div>
		</div>
	);
};
export default Ticker;
