import React from 'react';
import PropTypes from 'prop-types';
// import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Badge from './Badge';
import ButtonGroup from './ButtonGroup';
import './style.scss';

export default class Button extends React.Component {
	
	static propTypes = {
		/**
		 * Обработчик нажатия.
		 * @param {SyntheticEvent} event Объект события, см. <a href="https://reactjs.org/docs/events.html" target="_blank">документацию</a>.
		 */
		onClick: PropTypes.func,
		
		/**
		 * HTML-тип кнопки (*button*, *submit*, *reset* и т.д.).
		 *
		 * Еще один **параграф** в *Markdown*.
		 */
		type: PropTypes.string,
		
		/**
		 * Вариант кнопки.
		 * @since 3.0.0
		 */
		variant: PropTypes.oneOf(['default', 'grey', 'primary', 'green', 'danger', 'warning', 'link']),

		/**
		 * Размер кнопки.
		 */
		size: PropTypes.oneOfType([
			PropTypes.oneOf(['small', 'default', 'large']),
			PropTypes.number,
		]),

		/**
		 * Функция для получения DOM-элемента кнопки.
		 * @param {HTMLButtonElement} element DOM-элемент
		 */
		getRef: PropTypes.func,
		
		/** Признак "выключено". */
		disabled: PropTypes.bool,

		/**
		 * Текст кнопки.
		 * @deprecated с версии 3.0.0
		 */
		label: PropTypes.string,
		
	};
	
	static defaultProps = {
		variant: 'default',
		size: 'default',
		type: 'button',
	};
	
	static Badge = Badge;
	static Group = ButtonGroup;
	
	onMount = el => {
		this.button = el;
		this.props.getRef && this.props.getRef(el);
	};
	
	onLinkClick = e => {
		e.preventDefault();
		e.persist();
		this.handleClick(e);
	};
	
	handleClick = e => {
		// console.log('handleClick', e);
		if (this.props.onClick) this.props.onClick(e);
	};
	
	animate = () => {
		if (this.props.variant !== 'link' && this.button) {
			const button = this.button;
			requestAnimationFrame(() => {
				button.classList.remove('clicked');
				requestAnimationFrame(() => {
					button.classList.add('clicked');
				});
			});
		}
	};
	
	render() {
		const {
			variant,
			size,
			getRef,
			children,
			onClick,
			className: _className,
			style: _style,
			...rest
		} = this.props;
		
		const _children = React.Children.map(children, child => {
			if (typeof child === 'string' || typeof child === 'number') {
				return <span>{child}</span>
			}
			return child;
		});
		
		const className = classNames(
			'vote-btn',
			'btn-' + variant,
			typeof size !== 'number' && size !== 'default' ? 'btn-' + size : undefined,
			_className,
		);

		const style = typeof size === 'number' ?
			{ fontSize: size/2, height: size, lineHeight: `${size-2}px`, ..._style }
			:
			_style;
		
		if (variant === 'link') {
			return <a href="#" onClick={this.onLinkClick} className={className} ref={this.onMount} {...rest}>{children}</a>;
		}

		return (
			<button
				className={className}
				onMouseUp={this.animate}
				onClick={this.handleClick}
				style={style}
				ref={this.onMount}
				{...rest}>
				{_children}
			</button>
		);
		
	}
	
}
