import store from '../../../store';

export default async documentId => {
	const refLinks = await store.model.RefLink.find({
		where: { documentId: documentId },
		order: 'orderNum asc',
		include: [
			{
				relation: 'child',
				scope: {
					include: [
						{
							relation: 'refFiles',
							scope: {
								// fields: { id: true, description: true, filesize: true, docFileId: true },
								include: ['docFile']
							},
						},
						{
							relation: 'refLinks',
							scope: {
								include: [
									{
										relation: 'child',
										scope: {
											include: [
												{
													relation: 'refFiles',
													scope: {
														// fields: { id: true, name: true, description: true, filesize: true, docFileId: true },
														include: ['docFile']
													},
												},
											],
										}
									},
								],
							}
						}
					],
				}
			},
		],
	});
	
	const questions = [];
	
	for (let refLink of refLinks) {
		if (!refLink.child) continue;
		// console.log('> name', refLink.orderNum, refLink.child);
		
		const refFiles = [];
		refFiles.push(...refLink.child.refFiles());
		for (let _refLink of refLink.child.refLinks()) {
			refFiles.push(..._refLink.child.refFiles());
		}
		const filtered = refFiles.filter(file => !!file.docFile);
		const files = filtered.map(f => ({ id: f.docFile.id, name: f.docFile.contents, url: f.docFile.downloadFile('contents') }));
		
		// for (let file of files) {
		// 	console.log('-- file', file);
		// }
		
		questions.push({
			id: refLink.child.id,
			name: refLink.child.name,
			order: refLink.orderNum,
			files
		});
	}
	
	return questions;
};
