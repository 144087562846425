import store from '../../../store';
import getRoleNames from './getRoleNames';

export default async () => {
	if (!store.model.user) return false;
	
	const roleNames = await getRoleNames();
	
	return roleNames.includes('admin');
};
