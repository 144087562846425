import React from 'react';
import { Tabs } from 'root/site/components';
// import ContactGuide from './ContactGuide';
// import ContactApparatus from './ContactApparatus';
import ContactMap from './ContactMap';
import ContactInf from './ContactInf';
import { useBreadcrumbs } from 'root/site/utils/hooks';
import { Page } from '../../components';
import './styles.scss';

const ContactsPage = () => {
	useBreadcrumbs('Контакты', '/contacts');
	return (
		<>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 note-page-title mb-md-5 mt-5">
						<h1>Контактная информация</h1>
					</div>
				</div>
			</div>

			<div className="container pb-5 pt-5">
				<div className="row align-items-center">
					<div className="col-md-6 col-12">
						<ContactInf />
					</div>
					<div className="col-md-6 col-12">
						<ContactMap />
					</div>
				</div>
			</div>

			<div className="background-page-light-grey">
				<div className="container">
					<div className="row justify-content-center mb-4">
						<div className="col-12">
							<div className="note-page-title mb-5 mt-5">
								<h1>
									Телефонный справочник руководства, председателей постоянных комитетов и комиссии, аппарата
									Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="sep-white">
				<Tabs>
					Руководство, председатели постоянных комитетов и комиссии
					<Page route="/contacts/contacts-direction" contentOnly applyBreadcrumbs={false} />
					Аппарат <Page route="/contacts/contacts-apparatus" contentOnly applyBreadcrumbs={false} />
				</Tabs>
			</div>
		</>
	);
};

export default ContactsPage;
