import React, {useState, useEffect} from 'react';
import store from 'root/site/store';
import {Link} from "react-router-dom";


export default function PlenaryList(props) {
    const [list, setList] = useState([])
    useEffect(()=>{
        getList()
    },[])

    async function getList() {
        const l = await store.model.PlenarySession.find({order:'createdAt desc'});
        setList(l)
    }

    return <div className="container fontX2 search-page">
        <h1>Пленарные заседания</h1>
        <div>
            {list.map((e, i) => <div key={i}><Link to={`/plenary/${e.id}`}>{e.name}</Link></div>)}
        </div>
    </div>
}
