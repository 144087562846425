import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

import { Loader } from '@smartplatform/ui';
import { Button } from '../button';
import Results from './Results';
import store from '../../../store';
import { STATUS_INACTIVE, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED, STATUS_CALCULATING } from '../store-site';
import voting from '../store-site';

@observer
export default class Deputy extends React.Component {
	
	@observable voted = false;
	@observable isLoading = false;
	
	constructor(props) {
		super(props);
	}
	
	vote = async result => {
		this.voted = true;
		try {
			await voting.vote(this.props.question, result);
		}
		catch (e) {
			console.error(e);
			this.voted = false;
		}
	};
	
	toggleRegister = async typeId => {
		this.isLoading = true;
		if (!voting.currentRegistration) {
			// register
			await voting.speechRequest(typeId);
		}
		else {
			// cancel
			await voting.speechCancel(typeId);
		}
		this.isLoading = false;
	};
	
	render() {
		const { question, status } = this.props;
		
		if (!voting.session.registrationStart) {
			return <div className="deputy-panel">Ожидание регистрации</div>
		}
		
		if (!voting.isQuorum) {
			return <div className="deputy-panel">Ожидание кворума</div>
		}
		
		if (status === STATUS_CANCELED) {
			return <div className="deputy-panel">
				Голосование отменено
			</div>;
		}
		
		if (!voting.member) {
			return <div className="deputy-panel">Зарегистрируйтесь на голосование</div>
		}
		
		if (status === STATUS_DISCUSSED) {
			const registeredType = voting.currentRegistration ? voting.currentRegistration.typeId : null;
			const isActive = voting.currentStage && voting.currentStage.activeQueueId &&
				voting.currentStage.activeQueue.userId === voting.user.id;
			return <div className="deputy-panel">
				{!isActive ?
					<>
						<div>Заявка на выступление:</div>
						<div className={'speech-request' + (this.isLoading ? ' is-loading' : '')}>
							<Button
								className={'register-btn' + (registeredType === 1 ? ' active' : '')}
								disabled={registeredType !== null && registeredType !== 1}
								onClick={() => this.toggleRegister(1)}>
								Выступление
							</Button>
{/*
							<Button
								className={'register-btn' + (registeredType === 2 ? ' active' : '')}
								disabled={registeredType !== null && registeredType !== 2}
								onClick={() => this.toggleRegister(2)}>
								По ведению
							</Button>
							<Button
								className={'register-btn' + (registeredType === 3 ? ' active' : '')}
								disabled={registeredType && registeredType !== 3}
								onClick={() => this.toggleRegister(3)}>
								На вопросы
							</Button>
*/}
						</div>
					</>
					:
					<>
						<div>Выступление</div>
						<FontAwesomeIcon icon={faMicrophone}/> Микрофон
					</>
				}
			</div>
		}
		
		if (status === STATUS_CALCULATING) {
			return <div className="deputy-panel">
				<Loader size={20} /> Идет подсчет голосов...
			</div>
		}
		
		return <div className="deputy-panel">
			<Results useView question={question}/>
		</div>;
	}
	
}
