import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatDateStr } from '../../tools';
import store from '../../../../store';

@observer
export default class Appointment extends React.Component {
	
	@observable appointment = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.id = this.props.match.params.id;
		this.appointment = await store.model.Appointment.findById(this.id, {
			include: ['deputy'],
		});
	};
	
	render() {
		if (!this.appointment) return null;
		
		return <div className="appointment-page">
			<p>Заявка № <strong>{this.id}</strong> от <strong>{formatDateStr(this.appointment.createdAt)}</strong></p>
			
			<div><label>От:</label> {this.appointment.lastName} {this.appointment.firstName} {this.appointment.middleName}</div>
			<div><label>E-mail:</label> <a href={`mailto:${this.appointment.email}`}>{this.appointment.email}</a></div>
			{this.appointment.phone && <div><label>Контактный телефон:</label> {this.appointment.phone}</div>}
			{this.appointment.address && <div><label>Адрес места проживания:</label> {this.appointment.address}</div>}
			{this.appointment.subject && <div><label>Тема вопроса: </label> {this.appointment.subject}</div>}
 
		</div>;
	}
	
}
