export { default as cutEmptyParagraphs } from './cutEmptyParagraphs';
export { default as validateEmail } from './validateEmail';
export * from './dateUtils';
export { default as getCurrentDeputies, sortDep } from './getCurrentDeputies';
export { default as takeScreenshot, openScreenshot } from './takeScreenshot';
export { default as debugLog } from './debugLog';

export const fio = user => (user ? `${user.lastName || '-'} ${user.firstName || ''} ${user.middleName || ''}` : '-');
export const fioShort = user =>
	user
		? `${user.lastName || '-'} ${user.firstName ? user.firstName.substr(0, 1) + '.' : ''} ${
				user.middleName ? user.middleName.substr(0, 1) + '.' : ''
		  }`
		: '';
