import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import * as Pages from './pages';
import * as Components from './components';
import UILayer from './UILayer';
import store from './store';

import '@smartplatform/ui/src/styles/vars.scss';
import 'bootstrap-4-grid/scss/grid.scss';
import './styles/main.scss';
import { PrintCommitte } from './pages/committee/PrintCommitte';

@observer
export default class App extends React.Component {
	render() {
		return (
			<Router history={store.history} onUpdate={() => window.scrollTo(0, 0)}>
				{store.model.isInitialized && (
					<Switch>
						<Route exact path="/committees/:id/print" component={PrintCommitte} />
						<Route exact path={`/news/:id/print`} component={Pages.PrintVersion} />
						<Route exact path={`/questions/:id/print`} component={Pages.PrintQuestionsDocuments} />
						<Route exact path={`/questions/:id/preview`} component={Pages.DocumentPreview} />

						<Route path="/active-session/:id" exact component={Pages.ActiveSession} />
						<Route path="/active-session/:id/online" exact component={Pages.SessionMembers} />
						<Route path="/active-session/:id/protocol" exact component={Pages.SessionProtocol} />
						<Route path="/active-session/:id/protocol/:questionId" exact component={Pages.QuestionProtocol} />
						<Components.MainLayout>
							<UILayer />
						</Components.MainLayout>
					</Switch>
				)}
			</Router>
		);
	}
}
