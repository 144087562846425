import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../store';
import empty from '../../img/user.png';
import { userName } from './tools';

@observer
export default class Main extends React.Component {

	@observable user = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.user = await store.model.User.findById(store.model.user.id);
	};
	
	render() {
		if (!this.user) return '...';
		
		return <div className="profile-page">
			<div className="avatar">
				{this.user.avatar ?
					<img src={this.user.downloadFile('avatar')} />
					:
					<img src={empty} />
				}
			</div>
			<div className="details">
				<h2>{userName(this.user)}</h2>
				{this.user.username && <div className="field">
					Логин: <strong>{this.user.username}</strong>
				</div>}
				{this.user.email && <div className="field">
					E-mail: <strong>{this.user.email}</strong>
				</div>}
			</div>
		</div>;
	}
	
}
