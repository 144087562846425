import React, { useState } from 'react';
import Slider from 'react-slick';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import img from './img/newimg.jpg';
import { dateAndTime } from 'root/site/utils/date-format';

import { MediaPreview } from '../../components';
import sliderArrow from './img/sliderArrow.svg';
import { Badges } from '../../components/article/Badges';

const resize = '?image=[{"resize":{"width":1110}}]';

const PrevArrow = ({ onClick }) => (
	<ReactSVG className="photo-slider__arrow-prev" src={sliderArrow} onClick={onClick} />
);
const NextArrow = ({ onClick }) => (
	<ReactSVG className="photo-slider__arrow-next" src={sliderArrow} onClick={onClick} />
);

export const PhotoSlider = ({ noTitle, records, noPreview }) => {
	const [showGallery, setShowGallery] = useState();

	const Slide = ({ slide, noTitle }) => {
		const { name, publishAt, thumbnail, file } = slide;
		const img = slide.downloadFile('thumbnail');
		return (
			<div className="photo__slide" onClick={() => setShowGallery(slide.id)}>
				<div className="slide-photo">
					<div className='badges'>
						<Badges article={slide} width={200}/>
					</div>
					<img
						alt={name}
						className="main-slider-wrapper_img"
						src={
							(slide.imported
								? '/api/containers/attachments/download/' + slide.thumbnailImported
								: slide.downloadFile('thumbnail')) + resize
						}
					/>

					{!noTitle && (
						<div className="main-slider-wrapper-text">
							<h2 className="slide-photo-title">{name}</h2>
							<span className="note-page__date-small slide-photo-date">{dateAndTime(publishAt)}</span>
						</div>
					)}
				</div>
			</div>
		);
	};

	const slidesToShow = 1;
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow,
		slidesToScroll: 1,
		speed: 1000,
		cssEase: 'linear',
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			// {
			// 	breakpoint: 900,
			// 	settings: {
			// 		slidesToShow: 3,
			// 		slidesToScroll: 1,
			// 		initialSlide: 2,
			// 	},
			// },
			// {
			// 	breakpoint: 480,
			// 	settings: {
			// 		slidesToShow: 2,
			// 		slidesToScroll: 1,
			// 	},
			// },
		],
	};

	return (
		<>
			{showGallery && !noPreview && <MediaPreview galleryId={showGallery}
														onClose={() => {
															history.pushState({}, ``, `/`);
															setShowGallery();
														}}
														onClick={history.pushState({}, ``, `/photogallery/${showGallery}`)} />}
			<Slider {...settings}>
				{records.map((record, index) => {
					const { file } = record;
					return (
						<Slide key={`slide-${index}`} noTitle={noTitle} slide={record} onClick={() => setShowGallery(record)} />
					);
				})}
			</Slider>
		</>
	);
};
