import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Calendar, Separator } from '../../components';
import store from '../../store';
import Gallery from './Gallery';
// import { PER_PAGE } from 'root/site/utils/constants';
// import Pager from 'react-pager';
import { Loader, Pager } from '@smartplatform/ui';
import './Gallery.css';

const PER_PAGE = 5;
const TYPE = 'gallery';

const customFormat = recordsList => {
	const records = [];
	for (const record of recordsList) {
		const thumbnail = record.thumbnail ? record.downloadFile('thumbnail') : '';
		const views = record.views()?.totalCount;
		const imagesCount = record.images()?.totalCount;
		const tags = record.tags;
		records.push({ ...record, views, thumbnail, imagesCount, tags });
	}
	return records;
};

@observer
class GalleryList extends React.Component {
	@observable showVideo;
	@observable records;
	@observable tags = store.ui.tags.filter(tag => tag.type === TYPE) || [];
	@observable page;
	@observable totalCount;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.init();
	}

	@action init = async () => {
		const { records, page, totalCount } = await store.ui.getTagsWithRecords({
			model: 'Gallery',
			type: TYPE,
			relation: 'galleries',
			perPage: PER_PAGE,
			include: [{ relation: 'images', scope: { limit: 0 } }, { relation: 'tags' }],
			customFormat,
		});
		this.records = records;
		console.log(this.records,'r');
		this.page = page;
		this.totalCount = totalCount;
	};

	render() {
		const { tags } = this;
		const {
			route,
			ui: { rootPath },
		} = store;

		return (
			<div className="background-page-light-grey">
				<div className="container mb-5 pt-5">
					<div className="row">
						<div className="col-6">
							<h3>Фотогалерея</h3>
						</div>
						<div className="col-6">
							<Calendar />
						</div>
					</div>
				</div>
			
					<Separator
						tags={[{ name: 'Все галереи', id: 'all' }, ...tags || []]}
						onTagSwitch={e => {
							route.push({ path: rootPath, params: { page: 1, cat: e } });
						}}
					/>
				

				<div className="container pb-5 pt-5">
					{this.records ? (
						<>
							<div className="row">
								<Gallery records={this.records} {...this.props} />{' '}
							</div>
							<div className="container pager-div">
								<Pager
									itemsPerPage={PER_PAGE}
									totalCount={this.totalCount}
									current={this.page}
									onChange={page => {
										route.push({ params: { ...route.params, page: page } });
									}}
									className="iltumen-pager"
									directChange={true}
								/>
							</div>{' '}
						</>
					) : (<div style={{height:'100vh',display:'flex',justifyContent:'center'}}>
						<Loader />
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default GalleryList;
