import React from 'react';
import ReactSVG from 'react-svg';
import calendar from 'root/site/img/calendar.svg';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale'

import './style.scss';

export default ({ date, dateStr, className }) => <div className={'last-updated ' + className}>
	<div className="row align-items-center">
		<div className="col-12 col-md-6 pt-3">
			<span className="note-page__date note-page__date-grey">Дата последнего изменения</span>
		</div>
		<div className="col-12 col-md-6">
			<span className="note-page__date note-page__date-right">
				<ReactSVG className="simple-icon simple-icon-dark" src={calendar} /> {date ? format(date, 'dd MMMM yyyy', { locale: ru }) : dateStr}
			</span>
		</div>
	</div>
</div>;
