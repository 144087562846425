import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import store from 'root/site/store';
import { Loader, HtmlValue, Button } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import logo from 'root/site/img/print-logo.png';
import { sortDep } from '../../utils/getCurrentDeputies';

const positions = {
	0: 'член комитета',
	1: 'заместитель председателя',
	2: 'председатель комитета',
};

const positionsCommission = {
	0: 'член комиссии',
	1: 'заместитель председателя комиссии',
	2: 'председатель комиссии',
};

@observer
class _PrintCommitte extends React.Component {
	@observable record;
	async componentDidMount() {
		this.record = await store.model.Committee.findById(this.props.id, {
			include: [
				{
					relation: 'deputies',
					scope: {
						order: 'id asc',
						where: { deputyId: { neq: null } },
						include: [
							{
								relation: 'deputy',
								fields: { id: true, lastName: true, firstName: true, middleName: true, photo: true },
							},
						],
					},
				},
			],
		});
	}

	render() {
		if (!this.record) {
			return (
				<div className="container base-page">
					<Loader />
				</div>
			);
		}

		const sorted = this.record.deputies().sort((a, b) => sortDep(a.deputy, b.deputy));

		const members = sorted.filter(depCom => !depCom.positionNum);
		const chairman = sorted.filter(depCom => depCom.positionNum === 2);
		const zams = sorted.filter(depCom => depCom.positionNum === 1);

		const depComs = [...chairman, ...zams, ...members];

		const pos = this.record.id !== 15 ? positions : positionsCommission;

		const deputies = depComs.map(depCom => {
			const deputy = depCom.deputy;
			const photo = deputy.photo ? <img src={deputy.downloadFile('photo')} /> : <img src={empty} />;
			return (
				<div key={deputy.id} className="deputy">
					<div className="deputy-block" onClick={() => this.gotoDeputy(deputy)}>
						<div className="photo">{photo}</div>
						<div className="info">
							<div
								className="name"
								style={deputy.isDead ? { border: '2px solid black', display: 'inline-block', padding: '0 5px 0 5px' } : {}}
							>
								{deputy.lastName} {deputy.firstName} {deputy.middleName}
							</div>
							<div className="position">{pos[depCom.positionNum || 0]}</div>
							{deputy.contact && <HtmlValue className="comment" value={deputy.contact} />}
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className="print-version-wrapper committe">
				<div className="print-version__header">
					<img className="print-version__header-logo" src={logo}></img>
					<span>
						<span className="header__title-big">Государственное Cобрание (ИЛ ТУМЭН) </span>
						<span className="header__title-small">Республики Саха (Якутия)</span>
						<span className="header__title-small">Официальный сайт</span>
					</span>
				</div>
				<div className="content">
					<h3>{this.record.name}</h3>
					<div className="deputies">{deputies}</div>
				</div>
			</div>
		);
	}
}

export const PrintCommitte = ({ match }) => {
	const componentRef = useRef();
	const { id } = match.params;
	return (
		<div>
			<ReactToPrint
				trigger={() => (
					<div className="print-version__button">
						<Button label="Распечатать" />
					</div>
				)}
				content={() => componentRef.current}
			/>

			<_PrintCommitte ref={componentRef} id={id} />
		</div>
	);
};
