import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import head from './pobeda75.png';
import { Tab, Tabs } from '../../components/route-tabs';
import store from '../../store';
import proccessAccordeons from '../../components/page/accordeons';
import './style.scss';

const CODES = [
	'our-victory',
	'social',
	'stalingrad',
];

@observer
class Page extends React.Component {

	@observable content = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const pages = await store.model.Page.find({
			where: {
				and: [
					{ route: this.props.code },
				],
			}
		});
		this.content = pages.length > 0 ? pages[0] : null;
		this.isLoading = false;
	};
	
	onMount = el => {
		if (el) {
			proccessAccordeons(el, this);
		}
	};
	
	render() {
		if (this.isLoading) return '';
		
		return <div
			ref={this.onMount}
			className="static-page"
			dangerouslySetInnerHTML={{ __html: this.content ? this.content.text : 'Страница не найдена' }}
		/>;
	}
}

@observer
export default class Pobeda75 extends React.Component {

	render() {
		return <div className="container">
			<div className="base-page pobeda75">
				<div className="head">
					<img src={head} className="head-img" alt="" />
				</div>
				<div className="content">
					<Tabs>
						<Tab path={`/pobeda75`} title="Наша победа" exact><Page code="/pobeda75/our-victory" /></Tab>
						<Tab path={`/pobeda75/social`} title="Меры социальной поддержки" exact><Page code="/pobeda75/social" /></Tab>
						<Tab path={`/pobeda75/stalingrad`} title="Воины-якутяне в Сталинградской битве" exact><Page code="/pobeda75/stalingrad" /></Tab>
					</Tabs>
				</div>
			</div>
		</div>;
	}
	
}
