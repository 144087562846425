import React from 'react';
import { useMediaQuery } from 'react-responsive'
import './styles.scss';
import ReactSVG from 'react-svg';
import arrow from 'root/site/img/arrow.svg';
const tabl = [
	
	{
		name: 'Субъект права законодательной инициативы',
		number: 'Депутаты Государственной Думы О.Н.Алимова, В.А.Ганзя, Н.В.Коломейцев, А.В.Корниенко, А.В.Куринный, В.Ф.Рашкин, М.В.Щапов',
    },
    {
		name: 'Тематический блок',
		number: 'Сельское хозяйство, природопользование и земельные отношения',
    },
    {
		name: 'Профильный комитет',
        number: 'Комитет Государственной Думы по труду, социальной политике и делам ветеранов',
    },
    
    {
		name: 'Направление законотворческой деятельности',
		number: 'Нормативные правовые акты республиканского значения',
    },
    {
		name: 'Дополнительно',
		number: 'Внесение изменений и дополнений',
    },
    {
		name: 'Ответственный комитет',
        number: 'К-т по земельным отношениям, природным ресурсам и экологии',
    },
    
    {
		name: 'Проект закона',
        number: 'ГС № 122-VI (1 чтение Правительство РС(Я))',
	},
];

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 767 })
	return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
  }

export default class PassportDetails extends React.Component {
    constructor() {
		super();
		this.state = {
		arrow: false,
		show: true
	};
		this.Show = this.Show.bind(this);

	}

	
	Show() {
        this.setState({show: !this.state.show});
        this.setState({arrow: !this.state.arrow});
	}
    
    
	render() {
        
      
        const class1 = this.state.arrow ? 'arrow__icon' : 'arrow__icon_active';
		if (this.state.show) {
        return (
            <>
            	<div className="background-page-light-grey-mobile">
                <div className="container pb-5 pt-5">
                <div className="row">
                                
                                <div className="col-12">
        <div className="print-version"> <a onClick={this.Show}>   <span> Паспортные данные</span>
        <ReactSVG className={class1}  src={arrow} /></a></div></div>
            </div>
        </div> </div>

        <div className="container pb-5 pt-5">
                <div className="row">
                                
                                <div className="col-12">
            <table className="table_law">
             
                <tbody>
                    {
                        tabl.map( function(item,index)
                            {
                                return(
                                    <tr key={index}> 
                                        <th data-label={item.name}>{item.number}</th>
                                    </tr>
                                )
                            }
                        )
                    }</tbody>
            </table>
            
            </div>
            </div>
            </div>
           
            </>
         );
        }
        else {
            return (
                <>
                <div className="background-page-light-grey-mobile">
                <div className="container pb-5 pt-5">
                <div className="row">
                                
                                <div className="col-12 col-md-6">
                                <div className="print-version"> <a onClick={this.Show}>   <span> Паспортные данные</span>
        <ReactSVG className={class1}  src={arrow} /></a>
        </div>
                                
                                </div>
                                </div>
                                </div>
                                </div>

               
                </>
            );
        }
        
            }
        }

    