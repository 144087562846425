import React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';

import './style.scss';

@observer
export default class QuestionStatus extends React.Component {

	render() {
		const { question } = this.props;

/*
		let status = 'inactive';
		let statusName = '';
		
		const stage = question.stages().length > 0 ? question.stages()[0] : null;
		let typeName = '';
		
		if (stage) {
			typeName = stage.type ? stage.type.name : stage.customType || '';
			if (stage.statusId) {
				status = stage.statusId === 1 ? 'yes' : stage.statusId === 2 ? 'no' : 'canceled';
				statusName = stage.statusId === 1 ? 'Принято' : stage.statusId === 2 ? 'Не принято' : 'голосование отменено';
			} else if (!stage.voteStart && !stage.voteEnd && stage.discussionStart && !stage.discussionEnd) {
				status = 'is-discussed';
				statusName = 'идет обсуждение';
			} else if (!stage.voteStart && !stage.voteEnd && stage.discussionEnd) {
				status = 'awaiting-voting';
				statusName = 'ожидание голосования';
			} else if (stage.voteStart && !stage.voteEnd) {
				status = 'is-voting';
				statusName = 'идет голосование';
			}
		}
		
		let name = question.order ? `Вопрос №${question.order}` : question.code === 'procedural' ? 'Процедурный' : 'Утверждение повестки';
		
		return <div className={'question-status ' + status}>
			<span className="disc" />
			{name}{statusName ? ':' : ''} {statusName} {stage ? typeName ? `(${typeName})` : '' : ''}
		</div>;
*/
		let name = question.order ? `Вопрос №${question.order}` : question.code === 'procedural' ? 'Процедурный' : 'Утверждение повестки';

		return <div className="question-status">
			<span className="disc" />
			{name}
		</div>;
	}
	
}
