import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Votes from './Votes';
import VotesFromView from './VotesFromView';
import StagesEdit from './StagesEdit';
import {
	STATUS_INACTIVE,
	STATUS_DISCUSSED,
	STATUS_VOTING,
	STATUS_VOTED,
	STATUS_CANCELED,
	STATUS_CALCULATING,
	STATUS_FINISHED,
} from '../store-site';
import voting from '../store-site';

@observer
export default class Protocolist extends React.Component {
	
	render() {
		const { question, status } = this.props;
		
/*
		const currentStage = (voting.currentStage && voting.currentStage.questionId === question.id) ?
			<div className="current-stage">{voting.getStageName(voting.currentStage)}</div>
			:
			null;
*/
		
		const validStages = question.stages()
			.filter(stage => stage.canceledAt === null)
			// .filter(stage => stage.voteEnd !== null && stage.canceledAt === null)
			.sort((a, b) => b.id - a.id);
		
		// console.log('validStages', validStages.map(s => s.id));
		
		const lastValidStage = validStages[0];
		console.log('lastValidStage', lastValidStage);
		
		return <div className="protocolist-panel">
			<StagesEdit question={question} />
			{lastValidStage ?
				<>
					{/*<div className="current-stage">{voting.getStageName(lastValidStage)}</div>*/}
					<VotesFromView stage={lastValidStage} />
				</>
				:
				null
			}
		</div>;
	}
	
}
