import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';

import { HtmlValue } from '@smartplatform/ui';
import { cutEmptyParagraphs } from '../../utils';
import proccessAccordeons from './accordeons';
import proccessLinks from './links';
import { Tabs, Tab } from '../route-tabs';
import { Menu, MenuItem } from '../route-menu';
import { Links, LinkItem } from '../route-links';
import store from '../../store';

import './style.scss';

@withRouter @observer
export default class Page extends React.Component {

	static propTypes = {
		id: PropTypes.number,
		route: PropTypes.string,
		code: PropTypes.string,
		contentOnly: PropTypes.bool,
		afterContent: PropTypes.any,
		allowEmptyParagraphs: PropTypes.bool,
		breadCrumbs: PropTypes.array,
	};
	
	static defaultProps = {
		contentOnly: false,
		allowEmptyParagraphs: true,
		breadCrumbs: [],
	};
	
	@observable page = null;
	@observable error = null;
	
	constructor(props) {
		super(props);
		if (!props.id && !props.code && !props.route) {
			throw new Error(`You must provide one of the required props to Page: 'id' or 'code' or 'route'`);
		}
		this.init();
	}
	
	init = async () => {
		try {
			if (this.props.id) {
				this.page = await store.model.Page.findById(this.props.id, {
					include: [
						{ relation: 'nav' },
						{
							relation: 'children',
							scope: {
								fields: ['id', 'route', 'title', 'navId'],
								order: 'order asc',
							}
						},
						{
							relation: 'parent',
							scope: {
								fields: ['id', 'route', 'title', 'navId'],
								include: [
									{ relation: 'nav' },
								],
								order: 'order asc',
							}
						},
					]
				});
				
			}
			else if (this.props.code || this.props.route) {
				const route = this.props.code || this.props.route;
				console.log('route', route);
				const pages = await store.model.Page.find({ where: { route }});
				if (pages.length > 0) {
					this.page = pages[0];
				}
				else {
					throw new Error('Страница не найдена');
				}
			}
			const breadcrumbs = [...this.props.breadCrumbs, ...this.getBreadCrumbs(this.page)];
			// console.log('=>', breadcrumbs);
			store.ui.setBreadcrumbs(breadcrumbs);
		}
		catch (e) {
			this.error = e.message;
		}
	};
	
	getBreadCrumbs = page => {
		let breadcrumb = [{ name: page.title, link: page.route }];
		if (page.__parent) {
			breadcrumb = [...this.props.breadCrumbs, ...this.getBreadCrumbs(page.__parent), ...breadcrumb];
		}
		return breadcrumb;
	};
	
	gotoLink = (e, href) => {
		console.log('gotoLink', e, href);
		e.preventDefault();
		this.props.history.push(href);
	};
	
	onMount = el => {
		if (el) {
			proccessLinks(el);
			proccessAccordeons(el);
		}
	};
	
	supplyParentsWithFirstChild = (page, firstChild) => {
		return page.parent ?
			<>
				<Route path={page.parent.route} exact render={() => <Page id={firstChild.id} contentOnly />} />
				{this.supplyParentsWithFirstChild(page.parent, firstChild)}
			</>
			:
			null;
	};
	
	render() {
		if (!this.page && this.error) return <div className="container base-page static-page">{this.error}</div>;
		if (!this.page) return <div className="container base-page static-page"/>;
		
		const { contentOnly, afterContent, allowEmptyParagraphs } = this.props;
		
		let content;
		
		const pageContent = <>
			<HtmlValue className="editor-text" value={allowEmptyParagraphs ? this.page.text : cutEmptyParagraphs(this.page.text)} />
			{afterContent}
		</>;
		
		console.log('render', this.page.route);
		
		const children = this.page.children();
		let hideNav = false;
		
		if (this.page.nav) {
			let supplyParents = true;
			let Parent = Tabs;
			let Item = Tab;
			
			if (this.page.nav.code === 'menu') {
				Parent = Menu;
				Item = MenuItem;
			}
			
			if (this.page.nav.code === 'links') {
				Parent = Links;
				Item = LinkItem;
				supplyParents = false;
				if (new RegExp(`^${this.page.route}\/`, '').test(store.route.path)) {
					hideNav = true;
				}
				console.log('?', this.page.route, store.route.path, hideNav);
			}
			
			content = <>
				{this.page.showText === 'above' ? pageContent : null}
				<Parent className="static-page-nav">
					{children.length ? children.map((page, i) => <Item key={page.id} path={page.route} title={page.title}>
						<Page id={page.id} contentOnly />
					</Item>) : null}
					{supplyParents ?
						<>
							{children.length ?
								<>
									<Route path={this.page.route} exact render={() => <Page id={children[0].id} contentOnly />} />
									{supplyParents ? this.supplyParentsWithFirstChild(this.page, children[0]) : null}
								</>
								:
								<Page id={this.page.id} contentOnly />
							}
						</>
						:
						null
					}
				</Parent>
				{this.page.showText === 'below' ? pageContent : null}
			</>;
		}
		else {
			content = <>
				{pageContent}
				{children.length ? children.map((page, i) => <Route key={page.id} path={page.route} render={() => <Page id={page.id} contentOnly />}/>) : null}
			</>;
		}

		return contentOnly ?
			<div className={'static-page' + (hideNav ? ' hide-nav' : '')} ref={this.onMount}>
				{content}
			</div>
			:
			<div className="container base-page static-page" ref={this.onMount}>
				{!this.page.nav && this.page.showTitle !== false && <h2>{this.page.title}</h2>}
				{content}
			</div>;
	}
	
}
