import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import store from '../../store';
import List from './List';
import Single from './Single';

export default () => {
	useEffect(() => {
		return () => {
			console.log('will unmount');
			store.ui.setBreadcrumbs([]);
		};
	}, []);
	return (
		<div className="container documents">
			<Route path="/documents" exact component={List} />
			<Route path="/documents/:id" component={Single} />
		</div>
	);
};
