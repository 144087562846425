import html2canvas from 'html2canvas';

export default async function takeScreenshot(selector = '#root') {
	return new Promise((resolve, reject) => {
		let node = document.querySelector(selector);
		// console.log('takeScreenshot', node);
		html2canvas(node)
			.then(canvas => {
				let pngUrl = canvas.toDataURL();
				resolve(pngUrl);
			});
	});
};

export const openScreenshot = async (selector = '#root') => {
	const data = await takeScreenshot(selector);
	const img = new Image();
	img.src = data;
	const w = window.open('');
	w.document.write(img.outerHTML);
};
