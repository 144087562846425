import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import SessionSummary from './session-summary/SessionSummary';
import { formatDateStr } from 'root/site/utils';
import CurrentQuestion from './current-question/CurrentQuestion';
import QuestionCard from './question-card/QuestionCard';
import FilePages from './file-pages';
import { Admin } from './admin';
import voting from './store-site';
import store from '../../store';

import './style.scss';

@observer
export default class Session extends React.Component {

	constructor(props) {
		super(props);
		voting.user = store.model.user;
		if (this.props.id) {
			voting.id = parseInt(this.props.id);
			voting.init('constructor, id: ' + voting.id);
		}
		else {
			voting.error = 'Не указан ID.';
		}
	}
	
	componentDidMount() {
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.id && parseInt(this.props.id) !== voting.id) {
			voting.id = parseInt(this.props.id);
			voting.init('componentDidUpdate');
		}
	}
	
	componentWillUnmount() {
		clearTimeout(voting.votingTimer);
		voting.stopUpdates('Session');
		voting.reset();
	}
	
	onMount = el => {
		// console.log('onMount', el);
	};
	
	render() {
		if (voting.redirect) {
			console.log('>>>>', voting.redirect);
			return <Redirect to="/profile" />;
		}
		if (voting.error) return <div className="error">Session error: {voting.error}</div>;
		if (!voting.session) return <div className="base-page">Загрузка...</div>;
		
		let _questions = voting.questions;
		if (voting.isParticipant) {
			if (!voting.session.end) {
				if (voting.currentQuestion) {
					_questions = _questions.filter(question => voting.currentQuestion.id !== question.id);
				}
			}
			else {
				// теперь они внезапно захотели видеть процедурные вопросы, и выдают это за баг :(
				// _questions = _questions.filter(question => question.code !== 'procedural');
				_questions.sort((a, b) => {
					const a0 = a.code === 'agenda' ? 1 : 0;
					const b0 = b.code === 'agenda' ? 1 : 0;
					return b0 - a0;
				});
			}
		}
		if (!voting.isParticipant) {
			_questions = _questions.filter(question => question.code === 'question');
		}
		
		const questions = <div className="questions-container" ref={this.onMount}>
			{_questions.map(question => <QuestionCard key={question.id} question={question} />)}
		</div>
		
		return voting.isAdmin && !voting.session.end ?
			<div className="voting">
				<div className="session-control-panel">
					<h1>{voting.session.name}</h1>
					{voting.user && <SessionSummary />}
					<Admin />
				</div>
			</div>
			:
			voting.isParticipant ?
				<div className="voting">
					<div className="session-control-panel">
						<h1>{voting.session.name}</h1>
						{voting.user && <SessionSummary />}
						{!voting.session.end && <CurrentQuestion />}
						<FilePages />
					</div>
					{questions}
				</div>
				:
				<div className="voting">
					<h1>{voting.session.name}</h1>
					{/*<div>deputy: {JSON.stringify(voting.deputy)}</div>*/}
					{/*<div>isAdmin: {voting.isAdmin}</div>*/}
					<FilePages />
					{questions}
				</div>;
	}
	
}
