import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select, Checkbox } from '@smartplatform/ui';
import { VOTEEND_INTERVAL, STATUS_INACTIVE, STATUS_DISCUSSED, STATUS_VOTING, STATUS_VOTED, STATUS_CANCELED, STATUS_CALCULATING } from '../store-site';
import { Button } from '../button';
import Results from '../panels/Results';
import CurrentQuestionPanel from './CurrentQuestionPanel';
import VotingSettings from './VotingSettings';
import Requests from '../panels/speech-requests';
import voting from '../store-site';

@observer
export default class CurrentQuestion extends React.Component {

	@observable fraction = null;
	@observable from = null;
	@observable gotoVoting = false;

	constructor(props) {
		super(props);
	}
	
	render() {
		const stage = voting.currentStage;
		let status = STATUS_INACTIVE;
		
		if (stage) {
			if (stage.canceledAt) {
				status = STATUS_CANCELED;
			}
			else if (stage.voteEnd) {
				const timePassed = new Date().getTime() - new Date(stage.voteEnd).getTime();
				status = timePassed > VOTEEND_INTERVAL ? STATUS_VOTED : STATUS_CALCULATING;
			}
			else if (stage.voteStart) {
				status = STATUS_VOTING;
			}
			else if (stage.discussionStart) {
				status = STATUS_DISCUSSED;
			}
		}

		return <div className="admin-panel">
			{/*<div>status: {status}</div>*/}
			<div style={{ display: 'none' }}>{voting.lastUpdateDate.getTime()}</div>
			{voting.currentStage ?
				<>
					<div className="current-question-info">
						{voting.currentQuestion && voting.currentQuestion.order && <div className="order">Вопрос №{voting.currentQuestion.order}</div>}
						<div className="name">{voting.getQuestionName(voting.currentQuestion)}</div>
						<div className="stage">{voting.currentStage.type ? voting.currentStage.type.name : voting.currentStage.customType}</div>
					</div>
					<CurrentQuestionPanel status={status} />
				</>
				:
				<Requests />
			}
		</div>;
	}
	
}
