import React from "react";
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import store from '../../store';
import { Row, Checkbox } from '@smartplatform/ui';
import './style.scss';
@observer
export default class Subscriptions extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable clearAll = false;
	@observable submitted = false

	constructor(props) {
		super(props);
		this.init();
	}
	@action init = async () => {

		this.record = await store.model.Subscription.findOne({
			where: { hash: this.props.match.params.hash }
		});
		this.isLoading = false;
	};
	onNewsChange = (e) => {
		this.record.news = e
	}
	onDirectSpeechChange = (e) => {
		this.record['direct-speech'] = e
	}
	onFotoChange = (e) => {
		this.record.photo = e
	}
	onVideoChange = (e) => {
		this.record.video = e
	}
	onClearAll = (e) => {
		if (e) {
			this.clearAll = true
			this.record.news = null
			this.record['direct-speech'] = null
			this.record.photo = null
			this.record.video = null
		}
		else this.clearAll = false
	}
	onSubmit = async () => {
		console.log('dd')
		await this.record.save()
		this.submitted = true
		setTimeout(() => this.props.history.push("/"), 3000)
	}

	render() {
		if (this.isLoading) return '...';
		console.log(this.record)

		return <div className='subscription-list' >
			<div className='wrapper'>
				<h2>Управление рассылкой</h2>
				<div>E-mail: <span className='email'>{this.record.name}</span></div>
				<div>На какие материалы подписаться?</div>

				<Row>
					<Checkbox checked={this.record.news} label="Новости" onChange={this.onNewsChange} />
				</Row>
				<Row>
					<Checkbox checked={this.record['direct-speech']} label="Прямая речь" onChange={this.onDirectSpeechChange} />
				</Row>
				<Row>
					<Checkbox checked={this.record.video} label="Видео" onChange={this.onVideoChange} />
				</Row>
				<Row>
					<Checkbox checked={this.record.photo} label="Фото" onChange={this.onFotoChange} />
				</Row>
				<Row>
					<Checkbox checked={this.clearAll && (!this.record.news && !this.record['direct-speech'] && !this.record.video && !this.record.photo)} label="Отписаться от всех" onChange={this.onClearAll} />
				</Row>

				{this.submitted ?
					<div className='submitted'>Подписка изменена!</div>
					:
					<div className='submit-btn'>
						<button className="red-btn" onClick={this.onSubmit}>Сохранить</button>
					</div>
				}
			</div>
		</div >
	}
}

