import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faFile, faTimes } from '@fortawesome/free-solid-svg-icons';

import { MaskedInput, Popup, Loader, Select } from '@smartplatform/ui';
import store from '../../store';
import { APPEAL_NEW } from './statuses';
import validateEmail from '../../utils/validateEmail';
import getCurrentDeputies from '../../utils/getCurrentDeputies';
import { ReCaptcha } from '../../components';
import './style.scss';

@observer
export default class Reception extends React.Component {
	@observable register = false;
	@observable password = '';
	@observable password2 = '';
	@observable passwordError = '';
	@observable password2Error = '';

	@observable lastName = '';
	@observable firstName = '';
	@observable middleName = '';
	@observable email = '';
	@observable phone = '';
	@observable address = '';
	@observable deputy = null;
	@observable deputies = [];
	@observable text = '';

	@observable lastNameError = '';
	@observable firstNameError = '';
	@observable middleNameError = '';
	@observable emailError = '';
	@observable phoneError = '';
	@observable addressError = '';
	@observable deputyError = null;
	@observable textError = '';
	@observable connectError = '';
	@observable agreeError = '';

	@observable agreement = false;
	@observable isLoading = false;
	@observable initialized = false;
	@observable appealId = null;
	@observable file = null;

	@observable agree = false;
	@observable valid = false;

	constructor(props) {
		super(props);
		this.acceptFiletypes = [".doc", ".docx", ".xls", ".xslx", ".pdf", ".jpg", ".jpeg", ".gif", ".tiff", ".bmp", ".txt", ".rar", ".zip"];
		store.ui.setBreadcrumbs([{ name: 'Интернет-приемная', link: '/reception' }]);
		this.init();
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
	}

	onBtnMount = el => {
		this.btn = el;
	};

	onMouseMove = e => {
		if (this.btn && this.agree) {
			const x0 = e.clientX;
			const y0 = e.clientY;
			const rect = this.btn.getBoundingClientRect();
			const x1 = rect.x;
			const y1 = rect.y;
			const x2 = x1 + rect.width;
			const y2 = y1 + rect.height;
			const dist = 50;
			if (x0 > x1 - dist && x0 < x2 + dist && y0 > y1 - dist && y0 < y2 + dist) {
				// console.log('-', x0, y0, x1, y1);
				this.checkLastName();
				this.checkFirstName();
				this.checkMiddleName();
				if (!this.emailError) this.checkEmail();
				this.checkDeputy();
				this.checkText();
				if (this.register) {
					this.checkPassword();
					this.checkPassword2();
				}
			} else {
				this.resetErrors();
			}
		}
	};

	@action init = async () => {
		if (store.model.isAuthorized) {
			const user = await store.model.User.findById(store.model.user.id);
			this.lastName = user.lastName || '';
			this.firstName = user.firstName || '';
			this.middleName = user.middleName || '';
			this.email = user.email;
		}
		this.deputies = await getCurrentDeputies();

		const urlParams = new URLSearchParams(window.location.search);
		const deputy = urlParams.get('deputy');
		this.deputy = deputy ? this.deputies.find(dc => dc.id === parseInt(deputy)) : null;

		// document.addEventListener('mousemove', this.onMouseMove);
		this.initialized = true;
	};

	@action resetErrors = () => {
		this.lastNameError = '';
		this.firstNameError = '';
		this.middleNameError = '';
		if (!this.register) this.emailError = '';
		this.phoneError = '';
		this.addressError = '';
		this.deputyError = null;
		this.textError = '';
		this.connectError = '';
		this.passwordError = '';
		this.password2Error = '';
	};

	onLastNameChange = e => (this.lastName = e.target.value);
	onFirstNameChange = e => (this.firstName = e.target.value);
	onMiddleNameChange = e => (this.middleName = e.target.value);
	onEmailChange = e => {
		this.email = e.target.value;
		this.emailError = '';
	};
	onPhoneChange = e => (this.phone = e.target.value);
	onAddressChange = e => (this.address = e.target.value);
	onDeputyChange = deputy => (this.deputy = deputy);
	onTextChange = e => (this.text = e.target.value);
	onPasswordChange = e => (this.password = e.target.value);
	onPassword2Change = e => (this.password2 = e.target.value);

	deputyName = deputy => {
		// console.log('deputy', deputy);
		return deputy ? `${deputy.lastName || ''} ${deputy.firstName || ''} ${deputy.middleName || ''}` : '';
	};

	@action checkLastName = () => {
		this.lastName = this.lastName ? this.lastName.trim() : '';
		const valid = this.lastName.length > 0;
		if (!valid) this.lastNameError = 'Введите фамилию';
		return valid;
	};

	@action checkFirstName = () => {
		this.firstName = this.firstName ? this.firstName.trim() : '';
		const valid = this.firstName.length > 0;
		if (!valid) this.firstNameError = 'Введите имя';
		return valid;
	};

	@action checkMiddleName = () => {
		this.middleName = this.middleName ? this.middleName.trim() : '';
		const valid = this.middleName.length > 0;
		if (!valid) this.middleNameError = 'Введите отчество';
		return valid;
	};

	@action checkEmail = () => {
		this.email = this.email.trim();
		/*
		if (this.email.length === 0) {
			if (this.address.trim().length > 0) {
				return true;
			}
			else {
				this.addressError = 'Заполните поля E-mail или адрес';
			}
		}
*/
		const { valid, message } = validateEmail(this.email);
		this.emailError = message || '';
		return valid;
	};

	@action checkAddress = () => {
		this.address = this.address ? this.address.trim() : '';
		const valid = this.address.length > 0;
		if (!valid) this.addressError = 'Введите адрес';
		return valid;
	};

	@action checkPassword = () => {
		const valid = this.password.length > 0;
		if (!valid) this.passwordError = 'Введите пароль';
		return valid;
	};

	@action checkPassword2 = () => {
		if (this.password2.length === 0) {
			this.password2Error = 'Повторите пароль';
			return false;
		}
		if (this.password !== this.password2) {
			this.password2Error = 'Пароли не совпадают';
			return false;
		}
		return true;
	};

	toggleRegister = e => (this.register = e.target.checked);

	checkDeputy = () => {
		const valid = !!this.deputy;
		if (!valid) this.deputyError = 'Выберите адресата';
		return valid;
	};

	checkText = () => {
		const valid = this.text.length > 0;
		if (!valid) this.textError = 'Введите текст обращения';
		return valid;
	};

	@action onSubmit = async e => {
		e.preventDefault();
		this.resetErrors();
		let errors = 0;

		if (!this.valid) return;

		if (!this.checkLastName()) errors++;
		if (!this.checkFirstName()) errors++;
		if (!this.checkMiddleName()) errors++;
		if (!this.checkEmail()) errors++;
		if (!this.checkDeputy()) errors++;
		if (!this.checkText()) errors++;
		if (!this.agree) {
			this.agreeError = 'Вы должны дать согласие';
			errors++;
		}

		if (this.register) {
			if (!this.checkPassword()) errors++;
			if (!this.checkPassword2()) errors++;
			const users = await store.model.User.find({
				where: {
					email: this.email,
				},
			});
			if (users.length > 0) {
				this.emailError = 'Такой E-mail уже зарегистрирован';
				errors++;
			}
		}

		if (errors === 0) {
			this.isLoading = true;

			const appeal = new store.model.Appeal();
			appeal.lastName = this.lastName;
			appeal.firstName = this.firstName;
			appeal.middleName = this.middleName;
			appeal.email = this.email;
			appeal.phone = this.phone;
			appeal.address = this.address;
			appeal.deputy = this.deputy;
			appeal.text = this.text;
			appeal.statusId = APPEAL_NEW;

			try {
				if (this.register) {
					await store.model.register({
						firstName: this.firstName,
						middleName: this.middleName,
						lastName: this.lastName,
						email: this.email,
						password: this.password,
					});
					await store.model.login(this.email, this.password);
				}

				appeal.noHook = !!this.file;
				await appeal.save();
				if (this.file) {
					await appeal.uploadFile('attachment', this.file);
					appeal.attachment = this.file.name;
					appeal.noHook = false;
					await appeal.save();
				}
				this.appealId = appeal.id;

				this.props.history.push({
					pathname: `/reception/${appeal.id}`,
					state: { isNewUser: this.register },
				});
			} catch (e) {
				this.connectError = e.message;
			}

			this.isLoading = false;
		}
	};

	showAgreement = e => {
		e.preventDefault();
		this.agreement = true;
	};

	closeAgreement = () => {
		this.agreement = false;
	};

	onFileAttach = e => {
		if((e.target.files[0].size > 52428800) || !this.acceptFiletypes.includes(`.${e.target.files[0].name.split('.').pop()}`)) { //50Мб -> 52428800байт
			return;
		}
		this.file = e.target.files[0];
		console.log('onFileAttach', this.file);
	};

	deleteFile = () => (this.file = null);

	onAgreeChange = e => {
		this.agree = e.target.checked;
		if (this.agree) this.agreeError = '';
	};

	onCaptchaChange = res => (this.valid = res.success);
	onCaptchaExpired = () => (this.valid = false);

	render() {
		const isUser = store.model.isAuthorized;

		const deputies = this.deputies.map(d => ({ label: this.deputyName(d), value: d }));

		return (
			<div className={'container reception' + (this.isLoading ? ' is-loading' : '')}>
				<h1>Интернет-приемная</h1>

				<form onSubmit={this.onSubmit}>
					<div className="row">
						<div className="col-lg-5 col-md-5 col-xs-12">
							<div className="form-field">
								<input
									type="text"
									value={this.lastName}
									onChange={this.onLastNameChange}
									placeholder="Фамилия *"
									disabled={isUser && store.model.user.lastName}
								/>
								<div className="error">{this.lastNameError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.firstName}
									onChange={this.onFirstNameChange}
									placeholder="Имя *"
									disabled={isUser && store.model.user.firstName}
								/>
								<div className="error">{this.firstNameError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.middleName}
									onChange={this.onMiddleNameChange}
									placeholder="Отчество *"
									disabled={isUser && store.model.user.middleName}
								/>
								<div className="error">{this.middleNameError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.email}
									onChange={this.onEmailChange}
									placeholder="E-mail *"
									disabled={isUser}
								/>
								<div className="error">{this.emailError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.address}
									onChange={this.onAddressChange}
									placeholder="Адрес места жительства"
								/>
								<div className="error">{this.addressError}</div>
							</div>
							<div className="form-field">
								<MaskedInput
									mask="+7 999 999 9999"
									value={this.phone}
									onChange={this.onPhoneChange}
									placeholder="Контактный телефон"
								/>
								<div className="error">{this.phoneError}</div>
							</div>
							{!isUser && (
								<div className="form-field register">
									<input type="checkbox" checked={this.register} onChange={this.toggleRegister} />{' '}
									Создать личный кабинет
								</div>
							)}
							{this.register && (
								<>
									<div className="form-field">
										<input
											type="password"
											value={this.password}
											autoComplete="new-password"
											onChange={this.onPasswordChange}
											placeholder="Пароль *"
										/>
										<div className="error">{this.passwordError}</div>
									</div>
									<div className="form-field">
										<input
											type="password"
											value={this.password2}
											autoComplete="new-password"
											onChange={this.onPassword2Change}
											placeholder="Повторите пароль *"
										/>
										<div className="error">{this.password2Error}</div>
									</div>
								</>
							)}
						</div>
						<div className="col-lg-7 col-md-7 col-xs-12">
							<div className="form-field">
								<Select
									items={deputies}
									value={this.deputy}
									showValue={this.deputy ? this.deputyName(this.deputy) : <span>Адресат *</span>}
									onChange={this.onDeputyChange}
								/>
								<div className="error">{this.deputyError}</div>
							</div>
							<div className="form-field">
								<textarea
									value={this.text}
									onChange={this.onTextChange}
									placeholder="Ваше обращение *"
									rows={10}
								/>
								<div className="error">{this.textError}</div>
							</div>
							<div className="form-field">
								<input
									type="file"
									id="attachment"
									onChange={this.onFileAttach}
									accept={this.acceptFiletypes.join(',')}
								/>
								<label htmlFor="attachment">
									<FontAwesomeIcon icon={faPaperclip} /> Прикрепить файл
								</label>
								{this.file && (
									<span className="file-info">
										<FontAwesomeIcon icon={faFile} />
										<span className="filename">{this.file.name}</span>
										<span className="delete-btn" onClick={this.deleteFile}>
											<FontAwesomeIcon icon={faTimes} />
										</span>
									</span>
								)}
								<div className="hint">
									Принимаются файлы с расширениями <em>.doc, .docx, .xls, .xslx, .pdf, .jpg, .jpeg, .gif, .tiff, .bmp, .txt, .rar, .zip</em> до 50
									Мб
								</div>
							</div>
							<span className="form-field agreement">
								<input type="checkbox" value={this.agree} onChange={this.onAgreeChange} /> Я даю
								согласие на обработку персональных данных
								<div className="error">{this.agreeError}</div>
							</span>

							<ReCaptcha onChange={this.onCaptchaChange} onExpired={this.onCaptchaExpired} />

							<div className="submit">
								<button className="red-btn" type="submit" ref={this.onBtnMount} disabled={!this.valid}>
									Отправить
								</button>
								{this.isLoading && <Loader />}
								<div className="error">{this.connectError}</div>
							</div>
						</div>
					</div>
				</form>
				{this.agreement && (
					<Popup portalClassName="agreement-popup" width={800} height={500} onClose={this.closeAgreement}>
						<h2>Agreement</h2>
						<div style={{ height: 600 }} />
					</Popup>
				)}
			</div>
		);
	}
}
