import React from 'react';
import ReactSVG from 'react-svg';
import calendar from 'root/site/img/calendar.svg';
import { Accordion } from 'root/site/components';
import { withRouter } from 'react-router-dom';
import { useBreadcrumbs } from 'root/site/utils/hooks';


const Foreigners = () => {
	useBreadcrumbs('Устав об управлении инородцев', '');
		return (
			<>
			<div className="background-page-light-grey">
			
			<div className="container pb-5 pt-5">
				<div className="row align-items-center">
					<div className="col-6">
						<h2 className="header-2">УСТАВ ОБ УПРАВЛЕНИИ ИНОРОДЦЕВ</h2>
					</div>
					<div className="col-6">
					<span className="note-page__date note-page__date-right">
					<ReactSVG className="simple-icon simple-icon-dark" src={calendar} /> 22 июля 1822 года</span>
					</div>
				</div>
			</div>
		</div>
		<div className="background-page-light-grey">
		<div className="container pb-5 pt-5">
				<div className="row">
				<div className="col-12">
				<p className="pb-5" style={{textAlign : 'center'}}>
					<p>Полное собрание законов Российской империи с 1849 г. Т. 38. № 29. 120 сс. 394-416</p>
					<p>[Извлечение]</p>
					<p><em>Текст взят из: Кряжков В.А. Статус малочисленных народов России. Правовые акты. - М.: "Юринформцентр", 1999</em></p>
					</p>

					<Accordion title="Часть I. ПРАВА ИНОРОДЦЕВ">
					<p><strong>Глава I. Разделение</strong></p>
					<p>§ 1. Все обитающие в Сибири инородные племена, именуемыя по ныне ясачными, по различному степени Гражданского их образования и по настоящему образу жизни, разделяются на три главные разряда. В первой разряд включаются оседлые, то есть живущие в городах и селениях; во второй кочевые, занимающие определенные места по временам года переменяемыя; в третий бродячие или ловцы, переходящие с одного места на другое по рекам и урочищам.</p>
					<p>§ 7. По сему общему разделению инородцев, определяются права их и обязанности; а дабы сие определение имело надлежащий степень точности: то постановляется правилом при каждой общей переписи, к коей обыкновенно и инородцы изчисляются, наблюдать вышеозначенное разделение и каждый род относить к тому разряду, к коему по образу жизни он принадлежит. Между тем, дабы сие разделение для устройства инородцев необходимое, ныне же привести в действие: то на Гражданских Губернаторов возлагается, сообразив в Губернаторском Совете нынешнее состояние инородцев, особым расписание определить о каждом роде и племени, к какому именно разряду по вышеозначенному разделению должны быть оные отнесены.</p>
					<p>§ 11. Вообще при составлении росписания разделять инородцев по качеству главнаго их промысла, составляющаго главный способ их пропитания, а не по тем занятиям, кои могут они иметь случайно, временно и совокупно с главным.</p>
					
					<p><strong>Глава II. Общия права оседлых инородцев</strong></p>
					<p>§ 12. Оседлые инородцы, Христианскую веру исповедующие, не отличаются от Россиян никаким особым названием, те же из них, кои исповедуют языческую или магометанскую веру, для различия от прочих именуются, оседлыми инородцами.</p>
					<p>§ 13. Все вообще оседлые инородцы сравниваются с Россиянами в правах и обязанностях по сословиям, в который они вступят. Они управляются на основании общих узаконении и учреждений.</p>
					
					<p><strong>Глава V. Общия права кочевых инородцев</strong></p>
					<p>§ 24. Кочевые инородцы составляют особенное сословие в равном степени с крестьянским, но отличное от онаго в образе управления.</p>
					<p>§ 25. Кочующие инородцы остаются вообще на прежних их правах. Им внушить надлежит, что с умножением хлебопашества, они отнюдь не будут против воли их обращаемы в звание крестьян и вообще без собственнаго их желания не будут включены ни в какое другое сословие.</p>
					<p>§ 26. Кочующие инородцы для каждаго поколения имеют назначенный во владение земли.</p>
					<p>§ 27. Подробное разделение участков сих земель зависит от самих кочующих по жеребью или другим их обыкновениям.</p>
					<p>§ 28. Утверждаются во владении кочующих земли, ныне ими обитаемыя, с тем, чтоб окружность, каждым племенем владеемая, была по распоряжению местнаго Начальства подробно определена.</p>
					<p>§ 29. Инородцы имеют свободу заниматься земледелием, скотоводством и местными промыслами на водах и землях, каждому роду назначенных.</p>
					<p>§ 30. Инородцы ограждаются от взаимных стеснений, какия могут производить от перехода одних племен на земли, другим племенам принадлежащий, для производства промыслов, без обоюднаго на то согласия.</p>
					<p>§ 31. Строго запрещается Россиянам самовольно селиться на землях, во владение инородцам отведенных.</p>
					<p>§ 32. Россияне могут брать у инородцев места в оброчное содержание, но всегда по условиям с обществами.</p>
					<p>§ 33. Наймы инородцев в частную работу имеют быть производимы с ведома родоваго Начальства, как в особом положении о долгах и обязательствах крестьян и инородных предписано.</p>
					<p>§ 34. Инородцы управляются собственными своими Родоначальниками и почетными людьми, из коих составляется их степное Управление.</p>
					<p>§ 35. Кочующие управляются по степным законам и обычаям, каждому племени свойственным.</p>
					<p>§ 36. В уголовных только преступлениях кочующие судятся в Присутственных местах и по общим Государственным установлениям.</p>
					<p>§ 37. Уголовными делами относительно инородцев почитать: </p>
						   
					<ol>
						<li><p>возмущение,</p></li>
						<li><p>намеренное убийство,</p></li>
						<li><p>грабеж и насилие,</p></li>
						<li><p>делание ложной монеты и вообще похищение казеннаго и обще-ственнаго имущества.</p></li>
					</ol>
					<p> Все же прочие дела, не выключая и кражу, пока нравы их образованием не умягчатся, считать исковыми.</p>
					<p>§ 38. В проступках в Российских городах и селениях разбирают они местною Полицией) на основании общих положений.</p>
					<p>§ 39. Инородцы платят подати по особому положению с числа душ, которое определяется общею переписью (ревизиею).</p>
					<p>§ 40. Кочующие инородцы участвуют в общих по Губернии повинностях, как о том особенно постановлено.</p>
					<p>§ 41. Содержание степнаго Управления составляет внутреннюю повинность кочующих.</p>
					<p>§ 42. Все кочующие инородцы освобождаются от рекрутской повинности.</p>
					<p>§ 43. Казачьи полки из кочующих, для охранения границ составленные, комплектуются по прежнему.</p>
					<p>§ 44. От употребления гербовой бумаги, как равно и от других штемпелевых налогов, кочующие освобождаются.</p>
					<p>§ 45. Инородцы имеют право сбывать свои произведения и лов продажею и меною в городах, селениях и на учрежденных ярмарках.</p>
					<p>§ 46. Позволяется свободная торговля с кочующими во всякое время всеми припасами и изделиями, кроме горячих напитков.</p>
					<p>§ 47. Ввоз и продажа горячих напитков в стойбищах и на ярмарках кочующих строжайше запрещается.</p>
					<p>§ 48. Торговля с кочующими служащим в той же Губернии чиновникам, под каким бы то предлогом и видом ни было, запрещается.</p>
					<p>§ 49. Служащие чиновники не должны входить ни в какое частное посредство по торговле с кочующими и запрещается им ручаться за них по долгам или другим условиям.</p>
					<p>§ 50. В долговых делах частных людей с кочующими инородцами, наблюдается особенной порядок, в положении о долгах предписанный.</p>
					<p>§ 51. Необходимое пособие некоторым инородцам в продовольствии и промыслах, как то: продажа хлеба, пороха и свинца, в приличных местах доставляется от Губернскаго Начальства.</p>
					<p>§ 52. Кочующим дается право на отлучки по словесному позволению их старшин в соседственные города, селения и ярмарки, хотя бы и далее ста верст было. На отлучку далее 500 верст обязаны они от Земской полиции иметь письменный вид, как равно и в случае наймов у Русских в годовыя услуги.</p>
					<p>§ 53. Кочующие иноверцы пользуются свободою в вероисповедании и Богослужении.</p>
					<p>§ 54. Воспринявшие из них Христианскую веру, имеют право строить Христианския церкви с дозволения Епархиальнаго Архиерея.</p>
					<p>§ 55. Кочующим иноверцам дозволяется строить молитвенные домы, но всегда с разрешения Гражданскаго Губернатора.</p>
					<p>§ 56. Воспринятие Христианской веры не препятствует им оставаться на прежних правах.</p>
					<p>§ 57. Позволяется кочующим, если некоторые из них водворятся оседло, по собственной их воле вступать в сословие Государственных крестьян, равно в городовые жители и записываться в гильдии, на установленных правилах, без всякаго ограничения и стеснения и притом с свободою от рекрутства.</p>
					<p>§ 58. Инородцы имеют право отдавать детей своих для обучения в учрежденный от Правительства учебныя заведения. Имеют право заводить и собственный школы, но не иначе, как с позволения Гражданских Губернаторов или областных Начальников.</p>
					<p>§ 59. Каждый из инородцев и целые роды имеют полную свободу в учрежденных местах приносить жалобы на стеснения и обиды.</p>
					<p>§ 60. Права инородцев должны быть надлежащим образом им объявлены. Губернское или Областное Начальство обязано сделать должное по сему распоряжение, надзирать и ответствовать за исполнение. Все касающееся до сего предмета перевесть на язык инородцев, и буде можно, напечатать на оном, или на языке, с употребляемым ими сходственном и им понятном.</p>
					<p><strong>Глава VI. Права бродячих инородцев</strong></p>
					<p>§ 61. Права бродячих инородцев или ловцов, живущих в отдалении и разсеянии, вообще состоят в применении правил, для кочующих постановленных.</p>
					<p>§ 62. Изъятия и ограничения заключаются в следующем:</p>
					<ol className="ml-5">
							<li><p>Назначение земель по племенам и разделение оных по участкам, на бродячих инородцев не распространяется. Им назначаются по удобности целыя полосы земли и определяются токмо границы оных с землями, оседлым жителям и кочующим инородцам принадлежащими.</p></li>
							<li><p>Бродячие инородцы не участвуют в денежных Земских по Губернии повинностях и не производят никаких расходов на содержание степнаго Управления.</p></li>
							<li><p>На занимаемой ими полосе позволяется им переходить для промыслов из уезда в уезд и из Губернии в Губернии без всякаго стеснения.</p></li>
						</ol>
					
					<p><strong>Глава VIII. О законах и обычаях</strong></p>
					<p>§ 68. Все кочующие и бродячие инородцы, как выше сказано, управляются по их собственным степным законам и обычаям. Но поелику законы сии и обычаи в каждом племени имеют некоторое и часто важное от других отличие, притом же, сохраняясь поныне чрез одни изустныя предания, могут быть и сбивчивы и неопределенны" то по сим причинам предоставляется местному Начальству от почетнейших людей собрать полныя и подробныя о сих законах сведения, разсмотреть оныя по Губерниям в особых Временных Комитетах, смягчить все дикое и жестокое, отменить несообразное с другими установлениями, и разположив в надлежащем порядке, представить местному Главному Управлению на утверждение.</p>
					<p>§ 69. Таким образом, утвержденные степные законы имеют быть напечатаны на Российском языке, и если можно на языках тех самых племен, до коих оные относятся, или на языке, с употребляемым ими сходственном.</p>
					<p>§ 70. Законы сии и Российскими Присутственными местами приемлются в основание в суждениях о делах кочующих и бродячих инородцев.</p>
					<p>§ 71. По напечатании сих законов представить оные в Правительствующий Сенат, и не допускать потом в них никаких изменений, пока с переменою образа жизни и степени образования, не будет надобности изменить образ самаго их управления.</p>
					<p>§ 72. Недостаток в степных законах при решении дел дополняется Российскими узаконениями.</p>
					</Accordion>
					<Accordion title="Часть II. СОСТАВ УПРАВЛЕНИЯ ИНОРОДЦАМИ">
						<p><strong>Глава I. Управление оседлыми инородцами</strong></p>
						<p>§ 88. Оседлые земледельцы, живущие особыми деревнями, избирают сельских старост на общих правилах о сих старостах в Русских селениях.</p>
						<p>§ 89. Ежели число душ в селениях будет достаточно: то составляют особенную волость.</p>
						<p>§ 90. Состав и обязанности Волостнаго Управления суть те же, какия и в волостях из Россиян состоящих.</p>
						<p>§ 91. Ежели селения не могут составить особенной волости: то имеют принадлежать к ближайшей Русской волости.</p>
						<p>§ 92. Равномерно и разсеянные между Россиянами инородцы, ежели не могут составить особеннаго из себя селения, причисляются к Русским деревням.</p>
						
						<p><strong>Глава II. Состав управления кочующими инородцами</strong></p>
						<p>§ 94. Каждое стойбище или улус, в котором считается не менее 15 семейств, имеет собственное свое родовое управление.</p>
						<p>§ 95. Стойбища или улусы, менее 15 семейств имеющие, причисляются к другим ближайшим.</p>
						<p>§ 96. Родовое управление состоит из старосты и одного или двух помощников из почетных и лучших родовичей.</p>
						<p>§ 97. Староста избирается или наследует сие звание по обычаям. Между своими родовичами он может носить именование Князца; Зайсана и проч., но в сношениях с Правительством во всех племенах имеет называться старостою.</p>
						<p>§ 98. В помощники старост назначать инородцев всегда по выбору родовичей на определенное или неопределенное время.</p>
						<p>§ 99. Каждому стойбищу присвоить навсегда особенное название, и ежели не имеет онаго ныне, то по названию перваго старосты.</p>
						<p>§ 100. Во всех случаях, когда дело касаться будет до какого либо стойбища, относится на лицо старосты.</p>
						<p>§ 101. Родовое управление производит все дела словесно.</p>
						<p>§ 102. Ежели другаго степени управления не учреждено: то все сведения об улусе местная Полиция заимствует из показаний старосты и чрез приказания оному действует.</p>
						<p>§ 103. Несколько стойбищ или улусов однаго рода подчиняются инородной Управе.</p>
						<p>§ 104. Инородная Управа состоит из головы, двух выборных и, ежели возможно, завести письмоводство, из письмоводителя.</p>
						<p>§ 105. Инородной Управе во всем подчиняются Управления родовыя.</p>
						<p>§ 106. Головы получают звание сие наследственно или по выбору, сообразно с степными обычаями каждаго племени.</p>
						<p>§ 107. Выборные и письмоводители назначаются всегда по выбору родовичей на определенное или неопределенное время.</p>
						
						<p><strong>Глава III. Состав управления бродячими инородцами или ловцами</strong></p>
						<p>§ 111. Родовое Управление ловцов состоит одного старосты. Название сие приемлют нынешние Князцы и других наименований почетные люди ловцами управляющие.</p>
						<p>§ 112. Староста получает все права и власть обоих степеней степнаго Управления.</p>
						<p>§ 113. Письменных дел староста сей не производит. Потребныя сведения о вверенном ему роде заимствует местная Полиция из его показаний.</p>
						
						<p><strong>Глава V. Словесная Расправа инородцев</strong></p>
						<p>§ 122. Родовое Управление в исковых делах между инородцами имеет право Словесных Судов.</p>
						<p>§ 123. Инородцы, имеющие обычай разбираться особенными посредниками, могут сие делать, ежели обе стороны согласны, не относясь к Родовому Управлению. Решение сих посредников в то же время приводятся в исполнение и жалобы на оныя неприемлются.</p>
						<p>§ 124. Таковые посредники, хотя бы и постоянно одни и те же в каком-либо роде, по особенному уважению к ним Родовичей, были избираемы в действительном управлении по сему одному праву, никакого значения не имеют.</p>
						<p>§ 125. Таким образом первая степень словесной Расправы всегда считается в Родовом Управлении.</p>
						<p>§ 126. В делах между людьми разных стойбищ или по неудовольствиям на разбирательство Родоваго Управления, вторую степень Расправы Словесной составляет Инородная Управа.</p>
						<p>§ 127. В делах между людьми, подчиненными разным Управам, или по неудовольствиям на разбирательство инородной Управы третью и последнюю степень Словесной Расправы составляет местная земская Полиция.</p>
						<p>§ 128. Само по себе разумеется, что в тех племенах, где не будет учреждено инородных Управ Словесная Расправа состоит только из одного родоваго Старосты,</p>
						<p>§ 129. По неудовольствиям на разбирательство земской Полиции, должны инородцы вступать уже с письменною жалобою в Окружной Суд.</p>
						<p>§ 131. Тяжущиеся всегда имеют право даже и после разбирательства в 1-й, 2 или 3 степени Словесной Расправы кончить дело мировою или чрез посредников.</p>
						<p>§ 132. Уголовный дела Словесным Судом не разбирать.</p>
						
						<p><strong>Глава VI. О ярмарках и Сугланах</strong></p>
						<p>§ 133. Для удобства торговли инородцев по распоряжению Губернскаго Начальства и с утверждения местнаго Главнаго Управления назначить места для ярмарок и время, когда оныя имеют быть производимы сообразно с временем взноса податей и сообразно с нуждами инородцев.</p>
						<p>§ 135. В местах, куда не предвидится возможности привлечь потребнаго числа торгующих, для составления ярмарки, но где была бы оная для взимания податей необходима, назначить в определенное время сугланы.</p>
						<p>§ 136. Суглан есть не что иное, как мирское собрание инородцев.</p>
						<p>§ 138. По прибытии на ярмарку инородцы избирают из себя особеннаго старосту.</p>
						<p>§ 139. Староста сей во время продолжения ярмарки наблюдает за порядком, разбирает словесно по степным обычаям взаимный инородцев жалобы и прибегает к Российскому Правительству с просьбами о всем, в чем нужду иметь будет.</p>
						<p>§ 140. Дела подлежащия разбирательству сего старосты, суть только те, который собственно по мене и купле на ярмарке возникают. В дела, происшедшия в стойбищах, или которыя могут быть разобраны на месте, староста сей вмешиваться не должен.</p>
						<p>§ 141. Не зависимо от сего старосты разбор дел чрез посредников и на ярмарках остается в своей силе.</p>
						
						<p><strong>Глава VIL Содержание Степнаго Управления</strong></p>
						<p>§ 142. Старосты, помощники их, Головы, Выборные, Заседатели в Думах, Старосты на ярмарках и Главные Родоначальники не получают от Родовичей своих никакого жалованья; но исправляют должности по сим званиям как общественную службу.</p>
						<p>§ 144. В местах, где введено письменное производство, жалованье Письмоводителям, так же по точному определению со стороны Губернскаго Начальства для каждого места составляется раскладкою на Родовичей.</p>
						<p>§ 146. В пособие на содержание Канцелярий Земских Судов никаких сборов с инородцев не делать.</p>
						
						<p><strong>Глава VIIL Утверждение Начальника над инородцами</strong></p>
						<p>§ 147. При всякой перемене Старост, Выборных, Голов и Заседателей в Думах преемничеством, или выбором, утверждаются оные, если нет особенных тому препятствий, Гражданским Губернатором или Областным Начальником.</p>
						<p>§ 148. При всякой перемене Главнаго Родоначальника утверждается вновь вступающий, ежели нет особенных препятствий, Генерал-Губернатором.</p>
						<p>§ 149. Старосты, на ярмарках выбираемые, вступают в сию должность без особенных утверждений.</p>
						<p>§ 150. Препятствиями к утверждению почитать:</p>
						<ol className="ml-5">
							<li><p>Доказанное судом худое поведение.</p></li>
							<li><p>Несогласие на выбор более, нежели половины Родовичей.</p></li>
							<li><p>Уважительный отзыв от Управления самаго избираемаго, или наследующаго.</p></li>
						</ol>
						<p>§ 151. Во всех сих случаях Родовичи обязаны безпрекословно избирать других или назначать ближайших родственников прежняго.</p>
						<p>§ 154. Право удалять от должностей, в Степном Управлении занимаемых, предоставляется Гражданским Губернаторам, кроме Главных Родоначальников, коих удаление зависит от Генерал-Губернатора.</p>
						<p>§ 155. Причины удаления должны быть единственно:</p>
						<ol className="ml-5">
							<li><p>Уголовное преступление.</p></li>
							<li><p>Доказанное судом злоупотребление власти.</p></li>
							<li><p>Просьба подведомственных Родовичей, большинством голосов утвержденная.</p></li>
						</ol>
					</Accordion>
					<Accordion title="Часть III. НАКАЗ УПРАВЛЕНИЯМИ ИНОРОДЦАМИ">
					<p><strong>Глава I. Правила общия</strong></p>
					<p>§ 168. Существо и пределы власти, различным установлена ям в Губерниях, Областях и округах присвоенной, вообще сохраняет свое значение и относительно к инородцам. Но образ действия сей власти в отношении к инородцам, требует особенных применений.</p>
					<p>§ 169. Оседлые инородцы, имея равныя права с Россиянами, не подлежат никаким исключениям в зависимости от установленнаго Начальства.</p>
					<p>§ 170. Состояние инородцев, кочующих и бродячих, отличается:</p>
					<ol className="ml-5">
						<li><p>Непостоянством их жительства.</p></li>
						<li><p>Степенью Гражданскаго образования.</p></li>
						<li><p>Простотою нравов.</p></li>
						<li><p>Особыми обычаями. </p></li>
						<li><p>Образом пропитания.</p></li>
						<li><p>Трудностию взаимных сообщений.</p></li>
						<li><p>Недостатком монеты в обращении.</p></li>
						<li><p>Недостатком способов к сбыванию на месте лова и произведений.</p></li>
						
					</ol>
					<p>Отсюда происходят нижеследующия главныя правила в образе их управления.</p>
					<p>I. В частных делах предоставить инородцам кочующим словесную расправу по собственным их обычаям и законам и уменьшить число предметов, подлежащих Судебному разбирательству. 
						Отсюда:</p>
					<ol className="ml-5">
						<li><p>Ограничение дел уголовных, как в первой части сего Устава предписано.</p></li>
						<li><p>Особенный порядок следствий и розысканий, о котором ниже постановлено будет.</p></li>
						<li><p>Особыя обязанности степнаго управления и местной Полиции в качестве Словесной Расправы.</p></li>
					</ol>
					<p>II. Устранить всякия препятствия в торговле и промышленности инородцев, доставить им удобство к исполнению различных их обязанностей. 
						Отсюда:  </p>
					<ol className="ml-5">
						<li><p>Точное определение налогов.</p></li>
						<li><p>Особенный правила на взимание оных.</p></li>
						<li><p>Простота сделок и уменьшение промедлений и убытков, с тем сопряженных.</p></li>
					</ol>
					<p>§ 171. Для исполнения сих правил необходим деятельный надзор, который и возлагается в особенности на Гражданских Губернаторов и на Областных Начальников, как равно на них же возлагается и главная ответственность за общее благосостояние инородцев.</p>
					
					<p><strong>Глава II. Обязанности родовых управлений</strong></p>

					<p>§ 172. Родовое управление имеет ближайший надзор за порядком во вверенном ему роде или наслеге.</p>
					<p>§ 173. Управляющий родом приемлется в значении старшаго лица, как будто бы род составлял одно семейство. Почему благосостояние вверенных ему людей, их спокойствие, довольство и безопасность составляют важнейшую обязанность Родоваго Управления.</p>
					<p>§ 174. Управление сие имеет право взыскивать за маловажные проступки по обычаям каждаго племени и в качестве домашняго исправления.</p>
					<p>§ 175. Кроме родовых управлений, никакое лицо или место не имеет права на подобныя взыскания без суда и приговора.</p>
					<p>§ 176. Все распоряжения высшаго Правительства, относительно к инородцам, приводятся в исполнение чрез Родовыя Управления, выключая телесных наказаний, Уголовным Судом определенных.</p>
					<p>§ 177. Сбор податей возлагается непосредственно на родовое управление, так, что взыскиваются оныя с сего управления за весь род, как с одного нераздельнаго лица.</p>
					<p>§ 178. Всякаго преступника, беглеца, или нарушителя порядка в роде, Родовое Управление имеет право представить по Начальству, и буде нужно под надзором.</p>
					<p>§ 179. Староста имеет право без особенных от Родовичей доверенностей и письменных приговоров, приносить жалобы на притеснения и несправедливости, роду оказанные.</p>
					<p>§ 183. Родовыя Управления особенно имеют заботиться, чтоб Родовичи их старались иметь в случае обильнаго лова, или промыслов, достаточный запас для своего продовольствия.</p>
					<p>§ 184. Ежели представится необходимость в пособиях от Правительства по продовольствию: то родовыя Управления обязаны немедленно давать о том знать ближайшему своему Начальству.</p>
					<p>§ 185. Все выдачи предметов продовольствия в ссуду кочующим инородцам делать на лицо и на ответственность Управляющаго родом.</p>
					<p>§ 186. В особенности родовое Управление обязано прилагать старание, дабы постановления об инородцах, ему вверенных, были ему известны.</p>
					
					<p><strong>Глава III. Обязанности инородных Управ</strong></p>
					<p>§ 187. Власть инородной Управы состоит в надзоре за родовыми Управлениями и в местных распоряжениях.</p>
					<p>§ 188. В качестве надзора Инородная Управа наблюдает:</p>
					<ol className="ml-5">
						<li><p>чтоб законы, обычаи и обряды утвержденные, как выше предписано, исполнялись непременно; </p></li>
						<li><p>чтоб родовое Управление действовало не иначе, как к общей пользе, и ни под каким видом не стесняло вверенных ему людей; </p></li>
						<li><p>чтоб не упускало из вида нужд народных, но и не увеличивало бы напрасно показания об оньех.</p></li>
					</ol>
					<p>§ 189. Показания о родовых нуждах, когда они действительно встретятся, Инородная Управа обязана утвердить, или сделать против онаго свои замечания, которыя принимать в точное основание, с ответственностию Управ за всякой вред, которому таким образом будут они виною.</p>
					<p>§ 190. Жалобы большаго числа родовичей на несправедливость родоваго Управления, Управа имеет немедленно разсматривать и с своим мнением, также с ответственностию за справедливость, представлять по порядку.</p>
					<p>§ 192. Местные распоряжения Инородной Управы состоят:</p>
					<ol className="ml-5">
						<li><p>в точном исполнении всех предписаний Начальства;</p></li>
						<li><p>в понуждении к сбору податей;</p></li>
						<li><p>в сохранении благочиния и порядка;</p></li>
						<li><p>в охранении прав инородцев от всякаго посторонняго стеснения'</p></li>
						<li><p>в розысканиях, по особенным случаям нужных.</p></li>
					</ol>
					<p>§ 193. Инородная Управа имеет прямое и непосредственное сношение с Земскою Полицией, и исполняет все получаемый от оной предписания.</p>
					<p>§ 198. За ввоз в продажу кочующим горячих напитков, непосредственно ответствует Инородная Управа, и потому имеет право таковую продажу остановить, напитки отобрать и виновных представить Начальству для поступления по законам.</p>
					
					<p><strong>Глава IV. Обязанности Степных Дум</strong></p>
					<p>§ 203. Без точнаго назначения Степной Думы не допускается никакая разкладка и сбор с состоящих в ведении оной инородцев.</p>
					<p>§ 204. Степная Дума не назначает никакого сбора без точнаго предписания от высшаго Правительства. Всякой сбор, против сего правила сделанный, почитается незаконным, и незаконные поборы вообще ответствует Степная Дума.</p>
					<p>§ 208. О замеченном излишестве имеют право Родовыя Управления приносить жалобы, но не позже, как в течение одного года.</p>
					<p>§ 209. Степныя Думы имеют особенное старание, чтоб вверенные им инородцы не нуждались в продовольствии и не терпели бы стеснений в промыслах своих и в сбыте лова их и произведений.</p>
					<p>§ 210. Во всяком случае Думы имеют право ходатайствовать об общих пользах одного рода или целаго племени, и где учреждена Дума, там никто другой не имеет права на подобное ходатайство.</p>
					<p>§ 211. Где учреждена Степная Дума, там представления и удостоверения о народных нуждах зависят от оной.</p>
					<p>§ 212. Если бы встретилась казне надобность в поставке белки и других промыслов, или в перевозе каких либо тяжестей посредством найма инородцев, то контракты о сем заключать со Степными Думами.</p>
					<p>§ 213. Степная Дума, получив предварительныя условия, обязана предложить оныя чрез голов Родовичам, и буде согласятся, приступает к заключению контрактов.</p>
					
					<p><strong>Глава V. Обязанности Земской Полиции, по Управлению инородцами</strong></p>
					<p>§ 215. Земская Полиция по делам благочиния и порядка действует не иначе, как по правилам относительно инородцев выше постановленным.</p>
					
					<p><strong>Глава VI. Обязанность Словесной Расправы</strong></p>
					<p>§ 223. Главная обязанность Словесной Расправы состоит в прекращении частных между инородцами несогласий и в примирении спорящихся на основании степных законов и обычаев.</p>
					<p>§ 224. Решение каждой степени имеет полную силу, ежели тяжущиеся оным довольны.</p>
					<p>§ 225. Никакое дело не начинается без жалобы истца.</p>
					<p>§ 226. Никакое дело не разсматривается в высшей степени Словесной Расправы, ежели в низшей не было разсмотрено.</p>
					<p>§ 227. Никакое дело не возобновляется, ежели было по обоюдному согласию решено чрез посредников.</p>
					<p>§ 228. Никакое дело не возобновляется, ежели от приведения в исполнение решения которой нибудь степени Словесной Расправы, пройдет более года.</p>
					<p>§ 229. Словесная Расправа приступает к разбирательству, наблюдая все удобство в призыве ответчика и свидетелей.</p>
					<p>§ 230. Ежели ответчик или свидетели находиться будут на промыслах, или в отлучке не ближе трех дней хода, тогда разбирательство отсрочивать до их возвращения,</p>
					<p>§ 231. Для словесных разбирательств в Земских Судах, не требовать в города людей, далее двух дней хода находящихся.</p>
					<p>§ 232. Все дела, за таковым препятствием остановившияся, Земскому Суду или Заседателям разбирать во время разъездов для ревизии и следствий.</p>
					<p>§ 233. Для очных ставок в словесном разбирательстве не требовать инородцев, далее одного дня хода находящихся.</p>
					<p>§ 234. В словесном разбирательстве хотя и принимать на вид решение нижних степеней Расправы, но никаких штрафов, в случае отмены сих решений, на учинивших оныя не накладывать.</p>
					<p>§ 235. Долговые иски принадлежат к разбирательству Словесной Расправы, как равно иски по свадебным договорам.</p>
					
					<p><strong>Глава VII. О разъездах</strong></p>
					<p>§ 236. Разъезды казаков и чиновников по стойбищам инородцев допускаются в следующих случаях:</p>
					<ol className="ml-5">
						<li><p>для ревизии порядка по Управлению,</p></li>
						<li><p>для следствий,</p></li>
						<li><p>для сбора податей,</p></li>
						<li><p>для надзора за порядком на ярмарках.</p></li>
					</ol>
					<p><strong>Глава VIII. О следствиях</strong></p>
					<p>§ 244. Следствия над инородцами производить только по уголовным делам, в первой части сего Устава поименованным, и в случае важных происшествий.</p>
					<p>§ 245. Следствия производить чиновникам Земской Полиции письменно и отсылать на заключение в Судебный места по принадлежности.</p>
					<p>§ 246. В производстве следствий исполнять установленные обряды и порядок.</p>
					<p>§ 247. Показания отбирать вблизи от местопребывания инородцев и далее трех дней хода для опросов не вызывать.</p>
					<p>§ 248. Инородцев, в важных только преступлениях обличаемых, содержать под стражею; за маловажные проступки ни под каким видом от промыслов не отлучать.</p>
					<p>§ 249. Для обыкновенных опросов и очных ставок, включая и время переезда, не отлучать инородцев от их стойбищ далее нежели на 6 дней.</p>
					<p>§ 250. Если бы по сему разчету времени невозможно было дать кому либо очных ставок, тогда не давая оных, прилагать только к следствию приличныя о том объяснения.</p>
					<p>§ 251. Если следствие производится в отдалении от мест пребывания духовных тогда в потребных случаях приводить к присяге, или по обычаям различных племен, или просто отобранием клятвеннаго обещания без посредства духовнаго.</p>
					<p>§ 252. Рукоприкладство инородцев допускается по их обычаям Тамгами.</p>
					<p>§ 253. При ревизии следствий Губернскими Присутственными местами, всегда обращать внимание на разстояния, и если некоторый отступления от общих форм произойдут от неудобства в отобрании показаний, или очных ставок: то таковыя отступления в вину следователя не ставить.</p>
					<p>§ 254. Напротив того, за долговременную задержку кочующих, или вызов оных из большаго отдаления, подвергать чиновников взысканию.</p>
					<p>§ 255. Следствий для дополнения не обращать, если в том крайней нужды к объяснению дела настоять не будет.</p>
					
					<p><strong>Глава IX. О суде</strong></p>
					<p>§ 256. Уголовныя дела разсматривать и решить в учрежденных Присутственных местах установленным порядком и на основании общих узаконении.</p>
					<p>§ 257. Исковыя дела, до уездных Судов доходящия, не прежде начинать, как по неудовольствиям на решения во всех степенях словесной Расправы.</p>
					<p>§ 258. Истец должен в прошении своем изложить сии решения, которыя и принимать в соображение.</p>
					<p>§ 259. В решении исковых дел Уездные Суды основываются на степных законах и обычаях.</p>
					<p>§ 260. Приговоры по уголовным делам приводит в исполнение Земская Полиция.</p>
					<p>§ 261. Решения Уездных Судов по исковым делам приводятся в исполнение, или поступают в аппелляционное разсмотрение по установленному порядку.</p>
					<p><strong>Глава X. О народосчислении</strong></p>
					<p>§ 262. Родовыя управления всегда ведая о прибыли и убыли людей в их роде, обязаны доставлять о том при общей ревизии полныя сведения к ближайшему своему Начальству.</p>
					<p><strong>Глава XI. Казенныя продажи</strong></p>
					
					<p>§ 270. Все казенныя продажи, учреждаемый для кочующих имеют двоякую цель: </p>
					<ol className="ml-5">
						<li><p>доставление необходимаго пособия по продовольствию и промыслам кочующих;</p></li>
						<li><p>умерение вольных цен на необходимыя потребности.</p></li>
					</ol>
					<p>§ 271. Отсюда следует, что казенныя продажи ни в каком случае не должны делать ни малейшаго стеснения промышленности частных людей.</p>
					<p>§ 272. Предметы казенной продажи суть:</p>
					<ol className="ml-5">
						<li><p>хлеб,</p></li>
						<li><p>соль,</p></li>
						<li><p>порох,</p></li>
						<li><p>свинец.</p></li>
					</ol>
					<p>§ 273. Все сии вещи, выключая пороха, могут свободно привозить и продавать частые люди, право торговли имеющие.</p>
					<p>§ 274. Гражданские Губернаторы разпоряжают, дабы в приличных местах находились достаточные запасы сих вещей и назначают определительно места для магазинов и лавок.</p>
					<p>§ 277. При продаже в крайних случаях угрожающего голода, не только прибыли не налагать, но позволяется уменьшать продажную цену против истинной, если необходимость в том надлежащим образом будет доказана.</p>
					<p>§ 278. В сих только случаях позволяется продажа кочующим в долг, но неиначе, как по требованиям и на ответственности Старост, Голов, или Степных Дум.</p>
					<p>§ 281. В годы обильнаго улова зверей и рыбы содержание бедных семейств возлагается на попечение Старост.</p>
					<p>§ 282. В годы скуднаго улова зверей и рыбы, по надлежащем удостоверении о том, Гражданский Губернатор имеет назначить необходимое число потребных припасов в раздачу беднейшим безденежно.</p>
					<p>§ 283. По случаю недостатка где либо наличных денег на покупку предметов казенной продажи, Губернское Начальство разрешает платеж звериными шкурами по той самой цене, как в подати оная принимается.</p>
					<p><strong>Глава XII. Богослужение</strong></p>
					<p>§ 286. Инородцы, не исповедующие Христианской веры, имеют свободу отправлять Богослужение по их закону и обрядам.</p>
					<p>§ 287. Российское Духовенство в обращении их имеет поступать по правилам кротким, одними убеждениями без малейших принуждений.</p>
					<p>§ 288. Земское Начальство обязано не допущать стеснение инородцев под предлогом обращения в Христианскую веру.</p>
					<p>§ 289. Духовенство иноверческое состоит в зависимости местной Полиции наравне с прочими инородцами.</p>
					<p>§ 290. В принятии Христианской веры никому не препятствовать.</p>
					<p>§ 291. Не подвергать инородцев никаким изысканиям если они исповедуя Христианскую веру, окажутся по невежеству в упущении церковных обрядов. Внушения и убеждения суть одни приличныя в сем случае меры.</p>
					<p><strong>Глава XIII. Надзор и ответственность</strong></p>
					<p>§ 293. Гражданский Губернатор, или Областный Начальник обязан один раз в год сам лично или чрез довереннаго Чиновника по своему выбору подробно входить в дела инородцев, посещать их стойбища, или места, с которыми удобнее они могут иметь сообщение, внимать их жалобам и нуждам, доставлять удовлетворение, прекращать безпорядки, отсылать виновных к суду за злоупотребления, или о чем нужно, представлять вышнему Начальству.</p>
					<p>§ 294. После каждой годовой ревизии Губернатор доносит Начальству о положении инородцев.</p>
					<p>§ 295. Справедливость таковых удостоверений лежит на личной ответственности Губернатора.</p>
					</Accordion>
					<Accordion title="Часть IV. О ПОРЯДКЕ СБОРОВ ПОДАТЕЙ И ПОВИННОСТЕЙ С ИНОРОДЦЕВ">
					<p><strong>Введение</strong></p>
					<p>§ 298. Все вообще сборы с инородцев суть трех родов: </p>
					<ol className="ml-5">
						<li><p>Казенныя подати.</p></li>
						<li><p>Земские повинности.</p></li>
						<li><p>Повинности внутренний на содержание степнаго управления.</p></li>
					</ol>
					<p>§ 299. Порядок наложения сборов, изчисления и утверждения, разкладки, действительнаго взноса, здачи, употребления и отчетов, излагаются в нижеследующих главах.</p>
					<p><strong>Глава I. Наложение сборов</strong></p>
					<p>§ 300. Никакой новый общий по Государству налог нс разпространяется на Сибирских кочующих и бродящих инородцев, если о том именно не будет предписано.</p>
					<p>§ 301. Правила, по коим располагаются земския повинности по Губернии, означенны в особом положении. Местное Главное Управление определяет, до какой степени кочующие инородцы долженствуют участвовать в сих повинностях.</p>
					<p>§ 302. Количество таковаго сбора может быть различно для каждаго племени инородцев, по местному усмотрению особенных их способов промышленности.</p>
					<p>§ 303. Сборы на внутренния повинности для каждаго племени определяются Степною Думою, или где нет оной, общественным приговором инородцев.</p>
					<p><strong>Глава II. Исчисление сборов</strong></p>
					<p>§ 306. Гражданские Губернаторы или Областные Начальники в Сентябре месяце составляют для следующаго года подробное разчисление сборов, а именно: </p>
						<ol className="ml-5">
							<li><p>сколько с каждаго рода волости и со всех в Губернии или Области инородцов причитается порознь каждаго наименования и всех вообще сборов; </p></li>
							<li><p>в какие Уездныя казначейства сколько сборов сих, также с различием одного от другаго, поступить должно;</p></li>
							<li><p>на конец сколько всех сборов в общем счете с души для каждаго рода причитается.</p></li>
						</ol>
					<p>§ 308. Всякой сбор, в таковом разчислении не помещенный строжайше запрещается.</p>
					<p><strong>Глава V. Разкладка сборов</strong></p>
					<p>§ 309. Степныя Думы, получив росписание делают разкладки на Родовыя Управления сообразно с способами каждаго.</p>
					<p>§ 310. Родовыя Управления по росписаниям делают назначение, сколько именно каждое семейство взнести обязано звериными шкурами или деньгами, смотря по успеху промыслов и состоянию каждаго; но звериныя шкуры всегда должно приводить в денежный счет установленною оценкою.</p>
					<p><strong>Глава IV. Действительный сбор</strong></p>
					<p>§ 312. Обязанность Родоваго Управления состоит в том, чтоб пред наступлением назначеннаго к платежу податей времени иметь в сборе деньгами или звериными шкурами полное количество сбора по положению; но от его усмотрения зависит производить требование с родовичей тогда, как наступит время взноса в казну, или же взимать тотчас после удачнаго промысла.</p>
					<p>§ 313. Кроме Родовых Управлений, никто другой не имеет права производить сбор с инородцов. Как инородныя Управы, так и Земское Начальство обращаются с побуждениями своими только к Старостам.</p>
					<p><strong>Глава V. Составление денежных сумм</strong></p>
					<p>§ 317. Сбор вещами обращается в денежную сумму вообще свободною распродажею на ярмарках. Цена вещей завися, как обыкновенно, от пропорции между требованием в покупку и предложением в продажу, всякое влияние местнаго Начальства делает излишним и безполезным.</p>
					<p>§ 318. Местное Начальство обязано только наблюдать: 
						 </p>
					<ol className="ml-5">
						<li><p>чтоб Движение торговли и мены на ярмарке было совершенно свободно;</p></li>
						<li><p>чтоб стечение покупщиков было сколь возможно более;</p></li>
						<li><p>чтоб взнос податей не был вынуждаем при самом начале ярмарки, но тогда уже приступать к оному, когда частный условия к концу приходить будут.</p></li>
					</ol>
					<p>§ 319. Казенные Коммиссионеры, буде бы случились на ярмарках инородцов, выдерживают цены на равне с прочими покупателями и никакия изключительныя в пользу их настояния не допускаются.</p>
					<p>§ 320. Одно из значительных затруднений в распродаже вещей состоит в опасениях инородцов о подлинности монеты. Местное Начальство обязано употребить все старание, чтоб подлоги в сем случае были пресекаемы.</p>
					<p>§ 321. Открывая ложную монету в руках приезжаго торговца, немедленно отбирать оную и поступать по общим на сей предмет правилам.</p>
					<p>§ 322. Открывая ложную монету в руках кого либо из кочующих, надлежит обменивать на настоящую и отсылать куда следует, взыскивая цену оной немедленно с того торговца, от котораго за товар отдана была.</p>
					<p>§ 323. Предлагающих инородцам монету или ассигнации, явно ложныя, судить как за воровство.</p>
					<p><strong>Глава VI. Сдача податей и повинностей деньгами</strong></p>
					<p>§ 324. Вырученныя Родовыми Управлениями за продажу собранных вещей, или прямо к ним от инородцов поступивший деньги, во всякое время могут сдаваться чрез инородньгя Управы, или прямо отдельным Заседателям и в Земские Суды под квитанции.</p>
					<p><strong>Глава VII. Оценка и сдача рухляди</strong></p>
					<p>§ 330. Оценка делается с разделением рухляди, на два рода:</p>
					<ol className="ml-5">
						<li><p>на обыкновенную,</p></li>
						<li><p>на дорогую.</p></li>
					</ol>
					<p>§ 331. Для обыкновенной рухляди как то: соболя, белки, красной лисицы, волков и проч. Гражданские Губернаторы по соображении цен, существующих в Губернии, с утверждения местнаго Главного Управления, определяют благовременно на каждые три года постоянную среднюю цену на деньги по названиям рухляди.</p>
					<p>§ 332. Цена полагается особо на целыя шкуры, т.е. с лапами и хвостами, и особая без оных.</p>
					<p>§ 333. Дорогая рухлядь, как то: черныя и чернобурыя лисицы оцениваются на деньги, при свидетельстве оной в общих Окружных Присутствиях.</p>
					<p>§ 334. Все инородцы имеют право платить обыкновенною рухлядью, точно также наличными деньгами, сообразно с оценкою подати и повинности, а равно и за покупаемой в казне хлеб, порох и свинец.</p>
					<p>§ 335. Дорогая рухлядь принимается по примерной оценке, выдающих квитанции, если сдача произходить будет далее 100 верст от города, и отсылается для свидетельства и окончательной оценки в Общее Присутствие.</p>
					<p>§ 336. В случае разстояния не далея трех дней хода, дорогую рухлядь обязаны в Общее Присутствие представлять сами те инородцы, которые подать сдавать будут.</p>
					<p><strong>Глава IX. Книги и отчеты</strong></p>
					<p>§ 345. Все сборы, как деньгами, так и звериными шкурами поступающие, приемщики записывают в шнуровыя книги.</p>
					
					<p>§ 352. Земские Исправники, каждой по своему уезду составляют общий годовый отчет по предмету сборов с инородцов. 
						В отчете сем показывают они:</p>
					<ol className="ml-5">
						<li><p>количество сборов на каждую потребность,</p></li>
						<li><p>способ взыскания,</p></li>
						<li><p>составление денежных сумм,</p></li>
						<li><p>порядок сдачи,</p></li>
						<li><p>остающийся недоимки.</p></li>
					</ol>
					<p>§ 353. Гражданские Губернаторы из сих отчетов, и буде нужно, дополнив оные сведениями из Казенных Палат и собственными своими удостоверениями при обозрении Губерний сделанными, составляют общий отчет по управлению инородцами и представляют местному Главному Управлению.</p>
					<p><strong>Глава Х. Квитанции</strong></p>
					<p>§ 354. Исправность во взносе податей доказывается вообще предъявлением квитанции. Сколь ни простое средство сие, но оно требует особенных правил для кочующих народов.</p>
					<p><strong>Глава XI. Взыскание недоимок</strong></p>
					<p>§ 356. Поелику все сборы взыскиваются не посредственно со старост, родами управляющих; то и понуждения о взносе недоимок к ним должны обращаться.</p>
					<p>§ 357. Недоимки взыскивать неиначе, как на ярмарках и сугланах.</p>
					<p>§ 358. Если местное Начальство усмотрит, что по обилию Промыслов и по безбедному продовольствию недоимки могут быть взыскиваемы без большаго отягощения инородцов, то имеет право побуждать их к платежу: 
						 </p>
						<ol className="ml-5">
							<li><p>настоятельным убеждением,</p></li>
							<li><p>задержанием старосты, его сына, или ближняго родственника,</p></li>
							<li><p>задержанием части звериных шкур, в продажу Привезенных.</p></li>
						</ol>
						<p>Две последния меры позволяются тогда только, когда на каком нибудь роде почитается недоимки более третьей части всего количества годоваго сбора.</p>
					<p>§ 362. Если какой нибудь староста в течении двух лет не явится ни на какой ярмарке и не пришлет податей, то Земский Суд для побуждения его посылает казачьего старшину в самый Улус.</p>
					<p>§ 363. Ни в каких других случаях подобных побуждений не Делать.</p>
					<p>§ 364. Где не учреждено инородных Управ, там с одного стойбища за другое никаких взысканий не делать.</p>
					<p>§ 365. Где учреждены инородныя Управы, там понуждение о взносе податей, как сказано выше о старосте, обращать и на голову.</p>
					</Accordion>
				</div>
				</div>
			</div>
		</div>
	</>
	);}
	export default withRouter(Foreigners);
