import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faList, faFile, faImage } from '@fortawesome/free-solid-svg-icons';

import { Loader, HtmlValue } from '@smartplatform/ui';
import store from '../../store';

@withRouter @observer
export default class Single extends React.Component {
	
	@observable isLoading = true;
	@observable record = null;
	@observable files = [];
	@observable error = null;
	
	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([
			{ name: 'Итоги деятельности', link: `/itogi` },
			{ name: 'Итоги', link: `/itogi/${props.match.params.id}` },
		]);
		this.init();
	}
	
	init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;
		try {
			this.record = await store.model.ItogiPeriod.findById(this.id, {
				include: [ 'itogi' ],
			});
		}
		catch(e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return <Loader />;
		if (this.error) return this.error;
		
		return <div className="itogi-single">
{/*
			<p>{this.record.title}</p>
			<p>&nbsp;</p>
*/}
			
			{this.record.itogi().map(itog => <div key={itog.id}>
				{/*<p><strong>{itog.title}</strong></p>*/}
				<HtmlValue value={itog.text}/>
			</div>)}
		</div>;
	}
	
}
