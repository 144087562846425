import React, { useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
// import ReCAPTCHA from 'react-google-recaptcha';
import ReCAPTCHA from '../ReCaptcha';

const styleBtn = {
	width: '100%',
	height: '5.6rem',
};

const Form = props => {
	const [recaptcha, setRecaptcha] = useState();

	const { touched, errors, handleSubmit, isValid, isSubmitting } = props;

	const onRecaptchaValid = () => {
		handleSubmit();
		setRecaptcha();
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: '100%' }}>
			{recaptcha ? (
				<ReCAPTCHA onChange={onRecaptchaValid} />
			) : (
				<div className="row justify-content-end">
					<div className="col-12 col-md-6 col-lg-8">
						<div className="mb-5">
							<Field name="email" placeholder="Электронная почта" className="subscription-form__input" />
							{errors.email && touched.email && <span className="subscription-form__error">{errors.email}</span>}
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4 mb-5">
						<button
							className="btn subscription-form__button"
							type="button"
							onClick={() => setRecaptcha(true)}
							disabled={!isValid || isSubmitting}
							style={styleBtn}
						>
							Подписаться
						</button>
					</div>
				</div>
			)}
		</form>
	);
};

const SubscriptionForm = withFormik({
	mapPropsToValues: () => ({ email: '' }),

	validationSchema: Yup.object().shape({
		email: Yup.string()
			.email('Введите корректный email')
			.required('Заполните поле'),
	}),
	handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
		onSubmit(values);
		setSubmitting(false);
	},
	displayName: 'SubscriptionForm',
})(Form);

export default SubscriptionForm;
