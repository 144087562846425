import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { isAfter } from 'date-fns';

import { formatDateStr } from '../../utils';
import { HtmlValue } from '@smartplatform/ui';
import { trimmedName, formatDate } from '../documents/tools';
import { getStartNumDeputyLaws } from '../../utils/getStartNumDeputyLaws';
import LastUpdated from '../../components/last-updated';
import store from '../../store';

export const positions = {
	0: 'Член',
	1: 'Руководитель',
	2: 'Заместитель руководителя',
};

export const committieePositions = {
	0: 'член комитета',
	1: 'заместитель председателя',
	2: 'председатель комитета'
};

const formatStr = dateStr => new Date(dateStr).toLocaleString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' });

@observer
export default class Deputy extends React.Component {

	@observable deputy = null;
	@observable error = null;
	@observable deputyNews = [];
	@observable laws = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		const currentConvRes = await store.model.Convocation.find({ where: { current: true } });
		const currentConv = currentConvRes.length > 0 ? currentConvRes[0] : null;
		console.log('currentConv', currentConv);
		try {
			this.deputy = await store.model.Deputy.findById(this.id, {
				include: [
					{
						relation: 'depConv',
						scope: {
							order: 'convocationId desc',
							include: [
								{ relation: 'fraction', scope: { fields: [ 'id', 'name', 'fractionText' ]}},
								{ relation: 'division', scope: { fields: [ 'id', 'name', 'number' ]}},
								{ relation: 'divisionGroup' },
								{ relation: 'convocation', scope: { fields: [ 'id', 'name' ]} },
							],
						},
					},
					// { relation: 'fraction', scope: { fields: { id: true, name: true }}},
					// { relation: 'division', scope: { fields: { id: true, name: true }}},
					// { relation: 'divisionGroup', scope: { fields: { id: true, name: true }}},
					// {
					// 	relation: 'articles',
					// 	scope: {
					// 		fields: ['id', 'imported', 'thumbnail', 'image', 'name', 'publishAt'],
					// 	},
					// },
					{
						relation: 'committees',
						scope: {
							include: [
								{
									relation: 'committee',
									scope: { id: true, name: true },
								},
							],
						},
					},
				],
			});

			const organiz = await store.model.OrganizCl.find({ where: { deputyId: this.id }})
			// console.log('organiz', organiz);
			if (organiz.length > 0) {
				const res = await store.model.RefCorresp.find({
					where: { organizationId: { inq: organiz.map(o => o.id) }},
					include: [
						{
							relation: 'document',
							scope: {
								fields: ['id'],
								include: [
									{
										relation: 'refLinks',
										scope: {
											fields: ['id', 'childId'],
											where: {
												name: { ilike: '%Закон Республики Саха (Якутия)%' },
											},
											include: [
												{
													relation: 'child',
													scope: {
														fields: ['id', 'name', 'year', 'date', 'freeNum']
													}
												},
											]
										}
									}
								]
							}
						}
					],
				})
				const laws = [];
				res.forEach(r => {
					if (r.document && r.document.refLinks() && r.document.refLinks().length > 0) {
						const refLink = r.document.refLinks()[0];
						if (refLink.child && isAfter(new Date(refLink.child.date), new Date(2018, 8, 24))) {
							laws.push(refLink.child);
						}
					}
				});
				const years = {};
				laws
					.sort((a, b) => new Date(b.date) - new Date(a.date))
					.forEach(law => {
						if (!years[law.year]) years[law.year] = [];
						years[law.year].push(law);
					});
				console.log('laws', years);
				this.laws = years;
			}

			this.deputyNews = await this.deputy.articles.find({
				limit: 5,
				fields: ['id', 'imported', 'thumbnail', 'image', 'name', 'publishAt'],
				order: 'publishAt DESC',
			});

			store.ui.setBreadcrumbs([
				{ name: 'Депутаты', link: '/deputies' },
				{
					name: `${this.deputy.lastName} ${this.deputy.firstName} ${this.deputy.middleName}`,
					link: `/deputies/${this.id}`,
				},
			]);
		} catch (e) {
			this.error = e.message;
			console.warn(e);
		}
	};

	render() {
		if ((!this.deputy && !this.error) || !this.deputyNews) return '...';
		if (this.error) return <div className="container deputy-details">{this.error}</div>;

		const deputy = this.deputy;
		const depConv = this.deputy.depConv().length > 0 ? this.deputy.depConv()[0] : null;
		console.log('depConv', depConv);

		const photo = deputy.photo ? <img src={deputy.downloadFile('photo')} /> : <span className="empty" />;
		const division = depConv ? (
			<>
				{depConv.fraction && !depConv.division && <>{depConv.fraction.name} </>}
				{depConv.divisionGroup ? (
					<>({depConv.divisionGroup.name})</>
				) : (
					<>{depConv.division ? `${depConv.division.name} №${depConv.division.number}` : depConv.divisionText}</>
				)}
			</>
		) : null;

		const committees =
			deputy.committees().length > 0 ? (
				<div className="committees">
					<h3>Членство в комитетах:</h3>
					{deputy.committees().sort((a, b) => b.positionNum - a.positionNum).map(depCom => {
						const committee = depCom.committee;
						if (committee && !committee.deactivated) {
							return (
								<div key={committee.id} className="committee">
									<Link to={`/committees/${committee.id}`}>{committee.name}</Link>
									<br />
									{depCom.positionNum !== null ? committieePositions[depCom.positionNum] : depCom.position}
								</div>
							);
						}
					})}
				</div>
			) : null;

		let position = depConv ? positions[depConv.positionNum || 0] : '';
		position = position.substr(0, 1).toUpperCase() + position.substr(1);

		const deputyName = `${deputy.lastName} ${deputy.firstName} ${deputy.middleName}`;

		return (
			<div className="container deputy-details">
				<LastUpdated date={new Date(deputy.updatedAt)} className="mb-5" />
				<div className="row info">
					<div className="photo col-12 col-sm-12 col-md-3 col-lg-3">{photo}</div>
					<div className="details col-12 col-sm-12 col-md-9 col-lg-9">
						<div className="name" style={deputy.isDead ? {border: '3px solid black', display: 'inline-block', padding: '0 5px 0 5px'} : {}}>{deputyName}</div>
						<div className="division">
							{division}
							{depConv && depConv.endDate && (
								<div className="end" style={{fontWeight: 600}}>
									Полномочия сложены {formatDateStr(depConv.endDate, 'dd.MM.yyyy')}
									<br/>
									<a style={{fontWeight: 400}} href={this.deputy.endDateLink}>Ссылка на постановление Государственного Собрания (Ил Тумэн) о досрочном сложении полномочий</a>
								</div>
							)}
						</div>
						<div className="description">
							<HtmlValue value={deputy.description} />
						</div>
					</div>
				</div>

				{depConv.fraction && (
					<div className="fraction">
						<h3>Членство в депутатских объединениях:</h3>
						{position} <Link to={`/fractions/${depConv.fraction.id}`}>фракции</Link> {depConv.fraction.fractionText}.
					</div>
				)}

				{this.deputy.depConv().length > 0 && <div className="dep-conv">
					<h3>Созывы:</h3>
					{this.deputy.depConv().map(depConv => {
						if (!depConv.convocation) return null;
						let str = '.';
						if (depConv.startDate && depConv.endDate) {
							str = ` - работал в период ${formatStr(depConv.startDate)} - ${formatStr(depConv.endDate)}`;
						}
						else if (depConv.startDate) {
							str = ` - начало полномочий ${formatStr(depConv.startDate)}`;
						}
						return <div key={depConv.id}>
							{depConv.convocation.name}{str}
						</div>
					})}
				</div>}

				{committees}
				{deputy.contact && (
					<div className="contact">
						<h3>Контактная информация:</h3>
						<HtmlValue value={deputy.contact} />
					</div>
				)}
				{deputy.bio && (
					<div className="bio">
						<h3>Биографическая справка:</h3>
						<HtmlValue value={deputy.bio} />
					</div>
				)}
				{Object.keys(this.laws).length > 0 && (
					<div className="laws">
						<h3>Законы, инициированные депутатом:</h3>
						{Object.keys(this.laws)
							.sort((a, b) => +b - +a)
							.map((year, i, arr) => {

								const startNum = getStartNumDeputyLaws(this.laws, i, arr);
								
								return <div key={year} className="year">
									<p><strong>{year}</strong></p>
									<ol start={startNum}>
										{this.laws[year].map(law => <li key={law.id}>
											<Link
												to={`/documents/${law.id}`}>{law.freeNum} от {formatDate(new Date(law.date))} "{trimmedName(law.name)}"</Link>
										</li>)}
									</ol>
								</div>
							})}
						<Link to={`/deputies/${this.id}/laws`}>Показать все законы</Link>
					</div>
				)}
				{this.deputyNews.length > 0 && (
					<div className="related-articles">
						<h3>Новости депутата</h3>
						{this.deputyNews.map(article => (
							<div
								key={article.id}
								className="article-preview"
								onClick={() =>
									store.ui.setBreadcrumbs([...store.ui.breadcrumbs, { link: `/deputy/${deputy.id}`, name: deputyName }])
								}
							>
								<img
									alt=""
									className="image"
									src={
										article.imported
											? `/api/containers/articles/download/${article.image}`
											: article.downloadFile('thumbnail')
									}
								/>
								<div className="date">{formatDateStr(article.publishAt)}</div>
								<Link to={`/news/${article.id}`}>{article.name}</Link>
							</div>
						))}
						<Link to={`/deputies/${deputy.id}/news`}> Показать все новости</Link>
					</div>
				)}
					{deputy.prevConvocationInfo && (
					<div className='prevConvocationInfo'>
						<h3>Информация о предыдущих созывах</h3>
						<HtmlValue value={deputy.prevConvocationInfo} />
					</div>
				)}
			</div>
		);
	}
}
