import React from 'react';
import { observer } from 'mobx-react';
import { Link, Route, Redirect } from 'react-router-dom';

import { Tabs, Tab } from '../../components/route-tabs';
import { Page } from '../../components/page';
import LocalAgreements from './LocalAgreements';
import OtherAgreements from './OtherAgreements';
import { PATH } from './index';

@observer
export default class ExecutiveFunctions extends React.Component {
	
	render() {
		return <div className="container base-page">
			<Route path={PATH} exact render={() => <Redirect to={`${PATH}/local`}/>}/>
			<Tabs>
				<Tab path={`${PATH}/local`} title="Взаимодействие с органами местного самоуправления">
					<LocalAgreements />
				</Tab>
				<Tab path={`${PATH}/other`} title="Соглашения Государственного Собрания">
					<OtherAgreements />
				</Tab>
			</Tabs>
		</div>;
	}
	
}
