import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import img1 from './img/aai.jpg';
import img2 from './img/Анотонова.jpg';
import img3 from './img/Павлова ТВ-1_1.jpg';
import img4 from './img/Миронов ВС1_0.jpg';
import img5 from './img/Яковлева С_0.jpg';
import img6 from './img/Халыев МД.jpg';
import img7 from './img/Степанова НА_0.jpg';
import img8 from './img/Иванов ГЕ_0.jpg';
import img9 from './img/Брикова ТВ_0.jpg';
// import img10 from './img/Васильев ПС_0.jpg';
import img10_1 from './img/Гаврильев ЧН.jpg';
// import img11 from './img/syvcevSG.jpg';
import img11_1 from './img/Никифоров ДВ.jpg';
import img12 from './img/Семенова СС1.jpg';
import img13 from './img/Сизоев НН1_0.jpg';
import img14 from './img/Артамонов АВ_0.jpg';
import img15 from './img/Дьячковская НЕ.jpg';

const Apparatus =[
	{
		img : img1,
		position : 'Руководитель аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)',
		name : 'Антонен Андрей Иванович',
		number : '8 (4112) 43-52-00',
		additional : 'Секретарь приемной: Семенова Ольга Ефремовна, телефон: 8 (4112) 43-53-88',

	},
	{
		img : img2,
		position : 'Советник по обеспечению организационной работы Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)',
		name : 'Антонова Мария Яковлевна',
		number : '8 (4112) 43-56-00',
		additional : '',
	},
	{
		img : img3,
		position : 'Первый заместитель руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) - начальник государственно-правового управления',
		name : 'Павлова Татьяна Валерьевна',
		number : '8 (4112) 43-50-81',
		additional : '',
	},
	{
		img : img4,
		position : 'Заместитель руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия) – начальник информационно-аналитического управления',
		name : 'Миронов Василий Сергеевич',
		number : '8 (4112) 43-55-08',
		additional : ''
	},
	{
		img : img5,
		position : 'Заместитель руководителя аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)',
		name : 'Яковлева Саргылана Николаевна',
		number : '8 (495) 628-8194',
		additional : ''
	},
	{
		img : img6,
		position : 'Начальник управления информационно-технологического обеспечения',
		name : 'Халыев Михаил Демьянович',
		number : '8 (4112) 43-55-01',
		additional : ''
	},
	{
		img : img7,
		position : 'Заместитель начальника информационно-аналитического управления',
		name : 'Степанова Надежда Алексеевна',
		number : '8 (4112) 43-52-90',
		additional : ''
	},
	{
		img : img8,
		position : 'Заместитель начальника информационно-аналитического управления по вопросам взаимодействия с общественностью, представительными органами местного самоуправления, политическими партиями и обращениями граждан',
		name : 'Иванов Георгий Егорович',
		number : '8 (4112) 43-53-70',
		additional : ''
	},
	{
		img : img9,
		position : 'Начальник аналитического отдела',
		name : 'Брикова Татьяна Владимировна',
		number : '8 (4112) 43-51-56',
		additional : 'Адрес электронной почты: Brikova_TV@iltumen.ru'
	},
/*
	{
		img : img10,
		position : 'Начальник отдела по работе со средствами массовой информации',
		name : 'Васильев Петр Сергеевич',
		number : '8 (4112) 43-52-90',
		additional : ''
	},
*/
	{
		img : img10_1,
		position : 'Начальник отдела по работе со средствами массовой информации',
		name : 'Гаврильев Чокуур Николаевич',
		number : '8 (4112) 43-52-90',
		additional : ''
	},
/*
	{
		img : img11,
		position : 'Начальник управления административно-организационного обеспечения',
		name : 'Сивцев Сергей Гаврильевич',
		number : '8 (4112) 43-52-77',
		additional : ''
	},
*/
	{
		img : img11_1,
		position : 'Начальник управления административно-организационного обеспечения',
		name : 'Никифоров Дмитрий Власиевич',
		number : '8 (4112) 43-52-77',
		additional : ''
	},
	{
		img : img12,
		position : 'Заместитель начальника управления административно-организационного обеспечения по кадровым вопросам', 
		name : 'Семенова Саргылана Савельевна',
		number : '42-01-36',
		additional : ''
	},
	{
		img : img13,
		position : 'Начальник отдела мониторинга и контроля',
		name : 'Сизоев Николай Николаевич',
		number : '8 (4112) 43-51-46',
		additional : ''
	},
	{
		img : img14,
		position : 'Руководитель контрактной службы',
		name : 'Артамонов Александр Васильевич',
		number : '8 (4112) 43-50-97',
		additional : ''
	},
	{
		img : img15,
		position : 'Начальник управления документационного обеспечения',
		name : 'Дьячковская Нина Егоровна',
		number : '8 (4112) 43-56-05',
		additional : ''
	},
	
 ];

export default class ApparatusList extends React.Component {
	render() {
		return (
			<div className="container container_pt_40">
				<div className="row pb-5 pt-5">
					
					{
						Apparatus.slice(0, (Apparatus.length/2)).map(function(item, index) {
							return(
								<div className="col-6 col-sm-6 col-md-6 col-lg-6 pb-5 pt-5" key={index}>
									<div className="row ">
										<div
										     className="col-12 col-sm-6 col-md-4 col-lg-4">
											<img src={item.img} width="100%" />
										</div>
										
										<div className="col-12 col-sm-6 col-md-8 col-lg-8 ">
											<h2 className="header-2 pb-3">{item.name}</h2>
											<p className="pb-3"><strong>{item.position}</strong></p>
											<p className="pb-3"><FontAwesomeIcon size="1x" icon={faPhone} />
												<a href={`tel:${item.number}`}> {item.number}</a></p>
											<p className="pb-3">{item.additional}</p>
										</div>
									</div>
								</div>
							)
						}
					)}
							
					{
						Apparatus.slice((Apparatus.length/2), Apparatus.length).map(function(item, index) {
							return(
								<div className="col-6 col-sm-6 col-md-6 col-lg-6 pb-5 pt-5" key={index}>
									<div className="row ">
										<div
											className="col-12 col-sm-6 col-md-4 col-lg-4">
											<img src={item.img} width="100%" />
										</div>
										
										<div className="col-12 col-sm-6 col-md-8 col-lg-8 ">
											<h2 className="header-2 pb-3">{item.name}</h2>
											<p className="pb-3"><strong>{item.position}</strong></p>
											<p className="pb-3"><FontAwesomeIcon size="1x" icon={faPhone} />
												<a href={`tel:${item.number}`}> {item.number}</a></p>
											<p className="pb-3">{item.additional}</p>
										</div>
									</div>
								</div>
							)
						}
					)}
							
				</div>
				
			</div>
	);}
}
