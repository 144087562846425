import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import voting from '../store-site';
import store from '../../../store';
import { isDeputy } from 'root/site/pages';

@observer
export default class Votes extends React.Component {
	
	@observable record = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const { stage } = this.props;
		this.record = await store.model.ViewQuestionStage.findById(stage.id);
	};
	
	render() {
		if (!this.record) return '...';
		
		const stage = this.record;
		
		if (stage.statusId !== 3) {
			const isVisible = stage.publish === 'all' || (isDeputy() && stage.publish === 'deputy');
			
			let result = <div className="result">
				{stage.statusId === 1 ? <span className="green">Принято</span> : <span className="red">Не принято</span>}
				{(isVisible || voting.isAdmin || voting.isStaff || voting.isProtocolist) && <a href={`/active-session/${stage.sessionId}/protocol/${stage.id}`} target="_blank">Протокол</a>}
			</div>;
			
			if (voting.onlyVotes && voting.currentStage && voting.currentStage.id === stage.id) result = null;
			
			return <div className="votes-count votes-count-results from-view">
				{/*<div>id: {stage.id}</div>*/}
				{result}
				<div className="count"><em>За</em><span/><strong>{stage.yesCount || 0}</strong></div>
				<div className="count"><em>Против</em><span/><strong>{stage.noCount || 0}</strong></div>
				<div className="count"><em>Воздержалось</em><span/><strong>{stage.abstainCount || 0}</strong></div>
				<div className="count total"><em>Голосовало</em><span/><strong>{stage.yesCount + stage.noCount + stage.abstainCount}</strong></div>
				{document.location.hostname === 'localhost' && <>
					<div className="count present"><em>Присутствовало</em><span/><strong>{stage.present}</strong></div>
					<div className="count novote"><em>Не голосовало</em><span/><strong>{stage.noVote}</strong></div>
				</>}
			</div>;
		}

		return <div className="canceled">
			<strong>Голосование отменено</strong><br />
		</div>
	};
	
	
}
