import React, { useState, useEffect } from 'react';
import moment from 'moment';
import calendar from 'root/site/img/calendar.svg';
import { ElementItem, Button } from 'root/site/components';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import './styles.scss';
import RCalendar from 'react-calendar';
import store from '../../store';
import { observable, action, toJS } from 'mobx';

@observer
class Calendar extends React.Component {
	@observable pickDates;
	@observable currentDates;

	goToFirstPage = () => {
		const {
			ui: { rootPath },
			route,
		} = store;
		rootPath && route.push({ path: rootPath, params: { ...route.params, page: 1 } });
	};

	// @action onClickMonth = date => {
	// 	if (moment(date).format() === moment(this.currentDates).format()) {
	// 		console.warn('got same date');
	// 		this.pickDates = null;
	// 		return;
	// 	}

	// 	store.ui.setDateRange(date);
	// 	this.pickDates = null;
	// 	this.goToFirstPage();
	// };

	@action showAll = () => {
		store.ui.setDateRange();
		this.pickDates = null;
		this.goToFirstPage();
	};

	@action onChange = date => {
		console.log('date', date);
		store.ui.setDateRange(date);
		console.log('ui', store.ui.dateRange);
		this.currentDates = date;
		this.pickDates = null;
		this.goToFirstPage();
	};

	render() {
		return (
			<>
				{this.pickDates ? (
					<div className="monthpick">
						<button className="monthpick__selectall" onClick={this.showAll}>
							За все время
						</button>
						<RCalendar
							// maxDetail="year"
							// minDetail="decade"
							// view="month"
							selectRange
							onChange={this.onChange}
							returnValue="range"
							// value={store.ui.dateRange}
							// onClick={(date)=> {console.log('date', date)}}
							// onClickMonth={date => {
							// 	this.onClickMonth(date);
							// }}
						/>
					</div>
				) : (
					<div className="calendar" onClick={() => (this.pickDates = true)}>
						{store.ui.dateRange ? (
							<>
								<ElementItem
									medium
									icon={calendar}
									text={`${moment(store.ui.dateRange[0]).format('DD MMMM YYYY')} - ${moment(
										store.ui.dateRange[1]
									).format('DD MMMM YYYY')}`}
									fill="dark"
									center
									top="2px"
								/>
								{/* <div className="calendar__year">{moment(store.ui.dateRange).format('YYYY')}</div> */}
							</>
						) : (
							<ElementItem medium icon={calendar} text="За всё время" fill="dark" center top="2px" />
						)}
					</div>
				)}
			</>
		);
	}
}

export default Calendar;
