import React, { useEffect, useState } from 'react';
import store from '../../store';

export const useShowCounter = () => {
	const [showCounter, setShowCounter] = useState();
	useEffect(() => {
		userRole();
	}, []);

	const userRole = async () => {
		const userId = store.model.user && store.model.user.id;
		if (userId) {
			const {
				role: { name },
			} = await store.model.RoleMapping.findOne({
				where: { principalId: store.model.user.id },
				include: 'role',
			});
			(name === 'admin' || name === 'press') && setShowCounter(true);
		}
	};
	return showCounter;
};
