import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import img from './img/imgnews1.jpg';
import img2 from './img/imgnews2.jpg';
import Slider from 'react-slick';
import sliderArrow from './img/SliderArrow.svg';
import { MediaPreview } from 'root/site/components';
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 1224 })
	return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 1223 })
	return isMobile ? children : null
  }


const PrevArrow = ({ onClick }) => {
  const style = {
    top: '50%',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: '1',
    left: '-5%',
  };
  return <ReactSVG src={sliderArrow} style={style} onClick={onClick} />;
};
const NextArrow = ({ onClick }) => {
  const style = {
    top: '50%',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: '1',
    right: '-5%',
    transform: 'rotate(180deg)',
    color: "green",
  };
  return <ReactSVG src={sliderArrow} style={style} onClick={onClick} />;
};



export default class PlenaryGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
      }
    
      componentDidMount() {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2
        });
      }
    
      render() {
        
        const settings = {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />
          };
          const settings2 = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            };

        return (
          <div className="mt-md-5 pb-5">
            <Desktop>
            <Slider {...settings}
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
            >
              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img2}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img2}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>
              
                
              
            </Slider>
            
            <Slider
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={5}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-mini" src={img}></img>
			      	</div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-mini" src={img2}></img>
			      	</div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-mini" src={img}></img>
			      	</div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-mini" src={img2}></img>
			      	</div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-mini" src={img}></img>
			      	</div>
                
            </Slider>
            </Desktop>
            <Mobile>
            <Slider {...settings2}
            >
              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img2}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img2}></img>
				      </div>

              <div className="plenary-slider__wrapper mb-4">
				        <img className="plenary-slider__images-main" src={img}></img>
				      </div>
              
            </Slider>
            </Mobile>
          </div>
        );
      }
    }