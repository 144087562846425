import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Tabs, Tab } from '../../components/route-tabs';
import List from './List';
import Single from './Single';
import PlenaryQuestions from './PlenaryQuestions';
import CouncilQuestions from './CouncilQuestions';
import Gallery from './Gallery';
import store from '../../store';
import './style.scss';

const SessionsTabs = () => <Tabs className="sessions-tabs">
	<Tab path="/sessions/plenary" exact title="Пленарные заседания"><List type="plenary" /></Tab>
	<Tab path="/sessions/council" exact title="Заседания Совета"><List type="council" /></Tab>
</Tabs>;

export default () => {

	useEffect(()=> {
		return ()=> {
			store.ui.setBreadcrumbs([])
		}
	},[])

	return <div className="container base-page session-page">
		<Route path="/sessions/plenary" exact render={() => <SessionsTabs />}/>
		<Route path="/sessions/council" exact render={() => <SessionsTabs />}/>
		<Switch>
			<Route path="/sessions" exact render={() => <Redirect to="/sessions/plenary"/>} />
			
			{/*<Route path="/sessions/plenary" exact render={() => <List type="plenary" />} />*/}
			<Route path="/sessions/plenary/:id/questions" component={PlenaryQuestions} />
			<Route path="/sessions/plenary/:id/gallery" render={() => <Gallery type="plenary" />} />
			<Route path="/sessions/plenary/:id" render={() => <Single type="plenary"/>} />
			
			{/*<Route path="/sessions/council" exact render={() => <List type="council" />} />*/}
			<Route path="/sessions/council/:id/questions" component={CouncilQuestions} />
			<Route path="/sessions/council/:id/gallery" render={() => <Gallery type="council" />} />
			<Route path="/sessions/council/:id" render={() => <Single type="council"/>} />
		</Switch>
	</div>;
	
}