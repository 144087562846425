import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from '../../store';
import { Formik, Form, Field, FieldArray } from 'formik';
import { ReCaptcha } from '../../components';
import { fio } from '../../utils';
import PollResults from './PollResults';

@observer
export default class Poll extends React.Component {
	@observable poll;
	@observable voting;
	@observable votes;
	@observable voted;

	constructor(props) {
		super(props);
		this.poll = props.poll;
	}

	submit = async ({ checked, custom }) => {
		let votes = [];
		if (Array.isArray(checked)) {
			this.poll.fields().forEach(field => {
				const vote = new store.model.PollVote({ pollId: this.poll.id, pollFieldId: field.id });
				const yesVote = checked.find(e => +e === field.id);
				vote.answer = yesVote ? (field.user ? custom : '1') : '0';
				votes.push(vote);
			});
		} else {
			const yesVote = this.poll.fields().find(e => e.id === +checked);
			const vote = new store.model.PollVote({ pollId: this.poll.id, pollFieldId: yesVote.id, answer: custom || '1' });
			votes.push(vote);
		}
		this.votes = votes;
	};

	sendVotes = async values => {
		const { userIP: ip } = this.props;
		await this.votes.forEach(vote => {
			Object.assign(vote, values);
			vote.ip = ip;
			vote.save();
		});
		await this.props.onVote();
		this.voted = true;
	};

	onCaptchaExpired = () => (this.valid = false);

	render() {
		if (!this.poll) {
			return <div>...</div>;
		}
		const { multiselect, name } = this.poll;
		const pollFields = this.poll.fields();

		const pollForm = (
			<Formik
				initialValues={{
					checked: [],
					custom: '',
				}}
				className="poll"
				onSubmit={async (values, actions) => {
					this.submit(values);
				}}
			>
				{({ values }) => (
					<Form>
						<div className="poll-form">
							{pollFields.map(field =>
								field.user ? (
									<div className="poll-form-field" key={`vote-${field.id}`}>
										<Field name="checked" value={`${field.id}`} type={multiselect ? 'checkbox' : 'radio'} />
										<label>Ваш вариант</label>
										{(values.checked === field.id + '' || values.checked.indexOf(field.id + '') !== -1) && (
											<Field name="custom" placeholder="Укажите ваш вариант ответа" className="custom" />
										)}
									</div>
								) : (
									<div className="poll-form-field" key={`vote-${field.id}`}>
										<Field name="checked" value={`${field.id}`} type={multiselect ? 'checkbox' : 'radio'} />
										<label>{field.name}</label>
									</div>
								)
							)}
						</div>
						<button className="red-btn" type="submit" disabled={values.checked.length === 0}>
							Голосовать
						</button>
					</Form>
				)}
			</Formik>
		);

		const userForm = () => {
			const { user } = this.props;
			if (user) {
				const { phone, email } = user;
				const name = fio(user);
				this.sendVotes({ name, email, phone });
				return null;
			}
			return (
				<div className="poll-form">
					<h5>Укажите ваши контактные данные</h5>
					<Formik
						initialValues={{
							name: '',
							email: '',
							phone: '',
						}}
						onSubmit={async (values, actions) => {
							this.sendVotes(values);
						}}
					>
						{({ values: { name, email, phone }, handleSubmit }) => (
							<Form>
								<div className="poll-form-user">
									<Field name="name" placeholder="Ф.И.О." />
									<Field name="email" placeholder="Email" />
									<Field name="phone" placeholder="Телефон" />
								</div>
								{(name || email || phone) && (
									<div className="poll-captcha">
										<ReCaptcha onChange={handleSubmit} onExpired={this.onCaptchaExpired} />
									</div>
								)}
							</Form>
						)}
					</Formik>
				</div>
			);
		};

		if (this.voted) {
			return <PollResults poll={this.poll} />;
		}
		return (
			<div className="poll">
				<h4>Пожалуйста пройдите опрос</h4>
				<p className="name">{name}</p>
				{!this.voted && !this.votes && pollForm}
				{this.votes && !this.voted && userForm()}
			</div>
		);
	}
}
