import React from 'react';
import { Loader } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Pager } from '@smartplatform/ui';
import { Calendar, ArticleList } from '../../components';
import moment from 'moment';
import store from '../../store';

const PER_PAGE = 10;

@observer
class DirectSpeechList extends React.Component {
	@observable records;
	@observable tags;
	@observable page;

	type = 'direct-speech';
	componentDidMount() {
		window.scrollTo(0, 0);
		this.init();
	}

	init = async () => {
		const { records, page } = await store.ui.getTagsWithRecords({
			model: 'Article',
			modelType: this.type,
			include:['tags']
		});
		this.records = records;
		this.page = page;
	};

	render() {
		return (
			<div>
				<div className="container">
					<div className="row mb-4 mt-5">
						<div className="col-6">
							<h2 className="header-2">Прямая речь</h2>
						</div>
						<div className="col-6">
							<Calendar />
						</div>
					</div>
				</div>

				{this.records ? (
					<>
						<ArticleList records={this.records} type={this.type} />
						<div className="container pager-div">
							<Pager
								itemsPerPage={PER_PAGE}
								totalCount={this.records.totalCount}
								current={this.page}
								onChange={page => {
									store.route.push({ params: { ...store.route.params, page: page } });
								}}
								className="iltumen-pager"
								directChange={true}
							/>
						</div>
					</>
				) : (
					<Loader />
				)}
			</div>
		);
	}
}
export default DirectSpeechList;
