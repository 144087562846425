import React, { useState, useEffect } from 'react';
import store from '../../store';

export const delay = 3000;

export const useSubscription = ({ values, type }) => {
	const [message, setMessage] = useState();
	useEffect(() => {
		values && sendForm();
	}, [values]);

	const sendForm = async () => {
		const { email } = values
		const checkuser = await store.model.Subscription.find({
			where: { name: email },
		});
		if (checkuser && checkuser.length > 0) {
			setMessage({ text: 'Такой email уже подписан', ok: false });
			setTimeout(() => {
				setMessage();
			}, delay);
			return false;
		}
		const newSubscriber = new store.model.Subscription({
			name: email, news: true, ['direct-speech']: true,
			photo: true, video: true
		});
		if (newSubscriber) {
			newSubscriber.lastSendAt = new Date()
			await newSubscriber.save();
			setMessage({ text: 'Вы успешно подписались', ok: true })
		}
		setTimeout(() => {
			setMessage();
		}, delay);
	}
	return message && { text: message.text, ok: message.ok };
};
