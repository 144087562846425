import React, { useEffect, useState } from 'react';
import { DropDownList } from 'root/site/components';
import store from 'root/site/store';
import HtmlValue from '@smartplatform/ui/src/components/editor/HtmlValue';
import { Link } from 'react-router-dom';
import { formatDateStr } from '../profile/tools';

import './style.scss';

export default function GovernanceList() {
	const [list, setList] = useState([]);

	useEffect(() => {
		(async function() {
			const l = await store.model.Governance.find({
				include: [
					{
						relation: 'deputy',
						scope: {
							include: ['articles'],
						},
					},
				],
			});
			setList(l);
		})();
	}, []);

	return (
		<>
			{list.map(l => (
				<div key={l.id} className="row pb-5 pt-5">
					<div className="col-lg-2 col-md-3 col-sm-4 col-12">
						<img src={l.image} width="140px" />
					</div>
					<div className="col-lg-10 col-md-9 col-sm-8 col-12">
						<div className="note-page-title1 mb-5">
							<h2 className="header-2">
								<Link to={`/deputies/${l.deputyId}`}>{l.name}</Link>
								{/*<Link to={`/governance/${l.id}`}>{l.name}</Link>*/}
							</h2>
						</div>
						<div className="pb-5 governance-single">
							<HtmlValue value={l.contacts} />
							{/* {l.deputy && l.deputy.articles().length > 0 && (
								<div className="related-articles">
									<p>
										<strong>Статьи:</strong>
									</p>
									{l.deputy.articles().map(article => (
										<div key={article.id} className="article-preview">
											<img
												alt=""
												className="image"
												src={
													article.imported
														? `/api/containers/articles/download/${article.image}`
														: article.downloadFile('thumbnail')
												}
											/>
											<div className="date">{formatDateStr(article.publishAt)}</div>
											<Link to={`/news/${article.id}`}>{article.name}</Link>
										</div>
									))}
								</div>
							)} */}
						</div>
					</div>
				</div>
			))}
		</>
	);
}
