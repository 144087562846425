import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Loader } from '@smartplatform/ui';
import { Button } from '../button';
import voting from '../store-site';
import './style.scss';

@observer
export default class TypeSelect extends React.Component {
	
	@observable isLoading = false;

	constructor(props) {
		super(props);
	}
	
	onNameMount = el => {
		if (el) el.focus();
	};
	
	onCustomTypeChange = e => voting.customType = e.target.value;
	
	startDiscussion = async () => {
		this.isLoading = true;
		await voting.startDiscussion();
		this.isLoading = false;
	};
	
	render() {
		let code = 'question';
		if (voting.preselectedQuestion && voting.preselectedQuestion.code === 'procedural') code = 'procedural';
		if (voting.preselectedQuestion && voting.preselectedQuestion.code === 'agenda') code = 'agenda';
		
		const newSelected = voting.currentStage && voting.currentStage.voteStart && !voting.currentStage.voteEnd && !voting.currentStage.canceledAt ?
			voting.currentStage && voting.currentStage.typeId === null
			:
			voting.preselectedStage === 'new';
		
		const disabled = (voting.currentStage && voting.currentStage.voteStart && !voting.currentStage.voteEnd && !voting.currentStage.canceledAt) ||
			(!voting.currentQuestion && !voting.preselectedQuestion) ||
			voting.isEndingVoting;
		
		return <div className={'admin-types-list' + (disabled ? ' disabled' : '')}>
			<div className="list">

				{voting.questionTypes
					.filter(type => type.code === code)
					.map(type => {
						const selected = voting.preselectedStage === type;
						const current = voting.currentStage && voting.currentStage.typeId === type.id && voting.preselectedQuestion === voting.currentQuestion;
						const className = classNames('type', {
							selected,
							current,
						})
						const finishBtn = <Button size={24} className="finish-btn" variant="default" onClick={() => voting.cancelQuestion(voting.currentQuestion)}>Завершить</Button>;
						const startBtn = <Button size={24} onClick={this.startDiscussion} disabled={this.isLoading}>Начать</Button>;
						let btn = null;
						if (!voting.isEndingVoting) {
							if (current) {
								if (!voting.currentStage.canceledAt && !voting.currentStage.voteEnd) {
									btn = finishBtn;
								}
								else if (selected) {
									btn = startBtn;
								}
							}
							else if (selected && !voting.isEndingVoting) {
								if (voting.currentStage) {
									if (!voting.currentStage.voteStart || voting.currentStage.canceledAt || voting.currentStage.voteEnd) {
										btn = startBtn;
									}
								} else {
									btn = startBtn;
								}
							}
						}
						return <div key={type.id} className={className} onClick={() => voting.preselectType(type)}>
							<div className="name">{type.name}</div>
							<div className="actions">
								{this.isLoading && selected && <Loader size={18} style={{ marginRight: 5 }} />}
								{btn}
							</div>
						</div>;
					})
				}
				
				<div key="new" className={'type' + (newSelected ? ' selected' : '')} onClick={() => voting.preselectType('new')}>
					<div className="name">
						Другое
						{newSelected && <input type="text" className="custom-type" ref={this.onNameMount} value={voting.customType} onChange={this.onCustomTypeChange} />}
					</div>
					{!voting.isEndingVoting && <div className="actions" style={{ paddingRight: 5 }}>
						{newSelected ?
							voting.currentStage && !voting.currentStage.voteEnd && !voting.currentStage.canceledAt && !voting.currentStage.typeId ?
								<Button size={24} onClick={() => voting.cancelQuestion(voting.currentQuestion)}>Завершить</Button>
								:
								<Button size={24} onClick={voting.startDiscussion}>Начать</Button>
							:
							''
						}
					</div>}
				</div>
	
			</div>
		</div>;
	}
	
}
