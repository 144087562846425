import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import QuestionSelect from './QuestionSelect';
import TypeSelect from './TypeSelect';
import CurrentQuestion from './CurrentQuestion';
import Panel from '../panels/Panel';
import voting from '../store-site';

import './style.scss';

@observer
export default class Admin extends React.Component {

	static propTypes = {};
	static defaultProps = {};
	
	constructor(props) {
		super(props);
	}
	
	render() {
		return <div className="admin-main container base-page">
			<QuestionSelect />
			<TypeSelect />
			<CurrentQuestion />
		</div>;
	}
	
}
