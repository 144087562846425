import React from "react";
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { DatePicker } from '@smartplatform/ui';
import './style.scss';
import SearchList from './SearchList';
import store from '../../store';

export const dateFormat = 'dd.MM.yyyy';

@observer
export default class Search extends React.Component {

	@observable search = '';
	@observable actualSearch = '';
	@observable date0 = null;
	@observable date1 = null;

	constructor(props) {
		super(props);
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	componentDidMount() {
		this.search = store.route.params ? (store.route.params.query || '') : '';
		this.date0 = store.route.params && store.route.params.date0 ? parse(store.route.params.date0, dateFormat, new Date()) : null;
		this.date1 = store.route.params && store.route.params.date1 ? parse(store.route.params.date1, dateFormat, new Date()) : null;
	}

	onSearchMount = el => el && setTimeout(() => el.focus(), 0);

	onSearchChange = e => this.search = e.target.value;
	onDate0Change = date => this.date0 = date;
	onDate1Change = date => this.date1 = date;

	doSearch = () => {
		store.route.params = {};
		store.route.params.query = this.search.trim();
		store.route.params.date0 = this.date0 ? format(new Date(this.date0), dateFormat) : undefined;
		store.route.params.date1 = this.date1 ? format(new Date(this.date1), dateFormat) : undefined;
		store.route.push();
	};

	render() {
	    const entities = [
	        {title: 'Депутаты', model: 'Deputy', path: 'deputies', dataField:'startDate', titleFields: ['lastName', 'firstName', 'middleName'], excludeFields: ['photo'], fields: ['bio', 'contacts', 'description', 'fractionInfo', 'divisionText', 'laws']},
	        {title: 'Новости', model: 'Article', path: 'news', titleFields: ['name'], dateField: 'publishAt', excludeFields: [], order: 'publishAt DESC', filter: {field: 'type', values: [{value: 'news', title: 'Новости'}]}, fields: ['contentTop']},
	        {title: 'Прямая речь', model: 'Article', path: 'news', titleFields: ['name'], dateField: 'publishAt', excludeFields: [], order: 'publishAt DESC', filter: {field: 'type', values: [{value: 'direct-speech', title: 'Прямая речь'}]}, fields: ['contentTop']},
	        {title: 'Фото', model: 'Gallery', path: 'photogallery', titleFields: ['name'], dateField: 'publishAt', excludeFields: ['thumbnail'], fields: []},
			{title: 'Видео', model: 'Media', path: 'videogallery', titleFields: ['name'], dateField: 'publishAt', excludeFields: ['thumbnail'], fields: []},
	    ];

	    return <div className="container search-page">
	        <h1>Поиск</h1>

		    <div className="row search-form">
		        <div className="search-div col-lg-3 col-md-3 col-sm-3 col-12">
			        <input type="text" value={this.search} onChange={this.onSearchChange} ref={this.onSearchMount} placeholder="текст" />
			        {/*<input type="text" value={store.route.params.query} onChange={this.onSearchChange} ref={this.onSearchMount} placeholder="текст" />*/}
		        </div>
			    <div className="search-div col-lg-2 col-md-3 col-sm-2 col-6">
				    <DatePicker value={this.date0} onChange={this.onDate0Change} disabled={this.search.trim().length === 0} placeholder="с даты" />
			    </div>
		        <div className="search-div col-lg-2 col-md-3 col-sm-2 col-6">
			        <DatePicker value={this.date1} onChange={this.onDate1Change} disabled={this.search.trim().length === 0} placeholder="по дату" />
		        </div>
			    <div className="search-div col-lg-3 col-md-3 col-sm-3 col-12">
		            <button className="red-btn" onClick={this.doSearch} disabled={this.search.trim().length === 0}>Поиск</button>
			    </div>
		    </div>

		    {store.route.params && store.route.params.query && store.route.params.query.length > 0 &&
			    <div>
				    {entities.map((e, i) => <SearchList {...e} key={i} />)}
			    </div>
		    }

	    </div>
	}
}
