import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatDateStr } from '../../tools';
import store from '../../../../store';

@observer
export default class Appointment extends React.Component {
	
	@observable appointment = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.id = this.props.match.params.id;
		this.appointment = await store.model.Appointment.findById(this.id, {
			include: ['deputy'],
		});
	};
	
	render() {
		if (!this.appointment) return null;
		
		return <div className="appointment-page">
			<p>Заявка № <strong>{this.id}</strong> от <strong>{formatDateStr(this.appointment.createdAt)}</strong></p>
			
			<p><label>Депутату:</label>&nbsp;
				<Link to={`/deputies/${this.appointment.deputyId}`}>
					{this.appointment.deputy.lastName} {this.appointment.deputy.firstName} {this.appointment.deputy.middleName}
				</Link>
			</p>
		</div>;
	}
	
}
