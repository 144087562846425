import React from 'react';
import store from '../../store';

import { Popup, Loader } from '@smartplatform/ui';

import './styles.scss';
import { observer } from 'mobx-react';

// import './styles.scss';
@observer
class ImagePreview extends React.Component {
	// cosnole.log(store.ui.previewImgSrc)

	onClose = () => {
		store.ui.setPreviewImgSrc();
	};
	render() {
		return (
			<div>
				{store.ui.previewImgSrc && (
					<div>
						<Popup onClose={this.onClose} className="imamge-preview-popup">
							<div>
								<img
									alt={store.ui.previewImgSrc}
									className="image-preview"
									src={store.ui.previewImgSrc}
									onClick={this.onClose}
								/>
							</div>
						</Popup>
					</div>
				)}
			</div>
		);
	}
}
export default ImagePreview;
