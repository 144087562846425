import store from '../../../store';

export default async () => {
	if (!store.model.user) return [];
	if (!store.model.RoleMapping || !store.model.Role) return [];
	
	const roleMappings = await store.model.RoleMapping.find({
		where: {
			prinicalType: 'USER',
			principalId: store.model.user.id,
		},
		include: ['role']
	});
	return roleMappings.filter(rm => !!rm.role).map(rm => rm.role.name);
};
