import React from 'react';
import { Accordion } from 'root/site/components';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
const AnalyticalMaterials = () => {
	
	return (
		<>
        <div className="container">
			<div className="row pb-5 pt-5">
			<div className="col-12 pt-3 pb-5">
				<Accordion title="Тематические информационно-аналитические сборники">
					<p><a>Сборник "Меры государственной поддержки отдельным категориям граждан, проживающим на территории Республики Саха (Якутия)" (на январь 2019) (ppt, 4,62МБ)</a></p>
				</Accordion>
                <Accordion title="Информационно-аналитические записки">
                    <Accordion title="Региональное развитие Республики Саха (Якутия) в сравнении с субъектами Дальневосточного федерального округа">
                        <p><a>Аналитическая записка "Региональное развитие Республики Саха (Якутия) за январь-март 2019 года в сравнении с субъектами Дальневосточного федерального округа" (PDF, 787 Кб)</a></p>
                    </Accordion>
                    <Accordion title="Социально-экономическая ситуация в Республике Саха (Якутия)"></Accordion>
                    <Accordion title="Социально-экономическое развитие районов и городов Республики Саха (Якутия)"></Accordion>
				</Accordion>
				<Accordion title="Исполнение государственного бюджета Республики Саха (Якутия)">
				<p>Краткая справка об исполнении государственного бюджета РС(Я) на 1 сентября 2019 года</p>
				</Accordion>
				</div>
                <div className="col-12 pt-3 pb-5">
					<ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Итоговые документы парламентских слушаний и круглых столов</a></p>
				</div>
			</div>
		</div>
        </>
    );

};
export default AnalyticalMaterials;
