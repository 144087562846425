import React from 'react';
import ReactSVG from 'react-svg';
import docs from 'root/site/img/docs.svg';
const Publications = () => {
	
	return (
		<>
        
        <div className="container">
			<div className="row pb-5 pt-5">
                <div className="col-12">
            <h2 className="header-2">Издания Государственно-правового управления аппарата Государственного Собрания (Ил Тумэн) Республики Саха (Якутия)</h2>
           
            <div className="publications__docs">
            <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Положение о порядке проведения антикоррупционной экспертизы нормативных правовых актов и проектов нормативных правовых актов Государственным Собранием (Ил Тумэн) Республики Саха (Якутия)</a></p> </div>
            <div className="publications__docs">
            <ReactSVG className="simple-icon simple-icon-dark simple-icon-left" src={docs} />
					<p><a>Методические рекомендации по оформлению нормативных правовых актов</a></p> </div>
            
           
            </div>
            </div>
            </div>
        </>
    );

};
export default Publications;
