import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { ReCaptcha } from '../../components';
import userLogo from '../../img/user.png';
import store from '../../store';
import { Attachments } from '@smartplatform/ui';
import { Formik, Form, Field } from 'formik';
import { fio, formatDateStr } from '../../utils';

const colors = [
	'#a4c400',
	'#60a917',
	'#008a00',
	'#00aba9',
	'#1ba1e2',
	'#0050ef',
	'#6a00ff',
	'#aa00ff',
	'#f472d0',
	'#d80073',
	'#a20025',
	'#e51400',
	'#fa6800',
	'#f0a30a',
	'#d8c100',
	'#825a2c',
	'#6d8764',
	'#7a3b3f',
	'#647687',
	'#76608a',
];
@observer
export default class DiscussionComments extends React.Component {
	@observable comments;
	@observable done;
	@observable comment;
	@observable notRobot;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.comment = false;
		this.done = false;
		const { discussionId, user } = this.props;
		const comments = await store.model.Comment.find({
			where: {
				publish: true,
				// sended: true,
				discussionId,
			},
			include: ['attachments'],
			order: 'id desc',
		});
		user && (this.notRobot = true);
		this.comments = comments;
	};

	@action onSubmit = async values => {
		const { user, discussionId } = this.props;
		console.log('--- comment', this.comment);
		if (this.comment) {
			this.comment.sended = true;
			await this.comment.save();
			this.done = true;
			return;
		}
		const comment = new store.model.Comment();
		let roleId;
		console.log('new comment', comment);
		Object.assign(comment, values);

		if (user) {
			const rolesName = await store.model.user.getRoleNames();
			console.log('rolesName', rolesName);
			const role = rolesName.length > 0 && (await store.model.Role.findOne({ where: { name: rolesName[0] } }));
			console.log('role', role);
			roleId = role && role.id;
			comment.name = fio(user);
		}

		comment.discussionId = discussionId;
		roleId && (comment.roleId = roleId);
		await comment.save();
		this.comment = comment;
		console.log('saved comment', this.comment);
	};

	render() {
		const { user } = this.props;
		console.log('got user', store.model.user);
		if (!this.comments) {
			return <div>...</div>;
		}
		const comments = this.comments.map(comment => {
			return (
				<div key={comment.id} className="comment">
					<div className="avatar">
						<img src={userLogo} width={44} height={44} />
					</div>
					<div className="right">
						<div className="name" style={{ color: colors[comment.roleId] }}>
							{comment.name} {comment.roleId}
						</div>
						<div className="date">{formatDateStr(comment.createdAt)}</div>
						<div
							className="text"
							dangerouslySetInnerHTML={{ __html: comment.text.replace(/\n/g, '<br/>') }}
						/>
						<div>
							{comment.attachments().length > 0 &&
								comment.attachments().map(attach => (
									<p key={`attachment-${attach.id}`}>
										<a
											download
											href={attach.downloadFile('filename')}
											key={`attachment-${attach.id}`}
										>
											{attach.filename}
										</a>
									</p>
								))}
						</div>
					</div>
				</div>
			);
		});

		const renderForm = () => {
			console.log('--- comment', this.comment);
			console.log('not robot', this.notRobot);

			if (!this.done) {
				return (
					<div className="comment-form">
						<p>Оставьте комментарий</p>
						<Formik
							initialValues={{ name: '', text: '' }}
							onSubmit={async (values, actions) => {
								console.log('submit values', values);
								values.commentId = true;
								await this.onSubmit(values);
							}}
						>
							{({ values }) => (
								<Form>
									{!user && (
										<div className="form-field">
											<Field placeholder="Ф.И.О." name="name" />
										</div>
									)}
									<div className="form-field">
										<Field as="textarea" placeholder="Текст комментария" rows={7} name="text" />
									</div>
									{this.comment && this.comment.id ? (
										store.model.user ? (
											<Attachments
												record={this.comment}
												relation="attachments"
												store={store.model}
											/>
										) : (
											<div>Для прикрепления файлов, пожайлуста зарегистрируйтесь</div>
										)
									) : (
										<div> Сохраните комментарий для прикрепления приложений</div>
									)}
									{values.text !== '' && (
										<div className="poll-field-buttons">
											{!this.notRobot && this.comment && (
												<div>
													<ReCaptcha
														onChange={() => (this.notRobot = true)}
														onExpired={this.onCaptchaExpired}
													/>
												</div>
											)}
											{!this.comment && (
												<button className="red-btn" type="submit">
													Создать
												</button>
											)}
											{this.notRobot && this.comment && (
												<button className="red-btn" type="submit">
													Отправить
												</button>
											)}
										</div>
									)}
								</Form>
							)}
						</Formik>
					</div>
				);
			} else {
				return (
					<div className="done">
						Спасибо. Ваш комментарий будет опубликован после процедуры премодерации.
						<a href="#" onClick={this.init}>
							Назад
						</a>
					</div>
				);
			}
		};

		return (
			<>
				{this.props.isEnded && renderForm()}
				<div className="comments">
					<p>Комментарии</p>
					{comments}
				</div>
			</>
		);
	}
}
